import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {SimplePaginator} from '../../utils/paginator/simple-paginator';
import {Project} from 'api/models/asset/project';
import {ApiService} from '../../services/api.service';
import { Subscription} from 'rxjs';
import {SearchStateService} from '../../services/search-state.service';
import {MobileService} from '../../services/mobile.service';

@Component({
    selector: 'app-search-overview',
    templateUrl: './search-overview.component.html',
    styleUrls: ['./search-overview.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SearchOverviewComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    isOnMobile = false;
    overviewTiles: { label: string; route: string[]; srcset: string[] }[] = [
        {
            label: 'VolkerWessels projecten',
            route: ['/projects'],
            srcset: [
                '/assets/images/projectsW400.jpg 400w',
                '/assets/images/projectsW800.jpg 800w'
            ]
        },
        {
            label: 'Bedrijven',
            route: ['/companies'],
            srcset: [
                '/assets/images/companiesW400.jpg 400w',
                '/assets/images/companiesW800.jpg 800w'
            ]
        },
    ];
    recentProjectsPaginator = new SimplePaginator<Project>((page, pageSize, query, favorite, sort, filter) => {
        return this.apiService.filter<Project>('project', '', query, favorite, sort, page, pageSize, filter);
    }, 4);

    collections$ = this.apiService.getCollections(0, 3);

    favoriteProjectsPaginator = new SimplePaginator<Project>((page, pageSize, query, favorite, sort, projectFilter) => {
        return this.apiService.filter('project', '', '', true, sort, page, pageSize, projectFilter);
    }, 3);

    constructor(
        private apiService: ApiService,
        private mobileService: MobileService,
        private searchStateService: SearchStateService
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    ngOnInit(): void {
        this.recentProjectsPaginator.setSort('-aangemaakt_op');
        this.subscriptions.push(
            this.apiService.allCompaniesAndProjectsForMap$.subscribe(points => {
                this.searchStateService.pointResults$.next(points);
            }),
            this.mobileService.isOnMobile$.subscribe(isOnMobile => {
                this.isOnMobile = isOnMobile;
                this.favoriteProjectsPaginator.setPageSize(isOnMobile ? 2 : 3);
            })
        );
    }

}
