import {Component, HostBinding} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Params, Router, RouterLinkActive} from '@angular/router';
import {InformationBarService} from '../../services/information-bar.service';
import {PinnedLocationService} from '../../services/pinned-location.service';
import {MapboxService} from 'src/app/services/mapbox/mapbox.service';
import {BUILD_NUMBER} from '../../../environments/version';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: false
})
export class NavbarComponent {
    @HostBinding('class.hidden')
    hidden = false;
    buildNumber = BUILD_NUMBER;

    // Add explicit undefined queryParams to make sure filters query etc are removed when navigation from the navbar
    queryParams: Params = {
        item: undefined,
        query: undefined,
        filter: undefined,
        favorite: undefined,
        page: undefined,
        id: undefined,
        type: undefined
    };

    routes: { route: string; icon: string; tooltip: string }[][] = [
        [
            {
                route: '/search',
                icon: '/assets/icons/icon-24-search.svg',
                tooltip: 'Zoeken'
            },
            {
                route: '/projects',
                icon: '/assets/icons/icon-project.svg',
                tooltip: 'Projecten'
            },
            {
                route: '/companies',
                icon: '/assets/icons/icon-company.svg',
                tooltip: 'Bedrijven'
            }
        ],
        [
            {
                route: '/apps',
                icon: '/assets/icons/icon-24-apps.svg',
                tooltip: 'Apps'
            }
        ],
        [
            {
                route: '/layers',
                icon: '/assets/icons/icon-24-layers.svg',
                tooltip: 'Kaartweergave'
            }
        ]
    ];

    initials$ = this.authService.initials$;

    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private mapBoxService: MapboxService,
        public infoBarService: InformationBarService,
        public pinnedLocationService: PinnedLocationService
    ) {
    }

    onNavClick(link: RouterLinkActive) {
        // Navigate to / when user clicks on active link to make nav "collapse"
        if (link.isActive) {
            this.router.navigate([''], {
                queryParams: {
                    item: null,
                    type: null
                }, queryParamsHandling: 'merge'
            });
            return;
        }
    }

    async resetApplicationState(event: Event): Promise<void> {
        event.preventDefault();
        await this.router.navigate([''], {
            queryParams: {
                item: null,
                type: null
            }, queryParamsHandling: 'merge'
        });
        this.mapBoxService.flyTo({
            center: {lat: 52.24, lon: 5.13},
            zoom: 7,
            pitch: 0,
            bearing: 0
        });
    }
}
