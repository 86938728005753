module.exports = function flatten(list) {
  return _flatten(list);
  function _flatten(list) {
    if (Array.isArray(list) && list.length && typeof list[0] === 'number') {
      return [list];
    }
    return list.reduce(function (acc, item) {
      if (Array.isArray(item) && Array.isArray(item[0])) {
        return acc.concat(_flatten(item));
      } else {
        acc.push(item);
        return acc;
      }
    }, []);
  }
};