import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {SelectionService} from '../../services/selection.service';
import {combineLatest, lastValueFrom, Subscription} from 'rxjs';
import {UserSelected} from 'api/models/user-selected';
import {CollectionsService} from '../../services/collections.service';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {Collection} from 'api/models/collection';
import {filter} from 'rxjs/operators';
import {ModalService} from '../../services/modal.service';

@Component({
    selector: 'app-collections-create',
    templateUrl: './collections-create.component.html',
    standalone: false
})
export class CollectionsCreateComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private innerSubscription: Subscription = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private collectionService: CollectionsService,
        private mapboxService: MapboxService,
        private modalService: ModalService,
        private router: Router,
        private selectionService: SelectionService,
    ) {
    }

    ngOnInit() {
        this.mapboxService.collapseMenu$.next(true);
        this.subscriptions.push(
            this.activatedRoute.paramMap.subscribe( paramMap => {
                const collection = JSON.parse(paramMap.get('collection'));
                const selectedProjects: UserSelected[] = collection.items.map((id) => {
                    return {
                        id, icon: '', type: 'project'
                    } as UserSelected;
                });
                selectedProjects.forEach((selected: UserSelected) => {
                    this.selectionService.addSelected(selected);
                });
                this.collectionService.checkSelectedProjects().then(() => {
                    this.innerSubscription =
                        combineLatest([this.collectionService.projectsWithoutPrs$, this.collectionService.projectCount$]).pipe(
                        filter(([projectsWithoutPrs, projectsCount]) => projectsWithoutPrs !== undefined && projectsCount !== undefined))
                        .subscribe(([projectsWithoutPrs, projectsCount]) =>
                        {
                            this.router.navigate(['collections']);
                            if (projectsWithoutPrs.length === 0 && collection.items.length - projectsCount <= 0) {
                                this.addCollection(collection);
                            } else {
                                this.modalService.openCollectionModal(collection.name);
                            }
                        }

                    );
                });
            }),
        );
    }

    addCollection(collection: Collection) {
        try {
            lastValueFrom(this.apiService.postCollection(collection));
        } catch (e) {
            console.error('Create Collection failed', e.message);
        }
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
        this.innerSubscription.unsubscribe();
    }
}
