"use strict";

import a from "../EventProvider.js";
const t = new a(),
  d = "themeLoaded",
  o = e => {
    t.attachEvent(d, e);
  },
  n = e => {
    t.detachEvent(d, e);
  },
  r = e => t.fireEvent(d, e);
export { o as attachThemeLoaded, n as detachThemeLoaded, r as fireThemeLoaded };
