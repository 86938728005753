import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {cleanFilter, isFilterActive} from '../../utils/filter';
import {SelectionService} from '../../services/selection.service';
import {MobileService} from '../../services/mobile.service';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    standalone: false
})
export class CompaniesComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    form = new UntypedFormGroup({
        query: new UntypedFormControl(''),
        filter: new UntypedFormControl({})
    });

    kvwNumbers$ = this.apiService.kvwNumbers$;

    constructor(
        private mobileService: MobileService,
        private router: Router,
        private route: ActivatedRoute,
        private selectionService: SelectionService,
        private apiService: ApiService
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.route.queryParamMap.subscribe(queryParams => {
                const filter = JSON.parse(queryParams.get('filter') || '{}');
                if (queryParams.has('favorite')) {
                    filter.favorite = queryParams.get('favorite') === 'true';
                }

                this.form.patchValue({
                    query: queryParams.get('query'),
                    filter
                }, {emitEvent: false});
            }));
        this.subscriptions.push(this.form.valueChanges.subscribe(formValue => {
            const {favorite, ...filter} = formValue.filter;
            const searchActive = formValue.query || isFilterActive(filter) || favorite;
            const currentQueryParamsMap = this.route.snapshot.queryParamMap;

            if (
                currentQueryParamsMap.get('query') === formValue.query
                && (currentQueryParamsMap.get('filter') || '{}') === JSON.stringify(cleanFilter(filter))
                && currentQueryParamsMap.get('favorite') === (favorite || false).toString()
            ) {
                return;
            }

            this.router.navigate(
                searchActive ? ['/companies/search'] : ['/companies'],
                {
                    relativeTo: this.route,
                    queryParamsHandling: 'merge',
                    queryParams: searchActive ? {
                        query: formValue.query,
                        filter: JSON.stringify(cleanFilter(filter)),
                        favorite: favorite || undefined
                    } : {
                        query: undefined,
                        filter: undefined,
                        favorite: undefined
                    }
                }
            ).catch(e => {
                console.error('search failed', e);
            });
        }));
    }

    ngOnDestroy(): void {
        this.selectionService.disable();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onSearchClear() {
        this.form.setValue({query: '', filter: {}});
    }

    scrollToTop() {
        this.mobileService.scrollToTop();
    }
}
