<div class="flex flex-grow">
  @if (!dropdownOpen) {
    <button (click)="onDropdownOpenClick()" type="button"
      class="text-xl rounded py-2 font-medium hover:bg-neutral-4"
    >{{collection.name}}</button>
  } @else {
    <form [formGroup]="renameCollectionForm" class="flex flex-grow space-x-1">
      <vwui-input class="flex flex-grow" formControlName="name" placeholder="Vul hier de nieuwe collectie naam in"></vwui-input>
      <button (click)="cancelRenameCollection()"
        class="test-cancel-button w-12 self-stretch rounded bg-white border rounded border-neutral-2" inlineSVG="/assets/icons/icon-16-cross.svg">
      </button>
      <button [ngClass]="{'cursor-not-allowed' : !renameCollectionForm.valid || pendingSubmit}"
        [disabled]="!renameCollectionForm.valid || pendingSubmit" (click)="renameCollection()"
        class="test-submit-button w-12 self-stretch text-white rounded bg-gradient-to-r from-primary to-primary-light disabled:opacity-50"
        inlineSVG="/assets/icons/checkbox.svg"
      ></button>
    </form>
  }
</div>
