<div class="extensions-panel__header items-center flex">
  <a routerLink="/apps" queryParamsHandling="preserve" class="inline-block w-5 cursor-pointer"
  inlineSVG="/assets/icons/icon-arrow-left.svg"></a>
  <span class="ml-4 mr-3"></span>
  Kaartenservice
  <button class="ml-auto mt-1 text-neutral-1 text-sm"><span class="mr-1" inlineSVG="/assets/icons/icon-info.svg"></span>Hulp nodig?</button>
</div>
<hr>
  <div class="px-6 py-4 border-b border-white-2">
    @if (!submitted) {
      <label class="form-label">Type selectie</label>
      <div class="flex">
        <span class="extensions-panel__item__icon extensions-panel__item__icon--btn mr-3"
          inlineSVG="/assets/icons/icon-draw-rect.svg"
        [class.extensions-panel__item__icon--btn--active]="form.get('selectionMode').value === 'simple'" (click)="setDrawingRect()"></span>
        <span class="extensions-panel__item__icon extensions-panel__item__icon--btn mr-3"
          inlineSVG="/assets/icons/icon-draw-polygon.svg"
          [class.extensions-panel__item__icon--btn--active]="form.get('selectionMode').value === 'advanced'"
        (click)="setDrawingPolygon()"></span>
      </div>
      @if (form.get('selectionMode').value === 'simple') {
        <p class="extensions-panel__description">
          Zet het vierkant op de kaart om een gebied te selecteren en verklein of vergroot dit gebied door de hoeken te verslepen,
          daarna kunt u aangeven waar de geëxporteerde data naartoe gestuurd moet worden.
        </p>
      }
      @if (form.get('selectionMode').value === 'advanced') {
        <p class="extensions-panel__description">
          Klik op de kaart om een gebied te selecteren en bevestig met Enter of door op het beginpunt te klikken,
          daarna kunt u aangeven waar de geëxporteerde data naartoe gestuurd moet worden.
        </p>
      }
      <h3 class="mt-4">Bij het downloaden ontvangt u de volgende kaartlagen</h3>
      @for (layer of layers; track layer) {
        <div class="py-4 border-b border-neutral-3 bg-white">
          <div class="flex items-center h-14">
            @switch (layer.layerKey) {
              @case ('bgt') {
                <div class="on-0" inlineSVG="/assets/layericons/buildings.svg"></div>
              }
              @case ('buildings') {
                <div class="on-2" inlineSVG="/assets/layericons/buildings.svg"></div>
              }
              @case ('maatvoering') {
                <div class="on-1" inlineSVG="/assets/layericons/maatvoering.svg"></div>
              }
              @default {
                <div class="on" [inlineSVG]="'/assets/layericons/' + layer.layerKey + '.svg'"></div>
              }
            }
            <div class="ml-6 flex-grow">
              <h3>
                {{ layer.title }}
                <button class="ml-auto mt-1 text-neutral-1 text-sm" (click)="openLayerInfoModal($event, layer)">
                  <span class="mr-1" inlineSVG="/assets/icons/icon-info.svg"></span>
                </button>
              </h3>
              <span class="text-neutral">{{ layer.description }}</span>
            </div>
          </div>
        </div>
      }
    }
    @if (submitted) {
      <p class="extensions-panel__description">
        Uw kaartenservice-aanvraag is successvol verstuurd.
        U ontvangt een email op {{ form.value.email }} als uw export klaar staat.
      </p>
      <button type="button" class="export-button" routerLink="/" queryParamsHandling="preserve" >
        <span class="export-button__label">Sluiten</span>
      </button>
    }
  </div>
  @if (!submitted && form.controls.selection.value) {
    <div class="px-6 py-4">
      <form class="map-service-form" [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="form-field">
          <label class="form-label">Geselecteerde oppervlakte</label>
          <div class="form-static">{{ (form.controls.area.value / 1000000) | number }} km2</div>
        </div>
        <div class="form-field">
          <label class="form-label">Bestemming</label>
          <div class="form-option-group">
            <label class="form-option">
              <input class="form-option__input" name="destination" type="radio" value="trimble"
                formControlName="destination">
              <div class="form-option__label">
                Trimble
              </div>
            </label>
            <label class="form-option">
              <input class="form-option__input" name="destination" type="radio" value="zip"
                formControlName="destination">
              <span class="form-option__label">
                Zip Download
              </span>
            </label>
          </div>
        </div>
        @if (form.controls.destination.value === 'trimble' && form.controls['trimbleProject']) {
          <div class="form-field"
            >
            <label class="form-label">Trimble Connect project</label>
            <app-trimble-project-select formControlName="trimbleProject"></app-trimble-project-select>
          </div>
        }
        <div class="form-field">
          <label for="email-field" class="form-label">E-mail bij voltooiing</label>
          <input id="email-field" class="form-input" type="email" placeholder="E-mailadres"
            formControlName="email">
        </div>
        <div class="form-button-container">
          <button (click)="submitForm()"
            type="button"
            [disabled]="!form.valid"
            [ngClass]="{'cursor-not-allowed' : !form.valid || exporting}"
            class="w-full py-3 px-4 text-white rounded bg-gradient-to-r from-primary to-primary-light relative flex gap-4 text-center justify-center font-medium disabled:opacity-50">
            <span [hidden]="exporting">Start</span>
            <span class="w-8" inlineSVG="/assets/icons/spinner.svg" [hidden]="!exporting"></span>
          </button>
        </div>
      </form>
    </div>
  }
