<div class="w-full desktop:h-full overflow-x-hidden desktop:overflow-y-auto" cdkScrollable>
  <div [ngClass]="{'mobile:pb-28': mobileNavbarEnabled$|async}">
    <ng-content></ng-content>
  </div>
</div>
@if (allowResize && !isOnMobile) {
  <app-resize-handle
    (resizeChange)="onResizeChange($event)"
    (resizeEnd)="onResizeEnd()">
  </app-resize-handle>
}
@if (!isOnMobile) {
  <div class="flex items-center justify-content-center absolute top-0 -right-5 h-8 w-5 mt-8 bg-white rounded-r hover:bg-neutral-3 cursor-pointer"
    (click)="toggleCollapsed()">
    <span class="transform text-neutral-1"
      inlineSVG="/assets/icons/navigation-arrow-icon.svg"
      [class.rotate-180]="!collapsed"
    ></span>
  </div>
}
