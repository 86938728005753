import {Component, Input, OnInit} from '@angular/core';
import {Overlay} from '@angular/cdk/overlay';
import {ActivatedRoute} from '@angular/router';
import {SearchStateService} from '../../services/search-state.service';
import {SearchResultType} from 'api/models/search-preference';
import {take} from 'rxjs/operators';
import {FileUtil} from '../../utils/file';
import {ApiService} from '../../services/api.service';
import {ToastrService} from 'ngx-toastr';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ExportProjectModalComponent} from '../export-project-modal/export-project-modal.component';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-export-dropdown',
    templateUrl: './export-dropdown.component.html',
    standalone: false
})
export class ExportDropdownComponent implements OnInit {
    @Input() titleText: string;
    @Input() table: SearchResultType;
    @Input() hasListView = true;
    @Input() hasGridView = true;
    @Input() total = null;
    label: string;
    exportType = 'xlsx';
    loading = false;
    searchState$ = this.searchStateService.searchState$;
    dropdownOpen = false;
    scrollStrategy = this.overlay.scrollStrategies.close();

    constructor(
        private overlay: Overlay,
        private activatedRoute: ActivatedRoute,
        private searchStateService: SearchStateService,
        private apiService: ApiService,
        private toastrService: ToastrService,
        private modalService: VwuiModalService
    ) {
    }

    ngOnInit() {
        this.label = this.table === 'project' ? 'Projecten exporteren' : 'Bedrijven exporteren';
    }

    onDropdownOpenClick() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    onOutsideOverlayClick() {
        // Schedule so closing dropdown occurs after potential onDropdownOpenClick event
        setTimeout(() => this.dropdownOpen = false, 0);
    }

    async onClick() {
        if (this.loading) {
            return;
        }

        let exportParameters = null;

        const searchState = await lastValueFrom(this.searchState$.pipe(take(1)));

        if (this.table === 'project') {
            const modal = this.modalService.open(ExportProjectModalComponent, {
                data: {
                    searchState,
                    total: this.total,
                }
            });
            exportParameters = await lastValueFrom(modal.afterClosed.pipe(take(1)));
        }

        if (exportParameters !== undefined) {
            this.loading = true;

            try {
                const blob = await lastValueFrom(this.apiService.export(
                    this.table,
                    this.exportType,
                    searchState.query,
                    searchState.favorite,
                    searchState.sort,
                    searchState.filter,
                    exportParameters
                ));

                const filename = [
                    searchState.query,
                    this.table
                ].filter(it => !!it).join('_');

                FileUtil.downloadBlobAsFile(blob, `${filename}.${this.exportType}`);
            } catch (error) {
                console.error(error);
                this.toastrService.error('Exporteren mislukt.');
            } finally {
                this.loading = false;
            }
        }
    }
}
