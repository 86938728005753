<div class="page-header">
  @if (goBackPath) {
    <a [routerLink]="goBackPath" queryParamsHandling="preserve" class="page-header__back-link" aria-label="Go back link">
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M19.845 10.615H6.942l5.637-5.637c.45-.45.45-1.19 0-1.64a1.15 1.15 0 0 0-1.629 0L3.338 10.95a1.15 1.15 0 0 0 0 1.629l7.612 7.612c.45.45 1.179.45 1.629 0a1.15 1.15 0 0 0 0-1.628l-5.637-5.637h12.903c.635 0 1.155-.52 1.155-1.156 0-.635-.52-1.155-1.155-1.155Z" clip-rule="evenodd"/></svg>
    </a>
  }
  <div class="page-header__title-group">
    <h1 class="page-title">{{ pageTitle }}</h1>
    @if (pageSubtitle) {
      <h2 class="page-subtitle">{{ pageSubtitle }}</h2>
    }
  </div>
</div>
