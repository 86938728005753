<app-saved-filter-list type="company"></app-saved-filter-list>
@if ((favoriteCompaniesPaginator.content$|async)?.length > 0) {
  <div class="mb-6">
    <div class="pb-4 flex items-center">
      <div class="font-medium text-base mr-auto rounded py-1">
        Favoriete bedrijven
      </div>
      <a [routerLink]="['/companies/search']" [queryParams]="{favorite: true}" queryParamsHandling="merge"
        class="text-primary font-medium ring-2 ring-transparent focus:ring-primary focus:outline-none px-2 py-4 rounded">
        Alle favorieten
      </a>
    </div>
    @if (favoriteCompaniesPaginator.content$|async; as companies) {
      <app-company-tile-grid [companies]="companies"></app-company-tile-grid>
    }
  </div>
}
<div class="relative">
  <div class="pb-4 flex items-center">
    @if (!isOnMobile) {
      <app-export-dropdown titleText="Alle bedrijven" table="company">
      </app-export-dropdown>
    }
    @if (gridView$|async;) {
      <app-pagination-sort class="ml-auto mr-2" [paginator]="allCompaniesPaginator"
      [sortOptions]="companySortOptions" [setSortFromQueryParams]="true" [startOption]="''"></app-pagination-sort>
    }
    <app-results-view-switch class="mobile:hidden" [class.ml-auto]="(gridView$|async) === false">
    </app-results-view-switch>
    <app-pagination class="mobile:hidden" [paginator]="allCompaniesPaginator"></app-pagination>
  </div>
  @if (allCompaniesPaginator.content$|async; as companies) {
    @if ((gridView$|async) || isOnMobile) {
      <app-company-tile-grid [companies]="companies"></app-company-tile-grid>
    } @else {
      <app-company-list [items]="companies"></app-company-list>
    }
  }

  <div class="mt-4 flex items-end flex-col">
    <app-pagination [paginator]="allCompaniesPaginator"></app-pagination>
  </div>

  @if (allCompaniesPaginator.isLoading) {
    <span class="fixed top-96 left-1/2 transform -translate-x-1/2"
    inlineSVG="/assets/icons/loader.svg"></span>
  }
</div>
