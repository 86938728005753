<div class="px-6 mobile:pt-20">
  <div class="pt-8 h-24 mobile:hidden">
    <h1 appTitle="lg" class="m-0">Mijn Collecties</h1>
  </div>
    <div class="flex gap-2 border-t border-b border-neutral-3 py-4 relative
        mobile:fixed mobile:top-0 mobile:inset-x-0 mobile:z-20 mobile:bg-white mobile:px-4">
    <app-collections-search-input
      class="flex-grow"
      placeholder="Collecties doorzoeken"
      [formControl]="queryControl"
      [showBackButton]="true"
      [suggestions]="suggestions$|async"
      (clickedOnSuggestion)="navigateToCollection($event)"
      (searchSubmit)="handleSearch($event)"
    ></app-collections-search-input>
  </div>

  <div class="py-4">
    <div class="pb-4 flex items-center">
      <span class="font-medium text-base mr-auto rounded py-1">Mijn Collecties</span>
      <app-results-view-switch class="ml-auto"></app-results-view-switch>
    </div>
    @if (collectionsPaginator.content$|async; as collections) {
      @if (gridView$|async) {
        <div class="grid grid-cols-searchGrid gap-4">
          @for (collection of collections; track collection) {
            <app-collection-tile [collection]="collection"></app-collection-tile>
          }
          @if (collections.length < 2) {
            <div></div>
          }
          @if (collections.length < 3) {
            <div></div>
          }
        </div>
      } @else {
        <app-collection-list [collections]="collections"></app-collection-list>
      }
      @if (collections.length === 0) {
        <div class="text-center flex items-center flex-col mt-16">
          <span inlineSVG="/assets/icons/box-illustration.svg"></span>
          <span class="mt-6 font-medium text-lg text-darkBlue_2">
            {{queryControl.value ? 'Geen resultaten' : 'Geen collecties'}}
          </span>
          <span class="mt-4 text-neutral-1 w-1/2">
            {{
            queryControl.value
            ? 'Er zijn geen resultaten gevonden'
            : 'Er zijn nog geen collecties aangemaakt, selecteer projecten om een collectie aan te maken.'
            }}
          </span>
        </div>
      }
    } @else {
      <span class="fixed top-96 left-1/2 transform -translate-x-1/2" inlineSVG="/assets/icons/loader.svg"></span>
    }
  </div>
</div>
