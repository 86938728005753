import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-ui-info-box',
    templateUrl: './ui-info-box.component.html',
    styleUrls: ['./ui-info-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UiInfoBoxComponent {
    @Input() boxType: 'info' | 'alert' = 'info';
}
