import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {MapLayer} from '../../model/map-config';
import {ApiService} from '../../services/api.service';
import {Observable} from 'rxjs';
import {ArcgisService} from '../../services/arcgis.service';

@Component({
    selector: 'app-disclaimer-modal',
    templateUrl: './layer-information-modal.component.html',
    standalone: false
})
export class LayerInformationModalComponent {
    importDate$: Observable<string> = this.getImportMetadataByLayerKey(this.modalConfig.data.layerKey);

    constructor(
        public vwuiModalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<MapLayer>,
        private apiService: ApiService,
        private arcgisService: ArcgisService
    ) {}

    close() {
        this.vwuiModalRef.close();
    }

    getImportMetadataByLayerKey(layerKey: string): Observable<string>  {
        switch (layerKey) {
            case 'buildingLayer':
                return this.apiService.getImportMetadata('bag3d');
            case 'lot':
                return this.arcgisService.getLotsImportDate();
            case 'bestemmingsplan':
                return this.arcgisService.getBestemmingsplanImportDate();
            case 'natura2000':
                return this.arcgisService.getNatural2000ImportDate();
            case 'bgt':
            case 'maatvoering':
            case 'poi':
            case 'vwprojects':
                return this.apiService.getImportMetadata(layerKey);
        }
    }

    getImageSourceUrls(fileName: string): string[] {
        return [
            `/assets/layer-information-images/${fileName}.png 100w`,
            `/assets/layer-information-images/${fileName}@2x.png 200w`,
            `/assets/layer-information-images/${fileName}@3x.png 300w`
        ];
    }

    getUrlHostName(url: string) {
        return new URL(url).hostname;
    }

}
