"use strict";

import { DEFAULT_LANGUAGE as e } from "../generated/AssetParameters.js";
const n = /^((?:[A-Z]{2,3}(?:-[A-Z]{3}){0,3})|[A-Z]{4}|[A-Z]{5,8})(?:-([A-Z]{4}))?(?:-([A-Z]{2}|[0-9]{3}))?((?:-[0-9A-Z]{5,8}|-[0-9][0-9A-Z]{3})*)((?:-[0-9A-WYZ](?:-[0-9A-Z]{2,8})+)*)(?:-(X(?:-[0-9A-Z]{1,8})+))?$/i;
class r {
  constructor(s) {
    const t = n.exec(s.replace(/_/g, "-"));
    if (t === null) throw new Error(`The given language ${s} does not adhere to BCP-47.`);
    this.sLocaleId = s, this.sLanguage = t[1] || e, this.sScript = t[2] || "", this.sRegion = t[3] || "", this.sVariant = t[4] && t[4].slice(1) || null, this.sExtension = t[5] && t[5].slice(1) || null, this.sPrivateUse = t[6] || null, this.sLanguage && (this.sLanguage = this.sLanguage.toLowerCase()), this.sScript && (this.sScript = this.sScript.toLowerCase().replace(/^[a-z]/, i => i.toUpperCase())), this.sRegion && (this.sRegion = this.sRegion.toUpperCase());
  }
  getLanguage() {
    return this.sLanguage;
  }
  getScript() {
    return this.sScript;
  }
  getRegion() {
    return this.sRegion;
  }
  getVariant() {
    return this.sVariant;
  }
  getVariantSubtags() {
    return this.sVariant ? this.sVariant.split("-") : [];
  }
  getExtension() {
    return this.sExtension;
  }
  getExtensionSubtags() {
    return this.sExtension ? this.sExtension.slice(2).split("-") : [];
  }
  getPrivateUse() {
    return this.sPrivateUse;
  }
  getPrivateUseSubtags() {
    return this.sPrivateUse ? this.sPrivateUse.slice(2).split("-") : [];
  }
  hasPrivateUseSubtag(s) {
    return this.getPrivateUseSubtags().indexOf(s) >= 0;
  }
  toString() {
    const s = [this.sLanguage];
    return this.sScript && s.push(this.sScript), this.sRegion && s.push(this.sRegion), this.sVariant && s.push(this.sVariant), this.sExtension && s.push(this.sExtension), this.sPrivateUse && s.push(this.sPrivateUse), s.join("-");
  }
}
export default r;
