import {Component, NgZone, OnInit} from '@angular/core';
import {PinnedLocationService} from '../../services/pinned-location.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Verblijfsobject} from 'api/models/asset/verblijfsobject';
import {BehaviorSubject, combineLatest, lastValueFrom} from 'rxjs';
import {filter, take, tap} from 'rxjs/operators';
import {ConnectedPosition, Overlay} from '@angular/cdk/overlay';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {SendLocationModalComponent} from '../send-location-modal/send-location-modal.component';
import {verblijfsobjectToAddress} from '../../utils/verblijfsobject';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchComponent} from '../../pages/search/search.component';
import {ProjectsComponent} from '../../pages/projects/projects.component';
import {CompaniesComponent} from '../../pages/companies/companies.component';
import {MapboxService} from '../../services/mapbox/mapbox.service';

@Component({
    selector: 'app-pinned-location',
    templateUrl: './pinned-location.component.html',
    standalone: false
})
export class PinnedLocationComponent implements OnInit {
    public verblijfsObject$ = new BehaviorSubject<Verblijfsobject>(undefined);
    public loading = false;

    dropdownOpen = false;
    scrollStrategy = this.overlay.scrollStrategies.close();
    connectedPositions: ConnectedPosition[] = [{
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
    }];

    constructor(
        public pinnedLocationService: PinnedLocationService,
        private mapboxService: MapboxService,
        private overlay: Overlay,
        private toastrService: ToastrService,
        private apiService: ApiService,
        private router: Router,
        private modalService: VwuiModalService,
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone) {
    }

    onDropdownOpenClick() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    ngOnInit(): void {
        this.pinnedLocationService.coordinates$.pipe(
            tap(() => this.loading = true),
            filter(coordinates => coordinates !== undefined)).subscribe(
            coordinates => {
                lastValueFrom(this.apiService.filter<Verblijfsobject>('verblijfsobject', '', (coordinates.lat + ', ' + coordinates.lng),
                    false, '', 0, 1, {})
                ).then(results => {
                    this.verblijfsObject$.next(results.value[0]);
                    this.loading = false;
                });

            });
    }

    searchNearby() {
        this.pinnedLocationService.coordinates$.subscribe((coordinates) => {
                this.ngZone.run(() => {
                    const targetRoute = this.getTargetSearchRoute();
                    if (targetRoute !== null) {
                        this.router.navigate(targetRoute, {
                            queryParamsHandling: 'merge',
                            queryParams: {
                                query: `${coordinates.lat}, ${coordinates.lng}`,
                                favorite: undefined,
                                filter: '{}'
                            }
                        }).then(() => {
                            this.mapboxService.collapseMenu$.next(false);
                        });
                    }
                });
            }
        );
    }

    onOutsideOverlayClick() {
        // Schedule so closing dropdown occurs after potential onDropdownOpenClick event
        setTimeout(() => this.dropdownOpen = false, 0);
    }

    copyToClipboard() {
        document.execCommand('copy');
        navigator.clipboard.writeText(
            this.pinnedLocationService.coordinates$?.value.lat.toFixed(6) + ', ' +
            this.pinnedLocationService.coordinates$?.value.lng.toFixed(6)).then(
            () => this.toastrService.info('Coördinaten zijn gekopieerd naar het klembord.')
        );
    }

    emailLocation() {
        this.dropdownOpen = false;
        combineLatest([this.pinnedLocationService.coordinates$, this.verblijfsObject$]).pipe(
            take(1)
        ).subscribe(([coordinates, verblijfsobject]) => {
            this.modalService.open(SendLocationModalComponent, {
                closeOnBackdropClick: true,
                data: {
                    title: `${verblijfsobjectToAddress(verblijfsobject)}, ${verblijfsobject.woonplaatsNaam}`,
                    point: coordinates.toArray()
                }
            });
        });
    }

    getTargetSearchRoute(): string[] {
        const activePage = this.activatedRoute.firstChild?.component;
        if (activePage === undefined || activePage === SearchComponent) {
            return ['/search/all'];
        }
        if (activePage === ProjectsComponent) {
            return ['/projects/search'];
        }
        if (activePage === CompaniesComponent) {
            return ['/companies/search'];
        }
        return null;
    }
}
