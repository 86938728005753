@if (relation$ | async; as relation) {
  <div class="text-base font-medium text-dark truncate">
    <div class="ml-6 py-8 w-5 h-5">
      <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
      (click)="goBack()"></span>
      {{relation.name}}
    </div>
  </div>
  <div class="mx-6 mt-8 mobile:mt-0">
    <app-relation-tile class="block desktop:w-80 mb-4" [relation]="relation"></app-relation-tile>
  </div>
  @if (relation.projectIds.length > 0 && (projects$|async); as projects) {
    <div class="mx-6 mt-8 mobile:mt-0">
      @if (projects.count > 0) {
        <div class="flex items-center pt-3 pb-4 cursor-pointer border-t border-neutral-3"
          >
          <div class="flex cursor-pointer" (click)="projectsExpanded = !projectsExpanded">
            <div class="transform origin-center"
              [ngClass]="projectsExpanded ? 'rotate-90' : '-rotate-90'"
              inlineSVG="/assets/icons/navigation-arrow-icon.svg"
            ></div>
            <span class="font-medium text-base ml-4">Betrokken Projecten</span>
          </div>
          <span class="font-medium text-base text-primary ml-auto" (click)="goToProjects(relation.projectIds)">Alle {{projects.count}}
            projecten
          </span>
        </div>
      }
      <div [hidden]="!projectsExpanded">
        @if (projects.value.length === 0) {
          <div>Niet beschikbaar</div>
        }
        @for (project of projects.value; track project) {
          <div class="flex my-1 cursor-pointer"
            [routerLink]="['/projects/', project.id]"
            queryParamsHandling="preserve">
            <div class="flex align-center flex-nowrap items-center">
              <div class="relative w-8 h-8 rounded flex items-center justify-center"
                [class.bg-primary]="!(project | projectImage)">
                @if (project | projectImage) {
                  <img alt=""
                    [src]="project | projectImage"
                    class="absolute inset-0 w-full object-cover rounded">
                } @else {
                  <span class="absolute left-auto right-auto text-white"
                    inlineSVG="/assets/icons/icon-project.svg" [setSVGAttributes]="{width: 16, height: 16}">
                  </span>
                }
                <!--
                We _should_ be using thumbnail images here, but for unknown reasons suffixing ?size=thumbnail
                no longer works. We use full size images instead, which definitely works, but is obviously
                not ideal in terms of performance and bandwith used.
                -->
              </div>
              <span class="ml-4">{{ project.naam }}</span>
              <app-is-favorite [project]="project"></app-is-favorite>
            </div>
          </div>
        }
      </div>
      <app-collapse collapseTitle="Details">
        <app-information-item label="KVK-nummer" [value]="relation.kvk_nummer"></app-information-item>
        <app-information-item label="KVK-vestigingsnummer" [value]="relation.kvk_vestigingsnummer"></app-information-item>
        <app-information-item label="Is hoofdvestiging" [value]="getIsHoofvestiging(relation.root_name)"></app-information-item>
      </app-collapse>
    </div>
  } @else {
    @if (loadingProjects) {
      <div class="m-6 flex justify-center">
        <vwui-spinner></vwui-spinner>
      </div>
    }
  }
} @else {
  @if (!loading) {
    <div class="text-base font-medium text-dark truncate">
      <div class="ml-6 py-8 w-5 h-5">
        <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
        (click)="goBack()"></span>
        Leverancier niet gevonden
      </div>
    </div>
  }
  @if (loading) {
    <div class="m-6 flex justify-center">
      <vwui-spinner></vwui-spinner>
    </div>
  }
}

