"use strict";

import { isLegacyThemeFamily as i } from "../../config/Theme.js";
var t = (o => (o.SAPIconsV4 = "SAP-icons-v4", o.SAPIconsV5 = "SAP-icons-v5", o.SAPIconsTNTV2 = "tnt-v2", o.SAPIconsTNTV3 = "tnt-v3", o.SAPBSIconsV1 = "business-suite-v1", o.SAPBSIconsV2 = "business-suite-v2", o))(t || {});
const s = new Map();
s.set("SAP-icons", {
  legacy: "SAP-icons-v4",
  sap_horizon: "SAP-icons-v5"
}), s.set("tnt", {
  legacy: "tnt-v2",
  sap_horizon: "tnt-v3"
}), s.set("business-suite", {
  legacy: "business-suite-v1",
  sap_horizon: "business-suite-v2"
});
const c = (n, e) => {
    if (s.has(n)) {
      s.set(n, {
        ...e,
        ...s.get(n)
      });
      return;
    }
    s.set(n, e);
  },
  r = n => {
    const e = i() ? "legacy" : "sap_horizon";
    return s.has(n) ? s.get(n)[e] : n;
  };
export { c as registerIconCollectionForTheme, r as getIconCollectionForTheme, t as RegisteredIconCollection };
