import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize, map, shareReplay, switchMap} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {Project, ProjectTeamMember} from 'api/models/asset/project';
import {RouterHistoryService} from '../../services/router-history.service';
import {detailPageGoBack} from '../../utils/navigation';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-team-member-page',
    templateUrl: './team-member-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TeamMemberPageComponent {
    loading = {
        teamMember: true,
        projects: true
    };

    teamMemberProjects$: Observable<ProjectTeamMember[]> = this.activatedRoute.params.pipe(
        switchMap(params => {
            this.loading.teamMember = true;
            return this.api.getTeamMemberProjects(params.upn).pipe(
                finalize(() => this.loading.teamMember = false)
            );
        }),
        shareReplay(1)
    );
    teamMember$: Observable<ProjectTeamMember> = this.teamMemberProjects$.pipe(
        map(teamMembers => teamMembers[0]),
        shareReplay(1)
    );

    projects$ = this.teamMemberProjects$.pipe(
        map(teamMembers => teamMembers.map(it => it.projectId + '')),
        switchMap(projectIds => {
            this.loading.projects = true;
            return this.api.filter<Project>('project', '', '',
                    false, '', 0, 999, {id: projectIds}).pipe(
                finalize(() => this.loading.projects = false));
            }
        )
    );

    goBack = detailPageGoBack(this.router, this.routerHistory, ['projects']);

    constructor(
        private activatedRoute: ActivatedRoute,
        private api: ApiService,
        private router: Router,
        private routerHistory: RouterHistoryService
    ) {
    }

    getRoleByProjectId(projectId: string, teamMembers: ProjectTeamMember[]) {
        return teamMembers.find(it => it.projectId + '' === projectId).role;
    }
}
