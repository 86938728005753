import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SelectionService} from '../../services/selection.service';
import {filter, switchMap} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {SearchStateService} from '../../services/search-state.service';
import {InfiniteScrollPaginator} from '../../utils/infinite-scroll-paginator';
import {Collection} from 'api/models/collection';
import {CollectionsService} from '../../services/collections.service';
import {RouterHistoryService} from '../../services/router-history.service';
import {detailPageGoBack} from '../../utils/navigation';

@Component({
    selector: 'app-collections',
    templateUrl: './collections.component.html',
    standalone: false
})
export class CollectionsComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    query$ = new BehaviorSubject<string>('');
    queryControl = new UntypedFormControl('');
    gridView$ = this.searchStateService.gridView$;
    collectionsPaginator = new InfiniteScrollPaginator(
        page => this.query$.pipe(
            switchMap(query => query
                ? this.apiService.getCollectionsBySearch(page, query)
                : this.apiService.getCollections(page)
            )
        )
    );
    suggestions$ = this.queryControl.valueChanges.pipe(
        filter(it => it !== ''),
        switchMap(it => this.apiService.getCollectionsBySuggestions(it))
    );

    goBack = detailPageGoBack(this.router, this.routerHistory, ['/']);

    constructor(
        private apiService: ApiService,
        private collectionsService: CollectionsService,
        private route: ActivatedRoute,
        private router: Router,
        private routerHistory: RouterHistoryService,
        private searchStateService: SearchStateService,
        private selectionService: SelectionService
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.queryParamMap.subscribe(queryParams => {
                this.queryControl.setValue(queryParams.get('query'));
                this.query$.next(this.queryControl.value);
                this.collectionsPaginator.refresh();
            }),
            this.collectionsService.refreshCollections$.subscribe(() => {
                this.collectionsPaginator.refresh();
            })
        );
    }

    ngOnDestroy() {
        this.selectionService.disable();
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    onSearchClear() {
        this.queryControl.reset();
    }

    handleSearch(query: string) {
        if (this.queryControl.value === '' && query === '') {
            this.goBack();
            return;
        }
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {query},
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
    }

    navigateToCollection(collection: Collection) {
        this.router.navigate(
            ['/collections/', collection.id],
            {queryParamsHandling: 'preserve'}
        );
    }
}
