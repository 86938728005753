import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, lastValueFrom, Observable} from 'rxjs';
import {UserSelected, UserSelection} from 'api/models/user-selected';
import {map} from 'rxjs/operators';
import {Project} from 'api/models/asset/project';
import {Company} from 'api/models/asset/company';
import {FileUtil} from '../utils/file';
import {SearchStateService} from './search-state.service';
import {ApiService} from './api.service';
import {UserFavorite} from 'api/models/user-favorite';
import {FavoriteService} from './favorite.service';

@Injectable({
    providedIn: 'root'
})
export class SelectionService {
    private selectionInternal$ = new BehaviorSubject<UserSelection>([]);
    public selectionMode$ = new BehaviorSubject<'disabled'|'project'|'company'>('disabled');
    readonly selection$: Observable<UserSelection> = combineLatest([this.selectionMode$, this.selectionInternal$]).pipe(
        map(([mode, selection]) => {
            return selection.filter(
                (selected: UserSelected) => selected.type === mode
            );
        })
    );

    constructor(
        protected searchStateService: SearchStateService,
        protected apiService: ApiService,
        protected favoriteService: FavoriteService
    ) {
        this.init();
    }

    async loadSelection() {
        const selection = []; // TODO load from somewhere
        if (Array.isArray(selection)) {
            this.selectionInternal$.next(selection);
        } else {
            console.error('Invalid selection response', selection);
        }
    }

    async clearSelection() {
        this.selectionInternal$.next([]);
    }

    addSelected(selected: UserSelected) {
        const selection = this.selectionInternal$.value;
        if (!selection.some(item => item.type === selected.type && item.id === selected.id)) {
            selection.push(selected);
            this.selectionInternal$.next(selection);
        }
    }

    removeSelected(selected: UserSelected) {
        const selection = this.selectionInternal$.value;
        const index = selection.findIndex(item => item.type === selected.type && item.id === selected.id);
        if (index !== -1) {
            selection.splice(index, 1);
            this.selectionInternal$.next(selection);
        }
    }

    projectIsSelected(project: Project): boolean {
        const selection = this.selectionInternal$.value;
        return (selection.findIndex(item => item.id === project.id) !== -1);
    }


    companyIsSelected(company: Company): boolean {
        const selection = this.selectionInternal$.value;
        return (selection.findIndex(item => item.id === company.kvw_nummer) !== -1);
    }

    disable() {
        this.clearSelection();
        this.selectionMode$.next('disabled');
    }

    getSelectionIds() {
        return this.selectionInternal$.value.map((selected) => selected.id);
    }

    removeSelectedIds(ids: string[]){
        const selection = this.selectionInternal$.value;
        this.selectionInternal$.next(selection.filter(item => !ids.includes(item.id)));
    }

    async exportSelection(exportType) {
        const type = this.selectionMode$.value as 'project' | 'company';
        const selectionIds = this.getSelectionIds();
        const filter = (type === 'project') ? { id: selectionIds } : { kvw_nummer: selectionIds };
        const data = await lastValueFrom(this.apiService.export(
            type,
            exportType, //TODO CSV?
            '',
            null,
            null,
            filter
        ));

        const filename = [
            'geselecteerde',
            (type === 'company') ? 'bedrijven' : 'projecten'
        ].filter(it => !!it).join('_');

        FileUtil.downloadBlobAsFile(data, `${filename}.${exportType}`);
    }

    async favoritizeSelection() {
        const favorites = this.getSelectionAsFavorites();
        favorites.forEach((favorite) => {
            this.favoriteService.addFavorite(favorite);
        });
        return await this.favoriteService.saveFavorites();
    }

    getSelectionAsFavorites() {
        return this.selectionInternal$.value.map((selected) => {
            return {
                type: selected.type,
                id: selected.id
            } as UserFavorite;
        });
    }

    private async init() {
        await this.loadSelection();
    }

}
