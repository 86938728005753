import {Component, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {finalize, map, tap} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import {Project} from 'api/models/trimble/project';
import {TrimbleService} from '../../services/trimble.service';

@Component({
    selector: 'app-trimble-project-select',
    templateUrl: './trimble-project-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: TrimbleProjectSelectComponent,
            multi: true
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: TrimbleProjectSelectComponent,
            multi: true
        }
    ],
    standalone: false
})
export class TrimbleProjectSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    private onChange: (value: Project) => void;
    private onTouched: () => void;
    private subscription: Subscription;

    readonly projects$ = this.trimbleService.getProjects().pipe(
        finalize(() => this.loading = false)
    );

    projectControl = new UntypedFormControl(null);
    loading = true;
    trimbleAuthenticated: boolean;

    constructor(private trimbleService: TrimbleService) {
    }

    openTrimbleLogin() {
        this.trimbleService.redirectToTrimbleLogin(['/apps/','map-service', 'request']);
    }

    ngOnInit() {
        this.trimbleAuthenticated = this.trimbleService.isAuthenticated();

        this.subscription = this.projectControl.valueChanges.subscribe(project => {
            this.onTouched();
            this.onChange(project);
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    validate({value}: UntypedFormControl) {
        if (!value) {
            return of(false);
        }

        return this.trimbleService.checkProjectWritable(value).pipe(
            map(writable => writable ? null : ({insufficientPermissions: true})),
            tap(errors => this.projectControl.setErrors(errors, {emitEvent: false})),
        );
    }

    writeValue(value: Project) {
        this.projectControl.setValue(value, {emitEvent: false});
    }

    registerOnChange(fn: (value: Project) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }
}
