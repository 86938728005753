export function isTokenExpired(token: string, offsetSeconds: number = 0) {
    if (!token || token === '') {
        return true;
    }
    const date = getTokenExpirationDate(token);

    if (date === null) {
        return false;
    }

    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

function getTokenExpirationDate(token: string) {
    const decoded = decodeToken(token);

    if (!decoded || !Object.prototype.hasOwnProperty.call(decoded, 'exp')) {
        return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
}

function decodeToken(token: string) {
    if (!token || token === '') {
        return null;
    }

    const parts = token.split('.');

    if (parts.length !== 3) {
        throw new Error(
            'The inspected token doesn\'t appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.'
        );
    }

    const decoded = atob(parts[1]);
    if (!decoded) {
        throw new Error('Cannot decode the token.');
    }

    return JSON.parse(decoded);
}
