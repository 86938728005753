import {Component, OnDestroy, OnInit} from '@angular/core';
import {SearchStatePaginator} from '../../utils/paginator/search-state-paginator';
import {SearchStateService} from '../../services/search-state.service';
import {ApiService} from '../../services/api.service';
import {SearchRelation} from '../../model/search-relation';
import {withLatestFrom} from 'rxjs/operators';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {ScreenService} from '../../services/screen.service';
import {Subscription} from 'rxjs';
import {PointResult} from '../../model/search-response';
import {SortService} from '../../services/sort.service';

@Component({
    selector: 'app-search-relations',
    templateUrl: './search-relations.component.html',
    standalone: false
})
export class SearchRelationsComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    gridView$ = this.searchStateService.gridView$;

    paginator = new SearchStatePaginator<SearchRelation>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, filter) => {
            return this.apiService.filter<SearchRelation>(
                'relation',
                '',
                query,
                favorite,
                sort,
                page,
                pageSize,
                filter
            );
        }
    );
    isLoading$ = this.searchStateService.loadingSearchResults$;
    isOnMobile = false;

    constructor(
        private searchStateService: SearchStateService,
        private apiService: ApiService,
        private mapboxService: MapboxService,
        private screenService: ScreenService,
        private sortService: SortService
    ) {
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.paginator.responseWithParams$.pipe(
                withLatestFrom(this.screenService.screen$,  (response, screen) => ({response, screen})),
            ).subscribe(({response, screen}) => {
                const points = response.value.map(item => ({
                    ...item, title: item.name, id: item.id, type: 'relation'
                } as PointResult));
                this.searchStateService.pointResults$.next(points);
                const filteredResults = (response.value.filter(result => result.geog !== null)).map(result => result.geog.coordinates);
                if(screen === 'desktop') {
                    this.mapboxService.moveToLocation(filteredResults);
                }
            }),
            this.screenService.screen$.subscribe(screen => this.isOnMobile = screen === 'mobile')
        );
        this.sortService.paginator = this.paginator;

    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
        this.sortService.paginator = null;
    }
}
