import {Component, Inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TrimbleService} from '../../services/trimble.service';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-trimble-oauth',
    templateUrl: './trimble-oauth.component.html',
    standalone: false
})
export class TrimbleOAuthComponent {
    // matches kaartsenservice_<base64>
    static readonly KAARTENSERVICE_REGEX = /^kaartenservice_(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

    error = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private trimbleService: TrimbleService,
        @Inject(DOCUMENT) private document: Document
    ) {
        const routeParams = route.snapshot.queryParams;
        if (!routeParams.code) {
            throw Error('Missing \'code\' query parameter');
        }

        if (routeParams.state && TrimbleOAuthComponent.KAARTENSERVICE_REGEX.test('' + routeParams.state)) {
            const params = new URL(document.location.href).searchParams;
            const newParams = new URLSearchParams();
            newParams.append('authorizationCode', params.get('code'));
            newParams.append('state', params.get('state'));
            document.location.href = `${environment.azureBaseUrl}/kaartenservice/trimble?${newParams.toString()}`;
            return;
        }

        this.exchangeCodeForAccessToken(routeParams.code);
    }

    async exchangeCodeForAccessToken(code: string) {
        try {
            await this.trimbleService.exhangeCodeAndStoreJwt(code);
            const savedParams = localStorage.getItem('trimble.originalUrl');
            const trimbleRedirectUrl = JSON.parse(localStorage.getItem('trimble.redirectUrl'));
            if (savedParams) {
                await this.router.navigate(trimbleRedirectUrl, {
                    queryParams: {
                        ...JSON.parse(savedParams)
                    }
                });
            } else {
                await this.router.navigate(['/']);
            }
        } catch (error) {
            console.error(error);
            this.error = true;
        }
    }
}
