import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {AbstractTileContextMenuComponent} from './abstract-tile-context-menu.component';
import {Overlay} from '@angular/cdk/overlay';
import {Project} from 'api/models/asset/project';
import {ModalService} from '../../services/modal.service';
import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-project-card-context-menu',
    templateUrl: './project-card-context-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectCardContextMenuComponent extends AbstractTileContextMenuComponent {
    @Input() project: Project;

    constructor(
        protected changeDetectionRef: ChangeDetectorRef,
        protected overlay: Overlay,
        protected selectionService: SelectionService,
        protected router: Router,
        private modalService: ModalService,
    ) {
        super(changeDetectionRef, overlay, selectionService, router);
    }

    removeProjectFromCollection() {
        this.modalService.removeProjectFromCollection(this.project);
    }
}
