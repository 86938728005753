"use strict";

import { getThemeProperties as n, getRegisteredPackages as m, isThemeRegistered as d } from "../asset-registries/Themes.js";
import { removeStyle as g, createOrUpdateStyle as p } from "../ManagedStyles.js";
import c from "./getThemeDesignerTheme.js";
import { fireThemeLoaded as f } from "./ThemeLoaded.js";
import { getFeature as u } from "../FeaturesRegistry.js";
import { attachCustomThemeStylesToHead as T, getThemeRoot as h } from "../config/ThemeRoot.js";
import { DEFAULT_THEME as l } from "../generated/AssetParameters.js";
import { getCurrentRuntimeIndex as y } from "../Runtimes.js";
const s = "@ui5/webcomponents-theming",
  S = () => m().has(s),
  P = async e => {
    if (!S()) return;
    const t = await n(s, e);
    t && p(t, "data-ui5-theme-properties", s, e);
  },
  E = () => {
    g("data-ui5-theme-properties", s);
  },
  U = async (e, t) => {
    const o = [...m()].map(async a => {
      if (a === s) return;
      const i = await n(a, e, t);
      i && p(i, `data-ui5-component-properties-${y()}`, a);
    });
    return Promise.all(o);
  },
  w = async e => {
    const t = c();
    if (t) return t;
    const r = u("OpenUI5Support");
    if (r && r.isOpenUI5Detected()) {
      if (r.cssVariablesLoaded()) return {
        themeName: r.getConfigurationSettingsObject()?.theme,
        baseThemeName: ""
      };
    } else if (h()) return await T(e), c();
  },
  I = async e => {
    const t = await w(e);
    !t || e !== t.themeName ? await P(e) : E();
    const r = d(e) ? e : t && t.baseThemeName;
    await U(r || l, t && t.themeName === e ? e : void 0), f(e);
  };
export default I;
