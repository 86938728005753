import {Injectable} from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {from} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {environment} from '../../environments/environment';

@Injectable()
export class AzureAuthenticationInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url.startsWith(environment.azureBaseUrl) && request.headers.get('Authorization') === null) {
            return from(this.authService.getToken()).pipe(
                map(token => request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token.idToken}`,
                        Token: token.accessToken
                    }
                })),
                mergeMap(newRequest => next.handle(newRequest))
            );
        } else {
            return next.handle(request);
        }
    }
}
