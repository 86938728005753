import {Injectable} from '@angular/core';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import {SelectionService} from './selection.service';
import {ApiService} from './api.service';
import {Project} from 'api/models/asset/project';
import {Collection} from 'api/models/collection';

@Injectable({
    providedIn: 'root'
})
export class CollectionsService {
    projectsWithoutPrs$ = new BehaviorSubject<Project[]>(undefined);
    sharedProjectsCount$ = new BehaviorSubject<number>(0);
    projectCount$ = new BehaviorSubject<number>(0);
    compatibleProjects$ = new BehaviorSubject<Project[]>(undefined);
    currentCollection: Collection;
    refreshCollections$ = new BehaviorSubject<void>(undefined);

    constructor(
        private apiService: ApiService,
        private selectionService: SelectionService,
    ) {}

    async checkSelectedProjects() {
        const projectFilter = {id: this.selectionService.getSelectionIds()};
        await lastValueFrom(this.apiService.filter<Project>(
            'project',
            '',
            '',
            false,
            '',
            0,
            this.selectionService.getSelectionIds().length, projectFilter))
        .then(projects => {
            this.projectsWithoutPrs$.next(projects.value.filter(project => project.hasPrs !== 'Aanwezig'));
            this.compatibleProjects$.next(projects.value.filter(project => project.hasPrs === 'Aanwezig'));
            this.projectCount$.next(projects.count);
        });
    }
}
