import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {AbstractTileContextMenuComponent} from './abstract-tile-context-menu.component';
import {Overlay} from '@angular/cdk/overlay';
import {Collection} from 'api/models/collection';
import {ModalService} from '../../services/modal.service';
import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-collections-tile-context-menu',
    templateUrl: './collections-tile-context-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CollectionsTileContextMenuComponent extends AbstractTileContextMenuComponent {
    @Input() collection: Collection;

    constructor(
        protected changeDetectionRef: ChangeDetectorRef,
        protected overlay: Overlay,
        protected selectionService: SelectionService,
        protected router: Router,
        private modalService: ModalService,
    ) {
        super(changeDetectionRef, overlay, selectionService, router);
    }

    openNameChange() {
        this.modalService.openActionsModal(this.collection, 'name','modal-lg');
        this.onOutsideOverlayClick();
    }

    openRemoveCollection() {
        this.modalService.openActionsModal(this.collection, 'remove','modal-lg');
        this.onOutsideOverlayClick();
    }

    openShareCollection() {
        this.modalService.openActionsModal(this.collection, 'share','modal-md');
        this.onOutsideOverlayClick();
    }

    async openPrsPresentation() {
        await this.modalService.openPrsPresentation(this.collection);
        this.onOutsideOverlayClick();
    }
}
