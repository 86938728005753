<app-project-header [project]="project" (goBack)="goBack()"></app-project-header>

@if (metadata?.bim || metadata?.rcIndoor || metadata?.rcOutdoor || metadata?.fields) {
  <div
    class="flex w-full text-center desktop:px-6 desktop:mt-2.5">
    <a class="p-3 mobile:px-6 desktop:hover:text-primary cursor-pointer" (click)="activeTab = 'project'"
      [ngClass]="activeTab === 'project' ? ['text-primary', 'border-primary', 'border-b-2'] : ['text-neutral-1', 'border-b']"
      >
      Project
    </a>
    @if ((metadata?.bim || metadata?.rcIndoor || metadata?.rcOutdoor || metadata?.fields) && !isOnMobile) {
      <a
        class="p-3 desktop:hover:text-primary cursor-pointer" (click)="activeTab = 'project_dossier'"
        [ngClass]="activeTab === 'project_dossier' ? ['text-primary', 'border-primary', 'border-b-2'] : ['text-neutral-1', 'border-b']"
        >
        Project dossier
      </a>
    }
    @if (project.prs?.id !== null) {
      <a class="p-3 mobile:px-6 desktop:hover:text-primary cursor-pointer"
        (click)="activeTab = 'project_referentie_sheet'"
        [ngClass]="activeTab === 'project_referentie_sheet' ? ['text-primary', 'border-primary', 'border-b-2'] : ['text-neutral-1', 'border-b']">
        Project referentie
      </a>
    }
    <span class="flex-grow border-b"></span>
  </div>
}
<div class="mx-6">
  @if (project) {
    @if (activeTab === 'project') {
      <app-collapse collapseTitle="Projectinformatie">
        <app-information-item label="Opdrachtgever" [value]="project.opdrachtgever"></app-information-item>
        <app-information-item label="Onderneming" [value]="companies$ | async"></app-information-item>
        <app-information-item label="Locatie" [value]="getProjectLocation(project)"></app-information-item>
        <app-information-item label="Project omvang"
        [value]="projectBuildingClassification$|async"></app-information-item>
        @for (projectClassification of projectClassificationValuesToShow$ | async; track projectClassification) {
          <app-information-item
            [label]="projectClassification.kenmerk"
            [value]="getProjectClassificationValuesByDefinition(projectClassification.kenmerk) | async">
          </app-information-item>
        }
        @if (getProjectPercentage(); as percentage) {
          <div class="overflow-hidden">
            <div
              class="ml-auto mr-auto mb-1 mt-6 mx-2  h-6 text-darkBlue text-xs flex font-medium">
              @if (project.startdatum) {
                <span>{{ project.startdatum | date:'LLLL yyyy' }} </span>
              }
              @if (project.einddatum) {
                <span class="ml-auto"
                >{{ project.einddatum | date:'LLLL yyyy' }}</span>
              }
            </div>
            <div class="ml-auto mr-auto h-6 bg-neutral-4 rounded">
              <div class="h-full bg-primary rounded-l text-right flex relative"
                [style.width]="percentage + '%'"
                [class.rounded-r]="percentage === 100">
                @if (percentage !== 100) {
                  <span [class.invisible]="percentage <= 30"
                    class="ml-auto text-white text-xs mt-1 mr-2">
                    {{ currentDate | date:'LLLL yyyy' }}
                  </span>
                }
                @if (percentage !== 100) {
                  <span
                  class="transform -translate-y-1 border-r-4 border-primary h-8"></span>
                }
                <span [class.invisible]="percentage > 30"
                  class="absolute left-full text-primary text-xs mt-1 ml-2 whitespace-nowrap">
                  {{ currentDate | date:'LLLL yyyy' }}
                </span>
              </div>
            </div>
            <div class="ml-auto mr-auto mt-1 mx-2 h-6 text-neutral-2 text-xs flex justify-between font-medium">
              <span>Start bouw</span>
              <span>Opleverdatum</span>
            </div>
          </div>
        }
      </app-collapse>
      @if (project.prs?.projectRelations) {
        <app-collapse collapseTitle="Ontwerpteam">
          @if (project.prs?.projectRelations.length) {
            @for (projectRelation of project.prs.projectRelations; track projectRelation) {
              <app-project-relation-card
                [projectRelation]="projectRelation"
              ></app-project-relation-card>
            }
          } @else {
            Ontwerpteam niet beschikbaar
          }
        </app-collapse>
      }
      @if (projectTeam$|async; as projectTeam) {
        <app-collapse collapseTitle="Projectteam VolkerWessels">
          @if (projectTeam.team.length) {
            @for (projectTeamMember of projectTeam.team; track projectTeamMember) {
              <app-contact-card
                [projectTeamMember]="projectTeamMember"
              ></app-contact-card>
            }
          } @else {
            Projectteam VolkerWessels niet beschikbaar
          }
        </app-collapse>
      }
      @if (projectRelationsChapters$|async; as chapters) {
        <app-collapse collapseTitle="Leveranciers en onderaannemers">
          @if (chapters.length) {
            @for (chapter of chapters; track chapter) {
              <app-collapse [open]="false" [collapseTitle]="chapter.label">
                @for (projectRelation of filterDuplicatesByRelationName(chapter.projectRelations); track projectRelation) {
                  <div
                    class="cursor-pointer"
                    (click)="goToRelation(projectRelation.relation_id)">{{ projectRelation.relation_name }}
                  </div>
                }
              </app-collapse>
            }
          } @else {
            Leveranciers en onderaannemers niet beschikbaar
          }
        </app-collapse>
      }
    }
    @if (project && project.prs?.id && activeTab === 'project_referentie_sheet') {
      <app-collapse
        collapseTitle="Projectreferentie">
        <app-information-item label="Aangemaakt" [value]="project.prs.createdAt|date"></app-information-item>
        <app-information-item label="Aangemaakt door" [value]="project.prs.createdBy"></app-information-item>
        <app-information-item label="Laatste wijziging" [value]="project.prs.updatedAt|date"></app-information-item>
        <app-information-item label="Laatste wijziging door" [value]="project.prs.updatedBy"></app-information-item>
        <div class="flex align-center mt-2 space-x-2">
          <vwui-button id="open-prs" block="" class="flex-1 is-primary" (click)="openOverlay(project.prs, 'prs')">
            Referentie bekijken
          </vwui-button>
        </div>
      </app-collapse>
    }
    @if (activeTab === 'project_dossier' && !isOnMobile) {
      <app-collapse collapseTitle="Project views">
        @if (trimbleAuthenticated && (loadingTrimbleViews || loadingTrimbleApplications)) {
          <div
            class="text-primary">
            <span inlineSVG="/assets/icons/spinner.svg" class="w-4 ml-auto"></span>
          </div>
        }
        @if (trimbleAuthenticated) {
          @for (trimbleView of (trimbleViews$ | async); track trimbleView) {
            <app-project-view-link
              label="3D informatiemodellen"
              [thumbnail]="'/assets/icons/icon-bim.svg'"
              (clicked)="openOverlay(trimbleView, 'trimbleView')"
            ></app-project-view-link>
          }
          @if (((trimbleApplications$ | async)?.length > 0 &&
            (trimbleViews$ | async)?.length === 0 &&
            !trimbleViewAccesDenied)) {
            @for (projectApplication of (trimbleApplications$ | async); track projectApplication) {
              <app-project-view-link
                label="3D informatiemodellen"
                [thumbnail]="'/assets/icons/icon-bim.svg'"
                (clicked)="openOverlay(projectApplication, 'projectApplication')"
              ></app-project-view-link>
            }
          }
          @if (((trimbleApplications$ |async)?.length > 0 && (trimbleViews$ | async)?.length === 0)) {
            U heeft geen toegang tot dit project in Trimble, neem contact op met de
            projectleider {{ getProjectLeiderName() | async }}
          }
        } @else {
          <div class="trimble-login-container">
            Klik op de knop hieronder om in te loggen.
            <button class="trimble-login-container__button" aria-label="Trimble Connect login" type="button"
              (click)="openTrimbleLogin(project.id)">
              <span class="mr-2" inlineSVG="/assets/icons/trimble-connect.svg"></span> Trimble login
            </button>
          </div>
        }
        @for (bim of metadata.bim; track bim) {
          <app-project-view-link
            [label]="bim.name"
            [thumbnail]="bim.thumbnail"
            (clicked)="openOverlay(bim, 'bim')"
          ></app-project-view-link>
        }
        @for (rcOutdoor of metadata.rcOutdoor; track rcOutdoor) {
          <app-project-view-link
            [label]="rcOutdoor.name"
            [thumbnail]="rcOutdoor.thumbnail"
            (clicked)="openOverlay(rcOutdoor, 'rcOutdoor')"
          ></app-project-view-link>
        }
        @for (rcIndoor of metadata.rcIndoor; track rcIndoor) {
          <app-project-view-link
            [label]="rcIndoor.name"
            [thumbnail]="rcIndoor.thumbnail"
            (clicked)="openOverlay(rcIndoor, 'rcIndoor')"
          ></app-project-view-link>
        }
        @if (metadata.bim.length === 0 && metadata.rcOutdoor.length === 0 && metadata.rcIndoor.length === 0 && (trimbleApplications$ | async)?.length === 0) {
          Project views niet beschikbaar
        }
      </app-collapse>
    }
    @if (activeTab === 'project_dossier' && project.mca_matrix_url && !isOnMobile) {
      <app-collapse collapseTitle="MCA">
        <app-project-view-link
          label="Materialen matrix"
          thumbnail="/assets/icons/icon-bim.svg"
          (clicked)="openMCAModal(project.mca_matrix_url)"
        ></app-project-view-link>
      </app-collapse>
    }
  } @else {
    @if (loading) {
      <div class="m-6 flex justify-center">
        <vwui-spinner></vwui-spinner>
      </div>
    }
    @if (!loading) {
      <div class="pt-20 px-4">
        <app-empty-state
          title="Geen project gevonden"
          message="Er kon geen project gevonden worden, probeer het opnieuw via de zoekbalk."
        ></app-empty-state>
      </div>
    }
  }
  @if (activeTab === 'project_dossier' && !isOnMobile) {
    <app-collapse collapseTitle="Applicaties">
      @if (loadingProjectApplications) {
        <div class="text-primary">
          <span inlineSVG="/assets/icons/spinner.svg" class="w-4 ml-auto"></span>
        </div>
      }
      <app-project-app-store-list [projectId]="project.id" [projectAppStoreItems]="projectAppStoreItems$"></app-project-app-store-list>
    </app-collapse>
  }

</div>
