import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ChangeDetectorRef, Component} from '@angular/core';
import {ContactService} from '../../services/contact.service';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ContactRequestAttachment} from 'api/models/contact-request';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {animate, style, transition, trigger} from '@angular/animations';
import {lastValueFrom} from 'rxjs';

interface ContactAttachment {
    fileName: string;
    originalFileName: string;
}

@Component({
    selector: 'app-contact-modal',
    templateUrl: './contact-modal.component.html',
    animations: [
        trigger('insertRemoveTrigger', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('500ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('500ms', style({ opacity: 0 }))
            ])
        ]),
    ],
    standalone: false
})
export class ContactModalComponent {

    private readonly MAX_FILE_SIZE = 20000000;
    private readonly MAX_FILE_COUNT = 6;

    form: UntypedFormGroup;
    loading = false;
    uploadingFiles: File[];
    uploadPending = false;

    readonly fileColors = {
        PDF: '#FB3A30',
        XLSX: '#00BC64',
        DOCX: '#0097D9',
        BMP: '#0504FF',
        JPEG: '#00BB96',
        JPG: '#00BB96',
        PNG: '#9843CA'
    };

    constructor(
        private modalRef: VwuiModalRef,
        private modalConfig: VwuiModalConfig,
        private authService: AuthService,
        private contactService: ContactService,
        private toastrService: ToastrService,
        private cd: ChangeDetectorRef
    ) {
        this.form = new UntypedFormGroup({
            type: new UntypedFormControl('question', [Validators.required]),
            phone: new UntypedFormControl('', [Validators.required]),
            title: new UntypedFormControl('', [Validators.required]),
            description: new UntypedFormControl('', [Validators.required]),
            attachments: new UntypedFormControl([], [maxSizeValidator(this.MAX_FILE_SIZE), Validators.maxLength(this.MAX_FILE_COUNT)])
        });
    }

    hide() {
        this.modalRef.close();
    }

    async submit() {
        if (this.loading) {
            console.warn('Skipping submit, because button was pressed multiple times');
            return;
        }

        if (this.uploadPending) {
            this.toastrService.error('Wacht a.u.b. tot alle bestanden geüpload zijn.');
            return;
        }

        for (const control of Object.values(this.form.controls)) {
            control.markAsTouched();
        }
        this.cd.detectChanges();

        if (this.form.invalid) {
            if (this.form.get('attachments').errors?.maxFileSize) {
                this.toastrService.error('Upload mag maximaal 20 MB zijn.');
            }
            if (this.form.get('attachments').errors?.maxlength) {
                this.toastrService.error('Er mogen maximaal 6 bestanden gestuurd worden.');
            }
            Object.entries(this.form.controls).some(([key, control]) => {
                if (key !== 'attachments' && control.invalid) {
                    this.toastrService.error('Vul de verplichte velden in.');
                    return;
                }
            });

            console.warn('Invalid contact form submitted');
            return;
        }

        this.loading = true;
        try {
            await lastValueFrom(this.contactService.post({
                ...this.form.value
            }));
            this.toastrService.success('Bericht succesvol verzonden.');
        } catch (ex) {
            console.error('Unable to send contact form', ex);
            this.toastrService.error('Fout opgetreden bij verzenden bericht.');
        } finally {
            this.hide();
            this.loading = false;
        }
    }

    async onFileChange(event: Event) {
        const target = event.target as HTMLInputElement;
        const formControl = this.form.get('attachments');
        formControl.markAsTouched();

        if (target.files && target.files.length) {
            const files = Array.from(target.files);
            this.uploadingFiles = files;

            try {
                this.uploadPending = true;
                await Promise.all(files.map(file => this.uploadFile(file).then(attachment => {
                    const pendingUploadIndex = this.uploadingFiles.findIndex(it => it.name === attachment.originalFileName);
                    if (pendingUploadIndex !== -1) {
                        this.uploadingFiles.splice(pendingUploadIndex, 1);
                    }
                    formControl.patchValue([...(formControl.value), attachment]);
                    this.cd.markForCheck();
                })));
            } finally {
                this.uploadPending = false;
            }
        }
    }

    removeAttachment(other: ContactAttachment) {
        const formControl = this.form.get('attachments');
        formControl.setValue(formControl.value.filter((item: ContactAttachment) => other !== item));
    }

    private async uploadFile(file: File): Promise<ContactRequestAttachment> {
        const response = await lastValueFrom(this.contactService.getAttachmentSas(file.type));
        const blobClient = (await import('../../utils/lazy-blob-client')).getBlobClient(response.sasUri);
        await blobClient.uploadData(file);

        return {
            fileName: response.fileName,
            originalFileName: file.name,
            size: file.size
        };
    }
}

function maxSizeValidator(maxFileSize: number) {
    return (formControl: UntypedFormControl) => {
        const totalFileSize = formControl.value.reduce((result, current) => result + current.size, 0);

        if (totalFileSize > maxFileSize) {
            return {maxFileSize: `Max file size of ${maxFileSize} bytes exceeded`};
        }
    };
}
