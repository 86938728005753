import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'thousandSeparator',
    standalone: false
})
export class ThousandSeparatorPipe implements PipeTransform {

    transform(value: number): string {
        return `${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

}
