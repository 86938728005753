"use strict";

const m = (a = {}) => e => {
  if (Object.prototype.hasOwnProperty.call(e, "metadata") || (e.metadata = {}), typeof a == "string") {
    e.metadata.tag = a;
    return;
  }
  const {
    tag: f,
    languageAware: o,
    themeAware: r,
    cldr: s,
    fastNavigation: l,
    formAssociated: n,
    shadowRootOptions: d,
    features: i
  } = a;
  e.metadata.tag = f, o && (e.metadata.languageAware = o), s && (e.metadata.cldr = s), i && (e.metadata.features = i), r && (e.metadata.themeAware = r), l && (e.metadata.fastNavigation = l), n && (e.metadata.formAssociated = n), d && (e.metadata.shadowRootOptions = d), ["renderer", "template", "styles", "dependencies"].forEach(t => {
    a[t] && Object.defineProperty(e, t, {
      get: () => a[t]
    });
  });
};
export default m;
