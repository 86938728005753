@if (overlayData$ | async; as overlayData) {
  <div class="overlay-container" [class.is-visible]="!!overlayData"
    [class.no-header]="hideHeader"
    [class.info-bar-open]="(infoBarClosedState$|async) !== true"
    [class.is-header-only]="overlayData?.type === 'rcOutdoor'">
    <div class="overlay-container__header">
      <div class="overlay-container__title">{{ overlayData?.item?.name }}</div>
      <div class="overlay-container__close-btn" inlineSVG="/assets/icons/icon-24-close.svg" (click)="closeOverlay()"></div>
    </div>
    <div class="overlay-container__content" [class.overlay-container__content__no-header]="hideHeader">
      <!-- Dynamic content comes here -->
      @if (showIframe) {
        <iframe
          class="overlay-container__content__iframe"
          onmousewheel=""
          [src]="overlayData?.iframeUrl"
          (load)="setTrimbleTokenOnIFrame(overlayData.type)"
        ></iframe>
      }
    </div>
  </div>
}
