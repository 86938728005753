"use strict";

const e = {
  version: "2.5.1",
  major: 2,
  minor: 5,
  patch: 1,
  suffix: "",
  isNext: !1,
  buildTime: 1734430789
};
export default e;
