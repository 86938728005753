import {Component, OnInit} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {Collection, ShareCollection} from 'api/models/collection';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {Project} from 'api/models/asset/project';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
import {CollectionsService} from '../../services/collections.service';

@Component({
    selector: 'app-collections-name-modal',
    templateUrl: './collections-actions-modal.component.html',
    standalone: false
})

export class CollectionsActionsModalComponent implements OnInit {
    action$ = new BehaviorSubject<'name'|'remove'|'deleteProject'|'share'>(undefined);
    renameCollectionForm: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(this.modalConfig.data.collection.name,
            [Validators.minLength(1),
                Validators.maxLength(255),
                Validators.required])
    });
    shareCollectionEmailForm: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl('',
            [Validators.required,
                Validators.email])
    });
    pendingSubmit = false;

    constructor(
        public vwuiModalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ collection: Collection; action: 'name'|'remove'|'deleteProject'|'share'; project: Project }>,
        public apiService: ApiService,
        public collectionsService: CollectionsService,
        public toastrService: ToastrService,
        public collectionService: CollectionsService
    ) {}

    ngOnInit(): void {
        this.action$.next(this.modalConfig.data.action);
    }

    renameCollection() {
        try {
            this.renameCollectionForm.markAllAsTouched();
            if (this.renameCollectionForm && !this.renameCollectionForm.valid && !this.pendingSubmit) {
                return;
            }
            this.pendingSubmit = true;
            const collection = this.modalConfig.data.collection;
            collection.name = this.renameCollectionForm.value.name;
            lastValueFrom(this.apiService.postCollection(collection)).then(() => {
                this.collectionService.refreshCollections$.next();
            });
        } catch (e) {
            console.error('Create Collection failed', e.message);
        } finally {
            this.pendingSubmit = false;
            this.vwuiModalRef.close();
        }
    }

    async deleteCollection() {
        try {
            await lastValueFrom(this.apiService.deleteCollection(this.modalConfig.data.collection));
        } catch (e) {
            console.error('Failed to delete collection: ', e.message);
        } finally {
            this.collectionsService.refreshCollections$.next();
            this.vwuiModalRef.close();
        }
    }
    async removeProjectFromCollection() {
        try {
            const collection = this.collectionsService.currentCollection;
            collection.items = collection.items.filter(item => item !== this.modalConfig.data.project.id);
            await lastValueFrom(this.apiService.postCollection(collection));
        } catch (e) {
            console.error('Failed to remove project from collection: ', e.message);
        } finally {
            this.vwuiModalRef.close();
            this.collectionService.refreshCollections$.next();
        }
    }

    shareCollectionLink() {
        document.execCommand('copy');
        const collection = encodeURIComponent(JSON.stringify({
            name: `${this.modalConfig.data.collection.name} Kopie`,
            items: this.modalConfig.data.collection.items
        }));
        const url = `${environment.baseUrl}/collections/create/${collection}`;
        navigator.clipboard.writeText(url).then(
            () => this.toastrService.info('Link is gekopieërd naar het klembord.')
        );
    }

    async shareCollectionEmail() {
        this.pendingSubmit = true;

        try {
            this.renameCollectionForm.markAllAsTouched();
            if (this.renameCollectionForm && !this.renameCollectionForm.valid && !this.pendingSubmit) {
                return;
            }
            this.pendingSubmit = true;
            const shareCollection = {
                collection: this.modalConfig.data.collection,
                email: this.shareCollectionEmailForm.value.email
            } as ShareCollection;

            await lastValueFrom(this.apiService.postCollectionShareEmail(shareCollection)).then(() => {
                this.toastrService.info('Uitnodiging verstuurd naar: ' + this.shareCollectionEmailForm.value.email);
                this.vwuiModalRef.close();
            });

        } catch (e) {
            console.error('Error sending collection e-mail: ', e.message);
            this.toastrService.error('Uitnodiging versturen mislukt: ' + e.message);

        } finally {
            this.pendingSubmit = false;
        }
    }

}
