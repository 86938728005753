<app-modal-header>Veelgestelde vragen</app-modal-header>
<vwui-modal-body>
  @if ((data$|async); as data) {
    @if (data && activeQuestion !== null) {
      <div class="faq">
        @for (section of data.sections; track section) {
          <div>
            @for (questionAnswerPair of section.questionAnswerPairs; track questionAnswerPair) {
              <div class="border-b border-neutral-2 pt-4" [class.pb-4]="activeQuestion.question !== questionAnswerPair.question" >
                <div class="cursor-pointer flex items-center font-medium" (click)="activeQuestion = questionAnswerPair">
                  <span class="desktop:ml-4 mr-2 mobile:mr-4 transform" inlineSVG="/assets/icons/navigation-arrow-icon.svg"
                    [ngClass]="activeQuestion.question === questionAnswerPair.question ? '-rotate-90' : 'rotate-90'"
                  ></span>
                  <span [innerHTML]="questionAnswerPair.question"></span>
                </div>
                <div class="ml-11 mobile:ml-8 pt-1 pb-4" [hidden]="activeQuestion.question !== questionAnswerPair.question">
                  <span [innerHTML]="questionAnswerPair.answer"></span>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }
  }
</vwui-modal-body>
<vwui-modal-footer>
  <button class="mobile:hidden" (click)="close()">Sluiten</button>
</vwui-modal-footer>
