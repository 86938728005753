import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {MapComponent} from './pages/map/map.component';
import {InlineSVGModule} from 'ng-inline-svg-2';
import localeNL from '@angular/common/locales/nl';
import {registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ZoomControlsComponent} from './components/zoom-controls/zoom-controls.component';
import {OverlayContainerComponent} from './components/overlay-container/overlay-container.component';
import {ShareButtonComponent} from './components/share-button/share-button.component';
import {ExtensionsMapServiceComponent} from './pages/extensions-map-service/extensions-map-service.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {TrimbleOAuthComponent} from './pages/trimble-oauth/trimble-oauth.component';
import {DisclaimerModalComponent} from './components/disclaimer-modal/disclaimer-modal.component';
import {IconButtonComponent} from './components/icon-button/icon-button.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {DateHttpInterceptorService} from './interceptor/date-http-interceptor.service';
import {LayerLegendComponent} from './components/layer-legend/layer-legend.component';
import {ChunksPipe} from './pipes/chunks.pipe';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AzureAuthenticationInterceptor} from './interceptor/azure-authentication.interceptor';
import {ContactModalComponent} from './components/contact-modal/contact-modal.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FaqModalComponent} from './components/faq-modal/faq-modal.component';
import {UserUnauthorizedComponent} from './components/user-unauthorized/user-unauthorized.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {LayerInfoComponent} from './components/layerinfo/layer-info-component';
import {VwuiAngularModule} from '@recognizebv/vwui-angular';
import {NavbarComponent} from './components/navbar/navbar.component';
import {SearchComponent} from './pages/search/search.component';
import {ProjectsComponent} from './pages/projects/projects.component';
import {CompaniesComponent} from './pages/companies/companies.component';
import {AppsComponent} from './pages/apps/apps.component';
import {LayersComponent} from './pages/layers/layers.component';
import {ResizeHandleComponent} from './components/resize-handle/resize-handle.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AccountComponent} from './pages/account/account.component';
import {ProjectsDetailComponent} from './pages/projects-detail/projects-detail.component';
import {ProjectTileComponent} from './components/project-tile/project-tile.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {PaginationSortComponent} from './components/pagination-sort/pagination-sort.component';
import {ProjectsOverviewComponent} from './pages/projects-overview/projects-overview.component';
import {ProjectsSearchComponent} from './pages/projects-search/projects-search.component';
import {SearchInputComponent} from './components/search-input/search-input.component';
import {EmptyStateComponent} from './components/empty-state/empty-state.component';
import {ProjectListComponent} from './components/project-list/project-list.component';
import {SearchFilterComponent} from './components/search-filter/search-filter.component';
import {SearchFilterSelectCheckboxComponent} from './components/search-filter-select-checkbox/search-filter-select-checkbox.component';
import {ActiveFilterListComponent} from './components/active-filter-list/active-filter-list.component';
import {SaveFilterModalComponent} from './components/save-filter-modal/save-filter-modal.component';
import {VerblijfsobjectDetailComponent} from './pages/verblijfsobject-detail/verblijfsobject-detail.component';
import {VerblijfsobjectAddressPipe} from './pipes/verblijfsobject-address.pipe';
import {LngLatToStringPipe} from './pipes/lng-lat.pipe';
import {SavedFilterListComponent} from './components/saved-filter-list/saved-filter-list.component';
import {DeleteSavedFilterModalComponent} from './components/delete-saved-filter-modal/delete-saved-filter-modal.component';
import {CompaniesOverviewComponent} from './pages/companies-overview/companies-overview.component';
import {CompanyTileComponent} from './components/company-tile/company-tile.component';
import {HttpsPipe} from './pipes/https.pipe';
import {ExportDropdownComponent} from './components/export-dropdown/export-dropdown.component';
import {CompaniesDetailComponent} from './pages/companies-detail/companies-detail.component';
import {SearchOverviewComponent} from './pages/search-overview/search-overview.component';
import {IsFavoriteComponent} from './components/is-favorite/is-favorite.component';
import {CompanyTileContextMenuComponent} from './components/tile-context-menu/company-tile-context-menu.component';
import {SearchAllComponent} from './pages/search-all/search-all.component';
import {VerblijfsobjectListComponent} from './components/verblijfsobject-list/verblijfsobject-list.component';
import {WoonplaatsListComponent} from './components/woonplaats-list/woonplaats-list.component';
import {CompanyListComponent} from './components/company-list/company-list.component';
import {AssetTypeRoutePipe} from './pipes/asset-detail-url.pipe';
import {AssetTypeIconPipe} from './pipes/asset-type-icon.pipe';
import {ProjectTileContextMenuComponent} from './components/tile-context-menu/project-tile-context-menu.component';
import {SearchSuggestionComponent} from './components/search-suggestion/search-suggestion.component';
import {SearchProjectsComponent} from './pages/search-projects/search-projects.component';
import {SearchCompaniesComponent} from './pages/search-companies/search-companies.component';
import {SearchVerblijfsobjectenComponent} from './pages/search-verblijfsobjecten/search-verblijfsobjecten.component';
import {SearchWoonplaatsenComponent} from './pages/search-woonplaatsen/search-woonplaatsen.component';
import {CompaniesSearchComponent} from './pages/companies-search/companies-search.component';
import {RouterHistoryService} from './services/router-history.service';
import {SearchHighlightPipe} from './pipes/search-highlight.pipe';
import {ImageFadeDirective} from './directives/image-fade.directive';
import {IsSelectedComponent} from './components/is-selected/is-selected.component';
import {SelectionListComponent} from './components/selection-list/selection-list.component';
import {LotsDetailComponent} from './pages/lots-detail/lots-detail.component';
import {PandDetailComponent} from './pages/pand-detail/pand-detail.component';
import {ActionsMenuComponent} from './components/actions-menu/actions-menu.component';
import {PinnedLocationComponent} from './components/pinned-location/pinned-location.component';
import {SendLocationModalComponent} from './components/send-location-modal/send-location-modal.component';
import {AnimatedTooltipDirective} from './directives/animated-tooltip.directive';
import {ProjectDetailContextMenuComponent} from './components/tile-context-menu/project-detail-context-menu.component';
import {ResultsViewSwitchComponent} from './components/results-view-switch/results-view-switch.component';
import {DetectAdblockModalComponent} from './components/detect-adblock-modal/detect-adblock-modal.component';
import {ContactCardComponent} from './components/contact-card/contact-card';
import {CollectionsModalComponent} from './components/collections-modal/collections-modal.component';
import {LayerInformationModalComponent} from './components/layer-information-modal/layer-information-modal.component';
import {SearchFilterSelectRangeComponent} from './components/search-filter-select-range/search-filter-select-range.component';
import {AttributeValuePipe} from './pipes/attribute-value.pipe';
import {ThousandSeparatorPipe} from './pipes/thousand-separator.pipe';
import '@ui5/webcomponents/dist/RangeSlider.js';
import {CollectionListComponent} from './components/collection-list/collection-list.component';
import {CollectionProjectsIconsComponent} from './components/collection-projects-icons/collection-projects-icons.component';
import {CollectionsSearchInputComponent} from './components/collections-search-input/collections-search-input.component';
import {CollectionsActionsModalComponent} from './components/collections-actions-modal/collections-actions-modal.component';
import {CollectionsComponent} from './pages/collections/collections.component';
import {CollectionTileComponent} from './components/collection-tile/collection-tile.component';
import {CollectionsTileContextMenuComponent} from './components/tile-context-menu/collections-tile-context-menu.component';
import {CollectionsDetailComponent} from './pages/collections-detail/collections-detail.component';
import {ProjectCardComponent} from './components/project-card/project-card.component';
import {ProjectCardContextMenuComponent} from './components/tile-context-menu/project-card-context-menu.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CollectionsCreateComponent} from './pages/collections-create/collections-create.component';
import {CollectionsNameInputComponent} from './components/collections-name-input/collections-name-input.component';
import {TileContextMenuitemComponent} from './components/tile-context-menuitem/tile-context-menuitem.component';
import {InformationItemComponent} from './components/information-item/information-item.component';
import {CollapseComponent} from './components/collapse/collapse.component';
import {ProjectViewLinkComponent} from './components/project-view-link/project-view-link.component';
import {CollectionHeaderComponent} from './components/collection-header/collection-header.component';
import {ProjectHeaderComponent} from './components/project-header/project-header.component';
import {LinkComponent} from './components/link/link.component';
import {LayersPoiComponent} from './pages/layer-poi/layers-poi.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {MobileNavbarComponent} from './components/mobile-navbar/mobile-navbar.component';
import {DragCursorDirective} from './directives/drag-cursor.directive';
import {RadioButtonGroupComponent} from './components/radio-button-group/radio-button-group.component';
import {TitleDirective} from './directives/title.directive';
import {MapStyleSelectorComponent} from './components/map-style-selector/map-style-selector.component';
import {ProjectTileGridComponent} from './components/project-tile-grid/project-tile-grid.component';
import {ModalHeaderComponent} from './components/modal-header/modal-header.component';
import {InfoboxComponent} from './components/infobox/infobox.component';
import {CompanyTileGridComponent} from './components/company-tile-grid/company-tile-grid.component';
import {RelationsDetailComponent} from './pages/relations-detail/relations-detail.component';
import {RelationTileComponent} from './components/relation-tile/relation-tile.component';
import {SearchRelationsComponent} from './pages/search-relations/search-relations.component';
import {RelationListComponent} from './components/relation-list/relation-list.component';
import {PoiDetailComponent} from './pages/poi-detail/poi-detail.component';
import {ActiveFilterComponent} from './components/active-filter/active-filter.component';
import {ProjectImagePipe} from './pipes/project-image.pipe';
import {ProjectRelationCardComponent} from './components/project-relation-card/project-relation-card.component';
import {TeamMemberPageComponent} from './pages/team-member-page/team-member-page.component';
import {CardComponent} from './components/card/card.component';
import {SearchResultContainerComponent} from './components/search-result-container/search-result-container.component';
import {AppRoleAssignmentModule} from './app-role-assignment/app-role-assignment.module';
import {ExportProjectModalComponent} from './components/export-project-modal/export-project-modal.component';
import {ExportProjectErrorModalComponent} from './components/export-project-error-modal/export-project-error-modal.component';
import {MobileSelectionListComponent} from './components/mobile-selection-list/mobile-selection-list.component';
import {ClaimPandMenuComponent} from './components/claim-pand-menu/claim-pand-menu.component';
import {McaMatrixModalComponent} from './components/mca-matrix-modal/mca-matrix-modal.component';
import {FeatureFlagModule} from './modules/shared/feature-flag/feature-flag.module';
import {ProjectAppStoreListItemComponent} from './components/project-app-store-list-item/project-app-store-list-item.component';
import {ProjectAppStoreListComponent} from './components/project-app-store-list/project-app-store-list.component';
import {ProjectAppStoreItemHeaderComponent} from './components/project-app-store-item-header/project-app-store-item-header.component';
import {
    ProjectAppStoreListItemDetailComponent
} from './components/project-app-store-list-item-detail/project-app-store-list-item-detail.component';
import {UiModule} from './modules/shared/ui/ui.module';
import {TrimbleProjectSelectComponent} from './components/trimble-project-select/trimble-project-select.component';

registerLocaleData(localeNL, 'nl');

@NgModule({ declarations: [
        AccountComponent,
        ActionsMenuComponent,
        ClaimPandMenuComponent,
        ActiveFilterListComponent,
        AnimatedTooltipDirective,
        AppComponent,
        AppsComponent,
        AssetTypeIconPipe,
        AssetTypeRoutePipe,
        AttributeValuePipe,
        ChunksPipe,
        CollectionsDetailComponent,
        CollectionsCreateComponent,
        CollectionsComponent,
        CollectionListComponent,
        CollectionProjectsIconsComponent,
        CollectionsActionsModalComponent,
        CollectionsModalComponent,
        CollectionsSearchInputComponent,
        CollectionTileComponent,
        CollectionsTileContextMenuComponent,
        CollectionTileComponent,
        CompaniesComponent,
        CompaniesDetailComponent,
        CompaniesOverviewComponent,
        CompaniesSearchComponent,
        CompanyListComponent,
        CompanyTileComponent,
        CompanyTileContextMenuComponent,
        ContactCardComponent,
        ContactModalComponent,
        DeleteSavedFilterModalComponent,
        DetectAdblockModalComponent,
        DetectAdblockModalComponent,
        DisclaimerModalComponent,
        EmptyStateComponent,
        ExportDropdownComponent,
        ExtensionsMapServiceComponent,
        FaqModalComponent,
        HttpsPipe,
        IconButtonComponent,
        ImageFadeDirective,
        DragCursorDirective,
        IsFavoriteComponent,
        IsSelectedComponent,
        LayerInfoComponent,
        LayerInformationModalComponent,
        LayerLegendComponent,
        LayersComponent,
        LngLatToStringPipe,
        LotsDetailComponent,
        MapComponent,
        McaMatrixModalComponent,
        MobileSelectionListComponent,
        NavbarComponent,
        OverlayContainerComponent,
        PaginationComponent,
        PaginationSortComponent,
        PandDetailComponent,
        PinnedLocationComponent,
        ProjectCardComponent,
        ProjectCardContextMenuComponent,
        ProjectDetailContextMenuComponent,
        ProjectListComponent,
        ProjectTileComponent,
        ProjectTileContextMenuComponent,
        ProjectsComponent,
        ProjectsDetailComponent,
        ProjectsOverviewComponent,
        ProjectsSearchComponent,
        ResizeHandleComponent,
        ResultsViewSwitchComponent,
        SaveFilterModalComponent,
        SavedFilterListComponent,
        SearchAllComponent,
        SearchCompaniesComponent,
        SearchComponent,
        SearchFilterComponent,
        SearchFilterSelectCheckboxComponent,
        SearchFilterSelectRangeComponent,
        SearchHighlightPipe,
        SearchInputComponent,
        SearchOverviewComponent,
        SearchProjectsComponent,
        SearchSuggestionComponent,
        SearchVerblijfsobjectenComponent,
        SearchWoonplaatsenComponent,
        SelectionListComponent,
        SendLocationModalComponent,
        ShareButtonComponent,
        SidebarComponent,
        ThousandSeparatorPipe,
        TrimbleOAuthComponent,
        UserUnauthorizedComponent,
        VerblijfsobjectAddressPipe,
        VerblijfsobjectDetailComponent,
        VerblijfsobjectListComponent,
        WoonplaatsListComponent,
        ZoomControlsComponent,
        CollectionsNameInputComponent,
        TileContextMenuitemComponent,
        InformationItemComponent,
        CollapseComponent,
        ProjectViewLinkComponent,
        CollectionHeaderComponent,
        ProjectHeaderComponent,
        LinkComponent,
        LayersPoiComponent,
        CheckboxComponent,
        MobileNavbarComponent,
        RadioButtonGroupComponent,
        TitleDirective,
        MapStyleSelectorComponent,
        ProjectTileGridComponent,
        ModalHeaderComponent,
        InfoboxComponent,
        InfoboxComponent,
        CompanyTileGridComponent,
        RelationsDetailComponent,
        RelationTileComponent,
        SearchRelationsComponent,
        RelationListComponent,
        PoiDetailComponent,
        ActiveFilterComponent,
        ProjectImagePipe,
        ProjectRelationCardComponent,
        TeamMemberPageComponent,
        CardComponent,
        SearchResultContainerComponent,
        ExportProjectModalComponent,
        ExportProjectErrorModalComponent,
        ProjectAppStoreListItemComponent,
        ProjectAppStoreListComponent,
        ProjectAppStoreItemHeaderComponent,
        ProjectAppStoreListItemDetailComponent,
        TrimbleProjectSelectComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        SearchInputComponent
    ],
    bootstrap: [
        AppComponent
    ], imports: [AppRoleAssignmentModule,
        BrowserModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        InlineSVGModule.forRoot({ baseUrl: '' }),
        TooltipModule.forRoot(),
        VwuiAngularModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        AppRoutingModule,
        NgSelectModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-center',
            preventDuplicates: true
        }),
        InfiniteScrollModule,
        OverlayModule,
        DragDropModule,
        FeatureFlagModule,
        UiModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AzureAuthenticationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateHttpInterceptorService,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'nl'
        },
        RouterHistoryService,
        ThousandSeparatorPipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
