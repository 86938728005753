"use strict";

import u from "./getActiveElement.js";
const r = () => {
    const e = u();
    return e && typeof e.focus == "function" ? e : null;
  },
  a = e => {
    const n = r();
    return n ? l(e, n) : !1;
  },
  l = (e, n) => {
    let t = e;
    if (t.shadowRoot && (t = Array.from(t.shadowRoot.children).find(c => c.localName !== "style"), !t)) return !1;
    if (t === n) return !0;
    const o = t.localName === "slot" ? t.assignedNodes() : t.children;
    return o ? Array.from(o).some(s => l(s, n)) : !1;
  },
  m = (e, n, t) => e >= t.left && e <= t.right && n >= t.top && n <= t.bottom,
  f = (e, n) => {
    let t, o;
    if (e instanceof MouseEvent) t = e.clientX, o = e.clientY;else {
      const s = e.touches[0];
      t = s.clientX, o = s.clientY;
    }
    return m(t, o, n);
  };
function d(e) {
  return "isUI5Element" in e && "_show" in e;
}
const i = e => {
  const n = e.parentElement || e.getRootNode && e.getRootNode().host;
  return n && (d(n) || n === document.documentElement) ? n : i(n);
};
export { r as getFocusedElement, f as isClickInRect, i as getClosedPopupParent, a as isFocusedElementWithinNode };
