
@if (selectionMode$|async; as selectionMode) {
  @if (selectionCount$ | async; as selectionCount) {
    @if (selectionMode !== 'disabled' && selectionCount > 0) {
      <div class="z-20 fixed bottom-20 inset-x-8 flex flex-col gap-2">
        <button class="bg-primary text-white px-2 py-1 rounded shadow" (click)="toggleContextMenu()">
          @if (selectionMode === 'project') {
            @if (selectionCount === 1) {
              1 project geselecteerd
            }
            @if (selectionCount > 1) {
              {{ selectionCount }} projecten geselecteerd
            }
          }
          @if (selectionMode === 'company') {
            @if (selectionCount === 1) {
              1 bedrijf geselecteerd
            }
            @if (selectionCount > 1) {
              {{ selectionCount }} bedrijven geselecteerd
            }
          }
        </button>
        @if (showContextMenu) {
          <div class="flex flex-col gap-2 bg-white border-primary border rounded py-2">
            @if (selectionMode === 'project') {
              <button [disabled]="selectingAll" (click)="selectAllProjects()">
                Selecteer alle projecten
              </button>
            }
            @if (selectionMode === 'company') {
              <button [disabled]="selectingAll" (click)="selectAllCompanies()">
                Selecteer alle bedrijven
              </button>
            }
            <button [disabled]="favoritizing" (click)="favoritizeSelection()">
              Maak selectie favorieten
            </button>
            <button [disabled]="isLoadingModal || selectionMode !== 'project'" (click)="addToCollection(selectionCount)">
              Voeg toe aan collectie
            </button>
            <button [disabled]="exporting" (click)="exportSelection()">
              Exporteer selectie
            </button>
            <button (click)="clearSelection()">
              Alles deselecteren
            </button>
          </div>
        }
      </div>
    }
  }
}
