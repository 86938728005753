"use strict";

var c = {},
  e = c.hasOwnProperty,
  a = c.toString,
  o = e.toString,
  l = o.call(Object),
  i = function (r) {
    var t, n;
    return !r || a.call(r) !== "[object Object]" ? !1 : (t = Object.getPrototypeOf(r), t ? (n = e.call(t, "constructor") && t.constructor, typeof n == "function" && o.call(n) === l) : !0);
  };
export default i;
