import {Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SearchStateService} from '../../services/search-state.service';
import {filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {isFilterActive} from '../../utils/filter';
import {BehaviorSubject, combineLatest, lastValueFrom, Observable, of, Subscription} from 'rxjs';
import {FavoriteService} from '../../services/favorite.service';
import {recentVisitIsFavorite} from '../../utils/favorite';
import {SearchResultType} from '../../model/search-result-type';
import {ApiService} from '../../services/api.service';
import {SearchSuggestResponse} from '../../model/search-response';
import {ScreenService} from '../../services/screen.service';
import {UserRecentVisit} from 'api/models/user-recent-visit';
import {Verblijfsobject} from 'api/models/asset/verblijfsobject';
import {Router} from '@angular/router';


@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchInputComponent),
            multi: true,
        }
    ],
    standalone: false
})
export class SearchInputComponent implements ControlValueAccessor, OnDestroy {
    @Input() placeholder = 'Zoeken...';
    @Input() set type(value: SearchResultType) {
        this.type$.next(value);
    }
    @ViewChild('query', {static: false}) inputField: ElementRef<HTMLInputElement>;

    @Output() inputCleared = new EventEmitter<void>();

    private subscriptions: Subscription[] = [];

    // CurrentValue contains current searched value
    currentValue = '';
    // InputControl contains current typed value which will be written to currentValue on Enter or click on search icon
    inputControl = new UntypedFormControl('');
    showSuggestions = false;
    loading = false;
    type$ = new BehaviorSubject<SearchResultType>(null);
    filterActive$ = this.searchStateService.searchState$.pipe(
        map(state => isFilterActive(state.filter))
    );
    recentVisits$ = combineLatest([
        this.type$.pipe(switchMap(type => this.apiService.getUserRecentVisits(type))),
        this.favoriteService.favorites$
    ]).pipe(
        map(([recentVisits, favorites]) => {
            return recentVisits
                .map(it => ({...it, favorite: recentVisitIsFavorite(favorites, it)}))
                .slice(0, 4);
        })
    );
    recentVisits: UserRecentVisit[] = [];
    suggestions$: Observable<SearchSuggestResponse> = this.inputControl.valueChanges.pipe(
        filter(it => it !== ''),
        tap(() => this.loading = true),
        withLatestFrom(this.type$),
        switchMap(([term, type]) => term ? this.apiService.suggest(term, type) : of({suggestions: [], autocomplete: []})),
        withLatestFrom(this.favoriteService.favorites$),
        map(([response, favorites]) => {
            return ({
                suggestions: response.suggestions.map(it => ({...it, favorite: recentVisitIsFavorite(favorites, it)})),
                autocomplete: response.autocomplete.filter(it => it.queryPlusText !== this.inputControl.value)
            });
        }),
        tap(() => this.loading = false)
    );
    suggestions: SearchSuggestResponse|null = null;
    isOnMobile = false;

    constructor(
        private searchStateService: SearchStateService,
        private favoriteService: FavoriteService,
        private apiService: ApiService,
        private screenService: ScreenService,
        private router: Router
    ) {
        this.subscriptions.push(
            this.inputControl.valueChanges.subscribe(it => {
                sessionStorage.setItem('draft_searchword', it);
            }),
            this.screenService.screen$.subscribe(screen => this.isOnMobile = screen === 'mobile'),
            this.recentVisits$.subscribe(it => this.recentVisits = it),
            this.suggestions$.subscribe(it => this.suggestions = it)
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onChange: (value: string) => void = () => {};
    onTouch: () => void = () => {};

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(obj: string): void {
        this.currentValue = obj;
        this.inputControl.setValue(sessionStorage.getItem('draft_searchword') ?? obj ?? '');
    }

    clear() {
        this.inputControl.setValue('');
        sessionStorage.removeItem('draft_searchword');
    }

    commitValue() {
        this.currentValue = this.inputControl.value?.trim();
        this.onTouch();
        this.onChange(this.currentValue);
        this.showSuggestions = false;
        this.inputField.nativeElement.blur();
        sessionStorage.removeItem('draft_searchword');
    }

    clearAndCommit() {
        this.clear();
        this.commitValue();
        this.inputCleared.next();
    }

    search(text: string) {
        this.inputControl.setValue(text);
        this.commitValue();
    }

    async suggestionClick(suggestion){
        switch (suggestion.type) {
            case 'project':
                this.router.navigate(['/projects', suggestion.id]);
                break;
            case 'company':
                this.router.navigate(['/companies', suggestion.id]);
                break;
            case 'verblijfsobject':
                const metadata = await lastValueFrom(this.apiService.getMetadata<Verblijfsobject>('verblijfsobject', suggestion.id));
                this.router.navigate(['/verblijfsobject', suggestion.id], {
                    queryParamsHandling: 'merge',
                    queryParams: {
                        id: metadata.asset.pandref,
                        type: 'pand'
                    }
                });
                break;
            case 'relation':
                this.router.navigate(['/relations', suggestion.id]);
                break;
            default:
                console.warn(`No detail page for asset type ${suggestion.type}`);
        }
    }
}
