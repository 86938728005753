import {Directive, ElementRef, HostBinding, HostListener} from '@angular/core';
import {animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style} from '@angular/animations';

@Directive({
    selector: '[appImageFade]',
    standalone: false
})
export class ImageFadeDirective {
    @HostBinding('style.visibility')
    private visibility = 'hidden';

    private factory: AnimationFactory;
    private player: AnimationPlayer;

    constructor(
        private builder: AnimationBuilder,
        private el: ElementRef
    ) {
        this.factory = builder.build([
            style({ opacity: 0 }),
            animate(500, style({ opacity: 1 }))
        ]);
    }

    @HostListener('load') onLoad() {
        if (this.player) {
            this.player.destroy();
        }

        this.player = this.factory.create(this.el.nativeElement);
        this.player.play();
        this.visibility = 'visible';
    }
}
