const e = "Trimble.dispatcher.v1",
  t = {},
  n = {};
let o = 1;
function r(e) {
  const t = a();
  return n[t] = e, () => delete n[t];
}
function i(n, o, r, i, s = 1e4) {
  return new Promise((c, u) => {
    const l = a(),
      f = {
        scope: e,
        type: "request",
        id: l,
        api: r,
        args: i
      };
    let g;
    s > 0 && (g = setTimeout(() => {
      delete t[l], u(new Error("dispatcher.ts | sendRequest(): Operation timed out."));
    }, s)), t[l] = e => {
      delete t[l], g && clearTimeout(g), e.error ? u(e.error) : c(e.result);
    }, n.postMessage(f, o);
  });
}
async function s(o) {
  const r = o.source,
    i = "null" === o.origin ? "*" : o.origin,
    s = o.data;
  if (function (e) {
    return c(e) && "event" === e.type;
  }(s)) for (const e in n) {
    if (!n.hasOwnProperty(e)) continue;
    const t = n[e].event;
    t && t(r, i, s.event, s.data);
  } else if (function (e) {
    return c(e) && "request" === e.type;
  }(s)) {
    let t;
    for (const o in n) {
      if (!n.hasOwnProperty(o)) continue;
      const c = n[o].request;
      if (c && !t) {
        const n = c(r, i, s.api, s.args);
        if (void 0 !== n) try {
          t = {
            scope: e,
            type: "response",
            id: s.id,
            api: s.api,
            result: await n
          };
        } catch (n) {
          t = {
            scope: e,
            type: "response",
            id: s.id,
            api: s.api,
            error: String(n)
          };
        }
      }
    }
    t || (t = {
      scope: e,
      type: "response",
      id: s.id,
      api: s.api,
      error: "Not supported"
    }), r.postMessage(t, i);
  } else if (function (e) {
    return c(e) && "response" === e.type;
  }(s)) {
    const e = t[s.id];
    e && e(s);
  }
}
function c(t) {
  return !!t && t.scope === e;
}
function a() {
  return o++;
}
const u = {};
!function (e) {
  if (u[e]) return u[e];
  const t = {},
    n = {
      debug: "#7f8c8d",
      log: "#2ecc71",
      warn: "#f39c12",
      error: "#c0392b"
    },
    o = (t, ...o) => {
      const r = [`%c${e}`, [`border: 1px solid ${n[t]}`, "border-radius: 0.5em", `color: ${n[t]}`, "font-weight: bold", "padding: 2px 0.5em"].join(";")];
      console[t](...r, ...o);
    };
  for (const e of Object.keys(n)) t[e] = (...t) => o(e, ...t);
  u[e] = t;
}("App");
const l = {},
  f = [];
let g;
const p = new Promise(e => {
  g = () => {
    g = () => {}, e();
  };
});
function d(e) {
  if ("object" != typeof e) throw new Error("Api must be an object");
  w(l, e), g();
}
function w(e, t) {
  for (const n in t) {
    const o = t[n];
    if (o) if (n in e) {
      const t = e[n];
      if ("object" == typeof o && "object" == typeof t) w(t, o);else {
        if ("function" != typeof o || "function" != typeof t || o.name !== t.name) throw new Error(`Cannot merge ${n} (${t} and ${o})`);
        e[n] = o;
      }
    } else switch (typeof o) {
      case "object":
        {
          const t = {};
          w(t, o), e[n] = t;
          break;
        }
      default:
        e[n] = o;
    }
  }
}
function h(e, t, n) {
  const o = {};
  for (const r in e) {
    const i = e[r];
    if ((void 0 !== n || "onConnect" !== r && "onRequest" !== r) && i) if ("object" == typeof i) {
      const e = n && n + "." + String(r) || String(r);
      o[r] = h(i, t, e);
    } else o[r] = t(r, e[r], n);
  }
  return o;
}
r({
  request: (e, t, n, o) => {
    if (".connect_api_client_v1" === n) return p.then(() => {
      let n = f.find(t => t.dispatcher === e);
      var o;
      return n ? n.origin = t : (n = {
        dispatcher: e,
        origin: t
      }, f.push(n)), (o = l) && "function" == typeof o.onConnect && l.onConnect(n), h(l, (e, t) => "function" == typeof t ? ".api_function_v1" : t);
    });
    {
      const i = f.find(n => n.dispatcher === e && n.origin === t);
      if (i) {
        if ((r = l) && "function" == typeof r.onRequest) {
          const e = l.onRequest(i, n, o);
          if (void 0 !== e) return e;
        }
        const e = function (e, t) {
          const n = t.split(".");
          let o = e;
          for (const e of n) o = "object" == typeof o && o && e in o ? o[e] : void 0;
          return o;
        }(l, n);
        if ("function" == typeof e) {
          const t = e.apply(void 0, o);
          return void 0 === t ? Promise.resolve(t) : t;
        }
        return Promise.resolve(e);
      }
      return;
    }
    var r;
  }
});
class y {
  storage = new Map();
  itemInsertionCallback;
  get length() {
    return this.storage.size;
  }
  getItem(e) {
    const t = this.storage.get(e);
    return void 0 === t ? null : t;
  }
  setItem(e, t) {
    this.storage.set(e, t), this.itemInsertionCallback && this.itemInsertionCallback(this.length);
  }
  removeItem(e) {
    this.storage.delete(e);
  }
  clear() {
    return this.storage.clear();
  }
  key(e) {
    const t = [];
    for (const e of this.storage.keys()) t.push(e);
    return t[e];
  }
}
class m {
  storageType;
  _underlyingStorage;
  constructor(e) {
    this.storageType = e, this._underlyingStorage = this.getStorage();
  }
  getStorage() {
    let e;
    try {
      e = "undefined" == typeof window ? global[this.storageType] : window[this.storageType];
      const t = "__storage_test__";
      return e.setItem(t, t), e.removeItem(t), e;
    } catch (t) {
      if (22 !== t.code && 1014 !== t.code && "QuotaExceededError" !== t.name && "NS_ERROR_DOM_QUOTA_REACHED" !== t.name || !e || 0 === e.length) {
        const e = "undefined" == typeof window ? global : window,
          t = `emulated${this.storageType}`;
        return e.hasOwnProperty(t) || (e[t] = new y()), e[t];
      }
      throw t;
    }
  }
  get underlyingStorage() {
    return this._underlyingStorage;
  }
  reload() {
    this._underlyingStorage = this.getStorage();
  }
  get length() {
    return this._underlyingStorage.length;
  }
  getItem(e) {
    return this._underlyingStorage.getItem(e);
  }
  setItem(e, t) {
    return this._underlyingStorage.setItem(e, t);
  }
  removeItem(e) {
    return this._underlyingStorage.removeItem(e);
  }
  clear() {
    return this._underlyingStorage.clear();
  }
  key(e) {
    return this._underlyingStorage.key(e);
  }
}
var v, b, S;
new m("localStorage"), new m("sessionStorage"), function (e) {
  e[e.AllowNegative = 1] = "AllowNegative", e[e.AllowIntegers = 2] = "AllowIntegers", e[e.AllowDecimals = 4] = "AllowDecimals", e[e.AllowScientific = 8] = "AllowScientific", e[e.AllowFractions = 16] = "AllowFractions", e[e.Any = 31] = "Any";
}(v || (v = {})), function (e) {
  e[e.Feet = 0] = "Feet", e[e.Inches = 1] = "Inches", e[e.FeetInches = 2] = "FeetInches";
}(b || (b = {})), function (e) {
  e[e.LengthMeasure = 0] = "LengthMeasure", e[e.AreaMeasure = 1] = "AreaMeasure", e[e.VolumeMeasure = 2] = "VolumeMeasure", e[e.MassMeasure = 3] = "MassMeasure", e[e.AngleMeasure = 4] = "AngleMeasure", e[e.StringValue = 5] = "StringValue", e[e.IntValue = 6] = "IntValue", e[e.DoubleValue = 7] = "DoubleValue", e[e.DateTime = 8] = "DateTime", e[e.Logical = 9] = "Logical", e[e.Boolean = 10] = "Boolean";
}(S || (S = {}));
const _ = ["reset", "selection", "areaSelection", "measurement", "pointMarkup", "cloudMarkup", "arrowMarkup", "lineMarkup", "textMarkup", "freelineMarkup", "clipPlane", "verticalClipPlane", "picking"],
  M = ["edge", "point", "surface"];
var I;
function A(e, t, n) {
  return window.removeEventListener("message", T), window.addEventListener("message", T), function (e, t, n = 1e4) {
    async function o(e, t) {
      const o = await i(e, t, ".connect_api_client_v1", [], 0);
      if (o && "object" == typeof o) return h(o, (o, r, s) => {
        if (".api_function_v1" === r) {
          const r = s ? s + "." + String(o) : String(o);
          return (...o) => i(e, t, r, o, n);
        }
        return r;
      });
      throw new Error("Failed to connect");
    }
    function s(e) {
      if (!e) return "*";
      try {
        return new URL(e).origin;
      } catch {
        return "*";
      }
    }
    if (t && r({
      event: (e, n, o, r) => t(o, r)
    }), e === window) return 0 !== Object.keys(l).length ? Promise.resolve(l) : function () {
      const e = window;
      return e && "CefSharp" in e;
    }() ? new Promise(async e => {
      await CefSharp.BindObjectAsync("connectWsApiIntegrator");
      const t = {
        postMessage: (e, t) => {
          const n = JSON.stringify(e);
          connectWsApiIntegrator.postMessage(n);
        }
      };
      e(await o(t, "*"));
    }) : function () {
      const e = window;
      return e && e.chrome && e.chrome.webview;
    }() ? o({
      postMessage: (e, t) => {
        window.chrome.webview.postMessage(e);
      }
    }, "*") : Promise.resolve(l);
    if ((c = e) && "function" == typeof c.postMessage) return o(e, "*");
    if (function (e) {
      return e && "object" == typeof e.contentWindow;
    }(e)) {
      const t = [];
      return t.push(new Promise((t, n) => {
        const r = async () => {
          e.removeEventListener("load", r), e.contentWindow ? t(await o(e.contentWindow, s(e.src))) : n(new Error("Cannot access the target content window"));
        };
        e.addEventListener("load", r);
      })), e.contentWindow && t.push(o(e.contentWindow, s(e.src))), Promise.race(t);
    }
    return Promise.reject(new Error("Target must be a window or an iframe"));
    var c;
  }(e, t, n);
}
function O(e) {
  return d(e);
}
function E(t, n, o, r) {
  !function (t, n, o, r) {
    const i = {
      scope: e,
      type: "event",
      event: o,
      data: r
    };
    t.postMessage(i, n);
  }(t, n, o, r);
}
function T(e) {
  return s(e);
}
function k(e = "prod") {
  return `https://${{
    int: "web.int",
    qa: "web.qa",
    stage: "web.stage",
    prod: "web"
  }[e]}.connect.trimble.com?isEmbedded=true`;
}
!function (e) {
  e[e.SORT_NONE = 0] = "SORT_NONE", e[e.SORT_UP = 1] = "SORT_UP", e[e.SORT_DOWN = -1] = "SORT_DOWN";
}(I || (I = {}));
export { S as PropertyType, I as SortDirection, _ as TOOLS, M as TOOL_SNAP_TYPES, A as connect, T as dispatcherEventListener, O as expose, k as getConnectEmbedUrl, E as sendEvent };