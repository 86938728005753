import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {SearchStateService} from '../../services/search-state.service';
import {SelectableMetadataItem} from '../../model/metadata';
import {Role} from 'api/models/role';
import {AppFeatureFlag} from '../../modules/shared/feature-flag/app-feature-flags';

@Component({
    selector: 'app-apps',
    templateUrl: './apps.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppsComponent implements OnInit {
    showKaartenservice$ = this.authService.hasRole$(Role.KaartenService);
    showPRSApp$ = this.authService.hasRole$(Role.PRS_Editor);
    appFeatureFlags = AppFeatureFlag;

    constructor(
        private authService: AuthService, private searchStateService: SearchStateService) {
    }

    ngOnInit(): void {
        this.searchStateService.pointResults$.next([]);
    }

    openPRSOverlay() {
        this.searchStateService.setSelectedItem({
            item: {url: ''},
            type: 'prs'
        } as SelectableMetadataItem);
    }
}
