"use strict";

const t = typeof document > "u",
  e = {
    get userAgent() {
      return t ? "" : navigator.userAgent;
    },
    get touch() {
      return t ? !1 : "ontouchstart" in window || navigator.maxTouchPoints > 0;
    },
    get chrome() {
      return t ? !1 : /(Chrome|CriOS)/.test(e.userAgent);
    },
    get firefox() {
      return t ? !1 : /Firefox/.test(e.userAgent);
    },
    get safari() {
      return t ? !1 : !e.chrome && /(Version|PhantomJS)\/(\d+\.\d+).*Safari/.test(e.userAgent);
    },
    get webkit() {
      return t ? !1 : /webkit/.test(e.userAgent);
    },
    get windows() {
      return t ? !1 : navigator.platform.indexOf("Win") !== -1;
    },
    get macOS() {
      return t ? !1 : !!navigator.userAgent.match(/Macintosh|Mac OS X/i);
    },
    get iOS() {
      return t ? !1 : !!navigator.platform.match(/iPhone|iPad|iPod/) || !!(e.userAgent.match(/Mac/) && "ontouchend" in document);
    },
    get android() {
      return t ? !1 : !e.windows && /Android/.test(e.userAgent);
    },
    get androidPhone() {
      return t ? !1 : e.android && /(?=android)(?=.*mobile)/i.test(e.userAgent);
    },
    get ipad() {
      return t ? !1 : /ipad/i.test(e.userAgent) || /Macintosh/i.test(e.userAgent) && "ontouchend" in document;
    },
    _isPhone() {
      return u(), e.touch && !r;
    }
  };
let o, i, r;
const s = () => {
    if (t || !e.windows) return !1;
    if (o === void 0) {
      const n = e.userAgent.match(/Windows NT (\d+).(\d)/);
      o = n ? parseFloat(n[1]) : 0;
    }
    return o >= 8;
  },
  c = () => {
    if (t || !e.webkit) return !1;
    if (i === void 0) {
      const n = e.userAgent.match(/(webkit)[ /]([\w.]+)/);
      i = n ? parseFloat(n[1]) : 0;
    }
    return i >= 537.1;
  },
  u = () => {
    if (t) return !1;
    if (r === void 0) {
      if (e.ipad) {
        r = !0;
        return;
      }
      if (e.touch) {
        if (s()) {
          r = !0;
          return;
        }
        if (e.chrome && e.android) {
          r = !/Mobile Safari\/[.0-9]+/.test(e.userAgent);
          return;
        }
        let n = window.devicePixelRatio ? window.devicePixelRatio : 1;
        e.android && c() && (n = 1), r = Math.min(window.screen.width / n, window.screen.height / n) >= 600;
        return;
      }
      r = e.userAgent.indexOf("Touch") !== -1 || e.android && !e.androidPhone;
    }
  },
  l = () => e.touch,
  h = () => e.safari,
  g = () => e.chrome,
  b = () => e.firefox,
  a = () => (u(), (e.touch || s()) && r),
  d = () => e._isPhone(),
  f = () => t ? !1 : !a() && !d() || s(),
  m = () => a() && f(),
  w = () => e.iOS,
  A = () => e.macOS,
  P = () => e.android || e.androidPhone;
export { e as internals, l as supportsTouch, h as isSafari, g as isChrome, b as isFirefox, d as isPhone, a as isTablet, f as isDesktop, m as isCombi, w as isIOS, P as isAndroid, A as isMac };
