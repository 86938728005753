"use strict";

import { DEFAULT_THEME as l } from "../generated/AssetParameters.js";
import { mergeStyles as T } from "../ManagedStyles.js";
import { fireThemeRegistered as p } from "../theming/ThemeRegistered.js";
const y = new Map(),
  h = new Map(),
  S = new Map(),
  u = new Set(),
  i = new Set(),
  f = (e, t, r) => {
    h.set(`${e}/${t}`, r), u.add(e), i.add(t), p(t);
  },
  m = (e, t, r) => {
    S.set(`${e}/${t}`, r);
  },
  P = async (e, t, r) => {
    const a = `${e}_${t}_${r || ""}`,
      s = y.get(a);
    if (s !== void 0) return s;
    if (!i.has(t)) {
      const c = [...i.values()].join(", ");
      return console.warn(`You have requested a non-registered theme ${t} - falling back to ${l}. Registered themes are: ${c}`), g(e, l);
    }
    const [o, d] = await Promise.all([g(e, t), r ? g(e, r, !0) : void 0]),
      n = T(o, d);
    return n && y.set(a, n), n;
  },
  g = async (e, t, r = !1) => {
    const s = (r ? S : h).get(`${e}/${t}`);
    if (!s) {
      r || console.error(`Theme [${t}] not registered for package [${e}]`);
      return;
    }
    let o;
    try {
      o = await s(t);
    } catch (n) {
      console.error(e, n.message);
      return;
    }
    return o._ || o;
  },
  $ = () => u,
  D = e => i.has(e);
export { f as registerThemePropertiesLoader, m as registerCustomThemePropertiesLoader, P as getThemeProperties, $ as getRegisteredPackages, D as isThemeRegistered };
