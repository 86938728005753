import {Component} from '@angular/core';
import {Params} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {InformationBarService} from '../../services/information-bar.service';

@Component({
    selector: 'app-mobile-navbar',
    templateUrl: './mobile-navbar.component.html',
    standalone: false
})
export class MobileNavbarComponent {
    // Add explicit undefined queryParams to make sure filters query etc are removed when navigation from the navbar
    queryParams: Params = {
        item: undefined,
        query: undefined,
        filter: undefined,
        favorite: undefined,
        page: undefined,
        id: undefined,
        type: undefined
    };

    routes: { route: string; icon: string; tooltip: string }[][] = [
        [
            {
                route: '/search',
                icon: '/assets/icons/icon-24-search.svg',
                tooltip: 'Home'
            },
            {
                route: '/projects',
                icon: '/assets/icons/icon-project.svg',
                tooltip: 'Projecten'
            },
            {
                route: '/companies',
                icon: '/assets/icons/icon-company.svg',
                tooltip: 'Bedrijven'
            }
        ]
    ];

    initials$ = this.authService.initials$;

    constructor(
        private authService: AuthService,
        public infoBarService: InformationBarService,
    ) {
    }
}
