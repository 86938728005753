import {Injectable} from '@angular/core';
import {PublicClientApplication} from '@azure/msal-browser';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';
import {AccountInfo, AuthenticationResult} from '@azure/msal-common';
import {environment} from '../../environments/environment';
import {filter, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {getInheritedRoles, Role, legacyRoleToRoleMapping} from 'api/models/role';
import { HttpClient } from '@angular/common/http';
import {GraphGroup, GraphUser} from '../model/auth';

export enum AuthenticationStatus {
    Pending = 'Pending',
    Unauthenticated = 'Unauthenticated',
    Authenticated = 'Authenticated',
}

@Injectable({
    providedIn: 'root'
})
export class AuthService implements AuthService {
    private statusSubject = new BehaviorSubject<AuthenticationStatus>(AuthenticationStatus.Pending);
    private msalInstance = new PublicClientApplication({
        auth: {
            clientId: environment.authClientId,
            authority: environment.authAuthority,
            redirectUri: '/',
            navigateToLoginRequestUrl: true
        },
        cache: {
            cacheLocation: 'localStorage'
        }
    });

    readonly status$ = this.statusSubject.asObservable();
    readonly roles$ = this.statusSubject.pipe(
        filter(it => it === AuthenticationStatus.Authenticated),
        switchMap(async status => {
            if (status === AuthenticationStatus.Authenticated) {
                const token = await this.getTokenInternal();
                const claims: { [key: string]: any } = token.idTokenClaims;
                return getInheritedRoles(claims.roles?.map(it => legacyRoleToRoleMapping[it] ?? it) ?? []);
            } else {
                return null;
            }
        }),
        shareReplay({bufferSize: 1, refCount: true})
    );
    readonly me$ = this.statusSubject.pipe(
        filter(it => it === AuthenticationStatus.Authenticated),
        switchMap(async () => {
            const token = await this.getTokenInternal();
            return await lastValueFrom(this.http.get<GraphUser>(
                'https://graph.microsoft.com/v1.0/me?$select=mail,userPrincipalName,onPremisesExtensionAttributes', {
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`
                    }
                }));
        }),
        shareReplay({bufferSize: 1, refCount: true})
    );

    readonly meMemberOf$ = this.statusSubject.pipe(
        filter(it => it === AuthenticationStatus.Authenticated),
        switchMap(async () => {
            const token = await this.getTokenInternal();

            return await lastValueFrom(this.http.get<{ value: GraphGroup[] }>(
                'https://graph.microsoft.com/v1.0/me/memberOf', {
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`
                    }
                }));
        }),
        shareReplay({bufferSize: 1, refCount: true})
    );

    readonly email$ = this.me$.pipe(
        map(response => response.mail || response.userPrincipalName)
    );
    readonly companyNames$ = this.me$.pipe(
        map(response => response.onPremisesExtensionAttributes.extensionAttribute8?.split(',') ?? [])
    );

    readonly initials$ = this.status$.pipe(
        filter(it => it === AuthenticationStatus.Authenticated),
        map(() => {
            let name = this.getAccount()?.name || '';
            if (name.indexOf(',') !== -1) {
                name = name.split(',').reverse().join(' ');
            }

            return name.split('').filter(letter => /[A-Z]/.test(letter)).join('');
        })
    );

    constructor(private http: HttpClient) {
    }

    init(): void {
        this.msalInstance.handleRedirectPromise().then((tokenResponse) => {
            // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
            // If the tokenResponse === null, you are not coming back from an auth redirect.
            if (tokenResponse !== null) {
                this.statusSubject.next(AuthenticationStatus.Authenticated);
            } else {
                if (this.getMsalAccounts().length === 0) {
                    return this.loginRedirect();
                } else {
                    this.getTokenInternal()
                        .then(() => this.statusSubject.next(AuthenticationStatus.Authenticated))
                        .catch(error => {
                            console.error(error);
                            this.statusSubject.next(AuthenticationStatus.Unauthenticated);

                            // TODO: Token refreshing if token is invalid
                            return this.loginRedirect();
                        });
                }
            }
        }).catch((error) => {
            // handle error, either in the library or coming back from the server
            console.error('Redirect handling failed: ', error);
            this.statusSubject.next(AuthenticationStatus.Unauthenticated);
        });
    }

    async getToken(): Promise<AuthenticationResult> {
        await lastValueFrom(this.status$.pipe(
            filter(it => it === AuthenticationStatus.Authenticated),
            take(1)
        ));
        return this.getTokenInternal();
    }

    logout() {
        return this.msalInstance.logout({
            account: this.getAccount(),
            postLogoutRedirectUri: window.location.origin
        });
    }

    getAccount(): AccountInfo {
        const accounts = this.getMsalAccounts();
        return accounts.length > 0 ? accounts[0] : null;
    }

    hasRole$(role: Role): Observable<boolean> {
        return this.roles$.pipe(map(roles => roles && roles.has(role)));
    }

    private getTokenInternal(): Promise<AuthenticationResult> {
        return this.msalInstance.acquireTokenSilent({
            account: this.getAccount(),
            scopes: []
        });
    }

    private getMsalAccounts(): AccountInfo[] {
        const accounts = this.msalInstance.getAllAccounts();

        return accounts && accounts.length > 0 ? accounts : [];
    }

    private loginRedirect() {
        return this.msalInstance.loginRedirect({
            scopes: environment.authScopes,
            prompt: 'select_account'
        });
    }
}
