@if (favorite) {
  <app-active-filter class="mb-4 block" (removeOption)="removeFavorite()">
    Favorieten
  </app-active-filter>
}

@for (group of filter|keyvalue; track group) {
  @if (group.value.length === 1) {
    <div>
      @for (option of group.value; track option) {
        <app-active-filter (removeOption)="removeOption(group.key, option)">
          {{group.key === 'kvw_number' ? kvwNumbers[group.value] : filterValueTranslations[option] ?? option }}
        </app-active-filter>
      }
    </div>
  }
  @if (group.value.length > 1) {
    <div>
      <app-active-filter (removeOption)="removeRangeOption(group.key)">
        @if (isDateFilter(group.key)) {
          <div>
            {{ group.value[0] | date }} - {{ group.value[1] | date }}
          </div>
        } @else {
          {{ group.value[0] }} - {{ group.value[1] }}
        }
      </app-active-filter>
    </div>
  }
}
