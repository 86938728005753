import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {filter, retry, tap} from 'rxjs/operators';
import {UserPreferences} from 'api/models/user-preferences';

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService {

    private userPreferencesSubject = new BehaviorSubject<UserPreferences|null>(null);

    get value() {
        return this.userPreferencesSubject.value;
    }

    constructor(private http: HttpClient) {}

    asObservable(): Observable<UserPreferences> {
        return this.userPreferencesSubject.asObservable().pipe(filter(it => it !== null));
    }

    init() {
        this.http.get<UserPreferences>(`${environment.azureBaseUrl}/user-preferences`).pipe(
            retry(3)
        ).subscribe(userPreferences => this.userPreferencesSubject.next(userPreferences));
    }

    save(userPreferences: UserPreferences) {
        return this.http.post(`${environment.azureBaseUrl}/user-preferences`, userPreferences).pipe(
            tap(_ => this.userPreferencesSubject.next(userPreferences))
        );
    }

    patch(userPreferences: Partial<UserPreferences>) {
        const newPreferences = {
            ...this.userPreferencesSubject.value,
            ...userPreferences
        };
        return this.save(newPreferences);
    }
}
