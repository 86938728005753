@if (poiData$ | async; as poiData) {
  <div class="p-4">
    <div class="ml-2 mr-4 py-6 flex space-x-2 items-center">
      <button
        class="w-4 h-4 cursor-pointer"
        inlineSVG="/assets/icons/icon-arrow-left.svg"
        [setSVGAttributes]="{class: 'block'}"
        (click)="goBack()"
      ></button>
      <h1 appTitle="lg" class="m-0">{{poiData.poi.name}}</h1>
    </div>
    <h2 appTitle class="mb-4">Publieke informatie</h2>
    <div class="flex mb-2">
      <div class="w-1/3 flex-shrink-0 font-medium text-neutral-1">Adres</div>
      <div>{{poiData.address.freeformAddress}}</div>
    </div>
    @if (poiData.poi.phone) {
      <div class="flex mb-2">
        <div class="w-1/3 flex-shrink-0 font-medium text-neutral-1">Telefoonnummer</div>
        <div>{{poiData.poi.phone}}</div>
      </div>
    }
    <div class="flex mb-2">
      <div class="w-1/3 flex-shrink-0 font-medium text-neutral-1">Categorie</div>
      <div>
        @for (category of poiData.poi.categories; track category) {
          <div class="capitalize">{{category}}</div>
        }
      </div>
    </div>
  </div>
} @else {
  <div class="p-8">
    <vwui-spinner></vwui-spinner>
  </div>
}

