"use strict";

const e = {
  toAttribute(t) {
    return t instanceof HTMLElement ? null : t;
  },
  fromAttribute(t) {
    return t;
  }
};
export default e;
