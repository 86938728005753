import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, merge, of, Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SearchOverviewComponent} from '../search-overview/search-overview.component';
import {DEFAULT_PAGESIZE, emptyResults, SearchStateService} from '../../services/search-state.service';
import {ApiService} from '../../services/api.service';
import {filter, withLatestFrom} from 'rxjs/operators';
import {PointResult} from '../../model/search-response';
import {SearchCompany} from '../../model/search-company';
import {SearchProject} from '../../model/search-project';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {ScreenService} from '../../services/screen.service';
import {MobileService} from '../../services/mobile.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    standalone: false
})
export class SearchComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    defaultQueryParams: Params = {
        page: 0,
        pageSize: DEFAULT_PAGESIZE,
        sort: ''
    };

    results$ = merge(of(emptyResults), this.searchStateService.searchResults$);

    form = new UntypedFormGroup({
        query: new UntypedFormControl('')
    });

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private searchStateService: SearchStateService,
        private apiService: ApiService,
        private mapboxService: MapboxService,
        private mobileService: MobileService,
        private screenService: ScreenService
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(this.route.queryParamMap.subscribe(queryParams => {
                this.form.patchValue({
                    query: queryParams.get('query')
                }, {emitEvent: false});
                this.mobileService.navbarEnabled$.next(
                    this.isCurrentRouteOverview() ? true : false
                );
            }),
            this.results$.pipe(
                withLatestFrom(this.screenService.screen$,  (results, screen) => ({results, screen})),
                    filter(({results}) => results.project.count > 0 || results.company.count > 0))
                .subscribe(({results, screen}) => {
                    const pointResults: PointResult[] = [];
                    pointResults.push(...results.company.value.map((item: SearchCompany) => ({
                        ...item, title: item.naam, id: item.kvw_nummer
                    } as PointResult)));
                    pointResults.push(...results.project.value.map((item: SearchProject) => ({
                        ...item, title: item.naam, id: item.id
                    } as PointResult)));
                    this.searchStateService.pointResults$.next(pointResults);
                    if (screen === 'desktop') {
                        const filteredResults = (pointResults.filter(result => result.geog !== null))
                            .map(result => result.geog.coordinates);
                        this.mapboxService.moveToLocation(filteredResults);
                    }
                }),
            combineLatest([
                this.results$.pipe(filter(results => results.project.count === 0 && results.company.count === 0)),
                this.apiService.allCompaniesAndProjectsForMap$
            ]).subscribe(([_, points ]) => {
                this.searchStateService.pointResults$.next(points);
            }),
            this.form.valueChanges.subscribe(({query}) => {
                let newRoute = [];
                if (this.isCurrentRouteOverview() && query) {
                    newRoute = ['/search/all'];
                }
                if (!this.isCurrentRouteOverview() && !query) {
                    newRoute = ['/search'];
                }

                this.router.navigate(
                    newRoute,
                    {
                        relativeTo: this.route,
                        queryParamsHandling: 'merge',
                        queryParams: {query}
                    }
                ).catch(e => {
                    console.error('search failed', e);
                });
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    isCurrentRouteOverview() {
        return this.route.firstChild.component === SearchOverviewComponent;
    }

    isActiveTab(name: string) {
        return window.location.pathname === `/search/${name}`;
    }

    scrollToTop() {
        this.mobileService.scrollToTop();
    }
}
