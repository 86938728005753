"use strict";

import r from "../util/createLinkInHead.js";
import s from "../validateThemeRoot.js";
import { getThemeRoot as a } from "../InitialConfiguration.js";
import { getTheme as m } from "./Theme.js";
import { attachConfigurationReset as d } from "./ConfigurationReset.js";
let t;
d(() => {
  t = void 0;
});
const n = () => (t === void 0 && (t = a()), t),
  c = e => {
    if (t !== e) {
      if (t = e, !s(e)) {
        console.warn(`The ${e} is not valid. Check the allowed origins as suggested in the "setThemeRoot" description.`);
        return;
      }
      return i(m());
    }
  },
  u = e => `${n()}Base/baseLib/${e}/css_variables.css`,
  i = async e => {
    const o = document.querySelector(`[sap-ui-webcomponents-theme="${e}"]`);
    o && document.head.removeChild(o), await r(u(e), {
      "sap-ui-webcomponents-theme": e
    });
  };
export { n as getThemeRoot, c as setThemeRoot, i as attachCustomThemeStylesToHead };
