"use strict";

import i from "../locale/getLocale.js";
import { attachLanguageChange as b } from "../locale/languageChange.js";
import y from "../locale/normalizeLocale.js";
import D from "../locale/nextFallbackLocale.js";
import { DEFAULT_LANGUAGE as c } from "../generated/AssetParameters.js";
import { getFetchDefaultLanguage as p } from "../config/Language.js";
const d = new Set(),
  m = new Set(),
  g = new Map(),
  l = new Map(),
  u = new Map(),
  $ = (n, t, e) => {
    const r = `${n}/${t}`;
    u.set(r, e);
  },
  f = (n, t) => {
    g.set(n, t);
  },
  A = n => g.get(n),
  h = (n, t) => {
    const e = `${n}/${t}`;
    return u.has(e);
  },
  B = (n, t) => {
    const e = `${n}/${t}`,
      r = u.get(e);
    return r && !l.get(e) && l.set(e, r(t)), l.get(e);
  },
  M = n => {
    d.has(n) || (console.warn(`[${n}]: Message bundle assets are not configured. Falling back to English texts.`, ` Add \`import "${n}/dist/Assets.js"\` in your bundle and make sure your build tool supports dynamic imports and JSON imports. See section "Assets" in the documentation for more information.`), d.add(n));
  },
  L = (n, t) => t !== c && !h(n, t),
  w = async n => {
    const t = i().getLanguage(),
      e = i().getRegion(),
      r = i().getVariant();
    let s = t + (e ? `-${e}` : "") + (r ? `-${r}` : "");
    if (L(n, s)) for (s = y(s); L(n, s);) s = D(s);
    const I = p();
    if (s === c && !I) {
      f(n, null);
      return;
    }
    if (!h(n, s)) {
      M(n);
      return;
    }
    try {
      const o = await B(n, s);
      f(n, o);
    } catch (o) {
      const a = o;
      m.has(a.message) || (m.add(a.message), console.error(a.message));
    }
  };
b(n => {
  const t = [...g.keys()];
  return Promise.all(t.map(w));
});
export { $ as registerI18nLoader, w as fetchI18nBundle, A as getI18nBundleData };
