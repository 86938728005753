"use strict";

import o from "../EventProvider.js";
const e = new o(),
  t = "configurationReset",
  i = n => {
    e.attachEvent(t, n);
  },
  r = () => {
    e.fireEvent(t, void 0);
  };
export { i as attachConfigurationReset, r as resetConfiguration };
