import {Component, Input} from '@angular/core';
import {Project} from 'api/models/asset/project';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {Company} from 'api/models/asset/company';
import {UserSelected} from 'api/models/user-selected';
import {SelectionService} from '../../services/selection.service';

@Component({
    selector: 'app-is-selected',
    templateUrl: './is-selected.html',
    standalone: false
})
export class IsSelectedComponent {
    @Input() set project(project: Project) {
        this.projectSubject.next(project);
    }
    @Input() set company(company: Company) {
        this.companySubject.next(company);
    }

    projectSubject = new BehaviorSubject<Project>(null);
    companySubject = new BehaviorSubject<Company>(null);

    isSelected$ = combineLatest([this.projectSubject, this.companySubject, this.selectionService.selection$]).pipe(
        map(([project, company, selection]) => {
            if (project === null && company === null) {
                return false;
            }
            return selection.some(
                    (selected: UserSelected) => project && selected.type === 'project' && selected.id === project.id
                ) || selection.some(
                    (selected: UserSelected) => company && selected.type === 'company' && selected.id === `${company.kvw_nummer}`
                );
        }),
    );

    constructor(
        private selectionService: SelectionService
    ) {}
}
