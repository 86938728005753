import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, finalize, switchMap, take, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {Location} from '@angular/common';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {Pand} from 'api/models/asset/pand';
import {lastValueFrom, Subscription} from 'rxjs';
import {Verblijfsobject} from 'api/models/asset/verblijfsobject';
import {SearchStateService} from '../../services/search-state.service';
import {LngLatLike} from 'mapbox-gl';


@Component({
    selector: 'app-pand-detail',
    templateUrl: './pand-detail.component.html',
    standalone: false
})
export class PandDetailComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    metadata$ = this.activatedRoute.paramMap.pipe(
        tap(() => this.loading = true),
        switchMap(paramMap => {
            return this.apiService.getMetadata<Pand>('pand', paramMap.get('identificatie'))
                .pipe(finalize(() => this.loading = false));
        }),
    );
    loading = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private mapboxService: MapboxService,
        private searchStateService: SearchStateService,
        private router: Router,
        public location: Location
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.loadMap().then(async () => {
            this.metadata$.subscribe((pand) => {
                if (pand.asset.geog !== null) {
                    this.mapboxService.map.flyTo({
                        center: pand.asset.geog.coordinates as LngLatLike,
                        zoom: 17,
                        pitch: 50,
                        bearing: 0
                    });
                }

            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
        // Remove additional point results on closing of page
        this.searchStateService.pointResults$.next([]);
    }

    async loadMap() {
        if (!this.mapboxService.mapLoaded$.value) {
            await lastValueFrom(this.mapboxService.mapLoaded$.pipe(filter(it => it), take(1)));
        }
    }

    getTitle(verblijfsobjecten: Verblijfsobject[]): string {
        if (verblijfsobjecten.length === 0) {
            return 'Geen adres';
        }
        const first = verblijfsobjecten[0];
        const last = verblijfsobjecten[verblijfsobjecten.length - 1];
        return `${first.openbareruimteNaam} ${first.huisnummer} t/m ${last.huisnummer} ${first.postcode} ${first.woonplaatsNaam ?? ''}`;
    }

    onVerblijfsObjectClicked(item: Verblijfsobject, pandRef: string) {
        this.router.navigate(['/verblijfsobject', item.identificatie], {
            queryParamsHandling: 'merge',
            queryParams: {
                id: pandRef.replace('NL.IMBAG.Pand.', ''),
                type: 'pand'
            }
        });
    }
}
