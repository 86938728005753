import {
    Component,
    Input,
} from '@angular/core';
import {ProjectTeamMember} from 'api/models/asset/project';
import {Router} from '@angular/router';

@Component({
    selector: 'app-contact-card',
    templateUrl: './contact-card.html',
    standalone: false
})
export class ContactCardComponent{
    @Input() projectTeamMember: ProjectTeamMember;

    constructor(private router: Router) {
    }

    gotoTeamMemberPage(upn: string) {
        this.router.navigate([
            'project-team-member',
            btoa(upn) // need to encode to pass the waf
        ]);
    }
}
