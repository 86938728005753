<div class="grid desktop:grid-cols-searchGrid gap-4 mobile:grid-cols-2">
  @for (company of companies; track company) {
    <app-company-tile
      [company]="company"
      (clicked)="openCompanyDetailPage($event)"
    ></app-company-tile>
  }
  @if (companies.length < 2) {
    <div></div>
  }
  @if (companies.length < 3) {
    <div></div>
  }
</div>
