"use strict";

const r = 10;
class l {
  constructor() {
    this.list = [], this.lookup = new Set();
  }
  add(t) {
    this.lookup.has(t) || (this.list.push(t), this.lookup.add(t));
  }
  remove(t) {
    this.lookup.has(t) && (this.list = this.list.filter(e => e !== t), this.lookup.delete(t));
  }
  shift() {
    const t = this.list.shift();
    if (t) return this.lookup.delete(t), t;
  }
  isEmpty() {
    return this.list.length === 0;
  }
  isAdded(t) {
    return this.lookup.has(t);
  }
  process(t) {
    let e;
    const s = new Map();
    for (e = this.shift(); e;) {
      const i = s.get(e) || 0;
      if (i > 10) throw new Error("Web component processed too many times this task, max allowed is: 10");
      t(e), s.set(e, i + 1), e = this.shift();
    }
  }
}
export default l;
