import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {NavigationList} from '../../model/navigation-list';
import {FaqModalComponent} from '../../components/faq-modal/faq-modal.component';
import {ContactModalComponent} from '../../components/contact-modal/contact-modal.component';
import {DisclaimerModalComponent} from '../../components/disclaimer-modal/disclaimer-modal.component';
import {VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {Subscription} from 'rxjs';
import {MobileService} from '../../services/mobile.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    standalone: false
})
export class AccountComponent implements OnInit, OnDestroy {
    @Output() accountOpen = new EventEmitter<void>();
    userName: string;
    initials$ = this.authService.initials$;
    email$ = this.authService.email$;
    vwuiModalRef: VwuiModalRef;
    navigationListItems: NavigationList[] = [];
    defaultNavigationListItems: NavigationList[] = [
        {
            title: 'FAQ',
            description: '',
            linkedPage: 'faq'
        },
        {
            title: 'Contact',
            description: '',
            linkedPage: 'contact'
        },
        {
            title: 'Disclaimer',
            description: '',
            linkedPage: 'disclaimer'
        }
    ];
    mobileNavigationListItems: NavigationList[] = [
        {
            title: 'Mijn Collecties',
            description: '',
            linkedPage: 'collections'
        }
    ];

    subscriptions: Subscription[] = [];

    constructor(
        private authService: AuthService,
        private mobileService: MobileService,
        private modalService: VwuiModalService,
        private router: Router
    ) {
    }

    logOut() {
        this.authService.logout();
    }

    navigateTo(name: string) {
        if (this.vwuiModalRef) {
            return;
        }
        switch (name) {
            case 'faq':
                this.vwuiModalRef = this.modalService.open(FaqModalComponent, {
                    closeOnBackdropClick: false
                });
                this.accountOpen.emit();
                break;
            case 'contact':
                this.vwuiModalRef = this.modalService.open(ContactModalComponent, {
                    closeOnBackdropClick: false
                });
                this.accountOpen.emit();
                break;
            case 'disclaimer':
                this.vwuiModalRef = this.modalService.open(DisclaimerModalComponent, {
                    closeOnBackdropClick: false
                });
                this.accountOpen.emit();
                break;
            case 'collections':
                this.router.navigate(['collections'], {});
        }
        this.vwuiModalRef?.afterClosed.subscribe(() => {
            this.vwuiModalRef = undefined;
        });
    }

    ngOnInit(): void {
        this.userName = this.authService.getAccount().name;
        if (this.userName.indexOf(',') !== -1) {
            this.userName = this.userName.split(',').reverse().join(' ');
        }

        this.subscriptions.push(
            this.mobileService.isOnMobile$.subscribe(isOnMobile => {
                this.navigationListItems = isOnMobile
                    ? [...this.defaultNavigationListItems, ...this.mobileNavigationListItems]
                    : this.defaultNavigationListItems;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }
}
