@if (projects?.length) {
  <div
    class="grid gap-4 mobile:gap-3"
    [class.loading-blur]="isLoading"
    [ngClass]="threeColumns ? 'grid-cols-3' : 'grid-cols-2'"
    >
    @for (project of projects; track project; let i = $index) {
      <app-project-tile
        [project]="project"
        [style.grid-row]="!masonryGrid ? tileHeightDefault : (i % 2 === 0) ? tileHeightLarge : tileHeightDefault"
      ></app-project-tile>
    }
    @if (projects.length < 2) {
      <div></div>
    }
    @if (projects.length < 3) {
      <div></div>
    }
  </div>
}
