import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchWoonplaats} from '../../model/search-woonplaats';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {ScreenService} from '../../services/screen.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-woonplaats-list',
    templateUrl: './woonplaats-list.component.html',
    standalone: false
})
export class WoonplaatsListComponent implements OnInit, OnDestroy {
    @Input() items: SearchWoonplaats[] = [];
    @Input() addEmptyStateRow = false;
    private subscriptions: Subscription[] = [];
    isOnMobile = false;
    constructor(public mapbox: MapboxService,
                public screenService: ScreenService) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.screenService.screen$.subscribe(screen => {
                this.isOnMobile = screen === 'mobile';
            })
        );
    }

    ngOnDestroy() {
        if(!this.isOnMobile) {
            this.mapbox.removeWoonplaatsHighlight();
        }
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }
}
