"use strict";

import g from "../EventProvider.js";
const e = new g(),
  n = "languageChange",
  t = a => {
    e.attachEvent(n, a);
  },
  r = a => {
    e.detachEvent(n, a);
  },
  o = a => e.fireEventAsync(n, a);
export { t as attachLanguageChange, r as detachLanguageChange, o as fireLanguageChange };
