<div class="px-6">
  <div class="h-24 flex items-center space-x-3">
    <button routerLink="/layers"
      inlineSVG="/assets/icons/icon-arrow-left.svg"
      [setSVGAttributes]="{width: 16, height: 16}"
    ></button>
    <h1 appTitle="lg" class="m-0">Points of interest</h1>
  </div>

  @for (group of POICategoryTree; track group) {
    <div class="border-t border-neutral-3 pt-3 pb-5">
      <app-checkbox class="mb-2 block"
        [isChecked]="!!activeCategoriesByGroup && activeCategoriesByGroup[group.name]?.catIds.length === group.categories.length"
        [isHalfChecked]="!!activeCategoriesByGroup && activeCategoriesByGroup[group.name]?.catIds.length > 0 && activeCategoriesByGroup[group.name]?.catIds.length !== group.categories.length"
        (toggleCheckbox)="toggleGroupCategories(group.name, activeCategoriesByGroup[group.name].catIds.length !== group.categories.length)"
        >
        <h4>{{group.name}}</h4>
      </app-checkbox>
      @for (category of group.categories; track category) {
        <div class="flex justify-between items-center">
          <app-checkbox class="mb-0.5 block"
            [isChecked]="!!activeCategoriesByGroup && activeCategoriesByGroup[group.name]?.catIds.includes(category.id)"
            [isLoading]="loadingCategories.includes(category.id)"
            (toggleCheckbox)="toggleCategory($event, category.id)"
            >
            <div class="flex items-center space-x-2">
              <div [inlineSVG]="getIconUrl(category.icon)" [setSVGAttributes]="{width: 24, height: 24}"></div>
              <div>{{category.name}}</div>
            </div>
          </app-checkbox>
        </div>
      }
    </div>
  }
</div>
