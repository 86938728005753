import {Component, OnDestroy, OnInit} from '@angular/core';
import {lastValueFrom, Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {ApiService} from '../../services/api.service';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ShareLocationRequest} from 'api/models/share-location-request';
import {SendLocationModalData} from '../../model/send-location-modal-data';

@Component({
    selector: 'app-send-location-modal',
    templateUrl: './send-location-modal.component.html',
    standalone: false
})
export class SendLocationModalComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    form: UntypedFormGroup;
    sendingLocation = false;

    constructor(private authService: AuthService,
                private apiService: ApiService,
                private toastrService: ToastrService,
                public modalRef: VwuiModalRef,
                public modalConfig: VwuiModalConfig<SendLocationModalData>) {

        this.form = new UntypedFormGroup({
            email: new UntypedFormControl('', [Validators.email, Validators.required]),
        });
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.authService.email$.subscribe(email => {
                const formControl = this.form.get('email');
                if (formControl.value === '') {
                    formControl.setValue(email);
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    async sendLocation() {
        this.sendingLocation = true;
        const { title, point } = this.modalConfig.data;
        const shareLocationRequest = {
            email: this.form.value.email,
            title,
            point,
            user: this.authService.getAccount().username
        } as ShareLocationRequest;

        return await lastValueFrom(this.apiService.postShareLocation(shareLocationRequest)).then(() => {
            this.toastrService.success('Locatie verzonden naar: ' + shareLocationRequest.email);
        }).catch((e) => {
            this.toastrService.error('Locatie delen mislukt: ' + e.message);
            console.error('Error sharing location ' + e.message);
        }).finally(() => {
            this.sendingLocation = false;
            this.modalRef.close();
        });
    }
}
