<div
  class="flex flex-row items-center p-1 rounded"
  [ngClass]="{'hover:bg-neutral-4 cursor-pointer': clickable}"
  (click)="handleClick()"
  >
  <div class="bg-primary w-8 h-8 flex items-center justify-center rounded mr-4 flex-shrink-0">
    <span [inlineSVG]="icon" class="text-white" [setSVGAttributes]="{width: 16, height: 16}"></span>
  </div>
  <div class="flex flex-row flex-shrink-1 items-center min-w-0">
    <span class="truncate" [innerHTML]="label | searchHighlight:searchTerm"></span>
    @if (favorite) {
      <span inlineSVG="/assets/icons/icon-16-star-filled.svg"
        class="ml-2 -mt-0.5" [setSVGAttributes]="{width: 16, height: 16}"
      ></span>
    }
  </div>
</div>
