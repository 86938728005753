import * as mapboxgl from 'mapbox-gl';
import {
    Mesh,
    ShaderLib,
    ShaderMaterial,
    Color,
    DataTexture,
    RGBAFormat,
    FloatType,
    Vector3,
    Box3,
    sRGBEncoding,
    PlaneGeometry,
    MeshBasicMaterial
} from 'three';

import { environment } from '../../../../environments/environment';
import { Subscription, fromEvent, Subject } from 'rxjs';
import { BuildingColorMode } from 'api/models/building-color-mode';
import { batchIdShader } from './shaders/bag3dShader';


export class Bag3d implements mapboxgl.CustomLayerInterface {

    private tb: any;
    private map: mapboxgl.Map;
    private subscriptions: Subscription[] = [];
    private controller: AbortController = new AbortController();
    private loadedTilesIndex = {};
    private expiredTilesIndex = {};
    private fetching = false;
    private filteredBagPandIds = {};

    private pandDefaultColor = new Color(0xb7bcc3).convertSRGBToLinear();
    private pandHighlightedColor = new Color(0x32A3C9).convertSRGBToLinear();
    private pandSelectedColor = new Color(0x008cbc).convertSRGBToLinear();

    private opacity = 1;

    private colorRanges = [
        { year: 1005, color: new Color('#444444') },
        { year: 1700, color: new Color('#430719') },
        { year: 1750, color: new Color('#740320') },
        { year: 1800, color: new Color('#a50026') },
        { year: 1850, color: new Color('#d73027') },
        { year: 1900, color: new Color('#f46d43') },
        { year: 1925, color: new Color('#fdae61') },
        { year: 1950, color: new Color('#FEE090') },
        { year: 1975, color: new Color('#84c7db') },
        { year: 2000, color: new Color('#5096c2') },
        { year: 2015, color: new Color('#1d66aa') },
        { year: 2021, color: new Color('#117c3c') },
        { year: 9999, color: new Color('#444444') },
    ];

    public minZoom = 14.5;
    public maxZoom = 24;

    public onSelected$ = new Subject<mapboxgl.MapLayerMouseEvent & mapboxgl.EventData>();
    public onContextMenu$ = new Subject<mapboxgl.MapLayerMouseEvent & mapboxgl.EventData>();

    public isOn = false;
    colorMode: any = this.defaultColor;

    readonly type = 'custom';
    readonly renderingMode = '3d';

    constructor(
        public readonly id: string
    ) {
    }


    onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
        this.tb = (window as any).tb;
        this.map = map;
        this.tb.setLayerZoomRange(this.id, this.minZoom, this.maxZoom);
        this.loadModels();
        this.isOn = true;
        this.map.on('moveend', this.loadModels);


    }

    onRemove(map: mapboxgl.Map, gl: WebGLRenderingContext) {
        this.map.off('moveend', this.loadModels);
        const index = this.tb.zoomLayers.indexOf(this.id);
        if (index !== -1) { this.tb.zoomLayers.splice(index, 1); }
        this.clear();
        this.subscriptions.forEach(subscription => subscription.unsubscribe());

        this.isOn = false;
        if (this.fetching) {
            this.controller.abort();
            this.fetching = false;
        }
    }

    render(gl: WebGLRenderingContext, matrix: number[]) {
    }

    toggleOn() {
        this.isOn = true;
        const index = this.tb.zoomLayers.indexOf(this.id);
        if (index === -1) { this.tb.zoomLayers.push(this.id); }

        this.loadModels();
    }

    toggleOff() {
        const index = this.tb.zoomLayers.indexOf(this.id);
        if (index !== -1) { this.tb.zoomLayers.splice(index, 1); }

        this.clear();

        this.isOn = false;
        if (this.fetching) {
            this.controller.abort();
            this.fetching = false;
        }
    }

    clear() {
        this.tb.clear(this.id, false);
        this.loadedTilesIndex = {};
    }
    // we create an array which contains the opacity values of each building as being 0 for on and 1 for off
    // when we filter a building set we use this to substract the opacity from the buildings we want to show less off
    // we should dissallow highlight and selection of these buildings



    filterBagPand(object: { bagid: string; add: boolean }) {
        const bagpandid = object.bagid;

        this.filteredBagPandIds[bagpandid] = object.add;
        if (this.tb && this.map.getLayoutProperty(this.id, 'visibility') === 'visible') {
            for (const model of this.tb.world.children.filter(obj => obj.layer === this.id)) {
                if (!model.userData.properties.attributes[bagpandid] === undefined) {
                    this.updateColorTexture(model);
                }
            }
        }

    }

    setOpacity(opacity: number) {
        // we need to explicitly check the opacity value so our render loop doesnt try to update the opacity every cycle
        if (opacity !== this.opacity) {
            this.opacity = opacity;

            if (this.tb && this.map.getLayoutProperty(this.id, 'visibility') === 'visible') {
                for (const model of this.tb.world.children.filter(obj => obj.layer === this.id)) {
                    model.model.traverse((child: any) => {
                        if (child instanceof Mesh && child.geometry.type === 'BufferGeometry') {
                            child.material.uniforms.opacity.value = this.opacity;
                        }
                    });
                }
                this.tb.repaint();
            }
        }
    }

    setBuildingColorMode(mode: BuildingColorMode) {
        switch (mode) {
            case BuildingColorMode.ENERGYLABEL:
                this.colorMode = this.energylabelColor;
                break;
            case BuildingColorMode.BOUWJAAR:
                this.colorMode = this.bouwjaarColor;
                break;
            case BuildingColorMode.NONE:
                this.colorMode = this.defaultColor;
                break;
            default:
                this.colorMode = this.defaultColor;
        }

        this.updateColorTextures();
    }


    onSelectedChange(e: any) {
        if (!e.detail || !Object.prototype.hasOwnProperty.call(e.detail, 'userData')) {
            return;
        }
        const bagId = e.detail.userData.properties.batchidToBagidLookup[Math.round(e.detail.userData.batchid)];
        if (bagId) {

            e.target.click.features = [{ id: bagId, type: 'Feature', properties: { searchType: 'pand', identificatie: bagId } }];
            e.target.click.layer = 'bag3d';
            this.onSelected$.next(e.target.click);
        }
    }

    onContextMenu(e: any) {
        if (!e.detail || !Object.prototype.hasOwnProperty.call(e.detail, 'userData')) {
            return;
        }
        const bagId = e.detail.userData.properties.batchidToBagidLookup[Math.round(e.detail.userData.batchid)];
        if (bagId) {
            e.features = [{ id: bagId, type: 'Feature', properties: { searchType: 'pand', identificatie: bagId } }];
            e.layer = 'bag3d';
            this.onContextMenu$.next(e);
        }

    }


    loadModels = () => {
        const zoom = this.map.getZoom();
        if (zoom >= this.minZoom && zoom <= this.maxZoom) {
            if (this.fetching) {
                this.controller.abort();
                this.fetching = false;
            }


            const bbx = this.map.getBounds();

            const url = environment.geoserverUrl +
                '/Digibase/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Digibase%3Atileset' +
                '&outputFormat=application%2Fjson&srsName=EPSG%3A4326' +
                `&BBOX=${bbx.getWest()},${bbx.getSouth()},${bbx.getEast()},${bbx.getNorth()},EPSG:4326`;
            this.controller = new AbortController();
            const { signal } = this.controller;
            this.fetching = true;
            fetch(url, { signal })
                .then(response => response.json())
                .then(tileset => {
                    Object.assign(this.expiredTilesIndex, this.loadedTilesIndex);
                    tileset.features.forEach((m: { properties: any }) => {
                        m.properties.uri = `${environment.vectorTilesBlobUrl}/lod-3d-buildings/bag3dlod22/${m.properties.uri}`;

                        if (!this.loadedTilesIndex[m.properties.uri]) {

                            this.loadedTilesIndex[m.properties.uri] = true;
                            this.addModel(this.id, m.properties);
                        }

                        this.expiredTilesIndex[m.properties.uri] = false;

                    });

                }).then(() => {
                    if (this.tb.world.children.filter(obj => obj.layer === this.id).length > 80) {
                        for (const model of this.tb.world.children.filter(obj => obj.layer === this.id).slice(0, 25)) {
                            if (this.expiredTilesIndex[model.uuid]) {
                                this.tb.remove(model);
                                this.loadedTilesIndex[model.uuid] = false;
                                this.expiredTilesIndex[model.uuid] = true;
                            }
                        }
                    }
                    // make sure the lod 1 layer extrusion is set to 0
                    this.fetching = false;
                    this.updateColorTextures();

                })
                .catch(() => { });
            this.tb.repaint();
        }
    };

    public setSelected(bagId: string, selected: boolean) {
        const handler = () => {
            for (const model of this.tb.world.children.filter(obj => obj.layer === this.id)) {

                const bagPand = model.userData.properties.attributes[bagId];
                if (bagPand) {
                    model.model.traverse((child: any) => {
                        if (child instanceof Mesh && child.geometry.type === 'BufferGeometry') {
                            child.material.uniforms.selectedBatchId.value = selected ? bagPand.batchid : -1;
                            child.material.needsUpdate = true;
                        }
                    });
                    this.map.off('idle', handler);
                    break;
                }
            }
        };
        this.map.on('idle', handler);

    }



    private updateColorTextures() {
        if (this.tb && this.map.getLayoutProperty(this.id, 'visibility') === 'visible') {

            for (const model of this.tb.world.children.filter(obj => obj.layer === this.id)) {
                this.updateColorTexture(model);
            }
        }
    }

    private updateColorTexture(model: any) {
        const props = model.userData.properties;
        let colorsList = 'colorsList' in model.userData.properties ? model.userData.properties.colorsList : [];
        const leafSize = Object.keys(props.batchidToBagidLookup).length;
        if (colorsList.length !== leafSize) {
            colorsList = Array(leafSize).fill([0, 0, 0, 1], 0, leafSize);
        }

        for (let j = 0; j <= leafSize; j++) {
            const bagid = props.batchidToBagidLookup[j];
            const color = this.colorMode(props.attributes[bagid]);
            const rgb = new Color(color).convertSRGBToLinear();
            if (this.filteredBagPandIds[bagid]) {
                colorsList[j] = [rgb.r, rgb.g, rgb.b, 0];
            }
            else {
                colorsList[j] = [rgb.r, rgb.g, rgb.b, this.opacity];
            }
        }
        const data = new Float32Array(colorsList.flat());

        const dataTexture = new DataTexture(data, colorsList.length, 1, RGBAFormat, FloatType);
        dataTexture.encoding = sRGBEncoding;
        dataTexture.needsUpdate = true;
        model.userData.properties.colorsList = colorsList;

        model.traverse(child => {
            if (child instanceof Mesh && child.geometry.type === 'BufferGeometry') {
                child.material.uniforms.dataTexture.value = dataTexture;
                child.material.uniforms.maxBatchid.value = (leafSize);
                child.material.uniforms.opacity.value = this.opacity;
                child.material.needsUpdate = true;
            }
        });
    }

    private energylabelColor(value) {
        if (!value) {
            return '#dbd8d5';
        }

        if (!value.energy_class) {
            return '#dbd8d5';
        }

        const energyClass = value.energy_class;

        switch (energyClass) {
            case 'A++++':
            case 'A+++':
            case 'A++':
            case 'A+':
            case 'A':
                return '#009947';
            case 'B':
                return '#35a036';
            case 'C':
                return '#9fc61b';
            case 'D':
                return '#fef804';
            case 'E':
                return '#feb818';
            case 'F':
                return '#ea6017';
            case 'G':
                return '#e91521';
            default:
                return '#dbd8d5';
        }
    }

    private bouwjaarColor(value) {
        if (!value) {
            return this.defaultColor(value);
        }

        if (!value.bouwjaar) {
            return this.defaultColor(value);
        }

        return this.linearInterpolateColor(value.bouwjaar);
    }

    private linearInterpolateColor(bouwjaar) {
        for (let i = 0; i < this.colorRanges.length - 1; i++) {
            if (bouwjaar < this.colorRanges[i + 1].year) {
                const factor = (bouwjaar - this.colorRanges[i].year) / (this.colorRanges[i + 1].year - this.colorRanges[i].year);
                return '#' + this.colorRanges[i].color.clone().lerp(this.colorRanges[i + 1].color, factor).getHexString();
            }
        }
        return '#444444';
    }

    private defaultColor(_: unknown) {
        return '#b7bcc3';
    }
    private addModel(layerId: string, properties: { uri: string; threebox_X: number; threebox_Y: number; indexed_metadata: string }) {
        const options = {
            obj: properties.uri,
            type: 'gltf',
            scale: { x: 0.02555, y: 0.02555, z: 0.041 },
            units: 'scene',
            rotation: { x: 0, y: 0, z: 180 }, //default rotation
            anchor: 'auto',
            bbox: false,
        };
        // make sure we check for unique id when adding buildings
        if (this.tb.world.children.some(o => o.uuid === properties.uri)) {
            return;
        }

        this.tb.loadObj(options, (model: any) => {


            model.traverse((child: any) => {
                if (child instanceof Mesh && child.geometry.type === 'BufferGeometry') {
                    child.geometry.computeBoundingBox();
                    model.userData.properties = properties;
                    model.userData.properties.attributes = child.parent.userData.attributes;
                    model.userData.properties.batchidToBagidLookup = {};

                    for (const bagid in model.userData.properties.attributes) {
                        if (Object.prototype.hasOwnProperty.call(model.userData.properties.attributes, bagid)) {
                            const batchid = model.userData.properties.attributes[bagid].batchid;
                            model.userData.properties.batchidToBagidLookup[batchid] = bagid;
                        }
                    }
                    const props = model.userData.properties;


                    const leafSize = Object.keys(props.batchidToBagidLookup).length;

                    const colorsList = Array(leafSize).fill([0, 0, 0, 1], 0, leafSize);
                    for (let j = 0; j <= leafSize; j++) {
                        const bagid = props.batchidToBagidLookup[j];
                        const color = this.colorMode(props.attributes[bagid]);
                        const rgb = new Color(color).convertSRGBToLinear();

                        if (this.filteredBagPandIds[bagid]) {
                            colorsList[j] = [rgb.r, rgb.g, rgb.b, 0];
                        }
                        else {
                            colorsList[j] = [rgb.r, rgb.g, rgb.b, this.opacity];
                        }
                    }
                    model.userData.properties.colorsList = colorsList;

                    const data = new Float32Array(colorsList.flat());

                    const dataTexture = new DataTexture(data, colorsList.length, 1, RGBAFormat, FloatType);
                    dataTexture.encoding = sRGBEncoding;
                    dataTexture.needsUpdate = true;

                    child.material = new ShaderMaterial({
                        ...batchIdShader(ShaderLib.standard, dataTexture,
                            this.pandDefaultColor,
                            this.pandHighlightedColor,
                            this.pandSelectedColor),
                        transparent: true,
                        flatShading: false,
                        extensions: {
                            derivatives: true,
                        },
                        lights: true,

                    });
                    child.material.uniforms.maxBatchid.value = (leafSize);
                    child.material.needsUpdate = true;
                    child.material.uniforms.opacity.value = this.opacity;
                    child.material.roughness = 1;



                    const boundingBox = new Box3();
                    const offset = 100;

                    boundingBox.setFromPoints([
                        new Vector3(model.bottomLeft.x - offset, model.bottomLeft.y - offset, model.bottomLeft.z - 10),
                        new Vector3(model.bottomRight.x + offset, model.bottomRight.y - offset, model.bottomRight.z - 10),
                        new Vector3(model.topLeft.x - offset, model.topLeft.y + offset, model.topLeft.z - 10),
                        new Vector3(model.topRight.x + offset, model.topRight.y + offset, model.topRight.z - 10)
                    ]);

                    const width = boundingBox.max.x - boundingBox.min.x;
                    const height = boundingBox.max.y - boundingBox.min.y;

                    // Create a PlaneGeometry with the dimensions of the bounding box
                    const geometry = new PlaneGeometry(width, height);
                    // Rotate the geometry to face downwards
                    geometry.rotateX(Math.PI);
                    // Create a material with the desired color
                    const material = new MeshBasicMaterial({ color: new Color(1, 1, 0), transparent: true, opacity: 0 }); // RGB for yellow
                    // Create a mesh with the geometry and material
                    const rectangle = new Mesh(geometry, material);
                    // Position the rectangle at the center of the bounding box
                    rectangle.position.set(
                        (boundingBox.min.x + boundingBox.max.x) / 2,
                        (boundingBox.min.y + boundingBox.max.y) / 2,
                        boundingBox.min.z // Assuming the rectangle is on the same plane as the bounding box
                    );
                    rectangle.raycast = function() { };
                    // Add the rectangle to the model (assuming model is already defined)
                    model.add(rectangle);
                }

            });


            model.userData.batchid = -1;
            this.subscriptions.push(fromEvent<Event>(model, 'SelectedChange').subscribe((event: Event) => {
                this.onSelectedChange(event);
            }));
            this.subscriptions.push(fromEvent<Event>(model, 'ContextMenu').subscribe((event: Event) => {
                this.onContextMenu(event);
            }));
            model.setCoords([properties.threebox_X, properties.threebox_Y]);
            model.uuid = properties.uri;
            model.layer = layerId;
            this.tb.add(model, layerId);


            // setup color filter
            return model;
        });

    }
}

