import {Component, OnDestroy, OnInit} from '@angular/core';
import {SearchProject} from '../../model/search-project';
import {SearchStatePaginator} from '../../utils/paginator/search-state-paginator';
import {SearchStateService} from '../../services/search-state.service';
import {ApiService} from '../../services/api.service';
import {combineLatest, Subscription} from 'rxjs';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {Project} from 'api/models/asset/project';
import {ScreenService} from 'src/app/services/screen.service';
import {filter, switchMap} from 'rxjs/operators';
import {PointResult} from '../../model/search-response';

@Component({
    selector: 'app-search-projects',
    templateUrl: './search-projects.component.html',
    standalone: false
})
export class SearchProjectsComponent implements OnInit, OnDestroy {
    private allSearchPoints: PointResult[] = [];
    private subscriptions: Subscription[] = [];
    isLoading$ = this.searchStateService.loadingSearchResults$;
    gridView$ = this.searchStateService.gridView$;
    paginator = new SearchStatePaginator<SearchProject>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, projectsFilter) => {
            return this.apiService.filter<SearchProject>('project', '', query, favorite, sort, page, pageSize, projectsFilter);
        }
    );
    allSearchedProjectsPaginator = new SearchStatePaginator<Project>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, projectsFilter) => {
            return this.apiService.filter<SearchProject>('project', '', query, favorite, sort, page, 1000, projectsFilter);
        }
    );
    allResultsProjectsPaginator = new SearchStatePaginator<Project>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, projectsFilter) => {
            return this.apiService.allProjects$;
        }
    );
    isOnMobile = false;

    constructor(
        private searchStateService: SearchStateService,
        private apiService: ApiService,
        private mapboxService: MapboxService,
        private screenService: ScreenService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.screenService.screen$.pipe(
                filter(screen => screen === 'desktop'),
                switchMap(() => combineLatest([
                    this.allResultsProjectsPaginator.responseWithParams$,
                    this.allSearchedProjectsPaginator.responseWithParams$,
                    this.paginator.content$
                ]))
            ).subscribe(([allResults, allSearchedProjectsPaginator , pagedResults]) => {
                const filteredResults = pagedResults.filter(result => result.geog !== null)
                    .map(result => result.geog.coordinates);

                this.mapboxService.moveToLocation(filteredResults);

                this.allSearchPoints =  allResults.value
                    .filter(result => result.geog !== null)
                    .map(item => ({...item, title: item.naam, id: item.id, type: 'project'} as PointResult));

                const searchedPoints = allSearchedProjectsPaginator.value
                    .filter(result => result.geog !== null)
                    .map(item => ({...item, title: item.naam, id: item.id, type: 'project'} as PointResult));

                if (pagedResults.length === 0) {
                    this.searchStateService.pointResults$.next(this.allSearchPoints);
                } else {
                    this.searchStateService.pointResults$.next(searchedPoints);
                }

            }),
            this.screenService.screen$.subscribe(screen => this.isOnMobile = screen === 'mobile')
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }
}
