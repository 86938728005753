<div class="z-20 fixed top-0 bottom-0 left-0 w-20 bg-white border-r border-neutral-3 flex flex-col">
  <a class="h-24 border-b border-neutral-3 flex justify-center items-center cursor-pointer resetPrsPresentation"
    queryParamsHandling="preserve"
    inlineSVG="/assets/icons/icon-48-avatar-vw.svg"
    (click)="resetApplicationState($event)">
  </a>
  <div class="flex-1 p-4 flex flex-col items-center">
    @for (group of routes; track group; let isLast = $last) {
      <div class="space-y-1">
        @for (route of group; track route) {
          <button [routerLink]="route.route" queryParamsHandling="merge"
            [inlineSVG]="route.icon" [queryParams]="queryParams"
            class="flex justify-center items-center w-12 h-12 rounded-lg text-primary hover:bg-neutral-4"
            routerLinkActive #link="routerLinkActive" (click)="onNavClick(link)"
            [class.nav-active]="link.isActive"
            [class.text-neutral-1]="!link.isActive"
            [class.text-primary]="link.isActive"
            [disabled]="this.infoBarService.overLayOpened"
            [tooltip]="route.tooltip"
            placement="right"
            triggers=""
            [appAnimatedTooltip]="tooltip"
            #tooltip="bs-tooltip"
          ></button>
        }
      </div>
      @if (!isLast) {
        <div class="w-6 my-4 border-b border-neutral-3"></div>
      }
    }

    <div routerLink="/account" routerLinkActive #link="routerLinkActive" (click)="onNavClick(link)"
             class="flex justify-center items-center w-10 h-10 mt-auto rounded-full focus:outline-none
             bg-primary text-white uppercase text-base font-medium relative cursor-pointer"
      tooltip="Account en meer"
      placement="right"
      triggers=""
      [appAnimatedTooltip]="tooltip"
      #tooltip="bs-tooltip">
      <span>{{ initials$ | async }}</span>
      <button [disabled]="this.infoBarService.overLayOpened"
        id="profile-dots"
        class="absolute left-7 top-6 flex justify-center items-center bg-white rounded-full border border-neutral-3 resetPrsPresentation"
        inlineSVG="/assets/icons/icon-16-more-vertical.svg">
      </button>
    </div>
  </div>
  <div class="h-8 border-t border-neutral-3 flex justify-center items-center text-xs text-neutral-1">
    {{buildNumber}}
  </div>
</div>
@if ((pinnedLocationService.pinnedLocationState$|async) === 'pinned') {
  <app-pinned-location></app-pinned-location>
}
