import {Component, Input} from '@angular/core';
import {Company} from 'api/models/asset/company';
import {Router} from '@angular/router';

@Component({
    selector: 'app-company-tile-grid',
    templateUrl: './company-tile-grid.component.html',
    standalone: false
})
export class CompanyTileGridComponent {
    @Input() companies: Company[] = [];

    constructor(private router: Router) {
    }

    openCompanyDetailPage(company: Company) {
        this.router.navigate(['companies', company.kvw_nummer]);
    }
}
