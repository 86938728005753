import { html as t, svg as r } from "./lit-html.js";
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const e = Symbol.for(""),
  l = t => {
    if ((null == t ? void 0 : t.r) === e) return null == t ? void 0 : t._$litStatic$;
  },
  o = t => ({
    _$litStatic$: t,
    r: e
  }),
  i = (t, ...r) => ({
    _$litStatic$: r.reduce((r, e, l) => r + (t => {
      if (void 0 !== t._$litStatic$) return t._$litStatic$;
      throw Error(`Value passed to 'literal' function must be a 'literal' result: ${t}. Use 'unsafeStatic' to pass non-literal values, but\n            take care to ensure page security.`);
    })(e) + t[l + 1], t[0]),
    r: e
  }),
  s = new Map(),
  a = t => (r, ...e) => {
    const o = e.length;
    let i, a;
    const n = [],
      u = [];
    let c,
      $ = 0,
      f = !1;
    for (; $ < o;) {
      for (c = r[$]; $ < o && void 0 !== (a = e[$], i = l(a));) c += i + r[++$], f = !0;
      $ !== o && u.push(a), n.push(c), $++;
    }
    if ($ === o && n.push(r[o]), f) {
      const t = n.join("$$lit$$");
      void 0 === (r = s.get(t)) && (n.raw = n, s.set(t, r = n)), e = u;
    }
    return t(r, ...e);
  },
  n = a(t),
  u = a(r);
export { n as html, i as literal, u as svg, o as unsafeStatic, a as withStatic };
