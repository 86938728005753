@if ((company$|async ); as company) {
  <div class="ml-2 mr-6 flex items-center">
    <div class="p-4 cursor-pointer"
      inlineSVG="/assets/icons/icon-arrow-left.svg"
      [setSVGAttributes]="{width: 16, height: 16}"
      (click)="goBack()"
    ></div>
    <div appTitle="lg" class="truncate">{{ company.naam }}</div>
  </div>
  <div class="mx-6 mt-8 mobile:mt-0">
    <app-company-tile class="block desktop:w-80 mb-4" [company]="company"></app-company-tile>
    @if ((projects$|async); as projects) {
      <div class="flex items-center pt-3 pb-4 cursor-pointer border-t border-neutral-3">
        <div class="flex cursor-pointer" (click)="projectsExpanded = !projectsExpanded">
          <div class="transform origin-center"
            [ngClass]="projectsExpanded ? 'rotate-90' : '-rotate-90'"
            inlineSVG="/assets/icons/navigation-arrow-icon.svg"
          ></div>
          <span class="font-medium text-base ml-4">Recente Projecten</span>
        </div>
        <span class="font-medium text-base text-primary ml-auto" (click)="goToProjects(company.kvw_nummer, projects.count)">
          Alle {{projects.count}} projecten
        </span>
      </div>
      <div [hidden]="!projectsExpanded">
        @if (projects.value.length === 0) {
          <div>Projecten niet beschikbaar</div>
        }
        @for (project of projects.value; track project) {
          <div class="flex my-1 cursor-pointer"
            [routerLink]="['/projects/', project.id]"
            queryParamsHandling="preserve">
            <div class="flex align-center flex-nowrap items-center">
              <div class="relative w-8 h-8 rounded flex items-center justify-center"
                [class.bg-primary]="!(project | projectImage)">
                @if (project | projectImage) {
                  <img alt=""
                    [src]="project | projectImage"
                    class="absolute inset-0 w-full object-cover rounded">
                } @else {
                  <span class="absolute left-auto right-auto text-white"
                    inlineSVG="/assets/icons/icon-project.svg" [setSVGAttributes]="{width: 16, height: 16}">
                  </span>
                }
                <!--
                We _should_ be using thumbnail images here, but for unknown reasons suffixing ?size=thumbnail
                no longer works. We use full size images instead, which definitely works, but is obviously
                not ideal in terms of performance and bandwith used.
                -->
              </div>
              <span class="ml-4">{{ project.naam }}</span>
              <app-is-favorite [project]="project"></app-is-favorite>
            </div>
          </div>
        }
      </div>
    } @else {
      @if (loadingProjects) {
        <div class="m-6 flex justify-center">
          <vwui-spinner></vwui-spinner>
        </div>
      }
    }
    <app-collapse collapseTitle="Details" [leftIndent]="false">
      @for (item of companyDetails$ | async; track item) {
        <app-information-item
          [label]="item.label"
          [value]="item.value"
        ></app-information-item>
      }
    </app-collapse>
  </div>
} @else {
  @if (loadingCompany) {
    <div class="m-6 flex justify-center">
      <vwui-spinner></vwui-spinner>
    </div>
  }
  @if (!loadingCompany) {
    <div class="pt-20 px-4">
      <app-empty-state
        title="Geen bedrijf gevonden"
        message="Er kon geen bedrijf gevonden worden, probeer het opnieuw via de zoekbalk."
      ></app-empty-state>
    </div>
  }
}

