import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MapConfigService} from '../../services/mapbox/map-config.service';
import {LegendGroupDynamic, LegendTab} from '../../model/legend';
import {Subscription} from 'rxjs';
import {BuildingColorMode} from 'api/models/building-color-mode';
import {yiq} from 'yiq';

@Component({
    selector: 'app-layer-legend',
    templateUrl: './layer-legend.component.html',
    standalone: false
})
export class LayerLegendComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    currentTabIndex = -1;
    readonly tabs: LegendTab[] = [
        {
            label: 'Energielabels',
            layerIcon: 'buildings',
            layerIconClasses: 'on-1',
            visible: false,
            groups: [
                {
                    label: 'Energielabel',
                    property: 'energy_class',
                    layerId: 'pand',
                    type: 'match',
                    selectState: 'all',
                    items: [
                        {value: 'A++++', label: 'A++++', color: '#009947'},
                        {value: 'A+++', label: 'A+++', color: '#009947'},
                        {value: 'A++', label: 'A++', color: '#009947'},
                        {value: 'A+', label: 'A+', color: '#009947'},
                        {value: 'A', label: 'A', color: '#009947'},
                        {value: 'B', label: 'B', color: '#35a036'},
                        {value: 'C', label: 'C', color: '#9fc61b'},
                        {value: 'D', label: 'D', color: '#fef804'},
                        {value: 'E', label: 'E', color: '#feb818'},
                        {value: 'F', label: 'F', color: '#ea6017'},
                        {value: 'G', label: 'G', color: '#e91521'},
                        {value: 'null', label: 'Onbekend', color: '#b8ada2'}
                    ]
                }
            ]
        }, {
            label: 'Bouwjaar',
            layerIcon: 'buildings',
            layerIconClasses: 'on-2',
            visible: false,
            groups: [
                {
                    label: 'Bouwjaar',
                    property: 'bouwjaar',
                    layerId: 'pand',
                    type: 'interpolate',
                    selectState: 'all',
                    items: [
                        {label: '1700 of eerder', value: 1700, color: '#430719'},
                        {label: '1750', value: 1750, color: '#740320'},
                        {label: '1800', value: 1800, color: '#a50026'},
                        {label: '1850', value: 1850, color: '#d73027'},
                        {label: '1900', value: 1900, color: '#f46d43'},
                        {label: '1925', value: 1925, color: '#fdae61'},
                        {label: '1950', value: 1950, color: '#FEE090'},
                        {label: '1975', value: 1975, color: '#84c7db'},
                        {label: '2000', value: 2000, color: '#5096c2'},
                        {label: '2015', value: 2015, color: '#1d66aa'},
                        {label: '2021', value: 2021, color: '#117c3c'},
                        {label: 'Onbekend', value: null, color: '#444444'},
                    ]
                }
            ]

        }, {
            label: 'Bestemmingsplan',
            visible: false,
            layerIcon: 'bestemmingsplan',
            layerIconClasses: 'on',
            groups: [
                {
                    label: 'Enkelbestemming',
                    property: 'bestemmingshoofdgroep',
                    layerId: 'Enkelbestemming',
                    type: 'match',
                    selectState: 'all',
                    items: [
                        {value: 'agrarisch', label: 'Agrarisch', color: '#ebf0d2'},
                        {value: 'agrarisch met waarden', label: 'Agrarisch met waarden', color: '#d2e1a5'},
                        {value: 'bedrijf', label: 'Bedrijf', color: '#b45fd2'},
                        {value: 'bedrijventerrein', label: 'Bedrijventerrein', color: '#c8a0d7'},
                        {value: 'bos', label: 'Bos', color: '#64aa2d'},
                        {value: 'centrum', label: 'Centrum', color: '#ffc8be'},
                        {value: 'cultuur en ontspanning', label: 'Cultuur en ontspanning', color: '#ff3c82'},
                        {value: 'detailhandel', label: 'Detailhandel', color: '#ffa096'},
                        {value: 'dienstverlening', label: 'Dienstverlening', color: '#f091be'},
                        {value: 'gemengd', label: 'Gemengd', color: '#ffbe87'},
                        {value: 'groen', label: 'Groen', color: '#28c846'},
                        {value: 'horeca', label: 'Horeca', color: '#ff6923'},
                        {value: 'kantoor', label: 'Kantoor', color: '#ebc3d7'},
                        {value: 'maatschappelijk', label: 'Maatschappelijk', color: '#dc9b78'},
                        {value: 'natuur', label: 'Natuur', color: '#82a591'},
                        {value: 'overig', label: 'Overig', color: '#ebe1eb'},
                        {value: 'recreatie', label: 'Recreatie', color: '#b9d746'},
                        {value: 'sport', label: 'Sport', color: '#82c846'},
                        {value: 'tuin', label: 'Tuin', color: '#c8d76e'},
                        {value: 'verkeer', label: 'Verkeer', color: '#cdcdcd'},
                        {value: 'water', label: 'Water', color: '#afcde1'},
                        {value: 'wonen', label: 'Wonen', color: '#ffff00'},
                        {value: 'woongebied', label: 'Woongebied', color: '#FFFFB3'},
                    ]
                },
                {
                    label: 'Dubbelbestemming',
                    property: 'bestemmingshoofdgroep',
                    layerId: ['Dubbelbestemming', 'DubbelBestemming-border'],
                    type: 'match',
                    selectState: 'all',
                    items: [
                        {value: 'leiding', label: 'Leiding', pattern: 'blokjes'},
                        {value: 'waarde', label: 'Waarde', pattern: 'cross'},
                        {value: 'waterstaat', label: 'Waterstaat', pattern: 'slinger'}
                    ]
                }
            ]
        }, {
            label: 'Natura 2000',
            visible: false,
            layerIcon: 'natura2000',
            layerIconClasses: 'on',
            groups: [
                {
                    label: 'Natura 2000',
                    property: 'BESCHERMIN',
                    layerId: 'natura2000',
                    type: 'match',
                    selectState: 'all',
                    items: [
                        { value: 'HR', label: 'HR', color: '#e6e600'},
                        { value: 'VR', label: 'VR', color: '#bee6ff'},
                        { value: 'VR+HR', label: 'VR+HR', color: '#d2ff73'},
                        { value: 'HR groeve', label: 'HR groeve', color: '#ffa753'},
                    ]
                }
            ]
        }, {
            label: 'Leefbaarometer',
            visible: false,
            layerIcon: 'leefbaarometer',
            layerIconClasses: 'on',
            groups: [
                {
                    label: 'Leefbaarheidssituatie',
                    type: 'static',
                    selectState: 'all',
                    items: [
                        { value: null, label: 'Zeer onvoldoende', color: '#730000'},
                        { value: null, label: 'Ruim onvoldoende', color: '#e50000'},
                        { value: null, label: 'Onvoldoende', color: '#e1702e'},
                        { value: null, label: 'Zwak', color: '#ffffbe'},
                        { value: null, label: 'Voldoende', color: '#d3ffbe'},
                        { value: null, label: 'Ruim voldoende', color: '#93e667'},
                        { value: null, label: 'Goed', color: '#55be00'},
                        { value: null, label: 'Zeer goed', color: '#267300'},
                        { value: null, label: 'Uitstekend', color: '#263d00'},
                    ]
                }
            ]
        }, {
            label: 'Geluid alle bronnen (Lden)',
            visible: false,
            layerIcon: 'geluidkaart',
            layerIconClasses: 'on',
            groups: [
                {
                    label: 'Geluid alle bronnen (Lden)',
                    type: 'static',
                    selectState: 'all',
                    items: [
                        { value: null, label: '>= 75 dB', color: '#800000'},
                        { value: null, label: '75 - 70 dB', color: '#c00000'},
                        { value: null, label: '70 - 65 dB', color: '#ff0000'},
                        { value: null, label: '65 - 60 dB', color: '#ffa500'},
                        { value: null, label: '60 - 55 dB', color: '#ffd200'},
                        { value: null, label: '55 - 50 dB', color: '#ffff00'},
                        { value: null, label: '50 - 45 dB', color: '#ffffb2'}
                    ]
                }
            ]
        }, {
            label: 'Groenkaart (%)',
            visible: false,
            layerIcon: 'groenkaart',
            layerIconClasses: 'on',
            groups: [
                {
                    label: 'Groenkaart (%)',
                    type: 'static',
                    selectState: 'all',
                    items: [
                        { value: null, label: '< 5', color: '#ffffcc'},
                        { value: null, label: '5 - 40', color: '#c2e699'},
                        { value: null, label: '40 - 70', color: '#78c679'},
                        { value: null, label: '70 - 90', color: '#31a354'},
                        { value: null, label: '90 - 100', color: '#006837'}
                    ]
                }
            ]
        }, {
            label: 'Boomhoogte',
            visible: false,
            layerIcon: 'boomhoogte',
            layerIconClasses: 'on',
            groups: [
                {
                    label: 'Boomhoogte',
                    type: 'static',
                    selectState: 'all',
                    items: [
                        { value: null, label: '< 5', color: '#ffffcc'},
                        { value: null, label: '5 - 10', color: '#c2e699'},
                        { value: null, label: '10 - 15', color: '#78c679'},
                        { value: null, label: '15 - 20', color: '#31a354'},
                        { value: null, label: '> 20', color: '#006837'}
                    ]
                }
            ]
        }
    ];

    readonly form = new UntypedFormArray(this.tabs.map(tab =>
        new UntypedFormArray(tab.groups.map(group =>
            new UntypedFormGroup(group.items.reduce((controls: {[key: string]: UntypedFormControl}, item) => {
                controls[item.value] = new UntypedFormControl({value: true, disabled: group.type === 'static'});
                return controls;
            }, {}))
        ))
    ));

    readonly groupForm = new UntypedFormArray(this.tabs.map(tab =>
        new UntypedFormArray(tab.groups.map(group =>
            new UntypedFormControl({value: true, disabled: group.type === 'static'})
        ))
    ));

    constructor(private configService: MapConfigService) {
        this.form.valueChanges.subscribe((tabValues: { [key: string]: boolean }[][]) => {
            const filters = tabValues.flatMap((groupValues, tabIndex) => {
                const tab = this.tabs[tabIndex];
                return groupValues.flatMap((value, groupIndex) => {
                    const group = tab.groups[groupIndex];
                    if (group.type === 'static') {
                        return [];
                    }
                    const filteredValues = Object.keys(value).filter(key => {
                        return value[key] === true;
                    });
                    group.selectState = tab.groups[groupIndex].items.length === filteredValues.length ? 'all' : 'some';
                    if(filteredValues.length === 0)
                    {
                        group.selectState = 'none';
                    }
                    const filter = group.type === 'match'
                        ? getMatchFilter(group, value)
                        : getInterpolateFilter(group, value);

                    return Array.isArray(group.layerId)
                        ? group.layerId.map(layerId => ({layerId, filter}))
                        : {layerId: group.layerId, filter};
                });
            });


            this.configService.update({filters});
        });

        this.groupForm.controls.forEach((tabControl: UntypedFormArray, tabIndex) => {
            tabControl.controls.forEach((groupControl, groupIndex) => {
                groupControl.valueChanges.subscribe(value => {
                    const formGroup = this.form.get([tabIndex, groupIndex]) as UntypedFormGroup;
                    formGroup.patchValue(
                        Object.keys(formGroup.getRawValue()).reduce((newValue, key) => {
                            newValue[key] = value;
                            return newValue;
                        }, {})
                    );
                });
            });
        });
    }

    getFormControl(tabIndex: number, groupIndex: number, value: string): UntypedFormControl {
        return this.form.get([tabIndex, groupIndex, value]) as UntypedFormControl;
    }

    getGroupFormControl(tabIndex: number, groupIndex: number): UntypedFormControl {
        return this.groupForm.get([tabIndex, groupIndex]) as UntypedFormControl;
    }

    onTabClick(index: number) {
        this.currentTabIndex = this.currentTabIndex === index ? -1 : index;
    }

    getCheckboxColor(backgroundColor: string): string {
        return yiq(backgroundColor, {
            colors: {
                dark: 'rgba(34,47,62,0.5)',
                light: '#fff'
            },
            threshold: 180
        });
    }

    ceil(num: number) {
        return Math.ceil(num);
    }

    ngOnInit(): void {
        this.subscriptions.push(this.configService.asObservable().subscribe(({layers}) => {
            this.tabs[0].visible = layers.buildings.active && layers.buildingLayer.mode === BuildingColorMode.ENERGYLABEL;
            this.tabs[1].visible = layers.buildings.active && layers.buildingLayer.mode === BuildingColorMode.BOUWJAAR;
            this.tabs[2].visible = layers.bestemmingsplan.active;
            this.tabs[3].visible = layers.natura2000.active;
            this.tabs[4].visible = layers.leefbaarometer.active;
            this.tabs[5].visible = layers.geluidkaart.active;
            this.tabs[6].visible = layers.groenkaart.active;
            this.tabs[7].visible = layers.boomhoogte.active;
            this.currentTabIndex = this.tabs[this.currentTabIndex]?.visible
                ? this.currentTabIndex
                : this.tabs.findIndex(tab => tab.visible);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }
}

function getMatchFilter(group: LegendGroupDynamic, value: { [key: string]: boolean }) {
    return [
        'in',
        ['get', group.property],
        ['literal', Object.keys(value).filter(key => value[key]).map(it => it === 'null' ? null : it)]
    ];
}

function getInterpolateFilter(group: LegendGroupDynamic, value: { [key: string]: boolean }) {
    const steps = Object.keys(value).flatMap(key => [value[key], +key]);
    steps.pop();
    return [
        'step',
        ['get', group.property],
        ...steps
    ];
}
