<div class="extensions-panel__header items-center flex">
  <a routerLink="/apps" queryParamsHandling="preserve" class="inline-block w-5 cursor-pointer"
  inlineSVG="/assets/icons/icon-arrow-left.svg"></a>
  <span class="ml-4 mr-3"></span>
  Bedrijfsadmin&nbsp;@if (myCompanies$ | async; as companies) {
  <span>({{ companies }})</span>
}
</div>
<hr>
  <div class="px-6 py-4">
    @if (!userMissingKvwNumber) {
      <div class="relative mb-5">
        <div class="relative flex gap-4 mobile:gap-1 items-center px-4 py-3 border border-neutral-3 h-12 rounded transition-all
            overflow-hidden focus-within:border-primary">
          <div class="flex-grow">
            <input class="w-full mobile:h-9 text-m focus-visible:shadow-none" type="text"
              [formControl]="filterControl"
              placeholder="Zoek op naam">
          </div>
          @if (filterControl.value.length > 0) {
            <button type="button" class="text-primary"
              (click)="filterControl.setValue('')"
              inlineSVG="/assets/icons/icon-24-close.svg"
              [setSVGAttributes]="{width: '20', height: '20'}">
            </button>
          }
        </div>
      </div>
    }

    @if ((appRoles$ | async); as appRoles) {
      @if (form) {
        <div class="vwui-card__content" [formGroup]="form">
          @for (appRole of appRoles; track appRole) {
            <app-multi-select-users
              class="d-block mb-4"
              [formControlName]="appRole.id"
              [label]="appRole.displayName"
              [labelDescription]="appRole.description"
              [readonly]="saving || filterControl.value"
              [filter]="filterControl.value"
              (add)="createAppRoleAssignment($event, appRole.id)"
              (remove)="deleteAppRoleAssignment($event, appRole.id)"
            ></app-multi-select-users>
          }
        </div>
      }
    } @else {
      @if (userMissingKvwNumber) {
        <div>
          Het is niet mogelijk om de rollen en rechten te beheren, omdat de huidige gebruiker niet aan een bedrijf
          gekoppeld is.
        </div>
      }
    }

  </div>
