"use strict";

const d = e => (l, a) => {
  const r = l.constructor;
  Object.prototype.hasOwnProperty.call(r, "metadata") || (r.metadata = {});
  const o = r.metadata;
  o.slots || (o.slots = {});
  const t = o.slots;
  if (e && e.default && t.default) throw new Error("Only one slot can be the default slot.");
  const n = e && e.default ? "default" : a;
  e = e || {
    type: HTMLElement
  }, e.type || (e.type = HTMLElement), t[n] || (t[n] = e), e.default && (delete t.default.default, t.default.propertyName = a), r.metadata.managedSlots = !0;
};
export default d;
