"use strict";

const s = o => (p, r) => {
  const t = p.constructor;
  Object.prototype.hasOwnProperty.call(t, "metadata") || (t.metadata = {});
  const e = t.metadata;
  e.properties || (e.properties = {});
  const a = e.properties;
  a[r] || (a[r] = o ?? {});
};
export default s;
