<table class="w-full rounded-table-neutral-3 text-left table-fixed">
  <tr class="text-neutral-1 whitespace-nowrap">
    @if ((selectionMode$|async) === 'company') {
      <th class="w-12"></th>
    }
    <th class="py-3 px-6"><button (click)="sortService.sortBy('naam', false)" class="py-1 px-2 -ml-2 font-medium hover:bg-neutral-4 inline rounded">Bedrijf
      @if ((sortService.currentState$|async) === 'naam' || (sortService.currentState$|async) === '-naam') {
        <span class="inline-block transform" [class.rotate-180]="(sortService.currentSort$|async) === 2" [class.invisible]="(sortService.currentSort$|async) ===  0"
        [class.visible]="(sortService.currentState$|async) === 'naam'" inlineSVG="/assets/icons/icon-arrow-up.svg"></span>
      }</button></th>
      <th class="py-3 px-6">
        <button (click)="sortService.sortBy('vestiging_plaats', false)" class="py-1 px-2 -ml-4 font-medium hover:bg-neutral-4 rounded"
          >Plaats
          @if ((sortService.currentState$|async) === 'vestiging_plaats' || (sortService.currentState$|async) === '-vestiging_plaats') {
            <span class="inline-block transform" [class.rotate-180]="(sortService.currentSort$|async) ===  2" [class.invisible]="(sortService.currentSort$|async) ===  0" inlineSVG="/assets/icons/icon-arrow-up.svg"></span>
          }</button>
        </th>
        <th class="py-3 px-6">
          <button (click)="sortService.sortBy('hoofd_telefoonnummer', false)" class="py-1 px-2 -ml-4 font-medium hover:bg-neutral-4 rounded"
            >Telefoonnummer
            @if ((sortService.currentState$|async) === 'hoofd_telefoonnummer' || (sortService.currentState$|async) === '-hoofd_telefoonnummer') {
              <span class="inline-block transform" [class.rotate-180]="(sortService.currentSort$|async) === 2" [class.invisible]="(sortService.currentSort$|async) ===  0" inlineSVG="/assets/icons/icon-arrow-up.svg"></span>
            }</button>
          </th>
          <th class="py-3 px-6">
            <button (click)="sortService.sortBy('domainnaam', false)" class="py-1 px-2 font-medium -ml-4 hover:bg-neutral-4 rounded"
              >Website
              @if ((sortService.currentState$|async) === 'domainnaam' || (sortService.currentState$|async) === '-domainnaam') {
                <span class="inline-block transform" [class.rotate-180]="(sortService.currentSort$|async) ===  2" [class.invisible]="(sortService.currentSort$|async) ===  0" inlineSVG="/assets/icons/icon-arrow-up.svg"></span>
              }</button>
            </th>
          </tr>
          @if (!items || items.length === 0) {
            <tr>
              <td class="py-3 px-6 text-center" [attr.colspan]="(selectionMode$|async) === 'company' ? 5 : 4">
                Geen bedrijven gevonden.
              </td>
            </tr>
          }
          @for (item of items; track item) {
            <tr class="hover:bg-neutral-4 cursor-pointer group rounded table-row"
              (click)="onItemClicked($event, item, 'company')">
              @if ((selectionMode$|async) === 'company') {
                <td class="py-3 pl-6" (click)="$event.stopPropagation()">
                  <div class="flex items-center">
                    <app-is-selected [company]="item">
                      <div unchecked
                        class="cursor-pointer px-1 border-primary text-white border rounded hover:bg-neutral-3"
                        (click)="addCompanyToSelection($event, (item))">
                        <span class="" inlineSVG="/assets/icons/checkbox.svg"></span>
                      </div>
                      <div checked class="cursor-pointer bg-primary border rounded border-primary rounded"
                        (click)="removeCompanyFromSelection($event, (item))">
                        <span inlineSVG="/assets/icons/checkbox.svg" class="px-1"></span>
                      </div>
                    </app-is-selected>
                  </div>
                </td>
              }
              <td class="px-6">
                <div class="relative h-8 my-3" style="width: calc(100% - 3rem)">
                  <img [src]="item.entiteit_logo_url || '/assets/icons/thumbnail.svg'" [alt]="item.naam"
                    class="absolute left-0 h-full max-w-full object-scale-down" style="mix-blend-mode: multiply">
                  <app-is-favorite class="absolute -right-6 top-1/2 transform -translate-y-1/2"
                  [company]="item"></app-is-favorite>
                </div>
              </td>
              <td class="px-4">{{ item.vestiging_plaats }}</td>
              <td class="px-4">{{ item.hoofd_telefoonnummer }}</td>
              <td class="pl-4 pr-6 text-primary font-medium relative">
                <div
                  class="table-column-fade hover:underline"
                  (click)="goToDomain($event, item.domainnaam)"
                  [tooltip]="item.domainnaam"
                  >
                  {{item.domainnaam}}
                </div>
                <app-company-tile-context-menu
                  class="absolute top-1/2 transform -translate-y-1/2 right-4"
                  [company]="item" variant="list">
                </app-company-tile-context-menu>
              </td>
            </tr>
          }
        </table>
