import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {Project} from 'api/models/asset/project';
import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';
import {SelectionHelper} from '../is-selected/selection-helper';

@Component({
    selector: 'app-project-card',
    templateUrl: './project-card.component.html',
    styleUrls: ['./project-card.component.scss'],
    standalone: false
})
export class ProjectCardComponent extends SelectionHelper {
    @Input() project: Project;
    @Output() clicked = new EventEmitter<Project>();
    selectionMode$ = this.selectionService.selectionMode$;

    constructor(
        protected selectionService: SelectionService,
        protected router: Router) {
        super(selectionService, router);
    }

    getProjectLocation(project: Project) {
        return `${project.straat || ''} ${project.huisnummer || ''}, ${project.plaats || ''}`;
    }

}
