import {Pipe, PipeTransform} from '@angular/core';
import {LngLat} from 'mapbox-gl';

@Pipe({
    name: 'lngLatToString',
    standalone: false
})
export class LngLatToStringPipe implements PipeTransform {
    transform(coordinates: LngLat): any {
        if (!coordinates) {
            return '';
        }
        const {lat, lng} = coordinates;
        return `${lat.toFixed(6) ?? ''} , ${lng.toFixed(6) ?? ''}`;
    }

}
