<app-saved-filter-list></app-saved-filter-list>
@if (favoriteProjectsPaginator.content$|async; as projects) {
  <div class="mb-4">
    <div class="mt-8 mb-4 flex items-center justify-between">
      <div appTitle>Favoriete projecten</div>
      <a class="text-primary font-medium"
        routerLink="/projects/search" [queryParams]="{favorite: true}" queryParamsHandling="merge">
        Alle favorieten
      </a>
    </div>
    <app-project-tile-grid
      [projects]="projects"
      [threeColumns]="!isOnMobile"
    ></app-project-tile-grid>
  </div>
}
<div class="mb-6">
  <div appTitle class="mb-4">
    Direct naar
  </div>
  <div class="grid grid-cols-2 gap-4">
    @for (tile of overviewTiles; track tile) {
      <a [routerLink]="tile.route" queryParamsHandling="merge"
                class="block w-full relative rounded bg-white overflow-hidden desktop:hover:shadow-tile
                       ring-2 ring-transparent focus:ring-primary focus:outline-none border border-neutral-3 mobile:shadow">
        <div class="overview-tile-image-ratio">
          <img class="absolute inset-0" alt="" [src]="tile.srcset[0]" [srcset]="tile.srcset.join(', ')">
        </div>
        <div class="px-6 py-4 text-left text-base font-medium mobile:text-m text-dark mobile:px-4">{{ tile.label }}</div>
      </a>
    }
  </div>
</div>
<div class="relative">
  <div class="mb-4 flex items-center justify-between">
    <div appTitle>Recent toegevoegd</div>
    <a class="text-primary font-medium"
      routerLink="/projects" [queryParams]="{sort: '-aangemaakt_op'}" queryParamsHandling="merge">
      Meer recente projecten
    </a>
  </div>
  <app-project-tile-grid
    [projects]="recentProjectsPaginator.content$|async"
    [isLoading]="recentProjectsPaginator.isLoading"
    [masonryGrid]="true"
  ></app-project-tile-grid>

  @if (collections$|async; as collectionsResponse) {
    <div class="mt-8 mb-4 flex items-center justify-between">
      <div appTitle>Collecties</div>
      <a class="text-primary font-medium"
        routerLink="/collections" queryParamsHandling="merge">
        Alle collecties
      </a>
    </div>
    <div class="grid grid-cols-2 desktop:grid-cols-3 gap-4">
      @for (collection of collectionsResponse.content; track collection) {
        <app-collection-tile [collection]="collection"></app-collection-tile>
      }
    </div>
  }

  @if (recentProjectsPaginator.isLoading) {
    <div class="absolute top-24 left-1/2 transform -translate-x-1/2"
    inlineSVG="/assets/icons/loader.svg"></div>
  }
</div>
