@if (selectionMode$|async; as selectionMode) {
  @if (selectionCount$ | async; as selectionCount) {
    @if (selectionMode !== 'disabled' && selectionCount > 0) {
      <div
        class="selection-list-container py-3 pr-6 shadow-md flex justify-between">
        <div class="mobile:hidden flex flex-row items-center">
          <div class="w-20 text-center mr-6">
            <vwui-checkbox
              class="ml-4"
              [indeterminate]="selectionCount > 0"
              (vwuiChecked)="clearSelection()">
            </vwui-checkbox>
          </div>
          @for (selected of (selection$|async).slice(0, 5); track selected) {
            <div
              class="relative -ml-1 w-10 h-10 rounded flex items-center justify-center cursor-pointer hover:border-silver border-white border-2"
              [ngClass]="{'bg-white border-neutral-2 border': selectionMode === 'company' && selected.icon}"
              [class.bg-primary]="!selected.icon"
              (click)="removeSelected($event, (selected))">
              @if (selectionMode === 'project') {
                @if (selected.icon) {
                  <img alt=""
                    [src]="selected.icon"
                    class="absolute inset-0 w-full h-full object-left object-cover rounded">
                } @else {
                  <span class="absolute text-white rounded"
                    inlineSVG="/assets/icons/icon-project.svg"
                    [setSVGAttributes]="{width: 16, height: 16}"
                  ></span>
                }
              }
              @if (selectionMode === 'company' && selected.icon;) {
                <img alt=""
                  [src]="selected.icon"
                  class="max-h-5 object-scale-down">
              }
            </div>
          }
          @if (selectionCount > 5) {
            <div
              class="relative w-10 h-10 rounded flex items-center justify-center -mx-1 cursor-pointer hover:border-silver border-white border-2 bg-neutral-4">
              <span class="absolute left-auto right-auto text-xs text-dark rounded">
                +{{ selectionCount - 5 }}
              </span>
            </div>
          }
          @if (selectionMode === 'project') {
            <div class="flex-grow px-4 text-base text-dark">
              @if (selectionCount === 1) {
                <div>1 project geselecteerd</div>
              }
              @if (selectionCount > 1) {
                <div>{{ selectionCount }} projecten geselecteerd</div>
              }
            </div>
          }
          @if (selectionMode === 'company') {
            <div class="flex-grow px-4 text-base text-dark">
              @if (selectionCount === 1) {
                <div>1 bedrijf geselecteerd</div>
              }
              @if (selectionCount > 1) {
                <div>{{ selectionCount }} bedrijven geselecteerd</div>
              }
            </div>
          }
        </div>
        <div class="flex ml-6 items-center gap-2">
          @if (selectionMode === 'project') {
            <app-icon-button
              [tooltip]="'Selecteer alle projecten'"
              [loading]="selectingAll"
              [icon]="'/assets/icons/icon-project.svg'"
              [iconOnly]="true"
              (buttonPressed)="selectAllProjects()"
            ></app-icon-button>
          }
          @if (selectionMode === 'company') {
            <app-icon-button
              [tooltip]="'Selecteer alle bedrijven'"
              [loading]="selectingAll"
              [icon]="'/assets/icons/icon-company.svg'"
              [iconOnly]="true"
              (buttonPressed)="selectAllCompanies()"
            ></app-icon-button>
          }
          <app-icon-button
            [tooltip]="'Maak selectie favorieten'"
            [loading]="favoritizing"
            [icon]="'/assets/icons/icon-16-star.svg'"
            [iconOnly]="true"
            (buttonPressed)="favoritizeSelection()"
          ></app-icon-button>
          <app-icon-button
            [tooltip]="'Voeg toe aan collectie'"
            [icon]="'/assets/icons/icon-16-collection.svg'"
            [iconOnly]="true"
            [loading]="isLoadingModal"
            (buttonPressed)="addToCollection(selectionCount)"
            [disabled]="selectionMode !== 'project'"
          ></app-icon-button>
          <app-icon-button
            [tooltip]="'Exporteer selectie'"
            [loading]="exporting"
            [icon]="'/assets/icons/icon-16-download.svg'"
            [iconOnly]="true"
            (buttonPressed)="exportSelection()"
          ></app-icon-button>
          <app-icon-button (buttonPressed)="clearSelection()">
            Sluiten
          </app-icon-button>
        </div>
      </div>
    }
  }
}
