@if (isLoading === false) {
  @if (isEmpty === false) {
    <ng-content></ng-content>
  } @else {
    <div class="text-center flex items-center flex-col mt-16">
      <span inlineSVG="/assets/icons/box-illustration.svg"></span>
      <span class="mt-6 font-medium text-lg text-darkBlue_2">{{emptyTitle}}</span>
      <span class="mt-4 text-neutral-1 w-1/2">{{emptyDescription}}</span>
    </div>
  }
} @else {
  <div class="relative">
    <span class="absolute top-24 left-1/2 transform -translate-x-1/2" inlineSVG="/assets/icons/loader.svg"></span>
  </div>
}

