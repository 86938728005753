import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

import {Router} from '@angular/router';
import {SelectionHelper} from '../is-selected/selection-helper';
import {Relation} from 'api/models/asset/relation';
import {SelectionService} from '../../services/selection.service';

@Component({
    selector: 'app-relation-tile',
    templateUrl: './relation-tile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RelationTileComponent extends SelectionHelper {
    @Input() relation: Relation;
    @Output() clicked = new EventEmitter<Relation>();

    constructor(
        protected selectionService: SelectionService,
        protected router: Router) {
        super(selectionService, router);
    }

    renderPhoneNumber(phone: string): string {
        const phoneNumber = phone
            .replace(/ /g, '');
        return `tel:${phoneNumber}`;
    }
}
