import {Directive, ElementRef, Input} from '@angular/core';
import {TooltipDirective} from 'ngx-bootstrap/tooltip';
import {fromEvent, merge, of} from 'rxjs';
import {bufferCount, delay, filter, mapTo, switchMap} from 'rxjs/operators';

/**
 * The time it takes between a mouseenter event and the tooltip starting to fade in
 */
const TOOLTIP_DELAY = 1000;

/**
 * The time it takes for the tooltip to fade in or out
 * Must be kept in sync with the transitions in _elements.scss
 * */
const TOOLTIP_FADE_DELAY = 500;

/**
 * Takes over showing / hiding of ngx-bootstrap tooltips, using a delay before
 * fading in, and hiding after fading out.
 *
 * Must be given the ngx-bootstrap tooltip directive reference, and triggers must be set to empty.
 *
 * @example <div tooltip="Hello, world!" triggers="" [appAnimatedTooltip]="tooltip" #tooltip="bs-tooltip">I have a tooltip</div>
 */
@Directive({
    selector: '[appAnimatedTooltip]',
    standalone: false
})
export class AnimatedTooltipDirective {
    @Input() appAnimatedTooltip?: TooltipDirective;

    get element(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    get tooltipElement(): HTMLElement | undefined {
        return document.getElementById(this.element.getAttribute('aria-describedby'));
    }

    constructor(private elementRef: ElementRef<HTMLElement>) {
        // Defer to ensure directive is set.
        setTimeout(() => {
            // Deprecated, but still used (and must be set to 0, because we manually handle all the delays)
            this.appAnimatedTooltip.tooltipFadeDuration = 0;
        });

        const mouseEnter$ = fromEvent(this.element, 'mouseenter');
        const mouseLeave$ = fromEvent(this.element, 'mouseleave');

        const actions$ = merge(mouseEnter$.pipe<'enter'>(mapTo('enter')), mouseLeave$.pipe<'leave'>(mapTo('leave'))).pipe(
            switchMap(origin => {
                if (origin === 'enter') {
                    return merge(
                        of<'enter'>(origin),
                        of<'fadeinstart'>('fadeinstart').pipe(delay(TOOLTIP_DELAY)),
                        of<'fadeinend'>('fadeinend').pipe(delay(TOOLTIP_DELAY + TOOLTIP_FADE_DELAY))
                    );
                } else if (origin === 'leave') {
                    return merge(
                        of<'fadeoutstart'>('fadeoutstart'),
                        of<'fadeoutend'>('fadeoutend').pipe(delay(TOOLTIP_FADE_DELAY))
                    );
                }
            })
        );
        actions$.subscribe(action => {
            switch (action) {
                case 'enter':
                    this.tooltipElement?.classList?.remove?.('tooltip-fading');
                    break;
                case 'fadeinstart':
                    this.appAnimatedTooltip.show();
                    break;
                case 'fadeoutstart':
                    this.tooltipElement?.classList?.add?.('tooltip-fading');
                    break;
                case 'fadeoutend':
                    this.appAnimatedTooltip.hide();
                    break;
            }
        });

        // Immediately hide tooltip when mouse leaves after fadeinstart, but before fadeinend
        merge(
            actions$.pipe(filter(action => action.startsWith('fadein'))),
            mouseLeave$.pipe<'mouseleave'>(mapTo('mouseleave'))
        ).pipe(
            bufferCount(2, 1),
            filter(([previousAction, currentAction]) => {
                return previousAction === 'fadeinstart' && currentAction === 'mouseleave';
            })
        ).subscribe(() => this.appAnimatedTooltip.hide());
    }
}
