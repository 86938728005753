import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {AppAuthorizationPageComponent} from './pages/app-authorization-page/app-authorization-page.component';
import {MultiSelectUsersComponent} from './components/multi-select-users/multi-select-users.component';
import {AppRoleAssignmentServiceImpl} from './services/app-role-assignment.service';
import {AppRoleAssignmentServiceMock} from './services/mock/app-role-assignment.service.mock';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {RouterModule} from '@angular/router';

export function serviceWithMock(provide: string, serviceRef: any, mock: any): any {
    return {
        useClass: serviceRef, // No e2e env yet
        provide
    };
}

/**
 * Required scopes:
 * - User.ReadBasic.All
 * - AppRoleAssignment.ReadWrite.All
 * - optional? Application.Read.All
 */
@NgModule({
    declarations: [
        AppAuthorizationPageComponent,
        MultiSelectUsersComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        ReactiveFormsModule,
        InlineSVGModule.forRoot({baseUrl: ''}),
        TooltipModule,
        RouterModule,
    ],
    providers: [
        serviceWithMock('AppRoleAssignmentService', AppRoleAssignmentServiceImpl, AppRoleAssignmentServiceMock)
    ]
})
export class AppRoleAssignmentModule {
}
