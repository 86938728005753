import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ProjectAppStoreItem} from 'api/models/asset/project-app-store-item';

@Injectable({
    providedIn: 'root'
})
export class ProjectAppStoreItemService {
    constructor(private http: HttpClient) {
    }

    getByProjectId(projectId: number): Observable<ProjectAppStoreItem[]> {
        return this.http.get<ProjectAppStoreItem[]>(`${environment.azureBaseUrl}/project-app-store-item/${projectId}`);
    }
}
