@if (projectRelation) {
  <div class="rounded border pb-0 mb-2">
    <div class="text-dark p-4">
      <div>{{projectRelation.role}}</div>
      <div class="font-medium">{{projectRelation.companyName}}</div>
      <div>{{projectRelation.place}}</div>
    </div>
  </div>
}

