"use strict";

import f from "./thirdparty/merge.js";
import { getFeature as l } from "./FeaturesRegistry.js";
import { DEFAULT_THEME as c } from "./generated/AssetParameters.js";
import m from "./validateThemeRoot.js";
import d from "./types/AnimationMode.js";
import { resetConfiguration as h } from "./config/ConfigurationReset.js";
import { getLocationSearch as y } from "./Location.js";
let p = !1,
  t = {
    animationMode: d.Full,
    theme: c,
    themeRoot: void 0,
    rtl: void 0,
    language: void 0,
    timezone: void 0,
    calendarType: void 0,
    secondaryCalendarType: void 0,
    noConflict: !1,
    formatSettings: {},
    fetchDefaultLanguage: !1,
    defaultFontLoading: !0,
    enableDefaultTooltips: !0
  };
const C = () => (o(), t.animationMode),
  T = () => (o(), t.theme),
  S = () => (o(), t.themeRoot),
  L = () => (o(), t.language),
  F = () => (o(), t.fetchDefaultLanguage),
  U = () => (o(), t.noConflict),
  b = () => (o(), t.defaultFontLoading),
  D = () => (o(), t.enableDefaultTooltips),
  I = () => (o(), t.calendarType),
  O = () => (o(), t.secondaryCalendarType),
  R = () => (o(), t.timezone),
  M = () => (o(), t.formatSettings),
  i = new Map();
i.set("true", !0), i.set("false", !1);
const z = () => {
    const n = document.querySelector("[data-ui5-config]") || document.querySelector("[data-id='sap-ui-config']");
    let e;
    if (n) {
      try {
        e = JSON.parse(n.innerHTML);
      } catch {
        console.warn("Incorrect data-sap-ui-config format. Please use JSON");
      }
      e && (t = f(t, e));
    }
  },
  E = () => {
    const n = new URLSearchParams(y());
    n.forEach((e, a) => {
      const r = a.split("sap-").length;
      r === 0 || r === a.split("sap-ui-").length || u(a, e, "sap");
    }), n.forEach((e, a) => {
      a.startsWith("sap-ui") && u(a, e, "sap-ui");
    });
  },
  P = n => {
    const e = n.split("@")[1];
    return m(e);
  },
  w = (n, e) => n === "theme" && e.includes("@") ? e.split("@")[0] : e,
  u = (n, e, a) => {
    const r = e.toLowerCase(),
      s = n.split(`${a}-`)[1];
    i.has(e) && (e = i.get(r)), s === "theme" ? (t.theme = w(s, e), e && e.includes("@") && (t.themeRoot = P(e))) : t[s] = e;
  },
  j = () => {
    const n = l("OpenUI5Support");
    if (!n || !n.isOpenUI5Detected()) return;
    const e = n.getConfigurationSettingsObject();
    t = f(t, e);
  },
  o = () => {
    typeof document > "u" || p || (g(), p = !0);
  },
  g = n => {
    n && h(), z(), E(), j();
  };
export { C as getAnimationMode, T as getTheme, S as getThemeRoot, L as getLanguage, F as getFetchDefaultLanguage, U as getNoConflict, I as getCalendarType, O as getSecondaryCalendarType, R as getTimezone, M as getFormatSettings, b as getDefaultFontLoading, g as resetConfiguration, D as getEnableDefaultTooltips };
