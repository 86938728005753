import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AppStoreItem} from 'api/models/asset/app-store-item';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProjectAppStoreItem} from 'api/models/asset/project-app-store-item';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppStoreItemService {
    appStoreItems$: BehaviorSubject<AppStoreItem[]> = new BehaviorSubject([]);

    constructor(private http: HttpClient) {
    }

    init() {
        this.getAll().subscribe(appStoreItems => this.appStoreItems$.next(appStoreItems));
    }

    getAll(): Observable<AppStoreItem[]> {
        return this.http.get<AppStoreItem[]>(`${environment.azureBaseUrl}/app-store-item`);
    }

    findByProjectAppStoreItem(projectAppStoreItem: ProjectAppStoreItem): Observable<AppStoreItem> {
        return this.appStoreItems$.pipe(
            map(appStoreItems => appStoreItems.find(appStoreItem => appStoreItem.id === +projectAppStoreItem.appStoreItemId))
        );
    }
}
