"use strict";

import { getAllRegisteredTags as g } from "./CustomElementsRegistry.js";
import { getCustomElementsScopingRules as p, getCustomElementsScopingSuffix as R } from "./CustomElementsScopeUtils.js";
import d from "./generated/VersionInfo.js";
import h from "./getSharedResource.js";
let i,
  s = "";
const u = new Map(),
  r = h("Runtimes", []),
  x = () => {
    if (i === void 0) {
      i = r.length;
      const e = d;
      r.push({
        ...e,
        get scopingSuffix() {
          return R();
        },
        get registeredTags() {
          return g();
        },
        get scopingRules() {
          return p();
        },
        alias: s,
        description: `Runtime ${i} - ver ${e.version}${s ? ` (${s})` : ""}`
      });
    }
  },
  I = () => i,
  b = (e, m) => {
    const o = `${e},${m}`;
    if (u.has(o)) return u.get(o);
    const t = r[e],
      n = r[m];
    if (!t || !n) throw new Error("Invalid runtime index supplied");
    if (t.isNext || n.isNext) return t.buildTime - n.buildTime;
    const c = t.major - n.major;
    if (c) return c;
    const a = t.minor - n.minor;
    if (a) return a;
    const f = t.patch - n.patch;
    if (f) return f;
    const l = new Intl.Collator(void 0, {
      numeric: !0,
      sensitivity: "base"
    }).compare(t.suffix, n.suffix);
    return u.set(o, l), l;
  },
  C = e => {
    s = e;
  },
  $ = () => r;
export { I as getCurrentRuntimeIndex, x as registerCurrentRuntime, b as compareRuntimes, C as setRuntimeAlias, $ as getAllRuntimes };
