import {Component, OnInit} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';


@Component({
    selector: 'app-mca-matrix-modal',
    templateUrl: './mca-matrix-modal.component.html',
    standalone: false
})
export class McaMatrixModalComponent implements OnInit {
    mcaMatrixUrl: SafeResourceUrl;

    constructor(public vwuiModalRef: VwuiModalRef,
                public modalConfig: VwuiModalConfig<{ mcaMatrixUrl: string }>,
                private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        const mcaMatrixUrl = new URL(this.modalConfig.data.mcaMatrixUrl);
        if (!mcaMatrixUrl.searchParams.has('skipTrimbleInitialization')) {
            mcaMatrixUrl.searchParams.append('skipTrimbleInitialization', 'true');
        }
        this.mcaMatrixUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mcaMatrixUrl.toString());
    }

    close() {
        this.vwuiModalRef.close();
    }
}
