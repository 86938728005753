<div class="relative">
  <div class="pb-4 flex items-center">
    @if (!isOnMobile) {
      <app-export-dropdown titleText="Resultaten" table="company">
      </app-export-dropdown>
    }
    @if (gridView$|async;) {
      <app-pagination-sort class="ml-auto" [paginator]="paginator"
      [sortOptions]="companySortOptions" [setSortFromQueryParams]="true" [startOption]="'recent'"></app-pagination-sort>
    }
    <app-results-view-switch class="mobile:hidden" [class.ml-auto]="(gridView$|async) === false"></app-results-view-switch>
    <app-pagination class="mobile:hidden" [paginator]="paginator"></app-pagination>
  </div>
  @if (paginator.content$|async; as companies) {
    @if ((gridView$|async) || isOnMobile) {
      <app-company-tile-grid [companies]="companies">
      </app-company-tile-grid>
    } @else {
      <app-company-list [items]="companies"></app-company-list>
    }
  }

  @if (paginator.isLoading) {
    <span class="absolute top-24 left-1/2 transform -translate-x-1/2"
    inlineSVG="/assets/icons/loader.svg"></span>
  }
  @if (paginator.isLoading === false && paginator.isEmpty) {
    <app-empty-state
      style="padding-top: calc(50vh - 248px - 112px)">
    </app-empty-state>
  }
  <div class="mt-4 flex items-end flex-col">
    <app-pagination [paginator]="paginator"></app-pagination>
  </div>
</div>
