@if (projects$ | async; as projects) {
  <div class="flex">
    @for (project of projects.slice(0, 3); track project) {
      <div
        @fadeInAnimation
        [class.bg-primary]="!(project | projectImage)"
        class="relative w-8 h-8 rounded flex items-center justify-center -mx-1 cursor-pointer"
        >
        @if (project | projectImage) {
          <img
            [src]="project | projectImage"
            alt=""
            class="absolute inset-0 w-full object-cover rounded"
            >
        } @else {
          <span [setSVGAttributes]="{width: 16, height: 16}"
            class="absolute left-auto right-auto text-white rounded"
            inlineSVG="/assets/icons/icon-16-folder.svg"
          ></span>
        }
      </div>
    }
    @if (projects.length > 3) {
      <div
        class="relative w-8 h-8 rounded flex items-center justify-center -mx-1 cursor-pointer bg-neutral-4"
        >
        <span class="absolute left-auto right-auto text-xs text-dark rounded">+{{ projects.length - 3 }}</span>
      </div>
    }
  </div>
} @else {
  <div class="relative w-8 h-8 rounded flex items-center justify-center -mx-1 cursor-pointer bg-primary">
    <span [setSVGAttributes]="{width: 16, height: 16}"
      class="absolute left-auto right-auto text-white rounded"
      inlineSVG="/assets/icons/icon-16-folder.svg"
    ></span>
  </div>
}

