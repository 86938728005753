"use strict";

const r = new Set(),
  s = () => {
    let e = document.querySelector(".sapThemeMetaData-Base-baseLib") || document.querySelector(".sapThemeMetaData-UI5-sap-ui-core");
    if (e) return getComputedStyle(e).backgroundImage;
    e = document.createElement("span"), e.style.display = "none", e.classList.add("sapThemeMetaData-Base-baseLib"), document.body.appendChild(e);
    let t = getComputedStyle(e).backgroundImage;
    return t === "none" && (e.classList.add("sapThemeMetaData-UI5-sap-ui-core"), t = getComputedStyle(e).backgroundImage), document.body.removeChild(e), t;
  },
  o = e => {
    const t = /\(["']?data:text\/plain;utf-8,(.*?)['"]?\)$/i.exec(e);
    if (t && t.length >= 2) {
      let a = t[1];
      if (a = a.replace(/\\"/g, '"'), a.charAt(0) !== "{" && a.charAt(a.length - 1) !== "}") try {
        a = decodeURIComponent(a);
      } catch {
        r.has("decode") || (console.warn("Malformed theme metadata string, unable to decodeURIComponent"), r.add("decode"));
        return;
      }
      try {
        return JSON.parse(a);
      } catch {
        r.has("parse") || (console.warn("Malformed theme metadata string, unable to parse JSON"), r.add("parse"));
      }
    }
  },
  d = e => {
    let t, a;
    try {
      t = e.Path.match(/\.([^.]+)\.css_variables$/)[1], a = e.Extends[0];
    } catch {
      r.has("object") || (console.warn("Malformed theme metadata Object", e), r.add("object"));
      return;
    }
    return {
      themeName: t,
      baseThemeName: a
    };
  },
  m = () => {
    const e = s();
    if (!e || e === "none") return;
    const t = o(e);
    if (t) return d(t);
  };
export default m;
