"use strict";

import { registerI18nLoader as o, fetchI18nBundle as d, getI18nBundleData as g } from "./asset-registries/i18n.js";
import I from "./util/formatMessage.js";
const r = new Map();
let s;
class u {
  constructor(e) {
    this.packageName = e;
  }
  getText(e, ...i) {
    if (typeof e == "string" && (e = {
      key: e,
      defaultText: e
    }), !e || !e.key) return "";
    const t = g(this.packageName);
    t && !t[e.key] && console.warn(`Key ${e.key} not found in the i18n bundle, the default text will be used`);
    const l = t && t[e.key] ? t[e.key] : e.defaultText || e.key;
    return I(l, i);
  }
}
const a = n => {
    if (r.has(n)) return r.get(n);
    const e = new u(n);
    return r.set(n, e), e;
  },
  f = async n => s ? s(n) : (await d(n), a(n)),
  y = n => {
    s = n;
  };
export default u;
export { o as registerI18nLoader, f as getI18nBundle, y as registerCustomI18nBundleGetter };
