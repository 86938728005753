import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-ui-section',
    templateUrl: './ui-section.component.html',
    styleUrls: ['./ui-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UiSectionComponent {
    @Input() sectionTitle!: string;
}
