"use strict";

const o = t => {
    s(t) && e(t);
  },
  e = t => {
    if (t._internals?.form) {
      if (n(t), !t.name) {
        t._internals?.setFormValue(null);
        return;
      }
      t._internals.setFormValue(t.formFormattedValue);
    }
  },
  n = async t => {
    if (t._internals?.form) if (t.formValidity && Object.keys(t.formValidity).some(r => r)) {
      const r = await t.formElementAnchor?.();
      t._internals.setValidity(t.formValidity, t.formValidityMessage, r);
    } else t._internals.setValidity({});
  },
  i = t => {
    t._internals?.form?.requestSubmit();
  },
  m = t => {
    t._internals?.form?.reset();
  },
  s = t => "formFormattedValue" in t && "name" in t;
export { o as updateFormValue, e as setFormValue, n as setFormValidity, i as submitForm, m as resetForm };
