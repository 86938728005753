import {Injectable} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable, Subscription} from 'rxjs';
import {ApiService} from './api.service';
import {concatMap, skip} from 'rxjs/operators';
import {UserFavorite, UserFavorites} from 'api/models/user-favorite';

@Injectable({
    providedIn: 'root'
})
export class FavoriteService {
    private favoriteSaveCount$ = new BehaviorSubject(0);
    private favoritesInternal$ = new BehaviorSubject<UserFavorites>([]);
    private saveFavoritesSubscription: Subscription;
    readonly favorites$: Observable<UserFavorites> = this.favoritesInternal$.asObservable();

    constructor(
        private apiService: ApiService
    ) {
        this.init();
    }

    async saveFavorites() {
        this.favoriteSaveCount$.next(this.favoriteSaveCount$.value + 1);
        try {
            await lastValueFrom(this.apiService.postFavorites(this.favoritesInternal$.value));
        } catch (e) {
            console.error('Save favorite failed', e);
            await this.init();
        } finally {
            this.favoriteSaveCount$.next(this.favoriteSaveCount$.value - 1);
        }
    }

    async loadFavorites() {
        const favorites = await lastValueFrom(this.apiService.getFavorites());
        if (Array.isArray(favorites)) {
            this.favoritesInternal$.next(favorites);
        } else {
            console.error('Invalid favorites response', favorites);
        }
    }

    addFavorite(favorite: UserFavorite) {
        const favorites = this.favoritesInternal$.value;
        if (!favorites.some(item => item.type === favorite.type && item.id === favorite.id)) {
            favorites.push(favorite);
            this.favoritesInternal$.next(favorites);
        }
    }

    removeFavorite(favorite: UserFavorite) {
        const favorites = this.favoritesInternal$.value;
        const index = favorites.findIndex(item => item.type === favorite.type && item.id === favorite.id);
        if (index !== -1) {
            favorites.splice(index, 1);
            this.favoritesInternal$.next(favorites);
        }
    }

    private async init() {
        if (this.saveFavoritesSubscription) {
            this.saveFavoritesSubscription.unsubscribe();
        }
        await this.loadFavorites();
        this.saveFavoritesSubscription = this.favoritesInternal$.pipe(
            skip(1),
            concatMap(() => this.saveFavorites())
        ).subscribe();
    }
}
