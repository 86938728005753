import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'https',
    standalone: false
})
export class HttpsPipe implements PipeTransform {
    transform(value: any) {
        if (value && (''+value).toLowerCase().indexOf('https://') !== 0) {
            return `https://${value}`;
        }

        return value;
    }
}
