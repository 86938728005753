import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CreateSearchPreference, ExportPreference} from 'api/models/export-preference';

@Injectable({
    providedIn: 'root'
})
export class ExportPreferenceService {
    constructor(
        private httpClient: HttpClient
    ) {
    }

    getExportPreferences() {
        return this.httpClient.get<ExportPreference[]>(`${environment.azureBaseUrl}/export-preferences`);
    }

    postExportPreference(exportPreference: CreateSearchPreference) {
        return this.httpClient.post<ExportPreference>(`${environment.azureBaseUrl}/export-preferences`, exportPreference);
    }

    deleteExportPreference(id: number) {
        return this.httpClient.delete<void>(`${environment.azureBaseUrl}/export-preferences/${id}`);
    }
}
