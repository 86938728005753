import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-ui-empty-state',
    templateUrl: './ui-empty-state.component.html',
    styleUrls: ['./ui-empty-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UiEmptyStateComponent {
    @Input() emptyStateTitle!: string;
}
