"use strict";

import o from "./isElementHidden.js";
import E from "./isElementClickable.js";
import { instanceOfUI5Element as f } from "../UI5Element.js";
const r = e => e.hasAttribute("data-ui5-focus-trap"),
  c = async (e, t) => !e || o(e) ? null : m(e, !0, t),
  d = async (e, t) => !e || o(e) ? null : m(e, !1, t),
  T = e => e.hasAttribute("data-ui5-focus-redirect") || !o(e),
  m = async (e, t, a) => {
    let l,
      n,
      s = -1;
    e.shadowRoot ? l = t ? e.shadowRoot.firstChild : e.shadowRoot.lastChild : e instanceof HTMLSlotElement && e.assignedNodes() ? (n = e.assignedNodes(), s = t ? 0 : n.length - 1, l = n[s]) : a ? l = e : l = t ? e.firstElementChild : e.lastElementChild;
    let i;
    for (; l;) {
      const u = l;
      if (f(l) && (l = await l.getFocusDomRefAsync()), !l) return null;
      if (l.nodeType === 1 && T(l) && !r(l)) {
        if (E(l)) return l && typeof l.focus == "function" ? l : null;
        if (i = await m(l, t), i) return i && typeof i.focus == "function" ? i : null;
      }
      l = t ? u.nextSibling : u.previousSibling, n && !n[s].contains(l) && (s = t ? s + 1 : s - 1, l = n[s]);
    }
    return null;
  };
export { c as getFirstFocusableElement, d as getLastFocusableElement };
