const ICON_ACTION_SETTINGS = {
  key: "ICON_ACTION_SETTINGS",
  defaultText: "Settings"
};
const ICON_ACTIVATE = {
  key: "ICON_ACTIVATE",
  defaultText: "Activate"
};
const ICON_ADD = {
  key: "ICON_ADD",
  defaultText: "Add"
};
const ICON_ADD_CONTACT = {
  key: "ICON_ADD_CONTACT",
  defaultText: "Add Contact"
};
const ICON_ADD_FILTER = {
  key: "ICON_ADD_FILTER",
  defaultText: "Add Filter"
};
const ICON_ADD_PHOTO = {
  key: "ICON_ADD_PHOTO",
  defaultText: "Add Photo"
};
const ICON_BACK_TO_TOP = {
  key: "ICON_BACK_TO_TOP",
  defaultText: "Back to Top"
};
const ICON_COLLAPSE = {
  key: "ICON_COLLAPSE",
  defaultText: "Collapse"
};
const ICON_COLLAPSE_GROUP = {
  key: "ICON_COLLAPSE_GROUP",
  defaultText: "Collapse Group"
};
const ICON_CROP = {
  key: "ICON_CROP",
  defaultText: "Crop"
};
const ICON_DECLINE = {
  key: "ICON_DECLINE",
  defaultText: "Decline"
};
const ICON_DELETE = {
  key: "ICON_DELETE",
  defaultText: "Delete"
};
const ICON_DISPLAY = {
  key: "ICON_DISPLAY",
  defaultText: "Display"
};
const ICON_DOWN = {
  key: "ICON_DOWN",
  defaultText: "Down"
};
const ICON_DOWNLOAD = {
  key: "ICON_DOWNLOAD",
  defaultText: "Download"
};
const ICON_DRILL_DOWN = {
  key: "ICON_DRILL_DOWN",
  defaultText: "Drill Down"
};
const ICON_DRILL_UP = {
  key: "ICON_DRILL_UP",
  defaultText: "Drill Up"
};
const ICON_ERROR = {
  key: "ICON_ERROR",
  defaultText: "Error"
};
const ICON_EXIT_FULL_SCREEN = {
  key: "ICON_EXIT_FULL_SCREEN",
  defaultText: "Exit Full Screen"
};
const ICON_EXPAND = {
  key: "ICON_EXPAND",
  defaultText: "Expand"
};
const ICON_EXPAND_GROUP = {
  key: "ICON_EXPAND_GROUP",
  defaultText: "Expand Group"
};
const ICON_FILTER = {
  key: "ICON_FILTER",
  defaultText: "Filter"
};
const ICON_FLAG = {
  key: "ICON_FLAG",
  defaultText: "Flag"
};
const ICON_FORWARD = {
  key: "ICON_FORWARD",
  defaultText: "Forward"
};
const ICON_FULL_SCREEN = {
  key: "ICON_FULL_SCREEN",
  defaultText: "Enter Full Screen"
};
const ICON_GENERATE_SHORTCUT = {
  key: "ICON_GENERATE_SHORTCUT",
  defaultText: "Create Shortcut"
};
const ICON_GROUP_2 = {
  key: "ICON_GROUP_2",
  defaultText: "Group"
};
const ICON_HIDE = {
  key: "ICON_HIDE",
  defaultText: "Hide"
};
const ICON_IPAD = {
  key: "ICON_IPAD",
  defaultText: "Tablet"
};
const ICON_IPHONE = {
  key: "ICON_IPHONE",
  defaultText: "Phone"
};
const ICON_LAPTOP = {
  key: "ICON_LAPTOP",
  defaultText: "Laptop"
};
const ICON_MESSAGE_ERROR = {
  key: "ICON_MESSAGE_ERROR",
  defaultText: "Error"
};
const ICON_MESSAGE_INFORMATION = {
  key: "ICON_MESSAGE_INFORMATION",
  defaultText: "Information"
};
const ICON_MESSAGE_SUCCESS = {
  key: "ICON_MESSAGE_SUCCESS",
  defaultText: "Successful"
};
const ICON_MESSAGE_WARNING = {
  key: "ICON_MESSAGE_WARNING",
  defaultText: "Warning"
};
const ICON_MOVE = {
  key: "ICON_MOVE",
  defaultText: "Move"
};
const ICON_MULTI_SELECT = {
  key: "ICON_MULTI_SELECT",
  defaultText: "Multi Select"
};
const ICON_NAV_BACK = {
  key: "ICON_NAV_BACK",
  defaultText: "Navigate Back"
};
const ICON_OVERFLOW = {
  key: "ICON_OVERFLOW",
  defaultText: "More"
};
const ICON_REDO = {
  key: "ICON_REDO",
  defaultText: "Redo"
};
const ICON_REFRESH = {
  key: "ICON_REFRESH",
  defaultText: "Refresh"
};
const ICON_RESIZE = {
  key: "ICON_RESIZE",
  defaultText: "Resize"
};
const ICON_RESIZE_HORIZONTAL = {
  key: "ICON_RESIZE_HORIZONTAL",
  defaultText: "Resize Horizontally"
};
const ICON_RESIZE_VERTICAL = {
  key: "ICON_RESIZE_VERTICAL",
  defaultText: "Resize Vertically"
};
const ICON_RESPONSE = {
  key: "ICON_RESPONSE",
  defaultText: "Reply"
};
const ICON_SAVE = {
  key: "ICON_SAVE",
  defaultText: "Save"
};
const ICON_SEARCH = {
  key: "ICON_SEARCH",
  defaultText: "Search"
};
const ICON_SETTINGS = {
  key: "ICON_SETTINGS",
  defaultText: "Settings"
};
const ICON_SHOW = {
  key: "ICON_SHOW",
  defaultText: "Show"
};
const ICON_SORT = {
  key: "ICON_SORT",
  defaultText: "Sort"
};
const ICON_SORT_ASCENDING = {
  key: "ICON_SORT_ASCENDING",
  defaultText: "Sort Ascending"
};
const ICON_SORT_DESCENDING = {
  key: "ICON_SORT_DESCENDING",
  defaultText: "Sort Descending"
};
const ICON_SYNCHRONIZE = {
  key: "ICON_SYNCHRONIZE",
  defaultText: "Synchronize"
};
const ICON_UNDO = {
  key: "ICON_UNDO",
  defaultText: "Undo"
};
const ICON_UP = {
  key: "ICON_UP",
  defaultText: "Up"
};
const ICON_UPLOAD = {
  key: "ICON_UPLOAD",
  defaultText: "Upload"
};
const ICON_ZOOM_IN = {
  key: "ICON_ZOOM_IN",
  defaultText: "Zoom In"
};
const ICON_ZOOM_OUT = {
  key: "ICON_ZOOM_OUT",
  defaultText: "Zoom Out"
};
export { ICON_ACTION_SETTINGS, ICON_ACTIVATE, ICON_ADD, ICON_ADD_CONTACT, ICON_ADD_FILTER, ICON_ADD_PHOTO, ICON_BACK_TO_TOP, ICON_COLLAPSE, ICON_COLLAPSE_GROUP, ICON_CROP, ICON_DECLINE, ICON_DELETE, ICON_DISPLAY, ICON_DOWN, ICON_DOWNLOAD, ICON_DRILL_DOWN, ICON_DRILL_UP, ICON_ERROR, ICON_EXIT_FULL_SCREEN, ICON_EXPAND, ICON_EXPAND_GROUP, ICON_FILTER, ICON_FLAG, ICON_FORWARD, ICON_FULL_SCREEN, ICON_GENERATE_SHORTCUT, ICON_GROUP_2, ICON_HIDE, ICON_IPAD, ICON_IPHONE, ICON_LAPTOP, ICON_MESSAGE_ERROR, ICON_MESSAGE_INFORMATION, ICON_MESSAGE_SUCCESS, ICON_MESSAGE_WARNING, ICON_MOVE, ICON_MULTI_SELECT, ICON_NAV_BACK, ICON_OVERFLOW, ICON_REDO, ICON_REFRESH, ICON_RESIZE, ICON_RESIZE_HORIZONTAL, ICON_RESIZE_VERTICAL, ICON_RESPONSE, ICON_SAVE, ICON_SEARCH, ICON_SETTINGS, ICON_SHOW, ICON_SORT, ICON_SORT_ASCENDING, ICON_SORT_DESCENDING, ICON_SYNCHRONIZE, ICON_UNDO, ICON_UP, ICON_UPLOAD, ICON_ZOOM_IN, ICON_ZOOM_OUT };
