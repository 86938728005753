<div class="px-6 mobile:pt-20 mobile:px-0" [formGroup]="form">
  <div class="mobile:hidden h-24 flex items-center">
    <h1 appTitle="lg" class="m-0">Zoeken</h1>
    <button class="ml-auto text-neutral-1">
      <span class="mr-1" inlineSVG="/assets/icons/icon-info.svg"></span>
      Hulp nodig?
    </button>
  </div>
  <div class="mobile:fixed mobile:top-0 mobile:left-0 mobile:w-full mobile:z-20 mobile:bg-white">
    <div class="desktop:hidden h-4" (click)="scrollToTop()"></div>
    <div class="flex gap-2 desktop:border-t border-neutral-3 desktop:pt-4 pb-4 relative mobile:mx-4" [class.border-b]="isCurrentRouteOverview()">
      <app-search-input class="flex-grow" formControlName="query"></app-search-input>
    </div>
  </div>

  @if (!isCurrentRouteOverview()) {
    @if (results$ | async; as results) {
      <div class="flex w-full text-center overflow-x-auto">
        <a class="p-3 border-b-2 hover:text-primary" routerLink="/search/all"
          queryParamsHandling="merge" [queryParams]="defaultQueryParams"
          [ngClass]="isActiveTab('all') ? ['text-primary', 'border-primary'] : 'text-neutral-1'">
          Alle
        </a>
        <a class="p-3 border-b-2 hover:text-primary" routerLink="/search/projects"
          queryParamsHandling="merge" [queryParams]="defaultQueryParams"
          [ngClass]="isActiveTab('projects') ? ['text-primary', 'border-primary'] : 'text-neutral-1'">
          Projecten ({{results.project.count}})
        </a>
        <a class="p-3 border-b-2 hover:text-primary" routerLink="/search/companies"
          queryParamsHandling="merge" [queryParams]="defaultQueryParams"
          [ngClass]="isActiveTab('companies') ? ['text-primary', 'border-primary'] : 'text-neutral-1'">
          Bedrijven ({{results.company.count}})
        </a>
        <a class="p-3 border-b-2 hover:text-primary" routerLink="/search/adressen"
          queryParamsHandling="merge" [queryParams]="defaultQueryParams"
          [ngClass]="isActiveTab('adressen') ? ['text-primary', 'border-primary'] : 'text-neutral-1'">
          Adressen ({{results.verblijfsobject.count}})
        </a>
        <a class="p-3 border-b-2 hover:text-primary" routerLink="/search/relations"
          queryParamsHandling="merge" [queryParams]="defaultQueryParams"
          [ngClass]="isActiveTab('relations') ? ['text-primary', 'border-primary'] : 'text-neutral-1'">
          Leveranciers ({{results.relation.count}})
        </a>
        <a class="p-3 border-b-2 hover:text-primary" routerLink="/search/plaatsen"
          queryParamsHandling="merge" [queryParams]="defaultQueryParams"
          [ngClass]="isActiveTab('plaatsen') ? ['text-primary', 'border-primary'] : 'text-neutral-1'">
          Plaatsen ({{results.woonplaats.count}})
        </a>
        <span class="flex-grow border-b-2"></span>
      </div>
    }
  }

  <div class="py-4 mobile:px-4">
    <router-outlet></router-outlet>
  </div>
</div>
