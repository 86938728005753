@if (project) {
  <div class="overflow-hidden h-80 relative">
    @if (project | projectImage) {
      <img [src]="project | projectImage"
        class="absolute inline-block max-h-80 mobile:max-72 inset-0 w-full transform object-cover"
        >
    }
    <div class="absolute inset-x-0 bottom-0 px-6 pb-4 flex flex-col justify-end text-left h-24 bg-gradient-to-b from-transparent to-dark">
      @if (hasCommercialProjectName(project)) {
        <span
          class="text-3xl mobile:text-2xl font-medium text-white">
          {{ project.commercial_project_name }}
          <span [tooltip]="getProjectNameTooltipText(project)" class="mr-1" inlineSVG="/assets/icons/icon-info.svg"></span>
        </span>
      }
      @if (!hasCommercialProjectName(project)) {
        <span class="text-3xl mobile:text-2xl font-medium text-white">{{ project.naam }}</span>
      }
      <span class="mobile:text-m text-white block mt-2 mb-4">{{ project.plaats }}</span>
    </div>
    @if ((project | projectImage) === null) {
      <div class="absolute inset-0 bottom-18 bg-neutral-4">
        <span inlineSVG="/assets/icons/thumbnail.svg"
          class="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 object-contain"
        ></span>
      </div>
    }
    @if (!(project | projectImage)) {
      <div
        class="absolute inset-x-0 bottom-0 px-6 pb-4 flex flex-col justify-end text-left border-b border-neutral-4 h-18 bg-white"
        >
        @if (hasCommercialProjectName(project)) {
          <span class="text-base font-medium text-dark truncate">{{ project.commercial_project_name }}
            <span [tooltip]="getProjectNameTooltipText(project)" inlineSVG="/assets/icons/icon-info.svg"></span>
          </span>
        }
        @if (!hasCommercialProjectName(project)) {
          <span class="text-base font-medium text-dark truncate">{{ project.naam }}</span>
        }
        <span class="text-xs font-medium text-neutral-1">{{ project.plaats }}</span>
      </div>
    }
    <div (click)="handleGoBack()" class="test-back-button absolute left-6 top-6 justify-between shadow cursor-pointer">
      <div class="min-w-5 min-h-5 mt-1 w-5 h-5">
        <span class="p-1 bg-white rounded" inlineSVG="/assets/icons/icon-arrow-left.svg"></span>
      </div>
    </div>
    <app-project-detail-context-menu [project]="project" class="absolute top-6 right-6"></app-project-detail-context-menu>
  </div>
}
