import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {Project, projectImage} from 'api/models/asset/project';
import {AbstractTileContextMenuComponent} from './abstract-tile-context-menu.component';
import {FavoriteService} from '../../services/favorite.service';
import {Overlay} from '@angular/cdk/overlay';
import {SendLocationModalComponent} from '../send-location-modal/send-location-modal.component';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {SelectionService} from '../../services/selection.service';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-project-detail-context-menu',
    templateUrl: './detail-context-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectDetailContextMenuComponent extends AbstractTileContextMenuComponent {
    @Input() project: Project;

    constructor(
        protected changeDetectionRef: ChangeDetectorRef,
        protected overlay: Overlay,
        protected selectionService: SelectionService,
        protected router: Router,
        private favoriteService: FavoriteService,
        private modalService: ModalService,
        private vwuiModalService: VwuiModalService,
    ) {
        super(changeDetectionRef, overlay, selectionService, router);
    }

    onAddFavorite(event: MouseEvent, project: Project) {
        event.stopPropagation();
        this.favoriteService.addFavorite({
            type: 'project',
            id: project.id
        });
        this.onOutsideOverlayClick();
    }

    onRemoveFavorite(event: MouseEvent, project: Project) {
        event.stopPropagation();
        this.favoriteService.removeFavorite({
            type: 'project',
            id: project.id
        });
        this.onOutsideOverlayClick();
    }

    async emailLocation(event: Event, project: Project) {
        event.stopPropagation();
        const lngLat = project.geog.coordinates;
        this.vwuiModalService.open(SendLocationModalComponent, {
            closeOnBackdropClick: false,
            data: {
                title: project.naam,
                point: lngLat
            }
        });
        this.onOutsideOverlayClick();
    }

    addToCollection(event: Event, project: Project) {
        this.selectionService.addSelected({
            id: project.id,
            type: 'project',
            icon: projectImage(project)
        });
        this.modalService.openCollectionModal();
    }
}
