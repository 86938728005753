<button class="w-8 h-8 p-1 text-neutral-1 disabled:bg-white disabled:cursor-not-allowed desktop:hover:bg-neutral-4 rounded"
  [disabled]="!paginator.hasPrevious()"
  (click)="paginator.previous()"
  inlineSVG="/assets/icons/icon-chevron-down.svg"
  [setSVGAttributes]="{transform: 'rotate(90)'}">
</button>
<button #dropdownButton class="h-8 px-2 desktop:hover:bg-neutral-4 rounded" (click)="onDropdownOpenClick()" type="button"
  cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  {{paginator.currentPageFirstIndex}}-{{paginator.currentPageLastIndex}}
  van {{ paginator.totalElements }}
</button>
<button class="w-8 h-8 p-1 text-neutral-1 disabled:bg-white disabled:cursor-not-allowed desktop:hover:bg-neutral-4 rounded"
  [disabled]="!paginator.hasNext()"
  (click)="paginator.next()"
  inlineSVG="/assets/icons/icon-chevron-down.svg"
  [setSVGAttributes]="{transform: 'rotate(-90)'}">
</button>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpen"
  [cdkConnectedOverlayPositions]="connectedPositions"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  (overlayOutsideClick)="onOutsideOverlayClick()"
  (detach)="onOutsideOverlayClick()"
  >
  <div class="bg-white border border-neutral-3 rounded p-2 w-32 shadow-overlay">
    <div class="w-full px-4 py-2 text-neutral-1 text-xs font-medium">Toon</div>
    @for (size of pageSizes; track size) {
      <button class="w-full px-4 py-2 rounded hover:bg-neutral-4 text-left" (click)="onOptionClick(size)">
        1-{{size}}
      </button>
    }
  </div>
</ng-template>
