import {Injectable} from '@angular/core';
import {CollectionsModalComponent} from '../components/collections-modal/collections-modal.component';
import {Collection} from 'api/models/collection';
import {CollectionsActionsModalComponent} from '../components/collections-actions-modal/collections-actions-modal.component';
import {Project} from 'api/models/asset/project';
import {VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {CollectionsService} from './collections.service';
import {map} from 'rxjs/operators';
import {SelectableMetadataItem, SelectableMetadataItemType} from '../model/metadata';
import {SearchStateService} from './search-state.service';
import {ApiService} from './api.service';
import {McaMatrixModalComponent} from '../components/mca-matrix-modal/mca-matrix-modal.component';
import {lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    vwuiModalRef: VwuiModalRef;

    constructor(
        private apiService: ApiService,
        private collectionService: CollectionsService,
        private modalService: VwuiModalService,
        private searchStateService: SearchStateService,
    ) {}

    openCollectionModal(newCollectionName: string = '') {
        this.vwuiModalRef = this.modalService.open(CollectionsModalComponent, {
            closeOnBackdropClick: false,
            modalClass: 'modal-lg',
            data: {
                newCollectionName
            }
        });
    }

    openMCAMatrixModal(mcaMatrixUrl: string) {
        this.vwuiModalRef = this.modalService.open(McaMatrixModalComponent, {
            closeOnBackdropClick: false,
            modalClass: 'modal-xl',
            data: {
                mcaMatrixUrl
            }
        });
    }

    openActionsModal(selectedCollection: Collection, action: string, size: string = 'modal-lg') {
        this.vwuiModalRef = this.modalService.open(CollectionsActionsModalComponent, {
            closeOnBackdropClick: false,
            modalClass: size,
            data: {
                collection: selectedCollection,
                action
            }
        });
    }

    removeProjectFromCollection(project: Project) {
        this.vwuiModalRef = this.modalService.open(CollectionsActionsModalComponent, {
            closeOnBackdropClick: false,
            modalClass: 'modal-lg',
            data: {
                collection: this.collectionService.currentCollection,
                project,
                action: 'deleteProject'
            }
        });
    }

    async openPrsPresentation(collection: Collection) {
        const prsIds = await lastValueFrom(this.apiService.getProjects(collection.items).pipe(
            map(projects => projects.filter(p => p.prs !== null).map(p => p.prs_id))
        ));

        this.searchStateService.setSelectedItem({
            item: prsIds,
            type: SelectableMetadataItemType.PRS_COLLECTION,
        } as SelectableMetadataItem);
    }
}
