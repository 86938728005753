<div class="zoom-controls">
  <div>
    <app-share-button></app-share-button>
  </div>
  <div>
    <vwui-button [class.is-neutral]="measureToolEnabled" icon="icon-16-ruler" (click)="toggleMeasureTool()"
    tooltip="Opmeten" [delay]="1000" placement="left"></vwui-button>
  </div>
  <div>
    <vwui-button icon="icon-16-direction" [style.--rotation]="(mapbox.bearing$ | async) + 'deg'" (click)="resetRotation()"
    tooltip="Draai naar noorden" [delay]="1000" placement="left" triggers="" [appAnimatedTooltip]="tooltip" #tooltip="bs-tooltip"></vwui-button>
  </div>
  @if (locationAvailable) {
    <div>
      <vwui-button icon="icon-16-gps" (click)="gotoMyLocation()"
      tooltip="Huidige locatie" [delay]="1000" placement="left" [appAnimatedTooltip]="tooltip" #tooltip="bs-tooltip"></vwui-button>
    </div>
  }
  <div>
    <vwui-button [icon]="'icon-16-' + ((mapbox.is3D$ | async) ? '2' : '3') +  '-d'" (click)="mapbox.toggle3D()"
    tooltip="Wissel tussen 2D/3D" [delay]="1000" placement="left" [appAnimatedTooltip]="tooltip" #tooltip="bs-tooltip"></vwui-button>
  </div>
  <div>
    <vwui-button icon="icon-16-plus" (click)="mapbox.zoomIn()"
    tooltip="Inzoomen" [delay]="1000" placement="left" [appAnimatedTooltip]="tooltip" #tooltip="bs-tooltip"></vwui-button>
  </div>
  <div>
    <vwui-button icon="icon-16-minus" (click)="mapbox.zoomOut()"
    tooltip="Uitzoomen" [delay]="1000" placement="left" [appAnimatedTooltip]="tooltip" #tooltip="bs-tooltip"></vwui-button>
  </div>
</div>
