import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-search-suggestion',
    templateUrl: './search-suggestion.component.html',
    standalone: false
})
export class SearchSuggestionComponent  {
    @Input() icon: string;
    @Input() label: string;
    @Input() identification: string;
    @Input() searchTerm: string;
    @Input() favorite: boolean;
    @Input() clickable = true;
    @Output() clickedOnResult = new EventEmitter<void>();

    handleClick() {
        this.clickedOnResult.emit();
    }
}
