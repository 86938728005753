<app-search-result-container [isLoading]="isLoading$|async" [isEmpty]="paginator.isEmpty"
  emptyTitle="Geen leveranciers gevonden" emptyDescription="Er zijn geen leveranciers gevonden, probeer het opnieuw via de zoekbalk"
  >
  <div class="relative">
    <div class="pb-4 flex items-center">
      <div class="font-medium text-base mr-auto rounded px-2 py-1">
        Leveranciers
      </div>
      <app-results-view-switch class="ml-auto mobile:hidden">
      </app-results-view-switch>
      <app-pagination [paginator]="paginator"></app-pagination>
    </div>
    @if (paginator.content$|async; as relations) {
      @if ((gridView$|async) || isOnMobile) {
        <div
          class="grid gap-4 mobile:gap-3 desktop:grid-cols-2 mobile:grid-cols-1"
          [class.loading-blur]="paginator.isLoading">
          @for (relation of relations; track relation) {
            <app-relation-tile [relation]="relation">
            </app-relation-tile>
          }
        </div>
      } @else {
        <app-relation-list [items]="relations"></app-relation-list>
      }
    }
    <div class="mt-4 flex items-end flex-col">
      <app-pagination [paginator]="paginator"></app-pagination>
    </div>
  </div>
</app-search-result-container>
