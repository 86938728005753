import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LinkComponent {
    @Input() iconUrl: string;
    @Input() iconText: string;
    @Input() label: string;
}
