"use strict";

const l = (s, e = {}) => t => {
  Object.prototype.hasOwnProperty.call(t, "metadata") || (t.metadata = {});
  const n = t.metadata;
  n.events || (n.events = {});
  const a = n.events;
  a[s] || (e.bubbles = !!e.bubbles, e.cancelable = !!e.cancelable, a[s] = e);
};
export default l;
