import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[appTitle]',
    standalone: false
})
export class TitleDirective implements OnInit{
    @Input() appTitle = '';

    constructor(private elm: ElementRef) {
    }

    ngOnInit() {
        this.elm.nativeElement.classList.add('font-medium', 'leading-8', 'text-darkBlue');
        this.elm.nativeElement.classList.add(this.appTitle === 'lg' ? 'text-lg' : 'text-base');
    }
}
