import {Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivationEnd, Event, Router} from '@angular/router';
import {distinctUntilChanged, filter, startWith, switchMap, withLatestFrom} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {InformationBarService} from '../../services/information-bar.service';
import {MobileService} from '../../services/mobile.service';
import {CdkScrollable} from '@angular/cdk/overlay';
import {SidebarService} from 'src/app/services/sidebar.service';

const DEFAULT_SIDEBAR_WIDTH = 600;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: false
})
export class SidebarComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'relative transform transition-transform block desktop:h-full bg-white desktop:ml-20';
    @HostBinding('class.desktop:pr-2')
    allowResize = true;
    @HostBinding('class.-translate-x-full')
    collapsed = false;
    @ViewChild(CdkScrollable)
    scrollContainer: CdkScrollable;

    private documentWidth = document.body.offsetWidth;
    private navbarWidth = 80;
    private pageWidth = 400;

    private minWidth = 350;
    private subscriptions: Subscription[] = [];

    // Keeps track of user modified side bar widths for each route.
    // See route's data for identifiers.
    private sideBarWidthById: Map<string, number> = new Map();
    private currentRouteId?: string = null;

    isOnMobile = false;
    mobileNavbarEnabled$ = this.mobileService.navbarEnabled$.asObservable();

    get sideBarWidth(): number {
        if (this.currentRouteId == null) {
            return DEFAULT_SIDEBAR_WIDTH;
        }
        return this.sideBarWidthById.get(this.currentRouteId) ?? DEFAULT_SIDEBAR_WIDTH;
    }

    @HostBinding('style.width')
    get styleWidth(): string {
        return this.isOnMobile ? '100%' : `${this.pageWidth}px`;
    }

    constructor(
        private elementRef: ElementRef,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private mapboxService: MapboxService,
        private mobileService: MobileService,
        private informationBarService: InformationBarService,
        private sidebarService: SidebarService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(this.router.events.pipe(
            filter<ActivationEnd>(event => {
                return event instanceof ActivationEnd
                    && event.snapshot.component === this.activatedRoute.component;
            }),
            distinctUntilChanged<ActivationEnd>((a, b) => {
                return a.snapshot.firstChild?.toString() === b.snapshot.firstChild?.toString();
            }),
            startWith<Event, null>(null),
            switchMap(() => this.activatedRoute.firstChild?.data?.pipe(
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            ) || of(null)),
            withLatestFrom(this.mobileService.isOnMobile$)
            ).subscribe(([data, isOnMobile]) => {
                this.currentRouteId = data?.id;
                this.collapsed = false;
                this.setSideBarData(data);
                if(!isOnMobile) {
                    this.allowResize = !data?.blockResize;
                    this.onResizeChange(this.sideBarWidth);
                    this.informationBarService.toggleInfoBar(this.collapsed);
                    this.onResizeEnd();
                }

            }),
            this.mapboxService.collapseMenu$.pipe(
                withLatestFrom(this.mobileService.isOnMobile$)
            ).subscribe(([value, isOnMobile]) => {
                this.collapsed = value;
                if(!isOnMobile) {
                    this.onResizeEnd();
                }
            }));

        this.subscriptions.push(
            this.mobileService.isOnMobile$.subscribe(isOnMobile => {
                if (isOnMobile) {
                    this.isOnMobile = true;
                    this.collapsed = false;
                    this.informationBarService.toggleInfoBar(this.collapsed);
                    this.setWidth();
                } else {
                    this.isOnMobile = false;
                }
            })
        );

        this.subscriptions.push(
            this.mobileService.scrollToTop$.subscribe(() => {
                this.scrollContainer?.scrollTo({top: 0, behavior: 'smooth'});
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.documentWidth = document.body.offsetWidth;
        this.onResizeChange(this.pageWidth + this.navbarWidth);
        this.onResizeEnd();
    }

    onResizeChange(newSize: number) {
        this.pageWidth = Math.max(
            this.minWidth,
            Math.min(
                newSize - this.navbarWidth,
                this.documentWidth - this.navbarWidth
            )
        );
        this.sidebarService.setPageWidth(this.pageWidth);
        if (this.currentRouteId != null) {
            // Store _with_ navbarWidth, to prevent the width from getting
            // progressively smaller on each navigation.
            this.sideBarWidthById.set(this.currentRouteId, this.pageWidth + this.navbarWidth);
        }
        this.setWidth();
    }

    onResizeEnd() {
        // firstChild is null if sidebar is collapsed
        this.mapboxService.setMapPadding({
            top: 0,
            right: 0,
            bottom: 0,
            left: (this.activatedRoute.firstChild === null || this.collapsed) ? 0 : this.pageWidth
        });
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.informationBarService.toggleInfoBar(this.collapsed);
        this.onResizeEnd();
    }

    private setSideBarData(data): void {
        if (data == null) {
            return;
        }
        if (!this.sideBarWidthById.has(data.id)) {
            this.sideBarWidthById.set(data.id, data.sideBarWidth ?? DEFAULT_SIDEBAR_WIDTH);
        }
    }

    private setWidth() {
        (this.elementRef.nativeElement as HTMLElement).style.width = this.styleWidth;
    }
}
