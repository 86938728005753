<vwui-modal-header>
  Filter kan niet geselecteerd worden
</vwui-modal-header>
<vwui-modal-body>
  De door u geselecteerde exportlijst kan niet worden gegenereerd omdat de volgende filters in het projectoverzicht
  geselecteerd zijn:
  <ul class="mt-2 mb-2 list-disc list-inside">
    @for (selectedSearchFilter of modalConfig.data.selectedSearchFilters; track selectedSearchFilter) {
      <li>{{facetLabel(selectedSearchFilter)}}</li>
    }
  </ul>
  Verwijder deze filters om verder te kunnen met de export functie.
</vwui-modal-body>
<vwui-modal-footer>
  <button (click)="close()" class="ml-auto px-6 py-3 text-neutral-1 font-medium">Sluiten</button>
</vwui-modal-footer>
