import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {AzureMapsService} from '../../services/azure-maps.service';

@Component({
    selector: 'app-poi-detail',
    templateUrl: './poi-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PoiDetailComponent {
    poiData$ = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => {
            return this.azureMapsService.poi.pipe(
                map(poi =>poi.find(it => it.id === paramMap.get('id')))
            );
        }),
    );

    constructor(
        private activatedRoute: ActivatedRoute,
        private azureMapsService: AzureMapsService,
        private location: Location,
    ) {}

    goBack() {
        this.location.back();
    }
}
