"use strict";

import d from "./util/whenDOMReady.js";
import v from "./EventProvider.js";
import c from "./FontFace.js";
import u from "./SystemCSSVars.js";
import { getTheme as i } from "./config/Theme.js";
import a from "./theming/applyTheme.js";
import { registerCurrentRuntime as y } from "./Runtimes.js";
import { getFeature as m } from "./FeaturesRegistry.js";
import { attachThemeRegistered as g } from "./theming/ThemeRegistered.js";
import S from "./util/fixSafariActiveState.js";
let o = !1,
  r;
const p = new v(),
  h = () => o,
  P = e => {
    if (!o) {
      p.attachEvent("boot", e);
      return;
    }
    e();
  },
  l = async () => {
    if (r !== void 0) return r;
    const e = async n => {
      if (y(), typeof document > "u") {
        n();
        return;
      }
      g(b);
      const t = m("OpenUI5Support"),
        f = t ? t.isOpenUI5Detected() : !1,
        s = m("F6Navigation");
      t && (await t.init()), s && !f && s.init(), await d(), await a(i()), t && t.attachListeners(), c(), u(), S(), n(), o = !0, p.fireEvent("boot");
    };
    return r = new Promise(e), r;
  },
  b = e => {
    o && e === i() && a(i());
  };
export { l as boot, P as attachBoot, h as isBooted };
