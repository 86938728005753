<div class="info-box"
  [class.info-box--info]="boxType === 'info'"
  [class.info-box--alert]="boxType === 'alert'"
  >
  @if (boxType === 'info') {
    <div class="info-box__icon" inlineSVG="/assets/icons/icon-info.svg"></div>
  }
  <div class="info-box__content">
    <ng-content></ng-content>
  </div>
</div>
