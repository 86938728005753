"use strict";

class i {
  constructor() {
    this._eventRegistry = new Map();
  }
  attachEvent(t, r) {
    const n = this._eventRegistry,
      e = n.get(t);
    if (!Array.isArray(e)) {
      n.set(t, [r]);
      return;
    }
    e.includes(r) || e.push(r);
  }
  detachEvent(t, r) {
    const n = this._eventRegistry,
      e = n.get(t);
    if (!e) return;
    const s = e.indexOf(r);
    s !== -1 && e.splice(s, 1), e.length === 0 && n.delete(t);
  }
  fireEvent(t, r) {
    const e = this._eventRegistry.get(t);
    return e ? e.map(s => s.call(this, r)) : [];
  }
  fireEventAsync(t, r) {
    return Promise.all(this.fireEvent(t, r));
  }
  isHandlerAttached(t, r) {
    const e = this._eventRegistry.get(t);
    return e ? e.includes(r) : !1;
  }
  hasListeners(t) {
    return !!this._eventRegistry.get(t);
  }
}
export default i;
