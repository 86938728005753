import { registerIcon } from "@ui5/webcomponents-base/dist/asset-registries/Icons.js";
const name = "information";
const pathData = "M0 256q0-53 20.5-100t55-81.5T157 20t99-20q54 0 100.5 20t81 55 54.5 81.5 20 99.5q0 54-20 100.5t-54.5 81T356 492t-100 20q-54 0-100.5-20t-81-55T20 355.5 0 256zm226-89q14 11 30 11 17 0 29.5-11.5T298 138q0-19-13-31-12-12-29-12-19 0-30.5 12.5T214 138q0 17 12 29zm-34 201v33h128v-33h-32V215q0-6-7-6h-88v31h32v128h-33z";
const ltr = false;
const accData = null;
const collection = "SAP-icons-v4";
const packageName = "@ui5/webcomponents-icons";
registerIcon(name, {
  pathData,
  ltr,
  collection,
  packageName
});
export default "SAP-icons-v4/information";
export { pathData, ltr, accData };