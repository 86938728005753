"use strict";

import i from "../EventProvider.js";
const t = new i(),
  r = "themeRegistered",
  n = e => {
    t.attachEvent(r, e);
  },
  s = e => t.fireEvent(r, e);
export { n as attachThemeRegistered, s as fireThemeRegistered };
