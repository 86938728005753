import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import moveFeatures from './mapbox-draw-drag-helper';

import {point} from '@turf/helpers';
import bearing from '@turf/bearing';
import calculateCenter from '@turf/center';
import midpoint from '@turf/midpoint';
import distance from '@turf/distance';
import destination from '@turf/destination';
import transformRotate from '@turf/transform-rotate';
import transformScale from '@turf/transform-scale';

export enum TxCenter {
    Center,  // rotate or scale around center of polygon
    Opposite,  // rotate or scale around opposite side of polygon}
}
export enum TxMode {
    Scale = 1,
    Rotate = 2
}

function parseTxCenter(value: any, defaultTxCenter = TxCenter.Center) {
    if (value === undefined || value == null) {
        return defaultTxCenter;
    }

    if (value === TxCenter.Center || value === TxCenter.Opposite) {
        return value;
    }

    if (value === 'center') {
        return TxCenter.Center;
    }

    if (value === 'opposite') {
        return TxCenter.Opposite;
    }

    throw Error('Invalid TxCenter: ' + value);
}

const isRotatePoint = CommonSelectors.isOfMetaType(Constants.meta.MIDPOINT);
const isVertex = CommonSelectors.isOfMetaType(Constants.meta.VERTEX);

export interface TxRectModeOptions {
    featureId: string;
    featureIds?: string[];
    rotatePivot: TxCenter; // - change rotation pivot to the middle of the opposite polygon side
    scaleCenter: TxCenter; // - change scaling center to the opposite vertex
    singleRotationPoint: boolean; // - set true to show only one rotation widget
    rotationPointRadius: number; // - offset rotation point from feature perimeter
    canScale: boolean; // - set false to disable scaling
    canRotate: boolean; // - set false to disable rotation
    canTrash: boolean; // - set false to disable feature delete
    canSelectFeatures: boolean; // - set false to forbid exiting the mode

    startPos: any; // Undocumented option
    coordPath: any; // Undocumented option
}

export const TxRectMode = {
    onSetup(opts: TxRectModeOptions) {
        const featureId =
            (opts.featureIds && Array.isArray(opts.featureIds) && opts.featureIds.length > 0) ?
                opts.featureIds[0] : opts.featureId;

        const feature = this.getFeature(featureId);

        if (!feature) {
            throw new Error('You must provide a valid featureId to enter tx_poly mode');
        }

        if (feature.type !== Constants.geojsonTypes.POLYGON) {
            throw new TypeError('tx_poly mode can only handle polygons');
        }
        if (feature.coordinates === undefined
            || feature.coordinates.length !== 1
            || feature.coordinates[0].length <= 2) {
            throw new TypeError('tx_poly mode can only handle polygons');
        }

        const state = {
            featureId,
            feature,

            canTrash: opts.canTrash !== undefined ? opts.canTrash : true,

            canScale: opts.canScale !== undefined ? opts.canScale : true,
            canRotate: opts.canRotate !== undefined ? opts.canRotate : true,

            singleRotationPoint: opts.singleRotationPoint !== undefined ? opts.singleRotationPoint : false,
            rotationPointRadius: opts.rotationPointRadius !== undefined ? opts.rotationPointRadius : 1.0,

            rotatePivot: parseTxCenter(opts.rotatePivot, TxCenter.Center),
            scaleCenter: parseTxCenter(opts.scaleCenter, TxCenter.Center),

            canSelectFeatures: opts.canSelectFeatures !== undefined ? opts.canSelectFeatures : true,
            // selectedFeatureMode: opts.selectedFeatureMode !== undefined ? opts.selectedFeatureMode : 'simple_select',

            dragMoveLocation: opts.startPos || null,
            dragMoving: false,
            canDragMove: false,
            selectedCoordPaths: opts.coordPath ? [opts.coordPath] : []
        };

        if (!(state.canRotate || state.canScale)) {
            console.warn('Non of canScale or canRotate is true');
        }

        this.setSelectedCoordinates(this.pathsToCoordinates(featureId, state.selectedCoordPaths));
        this.setSelected(featureId);
        doubleClickZoom.disable(this);

        this.setActionableState({
            combineFeatures: false,
            uncombineFeatures: false,
            trash: state.canTrash
        });

        return state;
    },

    onDrag(state, e) {
        if (state.canDragMove !== true) {
            return;
        }
        state.dragMoving = true;
        e.originalEvent.stopPropagation();

        const delta = {
            lng: e.lngLat.lng - state.dragMoveLocation.lng,
            lat: e.lngLat.lat - state.dragMoveLocation.lat
        };
        if (state.selectedCoordPaths.length > 0 && state.txMode) {
            switch (state.txMode) {
                case TxMode.Rotate:
                    this.dragRotatePoint(state, e);
                    break;
                case TxMode.Scale:
                    this.dragScalePoint(state, e);
                    break;
            }
        } else {
            this.dragFeature(state, e, delta);
        }


        state.dragMoveLocation = e.lngLat;
    },

    onClick(state, e) {
        if (CommonSelectors.noTarget(e)) {
            return this.clickNoTarget(state, e);
        }
        if (CommonSelectors.isActiveFeature(e)) {
            return this.clickActiveFeature(state, e);
        }
        if (CommonSelectors.isInactiveFeature(e)) {
            return this.clickInactive(state, e);
        }
        this.stopDragging(state);
    },

    onMouseDown(state, e) {
        if (isVertex(e)) {
            return this.onVertex(state, e);
        }
        if (isRotatePoint(e)) {
            return this.onRotatePoint(state, e);
        }
        if (CommonSelectors.isActiveFeature(e)) {
            return this.onFeature(state, e);
        }
        // if (isMidpoint(e)) return this.onMidpoint(state, e);
    },

    onMouseUp(state) {
        if (state.dragMoving) {
            this.fireUpdate();
        }
        this.stopDragging(state);
    },

    onMouseOut(state) {
        // As soon as you mouse leaves the canvas, update the feature
        if (state.dragMoving) {
            this.fireUpdate();
        }
    },

    onTouchStart(state, e) {
        this.onMouseDown(state, e);
    },

    onTouchEnd(state) {
        this.onMouseUp(state);
    },

    onStop() {
        doubleClickZoom.enable(this);
        this.clearSelectedCoordinates();
    },

    onTrash() {
        // TODO check state.canTrash
        this.deleteFeature(this.getSelectedIds());
        // this.fireActionable();
    },

    toDisplayFeatures(state, geojson, push) {
        if (state.featureId === geojson.properties.id) {
            geojson.properties.active = Constants.activeStates.ACTIVE;
            push(geojson);


            const suppPoints = createSupplementaryPoints(geojson, {
                map: this.map,
                midpoints: false,
                selectedPaths: state.selectedCoordPaths
            });

            if (state.canScale) {
                this.computeBisectrix(suppPoints);
                suppPoints.forEach(push);
            }

            if (state.canRotate) {
                const rotPoints = this.createRotationPoints(state, geojson, suppPoints);
                rotPoints.forEach(push);
            }
        } else {
            geojson.properties.active = Constants.activeStates.INACTIVE;
            push(geojson);
        }

        // this.fireActionable(state);
        this.setActionableState({
            combineFeatures: false,
            uncombineFeatures: false,
            trash: state.canTrash
        });

        // this.fireUpdate();
    },

    // TODO why I need this?
    pathsToCoordinates(featureId, paths) {
        return paths.map(coord_path => ({feature_id: featureId, coord_path}));
    },

    computeBisectrix(points) {
        for (let i1 = 0; i1 < points.length; i1++) {
            const i0 = (i1 - 1 + points.length) % points.length;
            const i2 = (i1 + 1) % points.length;

            const a1 = bearing(points[i0].geometry.coordinates, points[i1].geometry.coordinates);
            const a2 = bearing(points[i2].geometry.coordinates, points[i1].geometry.coordinates);

            let a = (a1 + a2) / 2.0;

            if (a < 0.0) {
                a += 360;
            }
            if (a > 360) {
                a -= 360;
            }

            points[i1].properties.heading = a;
        }
    },

    createRotationPoint(rotationWidgets, featureId, v1, v2, rotCenter, radiusScale) {
        const cR0 = midpoint(v1, v2).geometry.coordinates;
        const heading = bearing(rotCenter, cR0);
        const distance0 = distance(rotCenter, cR0);
        const distance1 = radiusScale * distance0; // TODO depends on map scale
        const cR1 = destination(rotCenter, distance1, heading, {}).geometry.coordinates;

        rotationWidgets.push({
                type: Constants.geojsonTypes.FEATURE,
                properties: {
                    meta: Constants.meta.MIDPOINT,
                    parent: featureId,
                    lng: cR1[0],
                    lat: cR1[1],
                    coord_path: v1.properties.coord_path,
                    heading,
                },
                geometry: {
                    type: Constants.geojsonTypes.POINT,
                    coordinates: cR1
                }
            }
        );
    },

    createRotationPoints(state, geojson, suppPoints) {
        const {type} = geojson.geometry;
        const featureId = geojson.properties && geojson.properties.id;

        const rotationWidgets = [];
        if (type !== Constants.geojsonTypes.POLYGON) {
            return;
        }

        const corners = suppPoints.slice(0);
        corners[corners.length] = corners[0];

        let v1 = null;

        const rotCenter = this.computeRotationCenter(state, geojson);

        if (state.singleRotationPoint) {
            this.createRotationPoint(rotationWidgets, featureId, corners[0], corners[1], rotCenter, state.rotationPointRadius);
        } else {
            corners.forEach((v2) => {
                if (v1 !== null) {
                    this.createRotationPoint(rotationWidgets, featureId, v1, v2, rotCenter, state.rotationPointRadius);
                }

                v1 = v2;
            });
        }

        return rotationWidgets;
    },

    startDragging(state, e) {
        this.map.dragPan.disable();
        state.canDragMove = true;
        state.dragMoveLocation = e.lngLat;
    },

    stopDragging(state) {
        this.map.dragPan.enable();
        state.dragMoving = false;
        state.canDragMove = false;
        state.dragMoveLocation = null;
    },

    onVertex(state, e) {
        // convert internal MapboxDraw feature to valid GeoJSON:
        this.computeAxes(state, state.feature.toGeoJSON());

        this.startDragging(state, e);
        const about = e.featureTarget.properties;
        state.selectedCoordPaths = [about.coord_path];
        state.txMode = TxMode.Scale;
    },

    onRotatePoint(state, e) {
        // convert internal MapboxDraw feature to valid GeoJSON:
        this.computeAxes(state, state.feature.toGeoJSON());

        this.startDragging(state, e);
        const about = e.featureTarget.properties;
        state.selectedCoordPaths = [about.coord_path];
        state.txMode = TxMode.Rotate;
    },

    onFeature(state, e) {
        state.selectedCoordPaths = [];
        this.startDragging(state, e);
    },

    coordinateIndex(coordPaths) {
        if (coordPaths.length >= 1) {
            const parts = coordPaths[0].split('.');
            return parseInt(parts[parts.length - 1], 10);
        } else {
            return 0;
        }
    },

    computeRotationCenter(state, polygon) {
        return calculateCenter(polygon);
    },

    computeAxes(state, polygon) {
        // TODO check min 3 points
        const center0 = this.computeRotationCenter(state, polygon);
        const corners = polygon.geometry.coordinates[0].slice(0);

        const n = corners.length - 1;
        const iHalf = Math.floor(n / 2);

        const rotateCenters = [];
        const headings = [];

        for (let i1 = 0; i1 < n; i1++) {
            let i0 = i1 - 1;
            if (i0 < 0) {
                i0 += n;
            }

            const c0 = corners[i0];
            const c1 = corners[i1];
            const rotPoint = midpoint(point(c0), point(c1));

            let rotCenter = center0;
            if (TxCenter.Opposite === state.rotatePivot) {
                const i3 = (i1 + iHalf) % n; // opposite corner
                let i2 = i3 - 1;
                if (i2 < 0) {
                    i2 += n;
                }

                const c2 = corners[i2];
                const c3 = corners[i3];
                rotCenter = midpoint(point(c2), point(c3));
            }

            rotateCenters[i1] = rotCenter.geometry.coordinates;
            headings[i1] = bearing(rotCenter, rotPoint);
        }

        state.rotation = {
            feature0: polygon,  // initial feature state
            centers: rotateCenters,
            headings, // rotation start heading for each point
        };

        // compute current distances from centers for scaling
        const scaleCenters = [];
        const distances = [];
        for (let i = 0; i < n; i++) {
            const c1 = corners[i];
            let c0 = center0.geometry.coordinates;
            if (TxCenter.Opposite === state.scaleCenter) {
                const i2 = (i + iHalf) % n; // opposite corner
                c0 = corners[i2];
            }
            scaleCenters[i] = c0;
            distances[i] = distance(point(c0), point(c1), {units: 'meters'});
        }

        state.scaling = {
            feature0: polygon,  // initial feature state
            centers: scaleCenters,
            distances
        };
    },

    dragRotatePoint(state, e) {
        if (state.rotation === undefined || state.rotation == null) {
            console.error('state.rotation required');
            return;
        }

        const m1 = point([e.lngLat.lng, e.lngLat.lat]);

        const n = state.rotation.centers.length;
        const cIdx = (this.coordinateIndex(state.selectedCoordPaths) + 1) % n;
        // TODO validate cIdx
        const cCenter = state.rotation.centers[cIdx];
        const center = point(cCenter);

        const heading1 = bearing(center, m1);

        const heading0 = state.rotation.headings[cIdx];
        let rotateAngle = heading1 - heading0; // in degrees
        if (CommonSelectors.isShiftDown(e)) {
            rotateAngle = 5.0 * Math.round(rotateAngle / 5.0);
        }

        const rotatedFeature = transformRotate(state.rotation.feature0,
            rotateAngle,
            {
                pivot: center,
                mutate: false,
            });

        state.feature.incomingCoords(rotatedFeature.geometry.coordinates);
        // TODO add option for this:
        this.fireUpdate();
    },

    dragScalePoint(state, e) {
        if (state.scaling === undefined || state.scaling == null) {
            console.error('state.scaling required');
            return;
        }
        const cIdx = this.coordinateIndex(state.selectedCoordPaths);
        // TODO validate cIdx

        const cCenter = state.scaling.centers[cIdx];
        const center = point(cCenter);
        const m1 = point([e.lngLat.lng, e.lngLat.lat]);

        const dist = distance(center, m1, {units: 'meters'});
        let scale = dist / state.scaling.distances[cIdx];

        if (CommonSelectors.isShiftDown(e)) {
            // TODO discrete scaling
            scale = 0.05 * Math.round(scale / 0.05);
        }

        const scaledFeature = transformScale(state.scaling.feature0,
            scale,
            {
                origin: cCenter,
                mutate: false,
            });

        state.feature.incomingCoords(scaledFeature.geometry.coordinates);
        // TODO add option for this:
        this.fireUpdate();
    },

    dragFeature(state, e, delta) {
        moveFeatures(this.getSelected(), delta);
        state.dragMoveLocation = e.lngLat;
        // TODO add option for this:
        this.fireUpdate();
    },

    fireUpdate() {
        this.map.fire(Constants.events.UPDATE, {
            action: Constants.updateActions.CHANGE_COORDINATES,
            features: this.getSelected().map(f => f.toGeoJSON())
        });
    },

    clickActiveFeature(state, e) {
        state.selectedCoordPaths = [];
        this.clearSelectedCoordinates();
        state.feature.changed();
    },

    clickNoTarget(state, e) {
        if (state.canSelectFeatures) {
            this.changeMode(Constants.modes.SIMPLE_SELECT);
        }
    },

    clickInactive(state, e) {
        if (state.canSelectFeatures) {
            this.changeMode(Constants.modes.SIMPLE_SELECT, {
                featureIds: [e.featureTarget.properties.id]
            });
        }
    }
};
