<div class="project-app-store-list" role="list">
  @if (defaultListItem) {
    <app-project-app-store-list-item role="listitem" [projectAppStoreListItem]="defaultListItem"></app-project-app-store-list-item>
  }
  @for (projectAppStoreListItem of projectAppStoreListItems$ | async; track projectAppStoreListItem) {
    <app-project-app-store-list-item
      role="listitem"
      [projectAppStoreListItem]="projectAppStoreListItem"
    ></app-project-app-store-list-item>
  }
</div>
