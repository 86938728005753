"use strict";

import { reRenderAllUI5Elements as C } from "../Render.js";
import S from "../getSharedResource.js";
import m from "../EventProvider.js";
const r = () => S("CustomStyle.eventProvider", new m()),
  n = "CustomCSSChange",
  i = t => {
    r().attachEvent(n, t);
  },
  a = t => {
    r().detachEvent(n, t);
  },
  u = t => r().fireEvent(n, t),
  c = () => S("CustomStyle.customCSSFor", {});
let s;
i(t => {
  s || C({
    tag: t
  });
});
const g = (t, e) => {
    const o = c();
    o[t] || (o[t] = []), o[t].push(e), s = !0;
    try {
      u(t);
    } finally {
      s = !1;
    }
    return C({
      tag: t
    });
  },
  l = t => {
    const e = c();
    return e[t] ? e[t].join("") : "";
  };
export { g as addCustomCSS, l as getCustomCSS, i as attachCustomCSSChange, a as detachCustomCSSChange };
