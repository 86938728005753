<div class="flex w-full border-b border-neutral-3 pt-4 pb-4 pl-2 hover:bg-neutral-4 cursor-pointer">
  <div class="min-h-10 min-w-10 w-10 h-10 text-white rounded bg-gradient-to-r from-primary to-primary-light flex items-center justify-center mr-6">
    @if (iconUrl) {
      <span [inlineSVG]="iconUrl"></span>
    }
    @if (iconText) {
      <span>{{iconText}}</span>
    }
  </div>
  <div class="flex py-2 pr-2 items-center w-full">
    <div>
      <span class="font-medium block">{{label}}</span>
    </div>
    <span class="ml-auto" inlineSVG="/assets/icons/navigation-arrow-icon.svg"></span>
  </div>
</div>
