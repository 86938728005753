import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Project} from 'api/models/asset/project';
import {Subscription} from 'rxjs';
import {SidebarService} from 'src/app/services/sidebar.service';

@Component({
    selector: 'app-project-tile-grid',
    templateUrl: './project-tile-grid.component.html',
    standalone: false
})
export class ProjectTileGridComponent implements OnInit, OnDestroy {
    @Input() projects: Project[] = [];
    @Input() isLoading = false;
    @Input() masonryGrid = false;
    @Input() threeColumns = false;

    private subscriptions: Subscription[] = [];

    tileHeightDefault = 'span 11';
    tileHeightLarge   = 'span 16';

    constructor(private sidebarService: SidebarService) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.sidebarService.pageWidth$.subscribe(pageWidth => {
                const tileWidth = this.threeColumns
                    ? pageWidth / 3
                    : pageWidth / 2;
                this.tileHeightDefault = 'span ' + Math.floor(tileWidth / 11 * 3 / 4);
                this.tileHeightLarge = 'span ' + Math.floor(tileWidth / 11  * 4 / 4);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }
}
