@if (action$|async; as action) {
  @if (action === 'name') {
    <vwui-modal-header>Naam wijzigen van "{{this.modalConfig.data.collection.name}}"</vwui-modal-header>
    <vwui-modal-body>
      <div class="font-medium text-sm text-neutral-1">Vul de nieuwe naam van de collectie in</div>
      <form class="mt-1" [formGroup]="renameCollectionForm">
        <input class="rounded border-silver w-full border p-3" formControlName="name" placeholder="Vul hier de nieuwe collectie naam in">
      </form>
    </vwui-modal-body>
    <vwui-modal-footer>
      <button (click)="vwuiModalRef.close()">Annuleren</button>
      <button [ngClass]="{'cursor-not-allowed' : !renameCollectionForm.valid || pendingSubmit}"
        [disabled]="!renameCollectionForm.valid || pendingSubmit" (click)="renameCollection()"
        class="p-3 ml-6 text-white rounded bg-gradient-to-r from-primary to-primary-light disabled:opacity-50">
        Opslaan
      </button>
    </vwui-modal-footer>
  }
  @if (action === 'remove') {
    <vwui-modal-header>Verwijderen collectie</vwui-modal-header>
    <vwui-modal-body>
      <div class="font-medium w-full text-sm text-neutral-1">Weet u zeker dat u deze collectie wilt verwijderen?</div>
    </vwui-modal-body>
    <vwui-modal-footer>
      <button (click)="vwuiModalRef.close()">Annuleren</button>
      <button (click)="deleteCollection()"
        class="ml-6 p-3 text-white rounded bg-gradient-to-r from-primary to-primary-light disabled:opacity-50">
        Verwijder Collectie
      </button>
    </vwui-modal-footer>
  }
  @if (action === 'deleteProject') {
    <vwui-modal-header>Project verwijderen van collectie</vwui-modal-header>
    <vwui-modal-body>
      <div class="font-medium w-full text-sm text-neutral-1">Weet u zeker dat u dit project wilt verwijderen?</div>
    </vwui-modal-body>
    <vwui-modal-footer>
      <button (click)="vwuiModalRef.close()">Annuleren</button>
      <button (click)="removeProjectFromCollection()"
        class="ml-6 p-3 text-white rounded bg-gradient-to-r from-primary to-primary-light disabled:opacity-50">
        Verwijder project
      </button>
    </vwui-modal-footer>
  }
  @if (action === 'share') {
    <vwui-modal-header>Kopie van een collectie delen</vwui-modal-header>
    <vwui-modal-body>
      <div class="mb-6">
        <div class="mb-3">
          <h2>Delen via mail</h2>
          <p>Voeg een e-mailadres toe en klik op collectie delen.</p>
        </div>
        <form class="flex gap-2" [formGroup]="shareCollectionEmailForm">
          <vwui-input type="email" class="flex-grow"
          formControlName="email" placeholder="Vul hier een e-mailadres in"></vwui-input>
          <button [ngClass]="{'cursor-not-allowed' : !shareCollectionEmailForm.valid || pendingSubmit}"
            [disabled]="!shareCollectionEmailForm.valid || pendingSubmit" (click)="shareCollectionEmail()"
            class="px-6 py-3 text-white rounded bg-gradient-to-r from-primary to-primary-light disabled:opacity-50">
            Collectie delen
          </button>
        </form>
      </div>
      <hr>
        <div class="flex items-end">
          <div class="flex-grow">
            <h2>Delen via een link</h2>
            <p>Personen met de link kunnen de collectie toevoegen.</p>
          </div>
          <button (click)="shareCollectionLink()"
            class="font-medium pt-3 pb-2 px-6 border-2 text-primary rounded">
            Link kopiëren
          </button>
        </div>
      </vwui-modal-body>
      <vwui-modal-footer>
        <button (click)="vwuiModalRef.close()">Sluiten</button>
      </vwui-modal-footer>
    }
  }
