"use strict";

import { DEFAULT_LANGUAGE as e } from "../generated/AssetParameters.js";
const t = typeof document > "u",
  o = () => {
    if (t) return e;
    const a = navigator.languages,
      n = () => navigator.language;
    return a && a[0] || n() || e;
  };
export default o;
