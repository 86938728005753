import { Component } from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'app-user-unauthorized',
    templateUrl: './user-unauthorized.component.html',
    standalone: false
})
export class UserUnauthorizedComponent {

  constructor(private authService: AuthService) { }

    logout() {
        this.authService.logout();
    }
}
