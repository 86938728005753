<vwui-modal-header>Toevoegen aan collectie</vwui-modal-header>
@if (projectsWithoutPrs$|async; as projects) {
  <vwui-modal-body>
    @if ((restrictedProjectsCount$|async) < 0 && projects.length > 0;) {
      <div>
        <div class="px-6 py-4 gap-4 border border-neutral-4 rounded flex">
          <span inlineSVG="/assets/icons/icon-16-warning.svg" class="w-4 h-4"></span>
          <span class="font-medium">
            Let op! {{ compatibleProjectsCount }} van de {{projectsCount}}
            geselecteerde projecten hebben een referentie. Onderstaande projecten kunnen niet
            toegevoegd worden aan de collectie:
          </span>
        </div>
        <ul class="list-disc mt-4 ml-6">
          @for (project of projects; track project) {
            <li>{{project.naam}}, {{project.bedrijf}}</li>
          }
        </ul>
      </div>
    }
    @if ((restrictedProjectsCount$|async) > 0) {
      <div class="flex-column rounded  justify-center items-center mt-1 text-left">
        @if ((restrictedProjectsCount$|async) === 0) {
          <div class="py-4 px-6 gap-4 border border-neutral-4 rounded flex">
            <span class="w-8 h-8 mr-3" inlineSVG="/assets/icons/icon-16-error.svg"></span>
            <span class="text-dark text-base font-medium">
              Collectie kan niet worden toegevoegd aan collectie overzicht vanwege ontbrekende rechten
            </span>
          </div>
        } @else {
          <div class="px-6 py-4 gap-4 border border-neutral-4 rounded flex">
            <span inlineSVG="/assets/icons/icon-16-warning.svg" class="w-4 h-4"></span>
            <span class="font-medium">
              Niet alle projecten binnen de collectie zijn toegevoegd vanwege ontbrekende rechten.
              Alleen onderstaande projecten kunnen toegevoegd worden aan de collectie:
            </span>
          </div>
          <ul class="list-disc mt-4 ml-6 text-left">
            @for (project of compatibleProjects; track project) {
              <li>{{project.naam}}, {{project.bedrijf}}</li>
            }
          </ul>
        }
      </div>
    }
    @if (projects.length === 0 && (restrictedProjectsCount$|async) < 0) {
      <span class="font-medium text-sm text-neutral-1">Aan nieuwe collectie toevoegen</span>
      <form class="flex mt-1 mb-10" [formGroup]="newCollectionForm">
        <input class="rounded border-silver w-full border p-3"
          formControlName="name" placeholder="Nieuwe collectie">
        <button [ngClass]="{'cursor-not-allowed' : !newCollectionForm.valid || pendingSubmit}"
          [disabled]="!newCollectionForm.valid || pendingSubmit" (click)="createCollection()"
          class="ml-2 w-12 h-12 text-white rounded bg-gradient-to-r from-primary to-primary-light disabled:opacity-50">
          <span class="w-8 h-8" inlineSVG="/assets/icons/icon-plus.svg"></span>
        </button>
      </form>
      <div class="font-medium text-sm text-neutral-1">Aan bestaande collectie toevoegen</div>
      @if ((collections.content$|async); as collections) {
        @if (collections.length > 0) {
          @for (collection of collections; track collection) {
            <div class="flex mt-1 p-2 align-center border rounded flex-nowrap items-center"
              [class.border-primary]="collection.selected" [class.border-neutral-4]="!collection.selected">
              <div class="relative w-10 h-10 rounded flex-shrink-0 flex items-center justify-center"
                [class.bg-primary]="!collection.thumbnail">
                <!--
                We _should_ be using thumbnail images here, but for unknown reasons suffixing ?size=thumbnail
                no longer works. We use full size images instead, which definitely works, but is obviously
                not ideal in terms of performance and bandwith used.
                -->
                @if (collection.thumbnail) {
                  <img alt=""
                    [src]="collection.thumbnail"
                    class="absolute inset-0 w-full object-cover rounded">
                } @else {
                  <span class="absolute left-auto right-auto text-white"
                    inlineSVG="/assets/icons/icon-16-folder.svg" [setSVGAttributes]="{width: 16, height: 16}">
                  </span>
                }
              </div>
              <span class="ml-4"> {{ collection.name }}</span>
              <vwui-checkbox [checked]="collection.selected"
                (click)="toggleSelection(collection, !!$event.target.checked)"
              class="ml-auto"></vwui-checkbox>
            </div>
          }
        } @else {
          <div
            class="flex-column rounded p-12 border border-neutral-3 justify-center items-center text-center mt-1">
            <span class="block text-dark text-base font-medium">Nog geen collecties aangemaakt.</span>
            <span class="text-neutral-1 mt-2">Maak eerst een nieuwe collectie aan en<br>klik daarna op toevoegen.</span>
          </div>
        }
      } @else {
        <div class="m-6 flex justify-center">
          <vwui-spinner></vwui-spinner>
        </div>
      }
    }
  </vwui-modal-body>
} @else {
  <div class="m-6 flex justify-center">
    <vwui-spinner></vwui-spinner>
  </div>
}
@if (projectsWithoutPrs$|async; as projects;) {
  <vwui-modal-footer>
    @if (modalState$|async; as modalState) {
      <div class="flex">
        @if (compatibleProjectsCount > 0) {
          <button (click)="vwuiModalRef.close()">
            Annuleren
          </button>
          <button (click)="modalState === 'compatibleProjects' ? addToCollections(): continueToCollection()"
            [disabled]="selectedCollections.length === 0 && modalState === 'compatibleProjects'"
            class="ml-6 px-6 py-3 text-white font-medium rounded bg-gradient-to-r from-primary to-primary-light disabled:opacity-50"
            >
            Doorgaan
          </button>
        }
        @if (compatibleProjectsCount === 0) {
          <button
            (click)="vwuiModalRef.close()"
            class="ml-6 px-6 py-3 text-white font-medium rounded bg-gradient-to-r ml-auto from-primary to-primary-light disabled:opacity-50"
            >
            Sluiten
          </button>
        }
      </div>
    }
  </vwui-modal-footer>
}
