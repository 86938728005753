<button (click)="handleToggle()" class="cursor-pointer flex space-x-4 items-center">
  @if (!isChecked && !isHalfChecked && !isLoading) {
    <div
      class="w-5 h-5 border-neutral-2 text-white border rounded-sm hover:bg-neutral-3 flex items-center justify-center"
      inlineSVG="/assets/icons/checkbox.svg"
    ></div>
  }
  @if (isChecked && !isHalfChecked && !isLoading) {
    <div
      class="w-5 h-5 bg-primary border rounded-sm border-primary rounded-sm flex items-center justify-center"
      inlineSVG="/assets/icons/checkbox.svg"
    ></div>
  }
  @if (isHalfChecked && !isLoading) {
    <div
      class="w-5 h-5 bg-primary border rounded-sm border-primary rounded-sm flex items-center justify-center"
      inlineSVG="/assets/icons/icon-checkbox-line.svg"
    ></div>
  }
  @if (isLoading) {
    <div
      class="w-5 h-5 text-primary text-white border rounded-sm border-neutral-2 flex items-center justify-center"
      inlineSVG="/assets/icons/spinner.svg"
      [setSVGAttributes]="{style: 'width:12;height:12;'}"
    ></div>
  }
  @if (label) {
    <div>{{label}}</div>
  }
  <ng-content></ng-content>
</button>
