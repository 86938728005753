import { registerIcon } from "@ui5/webcomponents-base/dist/asset-registries/Icons.js";
const name = "not-editable";
const pathData = "M443 104q5 7 5 12 0 6-5 11L118 453q-4 4-8 4L0 480l22-110q0-5 4-9L352 36q4-4 11-4t11 4zm-34 11l-46-44-64 64 45 45zm-87 88l-46-45L52 381l46 46zm158 116l32 32-66 63 66 66-32 32-63-66-65 66-33-32 66-66-66-63 33-32 65 64z";
const ltr = false;
const accData = null;
const collection = "SAP-icons-v4";
const packageName = "@ui5/webcomponents-icons";
registerIcon(name, {
  pathData,
  ltr,
  collection,
  packageName
});
export default "SAP-icons-v4/not-editable";
export { pathData, ltr, accData };