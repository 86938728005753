import {Component, OnDestroy, OnInit} from '@angular/core';
import {AzureMapsService} from '../../services/azure-maps.service';
import {Subscription} from 'rxjs';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {getPOICategoryTree, PoiGroupMap} from '../../model/poi-category';

@Component({
    selector: 'app-layers-poi',
    templateUrl: './layers-poi.component.html',
    standalone: false
})
export class LayersPoiComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    readonly POICategoryTree = getPOICategoryTree();
    activeCategoriesByGroup: PoiGroupMap = {};
    loadingCategories: number[] = [];

    constructor(private azureMapsService: AzureMapsService, private mapsService: MapboxService) {}

    ngOnInit() {
        this.subscriptions.push(
            this.azureMapsService.activePOICategories$.subscribe(activeCategories => {
                this.activeCategoriesByGroup = {};
                this.POICategoryTree.map(
                    group => ({
                        ...group,
                        catIds: group.categories
                            .map(cat => cat.id)
                            .filter(id => activeCategories.includes(id))
                    }))
                    .forEach(group => this.activeCategoriesByGroup[group.name] = group);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    toggleCategory(toActive: boolean, categoryId: number) {
        this.loadingCategories = [
            ...(this.loadingCategories.filter(it => it !== categoryId)),
            categoryId
        ];
        if (toActive) {
            this.azureMapsService.activatePOICategory(categoryId)
                .finally(() => {
                    this.loadingCategories = this.loadingCategories.filter(it => it !== categoryId);
                    this.mapsService.updatePOISource();
                });
        } else {
            this.azureMapsService.deactivatePOICategory(categoryId)
                .finally(() => {
                    this.loadingCategories = this.loadingCategories.filter(it => it !== categoryId);
                    this.mapsService.updatePOISource();
                }
            );
        }
    }

    toggleGroupCategories(name: string, toActive: boolean) {
        let activeIds = Object.values(this.activeCategoriesByGroup).reduce(
            (result, next) => {
                if (next.catIds?.length) {
                    result.push(...next.catIds);
                }
                return result;
            },
            []
        );
        if (toActive) {
            this.POICategoryTree.find(group => group.name === name)?.categories.forEach(category => {
                if (!activeIds.includes(category.id)) {
                    activeIds.push(category.id);
                }
            });
        } else {
            this.POICategoryTree.find(group => group.name === name)?.categories.forEach(category => {
                activeIds = activeIds.filter(activeId => activeId !== category.id);
            });
        }
        this.azureMapsService.setActivePOICategories(activeIds).finally(() => {
            this.mapsService.updatePOISource();
        });
    }

    getIconUrl(iconName: string) {
        return `/assets/maki-icons/${iconName}.svg`;
    }
}
