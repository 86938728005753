import {Component, HostBinding, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {shareReplay, switchMap, withLatestFrom} from 'rxjs/operators';
import {SearchPreference} from 'api/models/search-preference';
import {SearchResultType} from '../../model/search-result-type';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {DeleteSavedFilterModalComponent} from '../delete-saved-filter-modal/delete-saved-filter-modal.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-saved-filter-list',
    templateUrl: './saved-filter-list.component.html',
    styleUrls: ['./saved-filter-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SavedFilterListComponent implements OnDestroy {
    @HostBinding('class')
    classes = 'flex gap-2';

    @Input() set type(value: SearchResultType) {
        this.type$.next(value);
    }

    type$ = new BehaviorSubject<SearchResultType>(null);

    filters$ = this.type$.pipe(
        switchMap(type => this.apiService.getSearchPreferences(type)),
        shareReplay({bufferSize: 1, refCount: false})
    );

    subscriptions: Subscription[] = [];

    filtersExpanded = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private modalService: VwuiModalService,
        private toast: ToastrService
    ) {
    }

    activateFilter(savedFilter: SearchPreference) {
        const searchResultRoute = this.searchResultRoute(savedFilter.table);
        if (!searchResultRoute) {
            this.toast.error('Deze filteroptie komt uit een vorige versie van VW-Maps en wordt niet meer ondersteund.');
            return;
        }

        this.router.navigate(
            [searchResultRoute],
            {
                relativeTo: this.route,
                queryParamsHandling: 'merge',
                queryParams: {
                    query: savedFilter.query,
                    filter: JSON.stringify(savedFilter.filter),
                    favorite: savedFilter.favorite || undefined
                }
            }
        ).catch(e => {
            console.error('search failed', e);
        });
    }

    openDeleteFilterModal(event: Event, savedFilter: SearchPreference) {
        event.stopPropagation();
        const modalRef = this.modalService.open(DeleteSavedFilterModalComponent, {
            closeOnBackdropClick: false,
            data: {
                filter: savedFilter
            }
        });
        this.subscriptions.push(modalRef.afterClosed.subscribe(async (searchPreference: SearchPreference) => {
            if (searchPreference) {
                try {
                    withLatestFrom(this.apiService.deleteSearchPreference(searchPreference.id));
                    this.toast.success('Filter successvol verwijderd');
                } catch (e) {
                    this.toast.error('Fout bij verwijderen filter');
                }
            }
            // Refresh filters after delete
            this.type$.next(this.type$.value);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private searchResultRoute(type: SearchResultType): string {
        switch (type) {
            case 'company':
                return '/companies/search';
            case 'project':
                return '/projects/search';
            default:
                return null;
        }
    }
}
