"use strict";

import { getCurrentRuntimeIndex as a, compareRuntimes as g } from "./Runtimes.js";
const l = typeof document > "u",
  o = (e, t) => t ? `${e}|${t}` : e,
  f = e => e === void 0 ? !0 : g(a(), parseInt(e)) === 1,
  u = (e, t, n = "", s) => {
    const i = typeof e == "string" ? e : e.content,
      c = a(),
      r = new CSSStyleSheet();
    r.replaceSync(i), r._ui5StyleId = o(t, n), s && (r._ui5RuntimeIndex = c, r._ui5Theme = s), document.adoptedStyleSheets = [...document.adoptedStyleSheets, r];
  },
  y = (e, t, n = "", s) => {
    const i = typeof e == "string" ? e : e.content,
      c = a(),
      r = document.adoptedStyleSheets.find(d => d._ui5StyleId === o(t, n));
    if (r) if (!s) r.replaceSync(i || "");else {
      const d = r._ui5RuntimeIndex;
      (r._ui5Theme !== s || f(d)) && (r.replaceSync(i || ""), r._ui5RuntimeIndex = String(c), r._ui5Theme = s);
    }
  },
  S = (e, t = "") => l ? !0 : !!document.adoptedStyleSheets.find(n => n._ui5StyleId === o(e, t)),
  p = (e, t = "") => {
    document.adoptedStyleSheets = document.adoptedStyleSheets.filter(n => n._ui5StyleId !== o(e, t));
  },
  m = (e, t, n = "", s) => {
    S(t, n) ? y(e, t, n, s) : u(e, t, n, s);
  },
  R = (e, t) => {
    if (e === void 0) return t;
    if (t === void 0) return e;
    const n = typeof t == "string" ? t : t.content;
    return typeof e == "string" ? `${e} ${n}` : {
      content: `${e.content} ${n}`,
      packageName: e.packageName,
      fileName: e.fileName
    };
  };
export { u as createStyle, S as hasStyle, y as updateStyle, p as removeStyle, m as createOrUpdateStyle, R as mergeStyles };
