import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, mergeMap, pluck, switchMap, tap, toArray} from 'rxjs/operators';
import * as atlas from 'azure-maps-rest';
import {BehaviorSubject, from, lastValueFrom, of} from 'rxjs';
import {CustomHttpParamEncoder} from '../utils/custom-http-param-encoder';
import {UserPreferencesService} from './user-preferences.service';
import {getPOICategoryTree, SearchPoiResultCustom} from '../model/poi-category';
import SearchPoiResponse = atlas.Models.SearchPoiResponse;

@Injectable({
    providedIn: 'root'
})
export class AzureMapsService {
    get activePOICategories$() {
        return this.userPreferencesService.asObservable().pipe(
            map(preferences => preferences
                ? {...preferences, poiCategories: preferences.poiCategories || [] as number[]}
                : {poiCategories: [] as number[]}
            ),
            pluck('poiCategories'),
        );
    }

    readonly POICategoryTree = getPOICategoryTree();

    poi = new BehaviorSubject<SearchPoiResultCustom[]>([]);

    constructor(
        private client: HttpClient,
        private userPreferencesService: UserPreferencesService
    ) {}

    getActivePOICategories() {
        return this.userPreferencesService.value.poiCategories || [];
    }

    async activatePOICategory(categoryId: number) {
        const activeCategoryIds = this.getActivePOICategories();
        const poiCategories = [
            ...(activeCategoryIds.filter(it => it !== categoryId)),
            categoryId
        ];
        await lastValueFrom(this.userPreferencesService.patch({poiCategories}));
    }

    async deactivatePOICategory(categoryId: number) {
        const activeCategoryIds = this.getActivePOICategories();
        const poiCategories = activeCategoryIds.filter(it => it !== categoryId);
        await lastValueFrom(this.userPreferencesService.patch({poiCategories}));
    }

    async setActivePOICategories(poiCategories: number[]) {
        await lastValueFrom(this.userPreferencesService.patch({poiCategories}));
    }

    async clearPOICategories() {
        await lastValueFrom(this.userPreferencesService.patch({poiCategories: []}));
    }

    getSearchPOICategory(lat: number, lng: number, offset: number, zoomLevel: number) {
        return this.activePOICategories$.pipe(
            map(catIds => catIds.map(id => this.POICategoryTree
                .reduce((result, next) => [...result, ...next.categories], [])
                .find(it => it.id === id)
            )),
            map(categories => categories
                .filter(category => !!category)
                .filter(category => category.minZoomLevel <= zoomLevel && category.maxZoomLevel >= zoomLevel)
            ),
            switchMap(categories => {
                if (categories.length === 0) {
                    return of([] as SearchPoiResultCustom[]);
                }
                // for each category we have to do a seperate api-call
                // we then merge them all and return one merged SearchPoiResponse-object
                return from(categories).pipe(
                    mergeMap(category => {
                        const params = new HttpParams({
                            encoder: new CustomHttpParamEncoder(),
                            fromObject: {
                                'api-version': '1.0',
                                language: 'nl-NL',
                                'subscription-key': environment.azureApiKey,
                                lat: lat.toString(),
                                lon: lng.toString(),
                                limit: '100',
                                ofs: offset.toString(),
                                categorySet: category.id.toString(),
                                query: category.name
                            }
                        });
                        return this.client.get<SearchPoiResponse>(
                            'https://atlas.microsoft.com/search/poi/category/json',
                            {params}
                        ).pipe(
                            pluck('results'),
                            map(results => results.map(it => ({
                                ...it,
                                icon: category.icon
                            } as SearchPoiResultCustom))),
                        );
                    }),
                    toArray(),
                    map(results => results.reduce(
                        (next, current) => ([...next, ...current]),
                        []
                    )),
                    tap(poi => this.poi.next(poi))
                );
            })
        );
    }
}
