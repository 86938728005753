<button
  class="font-medium text-base mr-auto rounded hover:bg-neutral-4 py-1 hover:bg-neutral-4"
  (click)="onDropdownOpenClick()" type="button"
  cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  {{ titleText}}
  <span class="transform inline-block"
    inlineSVG="/assets/icons/icon-16-dropdown.svg"
    [class.rotate-180]="this.dropdownOpen"
    [class.text-primary]="this.dropdownOpen">
  </span>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpen"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  (overlayOutsideClick)="onOutsideOverlayClick()"
  (detach)="onOutsideOverlayClick()">
  <div class="bg-white border-neutral-3 border rounded z-10">
    <div class="px-2 py-4">
      <div class="text-sm px-4 mb-3 text-neutral-1">Acties</div>
      <button class="hover:bg-neutral-4 rounded px-4 py-2" [disabled]="loading" (click)="onClick()">
        <span>{{ label }}</span>
        @if (loading) {
          <span class="ml-2" inlineSVG="/assets/icons/spinner.svg"></span>
        }
      </button>
    </div>
  </div>
</ng-template>
