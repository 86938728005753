"use strict";

import { getLanguage as a, getFetchDefaultLanguage as g } from "../InitialConfiguration.js";
import { fireLanguageChange as r } from "../locale/languageChange.js";
import { reRenderAllUI5Elements as u } from "../Render.js";
import { DEFAULT_LANGUAGE as o } from "../generated/AssetParameters.js";
import { isBooted as i } from "../Boot.js";
import { attachConfigurationReset as f } from "./ConfigurationReset.js";
let e, n;
f(() => {
  e = void 0, n = void 0;
});
const d = () => (e === void 0 && (e = a()), e),
  s = async t => {
    e !== t && (e = t, await r(t), i() && (await u({
      languageAware: !0
    })));
  },
  m = () => o,
  L = t => {
    n = t;
  },
  c = () => (n === void 0 && (n = g()), n);
export { d as getLanguage, s as setLanguage, m as getDefaultLanguage, L as setFetchDefaultLanguage, c as getFetchDefaultLanguage };
