import {Component, Input} from '@angular/core';
import {Project} from 'api/models/asset/project';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {FavoriteService} from '../../services/favorite.service';
import {UserFavorite} from 'api/models/user-favorite';
import {Company} from 'api/models/asset/company';

@Component({
    selector: 'app-is-favorite',
    templateUrl: './is-favorite.component.html',
    standalone: false
})
export class IsFavoriteComponent {
    @Input() set project(project: Project) {
        this.projectSubject.next(project);
    }
    @Input() set company(company: Company) {
        this.companySubject.next(company);
    }

    projectSubject = new BehaviorSubject<Project>(null);
    companySubject = new BehaviorSubject<Company>(null);

    isFavorite$ = combineLatest([this.projectSubject, this.companySubject, this.favoriteService.favorites$]).pipe(
        map(([project, company, favorites]) => {
            if (project === null && company === null) {
                return false;
            }

            return favorites.some(
                    (favorite: UserFavorite) => project && favorite.type === 'project' && favorite.id === project.id
                ) || favorites.some(
                    (favorite: UserFavorite) => company && favorite.type === 'company' && favorite.id === `${company.kvw_nummer}`
                );
        }),
    );

    constructor(
        private favoriteService: FavoriteService
    ) {}
}
