import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-ui-progress-bar',
    templateUrl: './ui-progress-bar.component.html',
    styleUrls: ['./ui-progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UiProgressBarComponent {
    @Input() progress: number;
}
