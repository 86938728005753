import {Component} from '@angular/core';
import {InformationBarService} from '../../services/information-bar.service';
import {map, shareReplay} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {SearchStateService} from '../../services/search-state.service';
import {SelectableMetadataItemType} from '../../model/metadata';
import {Bim} from 'common/models/asset/bim';
import {Prs} from 'api/models/prs';
import {environment} from '../../../environments/environment';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {View} from 'api/models/trimble/view';
import {ProjectApplication} from 'api/models/asset/project';
import {TrimbleService} from '../../services/trimble.service';
import * as TrimbleWorkspaceAPI from 'trimble-connect-workspace-api';
import {generatePrsCollectionLink} from '../../utils/prs-helper';
import {ProjectAppStoreListItem} from '../project-app-store-list-item/project-app-store-list-item.types';

@Component({
    selector: 'app-overlay-container',
    templateUrl: './overlay-container.component.html',
    standalone: false
})
export class OverlayContainerComponent {
    private trimbleConnectUrl = new RegExp('https:\\/\\/3d\\.(stage\\.)?connect\\.trimble\\.com');

    hideHeader = false;
    showIframe = false;

    api: TrimbleWorkspaceAPI.WorkspaceAPI;

    overlayData$ = this.selectedAssetService.assetMetadataItem$.pipe(map((it) => {
        this.hideHeader = Boolean(it &&
            [
                SelectableMetadataItemType.PRS,
                SelectableMetadataItemType.PRS_COLLECTION
            ].includes(it.type as SelectableMetadataItemType)
        );
        this.showIframe = Boolean(it &&
            [
                SelectableMetadataItemType.BIM,
                SelectableMetadataItemType.RC_INDOOR,
                SelectableMetadataItemType.PRS,
                SelectableMetadataItemType.PRS_COLLECTION,
                SelectableMetadataItemType.PROJECT_APPLICATION,
                SelectableMetadataItemType.TRIMBLE_VIEW,
                SelectableMetadataItemType.VW_SEARCH_IFRAME
            ].includes(it.type as SelectableMetadataItemType)
        );

        if (it && it.type === SelectableMetadataItemType.BIM) {
            this.infoBarService.toggleOverLayState(true);
            const bimItem = (it.item as Bim);
            const url = new URL(bimItem.url);
            const hash = url.hash.substr(1);
            const params = (new URL(hash, url)).searchParams;
            const projectId = params.get('projectId');
            const modelId = params.get('objIds') || params.get('modelId');

            if (!projectId || !modelId) {
                console.warn('Couldn\'t get projectId or modelId from url', url, projectId, modelId);
            }

            return {
                ...it, iframeUrl: this.trimbleConnectUrl.test(bimItem.url)
                    ? this.sanitizer.bypassSecurityTrustResourceUrl(this.absolutifyUrl(bimItem.url))
                    : this.getTrimbleIframeUrl(projectId, modelId)
            };
        } else if (it && it.type === SelectableMetadataItemType.PROJECT_APPLICATION) {
            this.infoBarService.toggleOverLayState(true);

            const projectApplication = it.item as ProjectApplication;
            if (!projectApplication.applicationNumber ) {
                console.warn('Couldn\'t get projectId or');
            }

            return {
                ...it, iframeUrl: this.getTrimbleIframeUrl(projectApplication.applicationNumber, '')
            };
        } else if (it && it.type === SelectableMetadataItemType.TRIMBLE_VIEW) {
            this.infoBarService.toggleOverLayState(true);

            const trimbleView = it.item as View;
            if (!trimbleView.projectId ) {
                console.warn('Couldn\'t get projectId or');
            }
            if (!trimbleView.id ) {
                console.warn('Couldn\'t get viewId or');
            }

            return {
                ...it, iframeUrl: this.getTrimbleIframeViewUrl(trimbleView.projectId, trimbleView.id)
            };
        } else if (it && it.type === SelectableMetadataItemType.RC_INDOOR) {
            this.infoBarService.toggleOverLayState(true);
            return {
                ...it,
                iframeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(this.absolutifyUrl((it.item as Bim).url))
            };
        } else if (it && it.type === SelectableMetadataItemType.PRS) {
            this.infoBarService.toggleInfoBar(true);
            this.mapboxService.collapseMenu$.next(true);
            const prsId = (it.item as Prs).id;
            const prsUrl = prsId ? `${environment.prsBaseUrl}project-references/${prsId}`: `${environment.prsBaseUrl}project-references`;
            window.addEventListener('message', event => {
                const iFrame: HTMLIFrameElement = document.querySelector('.overlay-container__content__iframe');
                const iframeOrigin = iFrame?.src && (new URL(iFrame.src)).origin;
                if (event.origin === iframeOrigin && event.data === 'back') {
                    this.closeOverlay();
                }
            });
            return {
                ...it,
                iframeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(this.absolutifyUrl(prsUrl))
            };
        } else if (it && it.type === SelectableMetadataItemType.PRS_COLLECTION) {
            this.infoBarService.toggleInfoBar(true);
            this.mapboxService.collapseMenu$.next(true);
            const ids = it.item as number[];
            const prsUrl = generatePrsCollectionLink(ids);
            window.addEventListener('message', event => {
                const iFrame: HTMLIFrameElement = document.querySelector('.overlay-container__content__iframe');
                const iframeOrigin = iFrame?.src && (new URL(iFrame.src)).origin;
                if (event.origin === iframeOrigin && event.data === 'back') {
                    this.closeOverlay();
                }
            });
            return {
                ...it,
                iframeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(this.absolutifyUrl(prsUrl))
            };
        } else if (it && it.type === SelectableMetadataItemType.VW_SEARCH_IFRAME) {
            this.infoBarService.toggleOverLayState(true);
            const item = it.item as ProjectAppStoreListItem;
            return {
                ...it,
                iframeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(item.projectAppStoreItemUrl)
            };
        }
        return it;

    }, shareReplay({bufferSize: 1, refCount: true})));

    infoBarClosedState$ = this.infoBarService.informationBarClosed$;

    constructor(
        private infoBarService: InformationBarService,
        private sanitizer: DomSanitizer,
        private selectedAssetService: SearchStateService,
        private mapboxService: MapboxService,
        private trimbleService: TrimbleService
    ) {
    }

    getTrimbleIframeUrl(projectId: string, modelId: string) {
        const params = new URLSearchParams({projectId, modelId});
        return this.sanitizer.bypassSecurityTrustResourceUrl(`https://web.connect.trimble.com/projects/${projectId}/viewer/3d/?${params}`);
    }

    getTrimbleIframeViewUrl(projectId: string, viewId: string) {
        const params = new URLSearchParams({projectId, viewId, embed: 'true'});
        return this.sanitizer.bypassSecurityTrustResourceUrl(`https://web.connect.trimble.com/projects/${projectId}/viewer/3d/?${params}`);
    }

    closeOverlay() {
        this.infoBarService.toggleOverLayState(false);
        this.selectedAssetService.setSelectedItem(null);
        this.mapboxService.collapseMenu$.next(false);
    }

    setTrimbleTokenOnIFrame(metadataType: SelectableMetadataItemType) {
        if(metadataType === SelectableMetadataItemType.TRIMBLE_VIEW ||
            metadataType === SelectableMetadataItemType.PROJECT_APPLICATION) {

            const iFrame: HTMLIFrameElement = document.querySelector('.overlay-container__content__iframe');

            TrimbleWorkspaceAPI.connect(iFrame).then((api) => {
                api.embed.setTokens({ accessToken: this.trimbleService.getAccessToken()});
            });
        }
    }

    private absolutifyUrl(possiblyRelativeUrl: string): string {
        return possiblyRelativeUrl.match(/^[a-z]+:\/\//) ? possiblyRelativeUrl : `https://${possiblyRelativeUrl}`;
    }
}
