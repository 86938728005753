import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import area from '@turf/area';
import {lastValueFrom, Subscription} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {CreateKaartenserviceRequest} from '../../model/create-kaartenservice-request';
import {ApplicationInsightsService} from '../../services/application-insights.service';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import polylabel from 'polylabel';
import {Marker} from 'mapbox-gl';
import {point, polygon} from '@turf/helpers';
import turfDestination from '@turf/destination';
import {TxCenter, TxRectMode} from '../../services/mapbox/mapbox-draw-rotate-scale-rect-mode';
import {AuthService} from '../../services/auth.service';
import {mapboxDrawStyle} from '../../services/mapbox/mapbox-draw-style';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import DrawRectangle from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode';
import {filter, skip, take} from 'rxjs/operators';
import {DrawCustomPolygon} from '../../services/mapbox/custom-draw-modes/mapbox-draw-polygon';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import distance from '@turf/distance';
import explode from '@turf/explode';
import {MapLayer, mapLayers} from '../../model/map-config';
import {LayerInformationModalComponent} from '../../components/layer-information-modal/layer-information-modal.component';
import {VwuiModalService} from '@recognizebv/vwui-angular';

@Component({
    selector: 'app-extensions-map-service',
    templateUrl: './extensions-map-service.component.html',
    standalone: false
})
export class ExtensionsMapServiceComponent implements OnInit, OnDestroy {
    @Output() finished = new EventEmitter();

    private subscriptions: Subscription[] = [];
    private marker: Marker;
    private areaLimit = 1000000;
    private readonly areaValidator: ValidatorFn;

    readonly layers: MapLayer[] = ['bgt', 'buildingLayer', 'lot', 'bestemmingsplan', 'maatvoering']
        .map(key => mapLayers.find(it => it.layerKey === key));

    submitted = false;
    draw: any;
    drawStyles = mapboxDrawStyle;
    form: UntypedFormGroup;
    exporting = false;

    constructor(
        private applicationInsightsService: ApplicationInsightsService,
        private authService: AuthService,
        private apiService: ApiService,
        private mapboxService: MapboxService,
        private modalService: VwuiModalService
    ) {
        this.areaValidator = (formControl) => {
            const value = formControl.value;
            const areaSize = area({
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: value
                },
                properties: {}
            });
            const roundArea = Math.round(areaSize * 10) / 10;
            if (roundArea > this.areaLimit) {
                return {areaLimit: 'Area selection larger than limit'};
            }

            return null;
        };
        this.form = new UntypedFormGroup({
            selectionMode: new UntypedFormControl(''),
            selection: new UntypedFormControl(null, [Validators.required, this.areaValidator]),
            area: new UntypedFormControl(null, [Validators.required]),
            destination: new UntypedFormControl('zip', [Validators.required]),
            email: new UntypedFormControl('', [Validators.email, Validators.required]),
        });
        this.subscriptions.push(this.authService.email$.subscribe(email => {
            const formControl = this.form.get('email');
            if (formControl.value === '') {
                formControl.setValue(email);
            }
        }));

        this.form.get('selectionMode').valueChanges.subscribe(mode => {
            this.form.patchValue({
                selection: null,
                area: null
            });
            const iconVisibility = mode === 'simple' ? 'visible' : 'none';
            this.drawStyles.filter(it => it.id === 'gl-draw-scale-icon' || it.id === 'gl-draw-rotate-icon').map(it => {
                (it.layout as any).visibility = iconVisibility;
            });
        });
    }

    ngOnInit(): void {
        this.mapboxService.setLayerClickDisabled(true);
        this.draw = new MapboxDraw({
            controls: {trash: true},
            displayControlsDefault: false,
            boxSelect: false,
            modes: {
                ...MapboxDraw.modes,
                draw_rectangle: DrawRectangle,
                draw_custom_polygon: DrawCustomPolygon,
                rotate_scale: TxRectMode
            },
            userProperties: true,
            styles: this.drawStyles
        });
        this.mapboxService.map.addControl(this.draw);
        this.applicationInsightsService.trackKaartenserviceOpen();

        this.subscriptions.push(
            this.mapboxService.drawFeatures$.pipe(
                // Skip the first value, as it might contain old features
                // from a previous form submission.
                skip(1)
            ).subscribe(featureCollection => {
                const feature = featureCollection[0] || null;
                this.form.patchValue({
                    selection: feature && feature.geometry && feature.geometry.coordinates || null,
                    area: feature && area(feature) || null
                });
            })
        );
        this.subscriptions.push(
            this.form.controls.destination.valueChanges.subscribe(destination => {
                if (destination === 'zip') {
                    if (this.form.controls.trimbleProject) {
                        this.form.removeControl('trimbleProject');
                    }
                } else {
                    this.form.addControl('trimbleProject', new UntypedFormControl(null, Validators.required));
                }
            })
        );

        this.restoreFormState();

        this.subscriptions.push(
            this.form.valueChanges.subscribe(value => {
                this.saveFormState(value);
            })
        );

        this.mapboxService.map.on('draw.create', this.onMarkerUpdate);
        this.mapboxService.map.on('draw.update', this.onMarkerUpdate);
        this.mapboxService.map.on('draw.delete', this.onMarkerUpdate);

    }

    ngOnDestroy(): void {
        this.mapboxService.setLayerClickDisabled(false);
        this.setDrawingMode('simple_select');
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
        if (this.marker) {
            this.marker.remove();
        }
        this.mapboxService.map.off('draw.create', this.onMarkerUpdate);
        this.mapboxService.map.off('draw.update', this.onMarkerUpdate);
        this.mapboxService.map.off('draw.delete', this.onMarkerUpdate);
        this.mapboxService.map.removeControl(this.draw);
        document.onkeyup = null;
    }

    setDrawingRect() {
        if (this.marker) {
            this.marker.remove();
        }
        this.form.get('selectionMode').setValue('simple');
        const center = this.mapboxService.map.getCenter();
        const rectRadius = 0.250; // in kilometers
        const rectCenter = point([center.lng, center.lat]);
        const topCenter = turfDestination(rectCenter, rectRadius, 0);
        const bottomCenter = turfDestination(rectCenter, rectRadius, 180);

        const rect = polygon([[
            turfDestination(topCenter, rectRadius, 90).geometry.coordinates, // topright
            turfDestination(topCenter, rectRadius, -90).geometry.coordinates, // topleft
            turfDestination(bottomCenter, rectRadius, -90).geometry.coordinates, // bottomleft
            turfDestination(bottomCenter, rectRadius, 90).geometry.coordinates, // bottomright
            turfDestination(topCenter, rectRadius, 90).geometry.coordinates, // toprigth
        ]]);
        rect.id = 'from_state';
        this.setDrawingFeatures({
            type: 'FeatureCollection',
            features: [rect]
        });
        this.onMarkerUpdate();
        this.changeToRotateMode();
    }

    setDrawingPolygon() {
        if (this.marker) {
            this.marker.remove();
        }
        this.form.get('selectionMode').setValue('advanced');
        return this.setDrawingMode('draw_custom_polygon');
    }

    changeToRotateMode() {
        this.draw.changeMode('rotate_scale', {
            featureId: 'from_state',
            canScale: true,
            canRotate: true,
            canTrash: true,
            rotatePivot: TxCenter.Center,
            scaleCenter: TxCenter.Opposite,
            singleRotationPoint: true,
            rotationPointRadius: 1.2,
            canSelectFeatures: false,
        });
    }

    async setDrawingMode(mode: string) {
        if (!this.mapboxService.mapLoaded$.value) {
            await lastValueFrom(this.mapboxService.mapLoaded$.pipe(filter(it => it), take(1)));
        }
        this.draw.deleteAll();
        this.draw.changeMode(mode);
    }

    getDrawingMode(): string {
        return this.mapboxService.mapLoaded$.value ? 'draw_custom_polygon' : 'simple_select';
    }

    setDrawingFeatures(featureCollection: any) {
        this.draw.set(featureCollection);
        this.mapboxService.drawFeatures$.next(this.draw.getAll().features);
    }

    async submitForm() {
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();

        if (this.form && !this.form.valid) {
            return;
        }

        try {
            this.exporting = true;

            let createRequest: CreateKaartenserviceRequest;
            if (this.form.value.destination === 'zip') {
                createRequest = {...this.form.value, fileFormat: 'ifc',};
            } else {
                createRequest = {
                    ...this.form.value,
                    fileFormat: 'ifc',
                    trimbleProject: this.form.value.trimbleProject.id,
                    trimbleProjectName: this.form.value.trimbleProject.name
                };
            }

            await lastValueFrom(this.apiService.postKaartenserviceRequest(createRequest));

            this.submitted = true;

            this.applicationInsightsService.trackKaartenserviceStart();

            this.clearFormState();
        } catch (e) {
            console.error('Create kaartenserviceRequest failed', e);
        } finally {
            this.exporting = false;
        }
    }

    openLayerInfoModal($event, layer: MapLayer) {
        $event.stopPropagation();
        this.modalService.open(LayerInformationModalComponent, {
            data: layer,
            modalClass: 'modal-lg',
            closeOnBackdropClick: false,
        });
    }

    private onMarkerUpdate = () => {
        const [feature] = this.draw.getAll().features;
        if (this.marker) {
            this.marker.remove();
        }

        if (feature) {
            const areaBbox = bboxPolygon(bbox(feature));
            const areaSize = area(areaBbox);
            const areaPoints = explode(areaBbox).features;
            const longestDistance = areaPoints.reduce((longest, areaPoint, index) => {
                if (index === 0) {
                    return 0;
                }
                return Math.max(longest, distance(areaPoint, areaPoints[index - 1], {units: 'meters'}));
            }, 0);

            this.form.get('area').setValue(areaSize);
            if (areaSize > this.areaLimit || longestDistance > 5000) {
                const message = areaSize > this.areaLimit
                    ? 'Maximale oppervlakte is 1km²'
                    : 'Maximale lengte of breedte is 5km';
                this.draw.setFeatureProperty(feature.id, 'size_exceed', true);
                const centerPoint = polylabel(feature.geometry.coordinates, 1.0);
                const el = document.createElement('div');
                el.className = 'extension-map-service__marker';
                el.innerHTML = `<span><i class="extension-map-service__marker__exclamation-mark"></i>${message}</span>`;

                this.marker = new Marker(el)
                    .setLngLat([centerPoint[0], centerPoint[1]])
                    .addTo(this.mapboxService.map);
            } else {
                this.form.patchValue({
                    selection: feature && feature.geometry && feature.geometry.coordinates || null,
                    area: feature && area(feature) || null
                });
                this.draw.setFeatureProperty(feature.id, 'size_exceed', false);
            }
        } else {
            this.form.get('selectionMode').setValue('null');

        }
    };

    private saveFormState(value: any) {
        localStorage.setItem('mapService.formValue', JSON.stringify(value));
    }

    private async restoreFormState() {
        try {
            const formValue = JSON.parse(localStorage.getItem('mapService.formValue'));

            if (formValue) {
                this.form.patchValue(formValue);
                if (formValue.selection) {
                    const state = {
                        type: 'FeatureCollection',
                        features: [{
                            id: 'from_state',
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'Polygon',
                                coordinates: formValue.selection
                            }
                        }]
                    };
                    await this.setDrawingMode('simple_select');
                    this.setDrawingFeatures(state);
                    this.onMarkerUpdate();
                }
                if (formValue.selectionMode === 'simple') {
                    this.changeToRotateMode();
                }
            }
        } catch (error) {
            console.error(error);
            this.clearFormState();
        }
    }

    private clearFormState() {
        localStorage.removeItem('mapService.formValue');
        localStorage.removeItem('mapService.drawingMode');
    }
}
