<div class="px-6" *ngIf="(mapConfig$ | async) as mapConfig">
    <div class="my-8">
        <div class="flex items-center">
            <h1 appTitle="lg" class="m-0">Weergave van de kaart</h1>
            <button class="ml-auto text-neutral-1">
                <span class="mr-1" inlineSVG="/assets/icons/icon-info.svg"></span>
                Hulp nodig?
            </button>
        </div>
        <div class="text-neutral text-xs">{{saveStateFeedback}}</div>
    </div>
    <app-map-style-selector
        [activeMapStyle]="mapConfig.styleName"
        (styleSelected)="selectMapStyle($event)"
    ></app-map-style-selector>
    <h2 appTitle>Points of interests</h2>
    <button routerLink="/layers/poi" class="flex items-center w-full">
        <div class="mr-6" inlineSVG="/assets/layericons/poi.svg"></div>
        <div class="text-left">
            <h3>Points of interests</h3>
            <div class="text-neutral">Zet nuttige plaatsen aan of uit op de kaart.</div>
        </div>
        <div class="ml-auto" inlineSVG="/assets/icons/navigation-arrow-icon.svg"></div>
    </button>
    <div class="flex items-center pt-8 pb-2">
        <h2 appTitle>Additionele kaartlagen</h2>
        <button *ngIf="anyLayerActive$|async" (click)="disableAllLayers(mapConfig)"
                class="ml-auto text-m font-medium text-right text-primary">
            Additionele lagen verbergen
        </button>
    </div>

    <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div class="py-4 border-b border-neutral-3 bg-white" cdkDrag appDragCursor *ngFor="let layer of layers">
            <ng-container *ngIf="mapConfig.layers[layer.layerKey] as mapLayer">
                <div class="flex items-center h-14">
                    <ng-container [ngSwitch]="layer.layerKey">
                        <ng-container *ngSwitchCase="'buildingLayer'">
                            <div *ngIf="mapConfig.layers.buildingLayer as buildingLayer"
                                 class="cursor-pointer"
                                 inlineSVG="/assets/layericons/buildings.svg"
                                 [class.on-0]="buildingLayer.active && buildingLayer.mode === buildingMode.NONE"
                                 [class.on-1]="buildingLayer.active && buildingLayer.mode === buildingMode.ENERGYLABEL"
                                 [class.on-2]="buildingLayer.active && buildingLayer.mode === buildingMode.BOUWJAAR"
                                 (click)="setLayerActive(layer.layerKey, !mapLayer.active)">
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'maatvoering'">
                            <div *ngIf="mapConfig.layers.maatvoering as maatvoeringLayer"
                                 class="cursor-pointer"
                                 inlineSVG="/assets/layericons/maatvoering.svg"
                                 [class.on-0]="maatvoeringLayer.active && maatvoeringLayer.mode === maatvoeringMode.NONE"
                                 [class.on-1]="maatvoeringLayer.active && maatvoeringLayer.mode === maatvoeringMode.EXTRUSION"
                                 (click)="setLayerActive(layer.layerKey, !mapLayer.active)">
                            </div>
                        </ng-container>
                        <div *ngSwitchDefault class="cursor-pointer"
                             [inlineSVG]="'/assets/layericons/' + layer.layerKey + '.svg'"
                             [class.on]="mapLayer.active"
                             (click)="setLayerActive(layer.layerKey, !mapLayer.active)">
                        </div>
                    </ng-container>

                    <div class="ml-6 flex-grow cursor-pointer" (click)="setLayerActive(layer.layerKey, !mapLayer.active)">
                        <h3>{{ layer.title }}
                            <button
                                class="ml-auto mt-1 text-neutral-1 text-sm"
                                (click)="openLayerInfoModal($event, layer)">
                                <span class="mr-1" inlineSVG="/assets/icons/icon-info.svg"></span>
                            </button>
                        </h3>
                        <span class="text-neutral">{{ layer.description }}</span>
                    </div>
                    <ng-container [ngSwitch]="layer.layerKey">
                        <div class="cursor-not-allowed">
                            <input *ngSwitchCase="'vwprojects'"
                                   disabled
                                   class="disabled flex-none vwui-range" aria-label="Layer opacity" type="range" min="0" max="1" step="0.1">
                        </div>
                        <input *ngSwitchDefault class="flex-none vwui-range" aria-label="Layer opacity" type="range" min="0" max="1" step="0.1"
                               [style.--range-position]="(mapLayer.opacity * 100) + '%'"
                               oninput="this.style.setProperty('--range-position',(this.value * 100) +'%')"
                               [class.disabled]="!mapLayer.active"
                               [value]="mapLayer.opacity"
                               (change)="setLayerOpacity(layer.layerKey, +$event.target.value)">
                    </ng-container>


                    <button class="flex-none w-4 h-4 ml-4" aria-label="layer active"
                            (click)="setLayerActive(layer.layerKey, !mapLayer.active)">
                        <img [src]="mapLayer.active ? '/assets/icons/icon-16-eye.svg' : '/assets/icons/icon-16-eye-off.svg'"
                             alt="">
                    </button>
                    <span cdkDragHandle inlineSVG="/assets/icons/icon-16-draganddrop.svg" class="cursor-grabbing ml-4 text-neutral-3 hover:text-primary"></span>

                </div>
                <div class="ml-24"
                     *ngIf="layer.layerKey === 'buildingLayer' && mapLayer.active && mapConfig.layers.buildingLayer as buildingLayer">
                    <div class="mt-3 text-neutral-1 font-medium leading-6">Toon extra informatie</div>
                    <app-radio-button-group
                        class="my-2"
                        [disabled]="(saveState$|async) === saveStates.SAVING"
                        [options]="buildingModeOptions"
                        [selectedValue]="buildingLayer.mode"
                        (selectValue)="setBuildingColorMode($event)"
                    ></app-radio-button-group>
                </div>
                <div class="ml-24"
                     *ngIf="layer.layerKey === 'maatvoering' && mapLayer.active && mapConfig.layers.maatvoering as maatvoeringLayer">
                    <div class="mt-3 text-neutral-1 font-medium leading-6">Toon extra informatie</div>
                    <div class="flex flex-wrap gap-2 my-2">
                        <label class="mode-radio">
                            2D
                            <input type="radio" [value]="maatvoeringMode.NONE" name="maatvoering-mode"
                                   [checked]="maatvoeringLayer.mode === maatvoeringMode.NONE"
                                   (change)="setMaatvoeringMode(+$event.target.value)">
                        </label>
                        <label class="mode-radio">
                            3D
                            <input type="radio" [value]="maatvoeringMode.EXTRUSION" name="maatvoering-mode"
                                   [checked]="maatvoeringLayer.mode === maatvoeringMode.EXTRUSION"
                                   (change)="setMaatvoeringMode(+$event.target.value)">
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

