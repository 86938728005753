import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ProjectAppStoreItem} from 'api/models/asset/project-app-store-item';
import {combineLatest, Observable} from 'rxjs';
import {
    appTypeTranslation,
    DisplayType,
    DisplayTypeSortOrder,
    ProjectAppStoreListItem,
    secondaryTypeTranslation
} from '../project-app-store-list-item/project-app-store-list-item.types';
import {AppStoreItemService} from '../../services/app-store-item.service';
import {map, mergeMap} from 'rxjs/operators';
import {AppStoreItem} from 'api/models/asset/app-store-item';
import {defaultAppStoreListItem} from './project-app-store-list.data';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-project-app-store-list',
    templateUrl: './project-app-store-list.component.html',
    styleUrls: ['./project-app-store-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectAppStoreListComponent implements OnInit {
    @Input() projectId!: string;
    @Input() projectAppStoreItems: Observable<ProjectAppStoreItem[]>;

    projectAppStoreListItems$: Observable<ProjectAppStoreListItem[]>;

    defaultListItem: ProjectAppStoreListItem;

    constructor(
        private appStoreItemService: AppStoreItemService,
    ) {
    }

    ngOnInit() {
        this.projectAppStoreListItems$ = this.projectAppStoreItems.pipe(
            map(projectAppStoreItems => this.prepareProjectAppStoreListItemList(projectAppStoreItems)),
            mergeMap((projectAppStoreListItemList) => combineLatest(projectAppStoreListItemList)),
            map((projectAppStoreListItemList) => this.sortProjectAppStoreListItemList(projectAppStoreListItemList))
        );

        this.defaultListItem = {
            ...defaultAppStoreListItem,
            url: `${environment.projectenPortaalBaseUrl}/projects/${this.projectId}`
        };
    }

    private prepareProjectAppStoreListItemList(list: ProjectAppStoreItem[]): Observable<ProjectAppStoreListItem>[] {
        return list.map((projectAppStoreItem) => {
            return this.appStoreItemService
                .findByProjectAppStoreItem(projectAppStoreItem).pipe(
                    map(appStoreItem => {
                        if (appStoreItem) {
                            return ({
                                ...projectAppStoreItem,
                                ...appStoreItem,
                                subtitle: this.getSubtitleForListItem(appStoreItem),
                                displayType: this.getDisplayTypeForListItem(appStoreItem),
                                sortOrder: DisplayTypeSortOrder[this.getDisplayTypeForListItem(appStoreItem)],
                                projectAppStoreItemUrl: projectAppStoreItem.url
                            });
                        }
                    })
                );
        });
    }

    private sortProjectAppStoreListItemList(list: ProjectAppStoreListItem[]): ProjectAppStoreListItem[] {
        return list.sort((a, b) => {
            return a.sortOrder - b.sortOrder;
        });
    }

    private getSubtitleForListItem(appStoreItem: AppStoreItem): string {
        let subtitle = 'Applicatie';

        if (appStoreItem.type === 'Primary') {
            const appTypeTranslationValue = appTypeTranslation[appStoreItem.type];
            subtitle = appTypeTranslationValue ? appTypeTranslationValue : subtitle;
        } else {
            const secondaryTypeTranslationValue = secondaryTypeTranslation[appStoreItem.secondaryType];
            subtitle = secondaryTypeTranslationValue ? secondaryTypeTranslationValue : subtitle;
        }

        return subtitle;
    }

    private getDisplayTypeForListItem(appStoreItem: AppStoreItem): DisplayType {
        let displayType: DisplayType;

        if (appStoreItem.type === 'Primary') {
            displayType = 'primary';
        } else if (appStoreItem.type === 'Secondary' && appStoreItem.secondaryType === 'Addon') {
            displayType = 'secondary';
        } else {
            displayType = 'other';
        }

        return displayType;
    }
}
