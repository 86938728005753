<div [class.hidden]="isOnMobile" class="fixed-container" id="mapbox-container"></div>

<app-selection-list></app-selection-list>

@if (!isOnMobile) {
  <app-layer-legend></app-layer-legend>
}

<div class="fixed-container pointer-events-none-self">
    <button class="start_feedback_guide_mening absolute right-0 top-1/2 bg-orange px-4 py-3 rounded-t
        text-white font-medium text-base shadow
        transform -rotate-90 origin-center translate-x-1/3">
    Start de tour
  </button>
  <div class="zoom-controls-container">
    <app-zoom-controls></app-zoom-controls>
  </div>
</div>

<app-sidebar class="z-10" [class.hidden]="activatedRoute.firstChild === null && !isOnMobile">
  <router-outlet></router-outlet>
</app-sidebar>
@if (!isOnMobile) {
  <app-navbar class="z-20"></app-navbar>
}
@if (isOnMobile && mobileNavbarEnabled$|async) {
  <app-mobile-navbar></app-mobile-navbar>
}

@if (!isOnMobile) {
  <div class="fixed-container app-container">
    <!--
    Overlay container component to show ERP info or for example Trimble viewer
    -->
    <app-overlay-container></app-overlay-container>
  </div>
}
