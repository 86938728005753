<app-search-result-container [isLoading]="isLoading$|async" [isEmpty]="(totalResults$|async) === 0">
  @if (results$ | async; as searchResponse) {
    <ng-container class="relative">
      @if (searchResponse.project.count > 0) {
        <div class="relative mb-6">
          <div class="mb-4 flex items-center justify-between">
            <div appTitle>Projecten</div>
            <a class="text-primary font-medium"
              routerLink="../projects" queryParamsHandling="preserve">
              Alle {{ searchResponse.project.count }} projecten
            </a>
          </div>
          @if (searchResponse.project.value; as projects) {
            @if (!isOnMobile) {
              <app-project-list [projects]="projects"></app-project-list>
            }
            @if (isOnMobile) {
              <app-project-tile-grid [projects]="projects"></app-project-tile-grid>
            }
          }
        </div>
      }
      @if (searchResponse.company.count > 0) {
        <div class="relative mb-6">
          <div class="mb-4 flex items-center justify-between">
            <div appTitle>Bedrijven</div>
            <a class="text-primary font-medium"
              routerLink="../companies" queryParamsHandling="preserve">
              Alle {{ searchResponse.company.count }} bedrijven
            </a>
          </div>
          @if (!isOnMobile) {
            <app-company-list [items]="searchResponse.company.value"></app-company-list>
          }
          @if (isOnMobile) {
            <app-company-tile-grid [companies]="searchResponse.company.value"></app-company-tile-grid>
          }
        </div>
      }
      @if (searchResponse.verblijfsobject.count > 0) {
        <div class="relative mb-6">
          <div class="mb-4 flex items-center justify-between">
            <div appTitle>Adressen</div>
            <a class="text-primary font-medium"
              routerLink="../adressen" queryParamsHandling="preserve">
              Alle {{ searchResponse.verblijfsobject.count }} adressen
            </a>
          </div>
          <app-verblijfsobject-list [items]="searchResponse.verblijfsobject.value" [addEmptyStateRow]="true">
          </app-verblijfsobject-list>
        </div>
      }
      @if (searchResponse.relation.count > 0) {
        <div class="relative mb-6">
          <div class="mb-4 flex items-center justify-between">
            <div appTitle>Leveranciers</div>
            <a class="text-primary font-medium"
              routerLink="../relations" queryParamsHandling="preserve">
              Alle {{ searchResponse.relation.count }} leveranciers
            </a>
          </div>
          @if (!isOnMobile) {
            <app-relation-list [items]="searchResponse.relation.value"></app-relation-list>
          }
        </div>
      }
      @if (searchResponse.woonplaats.count > 0) {
        <div class="relative mb-6">
          <div class="mb-4 flex items-center justify-between">
            <div appTitle>Plaatsen</div>
            @if (searchResponse.woonplaats.count > 3) {
              <a class="text-primary font-medium"
                routerLink="../plaatsen" queryParamsHandling="preserve">
                Alle {{ searchResponse.woonplaats.count }} plaatsen
              </a>
            }
          </div>
          @if (searchResponse.woonplaats.count > 0) {
            <app-woonplaats-list [items]="searchResponse.woonplaats.value" [addEmptyStateRow]="true">
            </app-woonplaats-list>
          }
        </div>
      }
    </ng-container>
  }
</app-search-result-container>
