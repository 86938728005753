import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchStateService} from '../../services/search-state.service';

@Component({
    selector: 'app-results-view-switch',
    templateUrl: './results-view-switch.component.html',
    standalone: false
})
export class ResultsViewSwitchComponent {
    @Input() titleText: string;
    @Input() hasListView = true;
    @Input() hasGridView = true;

    gridView$ = this.searchStateService.gridView$;

    constructor(private activatedRoute: ActivatedRoute,
                private searchStateService: SearchStateService,
                private router: Router) {
    }

    onOptionClick(viewType: string) {
        this.router.navigate([], {
            queryParams: {view: viewType},
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
        });
    }

}
