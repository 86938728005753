@if (!loading && (lot$ | async); as lot) {
  @if (lot) {
    <div class="text-base font-medium text-dark truncate">
      <div class="ml-6 py-8 w-5 h-5">
        <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
        (click)="goBack()"></span>
        Perceel {{ lot.kadastralegemeentecode}} {{ lot.sectie }} {{ lot.perceelnummer }}
      </div>
    </div>
    <div class="mx-6 mt-10 border-b border-neutral-3">
      <div class="flex pb-5">
        <span class="font-medium text-base">Publieke informatie</span>
      </div>
      <app-collapse collapseTitle="Eigenschappen perceel">
        <div class="flex align-center items-center mt-1">
          <span class="flex-half text-neutral-1 font-medium leading-6">Gemeentcode</span>
          <span>{{ lot.kadastralegemeentecode }}</span>
        </div>
        <div class="flex align-center items-center mt-1">
          <span class="flex-half text-neutral-1 font-medium leading-6">Sectie</span>
          <span>{{ lot.sectie }}</span>
        </div>
        <div class="flex align-center items-center mt-1">
          <span class="flex-half text-neutral-1 font-medium leading-6">Perceelnummer</span>
          <span>{{ lot.perceelnummer }}</span>
        </div>
        <div class="flex align-center items-center mt-1">
          <span class="flex-half text-neutral-1 font-medium leading-6">Oppervlakte</span>
          <span>{{ lot.kadastralegrootte }}  m²</span>
        </div>
        <div class="flex align-center items-center mt-1">
          <span class="flex-half text-neutral-1 font-medium leading-6">Kadastrale gemeente</span>
          <span>{{ lot.kadastralegemeentewaarde }}</span>
        </div>
        <div class="flex align-center items-center mt-1">
          <span class="flex-half text-neutral-1 font-medium leading-6">Laatste wijziging</span>
          <span>{{ lot.begingeldigheid | date }}</span>
        </div>
      </app-collapse>
    </div>
  }
} @else {
  @if (!loading) {
    <div class="text-base font-medium text-dark truncate">
      <div class="ml-6 py-8 w-5 h-5">
        <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
        (click)="goBack()"></span>
        Perceel niet gevonden
      </div>
    </div>
  }
}


@if (loading) {
  <div class="m-6 flex justify-center">
    <vwui-spinner></vwui-spinner>
  </div>
}
