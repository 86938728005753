<div class="flex bg-neutral-4 text-darkBlue p-0.5 rounded-sm">
  @for (option of options; track option) {
    <button
      class="rounded-sm py-0.5 flex-1 text-sm leading-6 text-center"
      [class.cursor-not-allowed]="disabled"
      [ngClass]=" option.value === selectedValue ? 'bg-white' : 'hover:bg-neutral-3'"
      [disabled]="disabled"
      (click)="handleClick(option)"
    >{{option.label}}</button>
  }
</div>
