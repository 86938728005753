@if (modalConfig.data; as projectAppStoreListItem) {
  <vwui-modal-header>
    <app-project-app-store-item-header
      [appTitle]="projectAppStoreListItem.title"
      [appSubtitle]="projectAppStoreListItem.subtitle"
      [appDisplayType]="projectAppStoreListItem.displayType"
    ></app-project-app-store-item-header>
  </vwui-modal-header>
  <vwui-modal-body>
    <div [innerHTML]="sanitizeDescription(projectAppStoreListItem.description)"></div>
  </vwui-modal-body>
  <vwui-modal-footer>
    <vwui-button class="is-light" (click)="close()">Sluiten</vwui-button>
  </vwui-modal-footer>
}
