import {Pipe, PipeTransform} from '@angular/core';
import {verblijfsobjectToAddress} from '../utils/verblijfsobject';

@Pipe({
    name: 'verblijfsobjectAddress',
    standalone: false
})
export class VerblijfsobjectAddressPipe implements PipeTransform {
    transform = verblijfsobjectToAddress;
}
