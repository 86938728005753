import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-search-result-container',
    templateUrl: './search-result-container.component.html',
    standalone: false
})
export class SearchResultContainerComponent {
    @Input() isLoading = false;
    @Input() isEmpty = false;
    @Input() emptyTitle = 'Geen resultaten gevonden';
    @Input() emptyDescription = 'Er zijn geen resultaten gevonden, probeer het opnieuw via de zoekbalk';
}
