<div class="app-legend" [class.app-legend--collapsed]="currentTabIndex === -1">
  <div class="app-legend__tabs">
    @for (tab of tabs; track tab; let tabIndex = $index) {
      <button class="app-legend__tab" (click)="onTabClick(tabIndex)"
        [class.app-legend__tab--active]="tabIndex === currentTabIndex" [class.hidden]="!tab.visible">
        @if (tab.visible) {
          <span
            [inlineSVG]="'/assets/layericons/' + tab.layerIcon + '.svg'"
            [ngClass]="tab.layerIconClasses">
          </span>
        }
      </button>
    }
  </div>
  @for (tab of tabs; track tab; let tabIndex = $index) {
    <div class="app-legend__content"
      [class.hidden]="tabIndex !== currentTabIndex">
      @for (group of tab.groups; track group; let groupIndex = $index; let isFirst = $first; let isLast = $last) {
        <div>
          <div class="app-legend__checkbox mb-5 border-neutral-3"
            [class.pt-4]="!isFirst" [class.mt-4]="!isFirst" [class.border-t]="!isFirst">
            <input [id]="group.label" checked type="checkbox" [style.--color]="'#a6afca'"
              [style.--b]="group.type === 'static' ? '#a6afca': null"
              [checked]="group.selectState !== 'none'"
              [style.--checkbox-scale]="group.selectState === 'none' ? 0: 1"
              [formControl]="getGroupFormControl(tabIndex, groupIndex)"
              [tooltip]="group.selectState === 'none' ? 'Alles selecteren' : 'Alles deselecteren'">
            <label [for]="group.label" class="app-legend__label head">
              @if (group.type !== 'static') {
                <span class="app-legend__checkbox"
                  [style.--checkbox-scale]="group.selectState === 'none' ? 0: 1"
                  [style.--checkbox-height]="group.selectState === 'some' ? '0.5rem' : '0.25rem'"
                [inlineSVG]="group.selectState === 'some' ? '/assets/icons/icon-checkbox-line.svg' : '/assets/icons/icon-check.svg'"></span>
              }
              {{ group.label }}
            </label>
          </div>
          <div class="app-legend__group-items" [style.--rowcount]="ceil(group.items.length / 2)">
            @for (item of group.items; track item) {
              <div class="app-legend__checkbox">
                <input [id]="item.label" checked type="checkbox"
                  [style.--color]="item.color ? item.color : ('url(\'/assets/legend/'+item.pattern+'.png\')')"
                  [style.--b]="group.type === 'static' ? item.color : null"
                  [style.--bc]="item.pattern ? '#000' : null"
                  [formControl]="getFormControl(tabIndex, groupIndex, item.value)">
                <label [for]="item.label" class="app-legend__label parent"
                  [style.--checkbox-height]="'0.25rem'"
                  [style.--stroke]="getCheckboxColor(item.color || '#000')">
                  @if (group.type !== 'static') {
                    <span class="app-legend__checkmark"
                    inlineSVG="/assets/icons/icon-check.svg"></span>
                  }
                  {{ item.label }}
                </label>
              </div>
            }
          </div>
        </div>
      }
    </div>
  }
</div>
