"use strict";

import g from "./EventProvider.js";
import v from "./RenderQueue.js";
import { getAllRegisteredTags as A } from "./CustomElementsRegistry.js";
import { isRtlAware as R } from "./locale/RTLAwareRegistry.js";
const s = new Set(),
  d = new g(),
  n = new v();
let t, a, m, i;
const l = async e => {
    n.add(e), await P();
  },
  c = e => {
    d.fireEvent("beforeComponentRender", e), s.add(e), e._render();
  },
  h = e => {
    n.remove(e), s.delete(e);
  },
  P = async () => {
    i || (i = new Promise(e => {
      window.requestAnimationFrame(() => {
        n.process(c), i = null, e(), m || (m = setTimeout(() => {
          m = void 0, n.isEmpty() && U();
        }, 200));
      });
    })), await i;
  },
  y = () => t || (t = new Promise(e => {
    a = e, window.requestAnimationFrame(() => {
      n.isEmpty() && (t = void 0, e());
    });
  }), t),
  I = () => {
    const e = A().map(r => customElements.whenDefined(r));
    return Promise.all(e);
  },
  f = async () => {
    await I(), await y();
  },
  U = () => {
    n.isEmpty() && a && (a(), a = void 0, t = void 0);
  },
  C = async e => {
    s.forEach(r => {
      const o = r.constructor,
        u = o.getMetadata().getTag(),
        w = R(o),
        p = o.getMetadata().isLanguageAware(),
        E = o.getMetadata().isThemeAware();
      (!e || e.tag === u || e.rtlAware && w || e.languageAware && p || e.themeAware && E) && l(r);
    }), await f();
  },
  T = e => {
    d.attachEvent("beforeComponentRender", e);
  },
  b = e => {
    d.detachEvent("beforeComponentRender", e);
  };
export { l as renderDeferred, c as renderImmediately, h as cancelRender, f as renderFinished, C as reRenderAllUI5Elements, T as attachBeforeComponentRender, b as detachBeforeComponentRender };
