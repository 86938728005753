import {Pipe, PipeTransform} from '@angular/core';
import {AssetType} from '../model/search-result-type';

@Pipe({
    name: 'assetTypeIcon',
    standalone: false
})
export class AssetTypeIconPipe implements PipeTransform {
    transform(type: AssetType): string {
        switch (type) {
            case 'project':
                return '/assets/icons/icon-project.svg';
            case 'company':
                return '/assets/icons/icon-company.svg';
            case 'verblijfsobject':
                return '/assets/icons/icon-24-pin.svg';
            case 'relation':
                return '/assets/icons/icon-company.svg';
            default:
                return '/assets/icons/icon-24-house.svg';
        }
    }
}
