import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ProjectAppStoreListItem} from './project-app-store-list-item.types';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ProjectAppStoreListItemDetailComponent} from '../project-app-store-list-item-detail/project-app-store-list-item-detail.component';
import {SelectableMetadataItem, SelectableMetadataItemType, SelectableMetadataItemTypes} from '../../model/metadata';
import {SearchStateService} from '../../services/search-state.service';

@Component({
    selector: 'app-project-app-store-list-item',
    templateUrl: './project-app-store-list-item.component.html',
    styleUrls: ['./project-app-store-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectAppStoreListItemComponent {
    @Input() projectAppStoreListItem: ProjectAppStoreListItem | undefined;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private modalService: VwuiModalService,
        private searchStateService: SearchStateService,
    ) {
    }

    showMoreInfo(projectAppStoreListItem: ProjectAppStoreListItem) {
        this.modalService.open(
            ProjectAppStoreListItemDetailComponent,
            {
                data: projectAppStoreListItem
            }
        );
    }

    openAppStoreItem(projectAppStoreListItem: ProjectAppStoreListItem) {
        if (projectAppStoreListItem.secondaryType === 'VWSearchIFrame') {
            this.openOverlay(projectAppStoreListItem, SelectableMetadataItemType.VW_SEARCH_IFRAME);
            return;
        }

        switch (projectAppStoreListItem.dtype) {
            case 'TrimbleConnect':
                this.document.defaultView.open(
                    `${projectAppStoreListItem.url}projects/${projectAppStoreListItem.applicationNumber}`,
                    '_blank'
                );
                break;
            default:
                this.document.defaultView.open(projectAppStoreListItem.url, '_blank');
        }
    }

    openOverlay(item, type: SelectableMetadataItemTypes) {
        this.searchStateService.setSelectedItem({
            item,
            type
        } as SelectableMetadataItem);
    }
}
