<app-modal-header>Contact</app-modal-header>
<vwui-modal-body>
  <form [formGroup]="form" (submit)="submit()">
    <div class="mb-4">
      <label class="block font-medium pb-3 mt-2 text-neutral">Soort melding</label>
      <div class="flex gap-3 mobile:flex-col">
        <label class="flex-shrink flex-half px-4 py-3 inline-flex items-center border rounded border-neutral-3">
          <span>Ik heb een vraag</span>
          <input type="radio" class="form-radio border rounded-full text-primary ml-auto w-5 h-5" name="type" value="question"
            formControlName="type">
        </label>
        <label class="flex-shrink flex-half px-4 py-3 inline-flex items-center border rounded border-neutral-3">
          <span>Ik heb een klacht</span>
          <input type="radio" class="form-radio border rounded-full  text-primary ml-auto w-5 h-5" name="type" value="malfunction"
            formControlName="type">
        </label>
      </div>
    </div>
    <div class="mb-4">
      <label for="title-field" class="block font-medium pb-3 mt-2 text-neutral">Titel</label>
      <input #title id="title-field" type="tel" placeholder="Titel" formControlName="title"
        [class.border-red-500]="title.classList.contains('ng-touched') && title.classList.contains('ng-invalid')"
        class="border rounded w-full h-12 px-3 text-darkBlue focus:border-primary"
        >
    </div>
    <div class="mb-4">
      <label for="phone-number-field" class="block font-medium pb-3 mt-2 text-neutral">Telefoonnummer</label>
      <input #phone id="phone-number-field" type="tel" placeholder="0612345678"
        [class.border-red-500]="phone.classList.contains('ng-touched') && phone.classList.contains('ng-invalid')"
        class="border rounded w-full h-12 px-3 text-darkBlue focus:border-primary"
        formControlName="phone">
    </div>
    <div class="mb-4">
      <label for="description-field" class="block font-medium pb-3 mt-2 text-neutral">Beschrijving</label>
      <textarea #description id="description-field" placeholder="Beschrijving"
        [class.border-red-500]="description.classList.contains('ng-touched') && description.classList.contains('ng-invalid')"
        class="border rounded w-full h-12 px-3 text-darkBlue focus:border-primary p-3"
        formControlName="description">
      </textarea>
    </div>
    <div class="mb-4">
      <label for="attachments-field" class="block font-medium pb-3 mt-2 text-neutral">Bijlagen <span
      class="text-neutral">(optioneel)</span></label>
      <input #fileUpload type="file" class="form-input" id="attachments-field" (change)="onFileChange($event)"
        multiple
        [hidden]="true"
        accept="image/jpeg,image/png,image/bmp,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
      <div class="cursor-pointer form-input flex items-center text-sm" (click)="fileUpload.click()"
        [class.ng-invalid]="form.get('attachments').invalid"
        [class.ng-touched]="form.get('attachments').touched">
        <span class="text-primary">Klik hier&nbsp;</span> om een bestand te uploaden
      </div>
      @if (form.get('attachments').errors?.maxFileSize) {
        <div @insertRemoveTrigger class="text-error font-medium mt-2 transition-all duration-500 ease-in opacity-100 text-sm">
          Upload mag maximaal 20 MB zijn.
        </div>
      }
      @if (form.get('attachments').errors?.maxlength) {
        <div @insertRemoveTrigger class="text-error font-medium mt-2 transition-all duration-500 ease-in opacity-100 text-sm">
          Er mogen maximaal 6 bestanden gestuurd worden.
        </div>
      }
    </div>
    <div class="mb-10 grid grid-cols-uploadfilesGrid gap-2">
      @for (item of uploadingFiles; track item) {
        <div class="flex items-center px-4 py-5 border border-neutral-4 shadow">
          <div class="flex items-center px-2 mr-3 flex-shrink-0 uppercase text-white text-sm font-medium"
            [ngStyle]="{backgroundColor: fileColors[item.name.split('.').slice(-1).pop().toUpperCase()]}">
            {{ item.name.split('.').slice(-1).pop() }}
          </div>
          <div class="text-darkBlue font-medium mb-1">
            {{ item.name }}
          </div>
          <span inlineSVG="/assets/icons/spinner.svg" class="w-4 ml-auto"></span>
        </div>
      }
      @for (item of form.get('attachments').value; track item) {
        <div class="flex items-center px-4 py-5 border border-neutral-4">
          <div class="flex items-center px-2 mr-3 flex-shrink-0 uppercase text-white text-sm font-medium"
            [ngStyle]="{backgroundColor: fileColors[item.fileName.split('.').slice(-1).pop().toUpperCase()]}">
            {{ item.fileName.split('.').slice(-1).pop() }}
          </div>
          <div class="text-darkBlue font-medium">
            {{ item.originalFileName }}
          </div>
          <span (click)="removeAttachment(item)" inlineSVG="/assets/icons/icon-24-close.svg"
          class="w-4 ml-auto cursor-pointer"></span>
        </div>
      }
    </div>
  </form>
</vwui-modal-body>
<vwui-modal-footer>
  <button (click)="hide()">Annuleren</button>
  <button
    class="ml-6 py-3 px-6 text-white rounded bg-gradient-to-r from-primary to-primary-light"
    (click)="submit()"
    [class.opacity-50]="form.invalid || uploadPending"
    >
    Verzenden
    <span inlineSVG="/assets/icons/spinner.svg" [hidden]="!loading"></span>
  </button>
</vwui-modal-footer>

