import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-ui-field',
    templateUrl: './ui-field.component.html',
    styleUrls: ['./ui-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UiFieldComponent {
    @Input() fieldLabel!: string;
    @Input() fieldSecondaryLabel?: string;
    @Input() fieldLabelFor?: string;
}
