<button
  type="button"
    class="inline-flex relative h-12 border border-neutral-3 rounded text-primary font-medium items-center shadow-button
            desktop:hover:bg-neutral-4 active:bg-neutral-3 active:shadow-none outline-0 disabled:opacity-75"
  [class.icon-button--loading]="loading"
  [class.cursor-not-allowed]="disabled"
  [ngClass]="iconOnly ? 'px-3' : 'px-6'"
  [disabled]="disabled || loading"
  [tooltip]="tooltip"
  (click)="onClick($event)"
  >
  @if (loading) {
    <span inlineSVG="/assets/icons/spinner.svg"></span>
  } @else {
    @if (icon) {
      <span
        [inlineSVG]="icon"
        [class.mr-4]="!iconOnly"
      ></span>
    }
  }
  @if (!iconOnly) {
    <span class="text-m"><ng-content></ng-content>{{label}}</span>
  }
  @if (badgeCount !== null && badgeCount > 0) {
    <span class="absolute flex items-center content-center -top-1 -right-1 bg-primary rounded-full h-3.5 px-1 text-xxs text-white font-bold"
    >{{badgeCount}}</span>
  }
</button>
