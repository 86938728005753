import {Pipe, PipeTransform} from '@angular/core';
import {ThousandSeparatorPipe} from './thousand-separator.pipe';

@Pipe({
    name: 'formatAttributeValue',
    standalone: false
})
export class AttributeValuePipe implements PipeTransform {
    constructor(private thousandSeperatorPipe: ThousandSeparatorPipe) {
    }

    transform(value: string|string[]|number|null|undefined, valuePrefix: string = '', valueSuffix: string = ''): string|number {
        if (value === null || value === undefined) {
            return '-';
        }

        const prefix = valuePrefix ? `${valuePrefix} ` : '';
        const suffix = valueSuffix ? ` ${valueSuffix}` : '';

        if (Array.isArray(value)) {
            return value.length > 0 ? value.join(', ') : '-';
        } else if (typeof value === 'number') {
            return `${prefix}${this.thousandSeperatorPipe.transform(value)}${suffix}`;
        } else {
            return value !== '' ? `${prefix}${value}${suffix}` : '-';
        }
    }

}
