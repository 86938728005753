<div class="flex items-baseline mt-1 ">
  <div [ngClass]="labelClasses" class="flex-half text-neutral-1 font-medium leading-6">
    {{label}}
  </div>
  <div class="flex-col">
    @for (value of values; track value) {
      <div>{{value}}</div>
    }
  </div>
</div>
