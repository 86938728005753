import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'app-ui-status-label',
    templateUrl: './ui-status-label.component.html',
    styleUrls: ['./ui-status-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UiStatusLabelComponent {
    @Input() status?: string;

    @HostBinding('class')
    get statusClass(): Record<string, boolean> {
        return {
            [`status-${this.status}`]: !!this.status,
        };
    }
}
