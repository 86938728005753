<app-saved-filter-list type="project"></app-saved-filter-list>
@if ((favoriteProjectsPaginator.content$|async)?.length > 0) {
  <div class="mb-7">
    <div class="pb-4 flex items-center">
      <div class="font-medium text-base mr-auto rounded py-1">
        Favoriete projecten
      </div>
      <a [routerLink]="['/projects/search']" [queryParams]="{favorite: true}" queryParamsHandling="merge"
        class="text-primary font-medium ring-2 ring-transparent focus:ring-primary focus:outline-none py-4 rounded">
        Alle favorieten
      </a>
    </div>
    <app-project-tile-grid
      [projects]="favoriteProjectsPaginator.content$|async"
      [threeColumns]="!isOnMobile"
    ></app-project-tile-grid>
  </div>
}
<div class="relative">
  <div class="pb-4 flex items-center">
    @if (!isOnMobile) {
      <app-export-dropdown [total]="totalProjectItems" titleText="Alle Projecten" table="project">
      </app-export-dropdown>
    }
    @if (gridView$|async;) {
      <app-pagination-sort class="ml-auto" [paginator]="allProjectsPaginator"
        [sortOptions]="projectSortOptions" [setSortFromQueryParams]="true" [startOption]="'recent'">
      </app-pagination-sort>
    }
    <app-results-view-switch class="mobile:hidden" [class.ml-auto]="(gridView$|async) === false"></app-results-view-switch>
    <app-pagination class="mobile:hidden" [paginator]="allProjectsPaginator"></app-pagination>
  </div>
  @if (allProjectsPaginator.content$|async; as projects) {
    @if ((gridView$|async) || isOnMobile) {
      <app-project-tile-grid
        [projects]="projects"
        [isLoading]="allProjectsPaginator.isLoading"
      ></app-project-tile-grid>
    } @else {
      <app-project-list [projects]="projects"></app-project-list>
    }
  }

  <div class="mt-4 flex items-end flex-col">
    <app-pagination [paginator]="allProjectsPaginator"></app-pagination>
  </div>

  @if (allProjectsPaginator.isLoading) {
    <span
      class="fixed top-96 left-1/2 transform -translate-x-1/2"
    inlineSVG="/assets/icons/loader.svg"></span>
  }
</div>
