/*!
 * VWUI by Recognize
 */
const VWUIInputMaskAliases = {
  priceEuro: {
    alias: 'numeric',
    allowMinus: true,
    groupSeparator: '.',
    prefix: '€ ',
    digits: 0,
    rightAlign: false,
    onUnMask: (visibleValue, originalValue) => {
      return originalValue.trim() === '' ? '' : visibleValue.replace(/[^0-9-]/g, '');
    },
    onBeforeMask: initialValue => {
      return initialValue.replace(/[^0-9-]/g, '');
    }
  },
  priceDecimalEuro: {
    alias: 'currency',
    allowMinus: true,
    radixPoint: ',',
    prefix: '€ ',
    digits: 2,
    rightAlign: false,
    onUnMask: (visibleValue, originalValue) => {
      return originalValue.trim() === '' ? '' : visibleValue.replace(/[^0-9-,]/g, '').replace(',', '.');
    },
    onBeforeMask: initialValue => {
      return initialValue.replace(/[^0-9-.,]/g, '').replace(',', '.').replace(/[.](?=.*[.])/g, '');
    }
  }
};
export { VWUIInputMaskAliases as V };