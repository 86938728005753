import {Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {lastValueFrom, Observable, of} from 'rxjs';
import {catchError, mapTo, mergeMap} from 'rxjs/operators';
import {Project} from '../model/trimble/project';
import {CreateFolder, Folder} from '../model/trimble/folder';
import {ActivatedRoute} from '@angular/router';
import {isTokenExpired} from '../utils/jwt';
import {View} from '../model/trimble/view';

interface TrimbleTokenResponse {
    openIdRefreshToken: string;
    trimbleAccessToken: string;
}

@Injectable({
    providedIn: 'root'
})
export class TrimbleService {
    static readonly PERMISSION_FOLDER_NAME = '__dia_permission_check';

    constructor(private http: HttpClient, private route: ActivatedRoute) {
    }

    isAuthenticated() {
        const token = localStorage.getItem('trimble.accessToken');
        return token && !isTokenExpired(token);
    }

    getAccessToken() {
        return localStorage.getItem('trimble.accessToken');
    }

    redirectToTrimbleLogin(redirectUrl) {
        localStorage.setItem('trimble.originalUrl', JSON.stringify(this.route.snapshot.queryParams));
        localStorage.setItem('trimble.redirectUrl', JSON.stringify(redirectUrl));

        location.href = environment.trimbleOAuthBaseUrl
            + `/oauth/authorize?response_type=code&scope=${environment.trimbleOAuthScope}`
            + `&client_id=${environment.trimbleOAuthClientId}`
            + `&redirect_uri=${environment.trimbleOAuthRedirectUri}`;
    }

    async exhangeCodeAndStoreJwt(code: string) {
        const url = `${environment.azureBaseUrl}/trimble/jwt?authorizationCode=${code}&clientId=${environment.trimbleOAuthClientId}`;
        const response = await lastValueFrom(this.http.get<TrimbleTokenResponse>(url));

        this.storeTokenResponse(response);
    }

    async tryRefreshAccessToken() {
        const refreshToken = localStorage.getItem('trimble.openIdRefreshToken');
        if (!refreshToken) {
            return;
        }

        try {
            const url = `${environment.azureBaseUrl}/trimble/jwt?refreshToken=${refreshToken}&clientId=${environment.trimbleOAuthClientId}`;
            const response = await lastValueFrom(this.http.get<TrimbleTokenResponse>(url));

            this.storeTokenResponse(response);
        } catch (error) {
            console.error(error);
            this.clearTokens();
        }
    }

    getProjects() {
        return this.http.get<Project[]>(`${environment.trimbleApiBaseUrl}/projects`, {headers: this.headers()});
    }

    getViews(projectId: string) {
        const headers = this.headers();
        return this.http.get<View[]>(`${environment.trimbleApiBaseUrl}/views?projectId=${projectId}`, {
            headers
        });
    }

    getFoldersItems(folderId: string) {
        return this.http.get<Folder[]>(`${environment.trimbleApiBaseUrl}/folders/${folderId}/items`, {headers: this.headers()});
    }

    postFolder(folder: CreateFolder) {
        return this.http.post<Folder>(`${environment.trimbleApiBaseUrl}/folders`, folder, {headers: this.headers()});
    }

    deleteFolder(id: string) {
        return this.http.delete<void>(`${environment.trimbleApiBaseUrl}/folders/${id}`, {headers: this.headers()});
    }

    checkProjectWritable(project: Project): Observable<boolean> {
        return this.postFolder({
            name: TrimbleService.PERMISSION_FOLDER_NAME,
            parentId: project.rootId
        }).pipe(
            mergeMap(folder => this.deleteFolder(folder.id)),
            mapTo(true),
            catchError(error => {
                console.error('project writable check error', error);
                if (error instanceof HttpErrorResponse && error.status === 409) { // folder already exists
                    return this.getFoldersItems(project.rootId).pipe(
                        mergeMap(folders => {
                            const permissionCheckFolder = folders.find(it => it.name === TrimbleService.PERMISSION_FOLDER_NAME);
                            if (permissionCheckFolder) {
                                return this.deleteFolder(permissionCheckFolder.id);
                            }
                        }),
                        mapTo(true),
                        catchError(() => of(true))
                    );
                } else {
                    return of(false);
                }
            })
        );
    }

    private headers() {
        const headers = new HttpHeaders();

        if (!this.isAuthenticated()) {
            return headers;
        }

        return headers.set('Authorization', `Bearer ${localStorage.getItem('trimble.accessToken')}`);
    }

    private clearTokens() {
        localStorage.removeItem('trimble.accessToken');
        localStorage.removeItem('trimble.openIdRefreshToken');
    }

    private storeTokenResponse(response: TrimbleTokenResponse) {
        localStorage.setItem('trimble.accessToken', response.trimbleAccessToken);
        localStorage.setItem('trimble.openIdRefreshToken', response.openIdRefreshToken);
    }
}
