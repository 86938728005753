"use strict";

const y = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    CONTROL: 17,
    ALT: 18,
    BREAK: 19,
    CAPS_LOCK: 20,
    ESCAPE: 27,
    SPACE: 32,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
    PRINT: 44,
    INSERT: 45,
    DELETE: 46,
    DIGIT_0: 48,
    DIGIT_1: 49,
    DIGIT_2: 50,
    DIGIT_3: 51,
    DIGIT_4: 52,
    DIGIT_5: 53,
    DIGIT_6: 54,
    DIGIT_7: 55,
    DIGIT_8: 56,
    DIGIT_9: 57,
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90,
    WINDOWS: 91,
    CONTEXT_MENU: 93,
    TURN_OFF: 94,
    SLEEP: 95,
    NUMPAD_0: 96,
    NUMPAD_1: 97,
    NUMPAD_2: 98,
    NUMPAD_3: 99,
    NUMPAD_4: 100,
    NUMPAD_5: 101,
    NUMPAD_6: 102,
    NUMPAD_7: 103,
    NUMPAD_8: 104,
    NUMPAD_9: 105,
    NUMPAD_ASTERISK: 106,
    NUMPAD_PLUS: 107,
    NUMPAD_MINUS: 109,
    NUMPAD_COMMA: 110,
    NUMPAD_SLASH: 111,
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    F11: 122,
    F12: 123,
    NUM_LOCK: 144,
    SCROLL_LOCK: 145,
    COLON: 186,
    PLUS: 187,
    COMMA: 188,
    SLASH: 189,
    DOT: 190,
    PIPE: 191,
    SEMICOLON: 192,
    MINUS: 219,
    GREAT_ACCENT: 220,
    EQUALS: 221,
    SINGLE_QUOTE: 222,
    BACKSLASH: 226
  },
  b = o => (o.key ? o.key === "Enter" : o.keyCode === y.ENTER) && !a(o),
  d = o => (o.key ? o.key === "Enter" : o.keyCode === y.ENTER) && e(o, !1, !1, !0),
  f = o => e(o, !0, !1, !1),
  i = o => (o.key ? o.key === "Spacebar" || o.key === " " : o.keyCode === y.SPACE) && !a(o),
  A = o => (o.key ? o.key === "Spacebar" || o.key === " " : o.keyCode === y.SPACE) && e(o, !1, !1, !0),
  C = o => (o.key ? o.key === "Spacebar" || o.key === " " : o.keyCode === y.SPACE) && e(o, !0, !1, !1),
  K = o => (o.key ? o.key === "ArrowLeft" || o.key === "Left" : o.keyCode === y.ARROW_LEFT) && !a(o),
  c = o => (o.key ? o.key === "ArrowRight" || o.key === "Right" : o.keyCode === y.ARROW_RIGHT) && !a(o),
  D = o => (o.key ? o.key === "ArrowUp" || o.key === "Up" : o.keyCode === y.ARROW_UP) && !a(o),
  P = o => (o.key ? o.key === "ArrowDown" || o.key === "Down" : o.keyCode === y.ARROW_DOWN) && !a(o),
  R = o => (o.key ? o.key === "ArrowLeft" || o.key === "Left" : o.keyCode === y.ARROW_LEFT) && e(o, !0, !1, !1),
  _ = o => (o.key ? o.key === "ArrowRight" || o.key === "Right" : o.keyCode === y.ARROW_RIGHT) && e(o, !0, !1, !1),
  U = o => (o.key ? o.key === "ArrowUp" || o.key === "Up" : o.keyCode === y.ARROW_UP) && e(o, !0, !1, !1),
  S = o => (o.key ? o.key === "ArrowDown" || o.key === "Down" : o.keyCode === y.ARROW_DOWN) && e(o, !0, !1, !1),
  O = o => (o.key ? o.key === "ArrowUp" || o.key === "Up" : o.keyCode === y.ARROW_UP) && e(o, !1, !1, !0),
  u = o => (o.key ? o.key === "ArrowDown" || o.key === "Down" : o.keyCode === y.ARROW_DOWN) && e(o, !1, !1, !0),
  N = o => (o.key ? o.key === "ArrowUp" || o.key === "Up" : o.keyCode === y.ARROW_UP) && e(o, !1, !0, !1),
  t = o => (o.key ? o.key === "ArrowDown" || o.key === "Down" : o.keyCode === y.ARROW_DOWN) && e(o, !1, !0, !1),
  w = o => (o.key ? o.key === "ArrowLeft" || o.key === "Left" : o.keyCode === y.ARROW_LEFT) && e(o, !1, !1, !0),
  T = o => (o.key ? o.key === "ArrowRight" || o.key === "Right" : o.keyCode === y.ARROW_RIGHT) && e(o, !1, !1, !0),
  h = o => (o.key ? o.key === "ArrowLeft" || o.key === "Left" : o.keyCode === y.ARROW_LEFT) && e(o, !0, !1, !0),
  I = o => (o.key ? o.key === "ArrowRight" || o.key === "Right" : o.keyCode === y.ARROW_RIGHT) && e(o, !0, !1, !0),
  L = o => (o.key ? o.key === "ArrowUp" || o.key === "Up" : o.keyCode === y.ARROW_UP) && e(o, !0, !1, !0),
  p = o => (o.key ? o.key === "ArrowDown" || o.key === "Down" : o.keyCode === y.ARROW_DOWN) && e(o, !0, !1, !0),
  F = o => (o.key ? o.key === "Home" : o.keyCode === y.HOME) && !a(o),
  W = o => (o.key ? o.key === "End" : o.keyCode === y.END) && !a(o),
  M = o => (o.key ? o.key === "Home" : o.keyCode === y.HOME) && e(o, !0, !1, !1),
  G = o => (o.key ? o.key === "Home" : o.keyCode === y.HOME) && e(o, !1, !1, !0),
  g = o => (o.key ? o.key === "End" : o.keyCode === y.END) && e(o, !0, !1, !1),
  n = o => (o.key ? o.key === "End" : o.keyCode === y.END) && e(o, !1, !1, !0),
  H = o => (o.key ? o.key === "Escape" || o.key === "Esc" : o.keyCode === y.ESCAPE) && !a(o),
  B = o => (o.key ? o.key === "Tab" : o.keyCode === y.TAB) && !a(o),
  m = o => (o.key ? o.key === "Tab" : o.keyCode === y.TAB) && e(o, !1, !1, !0),
  x = o => (o.key ? o.key === "Backspace" : o.keyCode === y.BACKSPACE) && !a(o),
  V = o => (o.key ? o.key === "Delete" : o.keyCode === y.DELETE) && !a(o),
  Q = o => (o.key ? o.key === "Delete" : o.keyCode === y.DELETE) && e(o, !1, !1, !0),
  X = o => (o.key ? o.key === "Insert" : o.keyCode === y.DELETE) && e(o, !1, !1, !0),
  J = o => (o.key ? o.key === "Insert" : o.keyCode === y.DELETE) && e(o, !0, !1, !1),
  Y = o => (o.key ? o.key === "PageUp" : o.keyCode === y.PAGE_UP) && !a(o),
  Z = o => (o.key ? o.key === "PageDown" : o.keyCode === y.PAGE_DOWN) && !a(o),
  j = o => (o.key ? o.key === "PageUp" : o.keyCode === y.PAGE_UP) && e(o, !1, !1, !0),
  q = o => (o.key ? o.key === "PageUp" : o.keyCode === y.PAGE_UP) && e(o, !1, !0, !1),
  z = o => (o.key ? o.key === "PageDown" : o.keyCode === y.PAGE_DOWN) && e(o, !1, !1, !0),
  $ = o => (o.key ? o.key === "PageDown" : o.keyCode === y.PAGE_DOWN) && e(o, !1, !0, !1),
  v = o => (o.key ? o.key === "PageUp" : o.keyCode === y.PAGE_UP) && e(o, !0, !1, !0),
  oo = o => (o.key ? o.key === "PageDown" : o.keyCode === y.PAGE_DOWN) && e(o, !0, !1, !0),
  yo = o => (o.key ? o.key === "+" : o.keyCode === y.PLUS) || o.keyCode === y.NUMPAD_PLUS && !a(o),
  eo = o => (o.key ? o.key === "-" : o.keyCode === y.MINUS) || o.keyCode === y.NUMPAD_MINUS && !a(o),
  ao = o => o.key ? s(o) || fo(o) : o.keyCode === y.F4 && !a(o) || o.keyCode === y.ARROW_DOWN && e(o, !1, !0, !1),
  so = o => o.key === "F2" && !a(o),
  s = o => o.key === "F4" && !a(o),
  ko = o => (o.key ? o.key === "F4" : o.keyCode === y.F4) && e(o, !1, !1, !0),
  ro = o => (o.key ? o.key === "F10" : o.keyCode === y.F10) && e(o, !1, !1, !0),
  lo = o => (o.key ? o.key === "F6" : o.keyCode === y.F6) && e(o, !1, !1, !1) || (o.key ? o.key === "ArrowDown" || o.key === "Down" : o.keyCode === y.ARROW_DOWN) && e(o, !0, !0, !1),
  Eo = o => (o.key ? o.key === "F6" : o.keyCode === y.F6) && e(o, !1, !1, !0) || (o.key ? o.key === "ArrowUp" || o.key === "Up" : o.keyCode === y.ARROW_UP) && e(o, !0, !0, !1),
  bo = o => (o.key ? o.key === "F7" : o.keyCode === y.F7) && !a(o),
  fo = o => (o.key === "ArrowDown" || o.key === "Down" || o.key === "ArrowUp" || o.key === "Up") && e(o, !1, !0, !1),
  io = o => o.key === "Shift" || o.keyCode === y.SHIFT,
  Ao = o => (o.key === "A" || o.key === "a" || o.which === y.A) && e(o, !0, !1, !1),
  Co = o => (o.key === "V" || o.key === "v" || o.which === y.V) && e(o, !0, !1, !1),
  Ko = o => (o.key === "A" || o.key === "a" || o.which === y.A) && e(o, !1, !1, !1),
  co = o => (o.key === "P" || o.key === "p" || o.which === y.P) && e(o, !1, !1, !1),
  a = o => o.shiftKey || o.altKey || k(o),
  k = o => !!(o.metaKey || o.ctrlKey),
  e = (o, r, l, E) => o.shiftKey === E && o.altKey === l && k(o) === r,
  Do = o => (o.key ? "0123456789".indexOf(o.key) !== -1 : o.keyCode >= y.DIGIT_0 && o.keyCode <= y.DIGIT_9) && e(o, !1, !1, !1),
  Po = o => (o.key ? o.key === ":" : o.keyCode === y.COLON) && e(o, !1, !1, !0);
export { b as isEnter, d as isEnterShift, f as isCtrl, i as isSpace, A as isSpaceShift, C as isSpaceCtrl, K as isLeft, c as isRight, D as isUp, P as isDown, R as isLeftCtrl, _ as isRightCtrl, U as isUpCtrl, S as isDownCtrl, O as isUpShift, u as isDownShift, N as isUpAlt, t as isDownAlt, w as isLeftShift, T as isRightShift, h as isLeftShiftCtrl, I as isRightShiftCtrl, L as isUpShiftCtrl, p as isDownShiftCtrl, F as isHome, W as isEnd, yo as isPlus, eo as isMinus, M as isHomeCtrl, g as isEndCtrl, G as isHomeShift, n as isEndShift, H as isEscape, B as isTabNext, m as isTabPrevious, x as isBackSpace, V as isDelete, ao as isShow, so as isF2, s as isF4, ko as isF4Shift, ro as isF10Shift, Eo as isF6Previous, lo as isF6Next, bo as isF7, Y as isPageUp, Z as isPageDown, j as isPageUpShift, q as isPageUpAlt, z as isPageDownShift, $ as isPageDownAlt, v as isPageUpShiftCtrl, oo as isPageDownShiftCtrl, io as isShift, Ao as isCtrlA, Co as isCtrlV, Ko as isKeyA, co as isKeyP, Q as isDeleteShift, X as isInsertShift, J as isInsertCtrl, Do as isNumber, Po as isColon };
