import {Component, OnDestroy, OnInit} from '@angular/core';
import {SearchStateService} from '../../services/search-state.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PointResult} from '../../model/search-response';
import {SearchCompany} from '../../model/search-company';
import {SearchProject} from '../../model/search-project';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {ScreenService} from '../../services/screen.service';
import {withLatestFrom} from 'rxjs/operators';

@Component({
    selector: 'app-search-all',
    templateUrl: './search-all.component.html',
    standalone: false
})
export class SearchAllComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    isLoading$ = this.searchStateService.loadingSearchResults$;
    results$ = this.searchStateService.searchResults$;
    totalResults$ = new BehaviorSubject<number>(0);
    isOnMobile = false;

    constructor(
        private searchStateService: SearchStateService,
        private mapboxService: MapboxService,
        private screenService: ScreenService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(this.results$.pipe(
            withLatestFrom(this.screenService.screen$, (results, screen) => ({results, screen})),
        ).subscribe(({results, screen}) => {
            this.totalResults$.next(
                results.company.count
                + results.verblijfsobject.count
                + results.woonplaats.count
                + results.project.count
                + results.relation.count);
            const pointResults: PointResult[] = [];
            pointResults.push(...results.company.value.map((item: SearchCompany) => ({
                ...item, title: item.naam, id: item.kvw_nummer, type: 'company'
            } as PointResult)));
            pointResults.push(...results.project.value.map((item: SearchProject) => ({
                ...item, title: item.naam, id: item.id, type: 'project'
            } as PointResult)));
            this.searchStateService.pointResults$.next(pointResults);
            if (screen === 'desktop') {
                if (results.woonplaats.count === 1) {
                    this.mapboxService.highlightWoonplaats(results.woonplaats.value[0]);
                }
            }
            this.isOnMobile = screen === 'mobile';
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }
}
