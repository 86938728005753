import {Injectable} from '@angular/core';
import {AppRoleAssignmentService} from '../app-role-assignment.service';
import {EMPTY, Observable, of} from 'rxjs';
import {AppRole, AppRoleAssignment, User} from '@microsoft/microsoft-graph-types';

@Injectable()
export class AppRoleAssignmentServiceMock implements AppRoleAssignmentService {
    getAppRoleAssignments(): Observable<AppRoleAssignment[]> {
        return of([
            {
                id: 'roleAssignment1',
                appRoleId: 'superAdmin',
                principalDisplayName: 'Dekker, Stein',
                principalId: 'user1',
                principalType: 'User'
            }
        ]);
    }

    getUserAppRoles(): Observable<AppRole[]> {
        return of([
            {
                id: 'superAdmin',
                displayName: 'Super Admin'
            },
            {
                id: 'trimbleManager',
                displayName: 'Trimble Manager'
            }
        ]);
    }

    searchUsers(term: string): Observable<User[]> {
        return of([
            {
                id: 'user1',
                displayName: 'Dekker, Stein',
            }
        ]);
    }

    createAppRoleAssignment(principalId: string, appRoleId: string): Observable<any> {
        return EMPTY;
    }

    deleteAppRoleAssignment(roleAssignmentId: string): Observable<any> {
        return EMPTY;
    }

}
