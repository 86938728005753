import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-project-view-link',
    templateUrl: './project-view-link.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectViewLinkComponent {
    @Input() label = '';
    @Input() thumbnail = '';
    @Input() clickable = true;
    @Output() clicked = new EventEmitter<void>();

    handleClick() {
        this.clicked.emit();
    }
}
