import {ChangeDetectionStrategy, Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ProjectAppStoreListItem} from '../project-app-store-list-item/project-app-store-list-item.types';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-project-app-store-list-item-detail',
    templateUrl: './project-app-store-list-item-detail.component.html',
    styleUrls: ['./project-app-store-list-item-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectAppStoreListItemDetailComponent {

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<ProjectAppStoreListItem>,
        private sanitizer: DomSanitizer
    ) {}

    close() {
        this.modalRef.close();
    }

    sanitizeDescription(description: string) {
        return this.sanitizer.bypassSecurityTrustHtml(description);
    }
}
