<div class="relative">
  <div
        class="relative flex gap-4 items-center px-4 py-3 border border-neutral-3 h-12 rounded transition-all
            overflow-hidden focus-within:border-primary"
    [class.pl-12]="currentValue || showBackButton" (click)="query.focus()">
    <button type="button" class="absolute text-dark left-4 top-1/2 transition-all transform -translate-y-1/2"
      [class.-translate-x-8]="!currentValue && !showBackButton"
      (click)="clearAndCommit()"
      inlineSVG="/assets/icons/icon-16-arrow-left.svg">
    </button>
    <input #query class="w-100 text-m flex-grow" type="text" [placeholder]="placeholder"
      [formControl]="inputControl" (keydown.enter)="commitValue()"
      (focus)="showSuggestions = true" (blur)="showSuggestions = false">
    @if (loading) {
      <div class="text-primary">
        <span inlineSVG="/assets/icons/spinner.svg" class="w-4 ml-auto"></span>
      </div>
    }
    @if (inputControl.value) {
      <button type="button" class="text-primary"
        (click)="clearAndCommit()"
        inlineSVG="/assets/icons/icon-24-close.svg"
        [setSVGAttributes]="{width: '20', height: '20'}">
      </button>
    }
    <button type="button" [ngClass]="inputControl.value ? 'text-primary' : 'text-neutral-1'"
      (click)="commitValue()"
      inlineSVG="/assets/icons/icon-24-search.svg"
      [setSVGAttributes]="{width: '20', height: '20'}">
    </button>
  </div>

  @if (showSuggestions && inputControl.value && suggestions) {
    <div (mousedown)="$event.preventDefault()"
      class="absolute top-full left-0 right-0 mt-2 pt-2 pb-4 px-4 bg-white border border-neutral-3 rounded z-10 shadow-md"
      >
      @if (!loading && suggestions.length === 0) {
        <div class="mt-2">
          Geen resultaten gevonden.
        </div>
      }
      @for (suggestion of suggestions; track suggestion) {
        <app-search-suggestion
          [searchTerm]="inputControl.value"
          [icon]="type === 'collection' ? '/assets/icons/icon-16-folder.svg' : '/assets/icons/icon-project.svg'"
          [label]="type === 'collection' ? suggestion.name : suggestion.naam"
          [identification]="suggestion.id.toString()"
          [clickable]="suggestionClickable"
          (clickedOnResult)="handleClickOnSuggestion(suggestion)"
        ></app-search-suggestion>
      }
    </div>
  }
</div>

