import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Collection} from 'api/models/collection';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {switchMap} from 'rxjs/operators';
import {fadeInAnimation} from '../../animations/fade.animation';

@Component({
    selector: 'app-collection-projects-icons',
    templateUrl: './collection-projects-icons.component.html',
    animations: [fadeInAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CollectionProjectsIconsComponent {
    @Input() set collection(value: Collection) {
        this.projectIdsSubject.next(value.items);
    }

    projectIdsSubject = new BehaviorSubject<string[]>([]);
    projects$ = this.projectIdsSubject.pipe(
        switchMap(ids => this.apiService.getProjects(ids))
    );

    constructor(protected apiService: ApiService) {}
}
