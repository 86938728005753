"use strict";

const s = new Map(),
  o = new Map(),
  n = new Map(),
  c = e => {
    if (!s.has(e)) {
      const a = p(e.split("-"));
      s.set(e, a);
    }
    return s.get(e);
  },
  l = e => {
    if (!o.has(e)) {
      const a = e.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
      o.set(e, a);
    }
    return o.get(e);
  },
  p = e => e.map((a, t) => t === 0 ? a.toLowerCase() : a.charAt(0).toUpperCase() + a.slice(1).toLowerCase()).join(""),
  b = e => {
    const a = n.get(e);
    if (a) return a;
    const t = c(e),
      r = t.charAt(0).toUpperCase() + t.slice(1);
    return n.set(e, r), r;
  };
export { c as kebabToCamelCase, l as camelToKebabCase, b as kebabToPascalCase };
