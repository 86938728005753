import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Lot} from 'api/models/asset/lot';

@Injectable({
    providedIn: 'root'
})
export class ArcgisService {
    constructor(private http: HttpClient) {
    }

    getLotMetaData(id: string): Observable<Lot> {
        const fields = [
            'identificatie',
            'begingeldigheid',
            'kadastralegemeentecode',
            'kadastralegemeentewaarde',
            'sectie',
            'perceelnummer',
            'kadastralegrootte'
        ];
        return this.http.get(environment.featureServices.baseUrl
            + `/arcgis/rest/services/DKK/DKKv4/FeatureServer/2/query?&objectIds=${id}&outFields=${fields.join(',')}&f=pjson`)
            .pipe(
                map((response: ({ features: { attributes: Lot }[] })) => {
                        return response.features[0]?.attributes;
                    }
                )
            );
    }

    getLotsImportDate(): Observable<string> {
        return this.http.get(environment.featureServices.baseUrl
            + `/arcgis/rest/services/DKK/DKKv4/FeatureServer/0/metadata?`, {
            responseType: 'text'
            }).pipe(
                map((response: string) => {
                    try {
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(response, 'text/xml');
                        const date = xmlDoc.getElementsByTagName('CreaDate')[0].textContent;
                        return date ? `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}` : '-';
                    } catch (e) {
                        return 'niet beschikbaar';
                    }
                })
        );
    }

    getBestemmingsplanImportDate(): Observable<string> {
        return this.http.get(environment.featureServices.baseUrl
            + `/arcgis/rest/services/IMRO/Ruimtelijke_plannen/FeatureServer/6/metadata`, {
            responseType: 'text'
            }).pipe(
                map((response: string) => {
                    try {
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(response, 'text/xml');
                        const date = xmlDoc.getElementsByTagName('CreaDate')[0].textContent;
                        return date ? `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}` : '-';
                    } catch (e) {
                        return 'niet beschikbaar';
                    }
                })
        );
    }

    getNatural2000ImportDate(): Observable<string> {
        return this.http.get(
            'https://services.arcgis.com/kE0BiyvJHb5SwQv7/ArcGIS/rest/services/N2000_dissolve_service/FeatureServer/info/metadata', {
            responseType: 'text'
            }).pipe(
                map((response: string) => {
                    try {
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(response, 'text/xml');
                        const date = xmlDoc.getElementsByTagName('CreaDate')[0].textContent;
                        return date ? `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}` : '-';
                    } catch (e) {
                        return 'niet beschikbaar';
                    }
            })
        );
    }
}
