import {Component, OnInit} from '@angular/core';
import {Faq, QuestionAnswerPair} from '../../model/faq';
import {Observable} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {VwuiModalRef} from '@recognizebv/vwui-angular';

@Component({
    selector: 'app-faq-modal',
    templateUrl: './faq-modal.component.html',
    standalone: false
})
export class FaqModalComponent implements OnInit {

    data$: Observable<Faq> = this.apiService.getFaq();
    activeQuestion: QuestionAnswerPair = null;

    constructor(
        private vwuiModalRef: VwuiModalRef,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.data$.subscribe(faqQuestions => {
            if (faqQuestions) {
                this.activeQuestion = faqQuestions.sections[0].questionAnswerPairs[0];
            }
        });
    }

    close() {
        this.vwuiModalRef.close();
    }

}
