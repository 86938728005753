import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, lastValueFrom, Subscription, throwError} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {catchError, first, map, shareReplay} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import {AppRoleAssignmentService} from '../../services/app-role-assignment.service';
import {User} from '@microsoft/microsoft-graph-types';
import {AuthService} from '../../../services/auth.service';
import {ApiService} from '../../../services/api.service';

@Component({
    selector: 'app-app-authorization-page',
    templateUrl: './app-authorization-page.component.html',
    standalone: false
})
export class AppAuthorizationPageComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    appRoles$ = this.appRoleAssignmentService.getUserAppRoles().pipe(
        catchError(error => {
            if (error instanceof HttpErrorResponse && error.error === 'User does not have kvw numbers') {
                this.userMissingKvwNumber = true;
            }
            return throwError(error);
        }),
        shareReplay(1)
    );
    userMissingKvwNumber = false;
    form: UntypedFormGroup;
    filterControl = new UntypedFormControl('');
    saving = false;
    myCompanies$ = this.apiService.getMyCompanies().pipe(
        map(companies => companies.map(company => `${company.kvw_nummer}: ${company.naam}`).join(', ')),
    );

    constructor(
        @Inject('AppRoleAssignmentService') private appRoleAssignmentService: AppRoleAssignmentService,
        private authService: AuthService,
        private toastService: ToastrService,
        private apiService: ApiService
    ) {
    }

    async ngOnInit() {
        await this.setupForm();
        await this.updateForm();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }

    groupedAppRoleAssignments() {
        return combineLatest([
            this.appRoles$,
            this.appRoleAssignmentService.getAppRoleAssignments()
        ]).pipe(
            map(([appRoles, appRoleAssignments]) => {
                return appRoles.map(appRole => ({
                    ...appRole,
                    users: appRoleAssignments
                        .filter(it => it.appRoleId === appRole.id)
                        .filter(it => it.principalType === 'User')
                        .map(roleAssignment => ({
                            id: roleAssignment.principalId,
                            displayName: roleAssignment.principalDisplayName,
                            appRoleAssignments: [
                                {
                                    id: roleAssignment.id,
                                    appRoleId: appRole.id
                                }
                            ]
                        }) as User)
                }));
            })
        );
    }

    async createAppRoleAssignment(user: User, roleId: string) {
        if (this.saving) {
            return;
        }

        try {
            this.saving = true;
            await lastValueFrom(this.appRoleAssignmentService.createAppRoleAssignment(
                user.id,
                roleId
            ));

            this.toastService.success('De rol is toegewezen aan de gebruiker.');
        } catch (error) {
            console.error('add role assignment error', error);
            this.handleGraphError(error);
        } finally {
            this.saving = false;
        }

        await this.updateForm();
    }

    async deleteAppRoleAssignment(user: User, roleId: string) {
        if (this.saving) {
            return;
        }
        const appRoleAssignmentsForRole = user.appRoleAssignments.filter(it => it.appRoleId === roleId);
        if (appRoleAssignmentsForRole.length !== 1) {
            throw Error(`Expect user with 1 appRoleAssignment for roleId: ${roleId}`);
        }

        try {
            this.saving = true;
            await lastValueFrom(this.appRoleAssignmentService.deleteAppRoleAssignment(
                appRoleAssignmentsForRole[0].id
            ));

            this.toastService.success('De rol is afgenomen van de gebruiker.');
        } catch (error) {
            console.error('delete role assignment error', error);
            this.handleGraphError(error);
        } finally {
            this.saving = false;
        }

        await this.updateForm();
    }

    private async setupForm() {
        const appRoles = await lastValueFrom(this.appRoles$.pipe(first()));

        this.form = new UntypedFormGroup(Object.fromEntries(
            appRoles.map(group => [group.id, new UntypedFormControl([])])
        ));
    }

    private async updateForm() {
        const groupedRoleAssignments = await lastValueFrom(this.groupedAppRoleAssignments().pipe(first()));

        groupedRoleAssignments.forEach(group => {
            this.form.controls[group.id].setValue(group.users);
        });
    }

    private handleGraphError(error) {
        let errorMessage: string;
        if (error instanceof HttpErrorResponse && error.status === 403) {
            errorMessage = 'U heeft niet genoeg rechten.';
        } else {
            errorMessage = 'Er is een onverwachte fout opgetreden.';
        }

        this.toastService.error(errorMessage);
    }
}
