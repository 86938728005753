<table class="w-full rounded-table-neutral-3 text-left">
  <tr class="text-neutral-1">
    @if ((selectionMode$|async) === 'project') {
      <th class="w-12"></th>
    }
    <th class="py-3 px-6"></th>
    @for (column of columns.value; track column) {
      @if (column.enabled) {
        <th class="py-3 whitespace-nowrap">
          <button (click)="sortService.sortBy(column.sort, false)"
            class="-ml-2 py-1 px-2 font-medium hover:bg-neutral-4 inline rounded">{{ column.name }}
            @if (((sortService.currentState$|async) === column.sort || (sortService.currentState$|async) === '-' + column.sort) && column.enabled) {
              <span
                class="inline-block transform" [class.rotate-180]="(sortService.currentSort$|async) === 2"
                [class.invisible]="(sortService.currentSort$|async) === 0"
                [class.visible]="(sortService.currentState$|async) === column.sort || (sortService.currentState$|async) === '-' + column.sort"
              inlineSVG="/assets/icons/icon-arrow-up.svg"></span>
            }
          </button>
        </th>
      }
    }
    <th class="px-6 w-px">
      <button
        class="w-6 h-6 rounded-lg hover:bg-neutral-3"
        (click)="onColumnPickerClick()"
        cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <span inlineSVG="/assets/icons/icon-16-columns.svg"></span>
      </button>
    </th>
  </tr>
  @if (!projects || projects.length === 0) {
    <tr>
      <td class="py-3 px-6 text-center" [attr.colspan]="(selectionMode$|async) === 'project' ? 3 : 2">
        Geen projecten gevonden.
      </td>
    </tr>
  }
  @for (project of projects; track project) {
    <tr class="hover:bg-neutral-4 cursor-pointer group"
      (click)="onItemClicked($event, project, 'project')">
      @if ((selectionMode$|async) === 'project') {
        <td class="py-3 pl-6" (click)="$event.stopPropagation()">
          <div class="flex items-center">
            <app-is-selected [project]="project">
              <div unchecked
                class="cursor-pointer px-1 border-primary text-white border rounded hover:bg-neutral-3"
                (click)="addProjectToSelection($event, (project))">
                <span class="" inlineSVG="/assets/icons/checkbox.svg"></span>
              </div>
              <div checked class="cursor-pointer bg-primary border rounded border-primary rounded"
                (click)="removeProjectFromSelection($event, (project))">
                <span inlineSVG="/assets/icons/checkbox.svg" class="px-1"></span>
              </div>
            </app-is-selected>
          </div>
        </td>
      }
      <td class="py-3 px-6">
        <div class="flex align-center flex-nowrap items-center">
          <div class="relative w-8 h-8 rounded flex-shrink-0 flex items-center justify-center"
            [class.bg-primary]="!(project | projectImage)">
            <!--
            We _should_ be using thumbnail images here, but for unknown reasons suffixing ?size=thumbnail
            no longer works. We use full size images instead, which definitely works, but is obviously
            not ideal in terms of performance and bandwith used.
            -->
            @if (project | projectImage) {
              <img alt=""
                [src]="project | projectImage"
                class="absolute inset-0 w-full object-cover rounded">
            } @else {
              <span class="absolute left-auto right-auto text-white"
                inlineSVG="/assets/icons/icon-project.svg" [setSVGAttributes]="{width: 16, height: 16}">
              </span>
            }
          </div>
          <app-is-favorite [project]="project"></app-is-favorite>
        </div>
      </td>
      @if (columns[0].value.enabled) {
        <td class="relative">
          {{ project.id }}
        </td>
      }
      @if (columns[1].value.enabled) {
        <td class="relative min-w-14">
          {{ hasCommercialProjectName(project) ? project.naam + ", " + project.commercial_project_name : project.naam }}
        </td>
      }
      @if (columns[2].value.enabled) {
        <td class="relative min-w-14">
          {{ project.bedrijf }}
        </td>
      }
      @if (columns[3].value.enabled) {
        <td class="relative min-w-14">
          {{ project.plaats }}
        </td>
      }
      @if (columns[4].value.enabled) {
        <td class="relative min-w-14">
          {{ project.operationele_projectstatus }}
        </td>
      }
      @if (columns[5].value.enabled) {
        <td class="relative min-w-14">
          {{ project.opdrachtgever }}
        </td>
      }
      <td class="relative" (click)="$event.stopPropagation()">
        <app-project-tile-context-menu
          class="absolute top-1/2 transform -translate-y-1/2 right-4"
          [project]="project"
          variant="list"
        ></app-project-tile-context-menu>
      </td>
    </tr>
  }
</table>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpen"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  (overlayOutsideClick)="onOutsideOverlayClick()"
  (detach)="onOutsideOverlayClick()">
  <div class="bg-white border-neutral-3 border rounded z-10 px-2 py-2">
    @for (column of columns.controls; track column) {
      <label
        [formGroup]="column" class="custom-checkbox grey-checkbox px-4 py-2 rounded hover:bg-neutral-4 cursor-pointer select-none">
        <input [id]="'checkbox-' + column.value.name" type="checkbox" formControlName="enabled" (change)="onColumnPickerUpdate()">
        <label [for]="'checkbox-' + column.value.name">
          <span inlineSVG="/assets/icons/icon-check.svg"></span>
          {{column.value.name}}
        </label>
      </label>
    }
  </div>
</ng-template>
