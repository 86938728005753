"use strict";

import { getDefaultFontLoading as t } from "../InitialConfiguration.js";
import { attachConfigurationReset as e } from "./ConfigurationReset.js";
let o;
e(() => {
  o = void 0;
});
const a = () => (o === void 0 && (o = t()), o),
  i = n => {
    o = n;
  };
export { a as getDefaultFontLoading, i as setDefaultFontLoading };
