import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, finalize, map, switchMap, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {Location} from '@angular/common';
import {detailPageGoBack} from '../../utils/navigation';
import {RouterHistoryService} from '../../services/router-history.service';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {MapConfigService} from '../../services/mapbox/map-config.service';
import {Project, projectTypeTranslation} from 'api/models/asset/project';
import {MobileService} from '../../services/mobile.service';
import {lastValueFrom, Subscription} from 'rxjs';
import {Relation} from 'api/models/asset/relation';
import {SearchStateService} from '../../services/search-state.service';

@Component({
    selector: 'app-lots-detail',
    templateUrl: './relations-detail.component.html',
    standalone: false
})
export class RelationsDetailComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    protected readonly projectTypeTranslation = projectTypeTranslation;

    relation$ = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => {
            this.loading = true;
            return this.apiService.getMetadata<Relation>('relation', paramMap.get('id'))
                .pipe(
                    map(response => response.asset),
                );
        }),
        finalize(() => this.loading = false)
    );
    projects$ = this.relation$.pipe(
        switchMap(relation => {
            this.loadingProjects = true;
            return this.apiService.filter<Project>('project', '', '',
                false, '-aangemaakt_op', 0,
                10, {id: relation.projectIds.map(id => id.toString())});
        }),
        finalize(() => this.loadingProjects = false)
    );
    loading = true;
    loadingProjects = true;
    projectsExpanded = true;

    goBack = detailPageGoBack(this.router, this.routerHistory, ['/search']);

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private location: Location,
        private mapboxService: MapboxService,
        private mapConfigService: MapConfigService,
        private router: Router,
        private routerHistory: RouterHistoryService,
        private mobileService: MobileService,
        private searchStateService: SearchStateService
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.mobileService.navbarEnabled$.next(false);
        this.subscriptions.push(
            this.relation$.subscribe((relation: Relation) => {
                this.loadMap().then(() => {
                    if (relation.geog !== null) {
                        this.mapConfigService.updateLayer('buildings', {active: true});
                        this.mapboxService.map.flyTo({
                            center: [relation.geog.coordinates[0], relation.geog.coordinates[1]],
                            zoom: 17,
                            pitch: 70,
                            bearing: 0
                        });
                    }
                    this.searchStateService.pointResults$.next([
                        {id: relation.id, type: relation.type, geog: relation.geog, title: relation.name}
                    ]);
                });
            })
        );
    }

    ngOnDestroy() {
        this.mobileService.navbarEnabled$.next(true);
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
        // Remove additional point results on closing of page
        this.searchStateService.pointResults$.next([]);
    }

    goToProjects(projectIds: number[]) {
        this.router.navigate(
            ['/search/projects'],
            {
                queryParamsHandling: 'merge',
                queryParams: {
                    filter: JSON.stringify({id: projectIds.map(id => id.toString())}),
                }
            }
        ).catch(e => {
            console.error('search failed', e);
        });
    }

    async loadMap() {
        if (!this.mapboxService.mapLoaded$.value) {
            await lastValueFrom(this.mapboxService.mapLoaded$.pipe(filter(it => it), take(1)));
        }
    }

    getIsHoofvestiging(rootName): string {
        return rootName === null ? 'Ja' : `Nee (${rootName})`;
    }
}
