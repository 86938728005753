@if (projectAppStoreListItem) {
  <div class="project-app-store-list-item">
    <app-project-app-store-item-header
      [appTitle]="projectAppStoreListItem.title"
      [appSubtitle]="projectAppStoreListItem.subtitle"
      [appDisplayType]="projectAppStoreListItem.displayType"
    ></app-project-app-store-item-header>
    <div class="project-app-store-list-item__actions">
      <button
        aria-label="Informatie over deze applicatie"
        class="project-app-store-list-item__action"
        (click)="showMoreInfo(projectAppStoreListItem)"
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10ZM12 16v-4M12 8h.01"/>
        </svg>
      </button>
      <button
        aria-label="Open de applicatie"
        class="project-app-store-list-item__action project-app-store-list-item__action--primary"
        [disabled]="!projectAppStoreListItem.url"
        (click)="openAppStoreItem(projectAppStoreListItem)"
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3"/>
        </svg>
      </button>
    </div>
  </div>
}
