"use strict";

import u from "./EventProvider.js";
class d {
  constructor(...t) {}
}
const s = new Map(),
  o = new Map(),
  i = new Map(),
  p = "componentFeatureLoad",
  a = new u(),
  c = e => `${p}_${e}`,
  g = (e, t) => {
    s.set(e, t);
  },
  m = e => s.get(e),
  f = async (e, t) => {
    await Promise.all(t.dependencies?.map(n => n.define()) || []), await t.define?.(), o.set(e, t), E(e);
  },
  F = e => o.get(e),
  b = (e, t, n) => {
    const r = i.get(t);
    r?.includes(e) || (r ? r.push(e) : i.set(t, [e]), a.attachEvent(c(e), n));
  },
  E = e => {
    a.fireEvent(c(e), void 0);
  };
export { g as registerFeature, m as getFeature, f as registerComponentFeature, F as getComponentFeature, b as subscribeForFeatureLoad, d as ComponentFeature };
