import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MapStyle, mapStyles} from '../../services/mapbox/map-styles';
import {fromEvent, Subscription} from 'rxjs';

@Component({
    selector: 'app-map-style-selector',
    templateUrl: './map-style-selector.component.html',
    standalone: false
})
export class MapStyleSelectorComponent implements AfterViewInit, OnDestroy {
    @Input() activeMapStyle: string;
    @Output() styleSelected = new EventEmitter<MapStyle>();
    @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;
    private subscriptions: Subscription[] = [];
    readonly mapStyles = mapStyles;
    showScrollLeft = false;
    showScrollRight = true;

    ngAfterViewInit() {
        this.subscriptions.push(
            fromEvent(this.scrollContainer.nativeElement, 'scroll').subscribe(event => {
                this.renderScrollButtons(event.target as HTMLDivElement);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }

    renderScrollButtons(target: HTMLDivElement) {
        this.showScrollLeft = target.scrollLeft > 0;
        this.showScrollRight = (target.scrollWidth - target.clientWidth) > target.scrollLeft;
    }

    selectStyle(mapStyle: MapStyle) {
        this.styleSelected.emit(mapStyle);
    }

    getStyleSrc(styleName: string) {
        return `/assets/mapicons/${styleName}.jpg`;
    }

    getStyleSrcset(styleName: string) {
        return [
            `/assets/mapicons/${styleName}.jpg 100w`,
            `/assets/mapicons/${styleName}@2x.jpg 200w`,
            `/assets/mapicons/${styleName}@3x.jpg 300w`
        ].join(', ');
    }

    scroll(toRightDirection: boolean) {
        this.scrollContainer.nativeElement.scrollBy({
            left: toRightDirection ? 117 : -117,
            behavior: 'smooth'
        });
    }
}
