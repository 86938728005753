"use strict";

import { DEFAULT_LOCALE as t } from "../generated/AssetParameters.js";
const e = r => {
  if (!r) return t;
  if (r === "zh_HK") return "zh_TW";
  const n = r.lastIndexOf("_");
  return n >= 0 ? r.slice(0, n) : r !== t ? t : "";
};
export default e;
