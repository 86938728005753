<app-icon-button
  icon="/assets/icons/icon-16-filter.svg"
  (buttonPressed)="dropdownOpen = !dropdownOpen"
  [badgeCount]="activeFilterCount$|async">
  {{isOnMobile ? 'Filter' : 'Filteren'}}
</app-icon-button>
@if ((facets$|async); as facets) {
  <div class="absolute top-full -mt-2 inset-x-0 bg-white border border-neutral-3 shadow-overlay rounded z-10 px-6 py-4"
    [class.d-none]="!dropdownOpen" [formGroup]="facets.group"
    [ngClass]="{'overflow-y-auto h-screen pb-40' : isOnMobile}"
    >
    <div class="flex gap-3 items-center mb-1">
      <h2 class="mr-auto">Filteren</h2>
      @if ((activeFilterCount$|async) > 0) {
        <button type="button" class="font-medium text-m text-primary" (click)="clearFilters(facets)">
          Filters verwijderen
        </button>
        <div class="w-px h-full border bg-neutral-3"></div>
        <button type="button" class="font-medium text-m text-primary" (click)="openSaveFilterModal()">
          Filters opslaan
        </button>
      }
    </div>
    <div class="py-4 border-neutral-3 border-b">
      <div class="custom-checkbox">
        <input id="filter-favorites" type="checkbox" [formControl]="favoriteControl">
        <label for="filter-favorites">
          <span inlineSVG="/assets/icons/icon-check.svg"></span>
          Alleen favorieten tonen ({{favoriteCount$|async}})
        </label>
      </div>
      @for (facet of facetsByGroup.Top; track facet; let isLast = $last) {
        <div>
          <div class="mt-3">
            @switch (facet.type) {
              @case ('SIMPLE') {
                <app-search-filter-select-checkbox
                  [formControlName]="facet.key"
                  [options]="facet.options">
                </app-search-filter-select-checkbox>
              }
              @case ('RANGE') {
                <app-search-filter-select-range
                  class="block py-4 border-neutral-3"
                  [group]="$any(facet)"
                  [formControlName]="facet.key"
                ></app-search-filter-select-range>
              }
            }
          </div>
        </div>
      }
    </div>
    @for (group of facetGroups$|async; track group; let isLast = $last) {
      <div class="border-neutral-3"
        [class.border-b]="!isLast">
        <div class="flex items-center cursor-pointer border-b py-4" (click)="toggleFacetGroupCollapsed(group)">
          <div class="flex items-center justify-center w-5 h-5 text-primary transform"
            inlineSVG="/assets/icons/icon-chevron-down.svg" [setSVGAttributes]="{width: 16, height: 16}"
            [class.rotate-180]="!isFacetGroupCollapsed(group)">
          </div>
          <h3 class="ml-4">{{ group }}</h3>
        </div>
        @for (facet of facetsByGroup[group]; track facet; let isLast = $last) {
          <div [class.d-none]="isFacetGroupCollapsed(group)"
            class="pt-4"
            [class.pb-4]="isLast">
            <div class="flex items-center">
              <h3>{{ facetLabel(facet.key) }}</h3>
            </div>
            <div class="mt-1">
              @switch (facet.type) {
                @case ('SIMPLE') {
                  <app-search-filter-select-checkbox
                    [filterKey]="getFacetOptionListFilter(facet.key)"
                    [formControlName]="facet.key"
                    [options]="facet.options"
                    [listOpened]="isFacetOptionListOpenend(facet.key)"
                    (filterKeyChange)="storeFacetOptionListFilter(facet.key, $event)"
                    (listOpenedChange)="storeFacetOptionListOpened(facet.key, $event)"
                  ></app-search-filter-select-checkbox>
                }
                @case ('RANGE') {
                  <app-search-filter-select-range
                    class="block w-1/2"
                    [group]="$any(facet)"
                    [formControlName]="facet.key"
                  ></app-search-filter-select-range>
                }
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
}
